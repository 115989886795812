<template>
  <div>
    <van-popup v-model="show" class="custom-popup">
      <div v-if="step == 1">
        <h1>Change Your Email Address</h1>
        <div class="text-center py-3 my-4">
          <div class="text-muted">Your current email address is</div>
          <h3>{{ params.email }}</h3>
        </div>
        <div class="px-3">
          <Input
              :params="formData"
              :errors="errors"
              field="email"
              label="New email address"
              type="email"
              placeholder="Enter your new email"
          />
          <div class="d-flex justify-content-end mt-4 pt-2">
            <button @click="show = false" class="btn btn-cancel mr-4">Cancel</button>
            <button @click="getCode()" class="btn btn-primary">Next</button>
          </div>
        </div>
      </div>

      <div v-if="step == 2">
        <h1>Enter Verification Code</h1>
        <div class="text-center pt-3 my-4">
          <div class="text-muted">A code has been sent to</div>
          <h3>{{ formData.email }}</h3>
        </div>
        <div class="text-center">
          <div v-if="errors.code" class="text-danger mb-3">{{ errors.code }}</div>
          <div class="d-flex justify-content-center">
            <otp-input
                :input-classes="errors.code ? 'code error' :  'code'"
                :num-inputs="6"
                :should-auto-focus="true"
                @on-complete="handleOnComplete"
            />
          </div>
          <div class="d-flex justify-content-center mt-4">
            <div class="text-muted">Didn't received?</div>
            <div @click="getCode()" class="text-bold ml-2 pointer"> Send Again</div>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-4">
          <button @click="step = 1" class="btn btn-cancel mr-4">Back</button>
          <button @click="enterCode()" class="btn btn-primary">Verify</button>
        </div>
      </div>

    </van-popup>

    <alert-popup message="Your new email address is" :data="params.email" :autoCloseTime="2000" ref="emailAlert"/>
  </div>
</template>

<script>
import {Toast} from 'vant'

export default {
  props: ['params'],
  data() {
    return {
      show: false,
      step: 1,
      formData: {
        email: null,
        code: null,
      },
      errors: {}
    }
  },
  methods: {
    async getCode() {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        duration: 0,
      })
      let res = await axios.post('change/email', {email: this.formData.email})
      if (res) {
        if (res.data.code == 422) {
          this.errors = res.data.error

        } else {
          this.step = 2
        }
        Toast.clear()
      }
    },
    async enterCode() {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        duration: 0,
      })
      let res = await axios.post('change/email/enter_code', this.formData)
      if (res.data.success) {
        this.show = false
        this.params.email = this.formData.email
        this.$refs.emailAlert.open()
        this.$auth.fetch({url: 'auth/user', method: 'get', redirect: false})
      } else {
        this.errors = {code: 'Your code is wrong. Please try again.'}
      }
      Toast.clear()

    },
    open() {
      this.formData = {
        email: null,
        code: null,
      }
      this.errors = {}
      this.step = 1
      this.show = true
    },
    handleOnComplete(value) {
      this.formData.code = value
    },
  }
}
</script>

<style lang="scss" scoped>
.custom-popup {
  width: 480px;
  border-radius: 10px;
  padding: 28px;
  font-size: 13px;
}

h3 {
  margin-top: 5px;
  font-size: 13px;
  font-weight: 600;
}

.text-muted {
  color: rgba(0, 0, 0, 0.5);
}

.btn-primary {
  font-weight: 400;
  padding: 0 45px;
  height: 40px;

  &:hover {
    font-weight: 400;
    height: 40px;
  }
}

.btn-cancel {
  font-weight: 400;
  padding-right: 0 20px;
}

</style>

<style lang="scss">
.code {
  border: none;
  border-bottom: 3px solid black;
  border-radius: 0;
  width: 40px;
  text-align: center;
  font-size: 28px;
  margin: 8px;

  &.error {
    border-color: var(--danger);
    color: var(--danger)
  }
}
</style>