<template>
  <div class="pm-left bg-white">
    <van-divider class="mb-0 mt-0" content-position="left">
      <small>{{ $t('mngmt.inventory') }}</small>
    </van-divider>

    <!--  -->
    <router-link to="/management/products/list">
      <div
          :class="linkIsActive('/management/products/') ? 'nav-left-items-active' : ''"
          class="col-11 nav-left-items mt-2"
          pos-tooltip-step="14"
          @click="$events.fire('nextStep')"
      >
        <div class="d-flex nav-left-items-container">
          <div class="pm-all-products-icons mr-3"></div>
          <p class="pt-1 text-dark pb-0 mb-0">{{ $t('mngmt.products') }}</p>
        </div>
      </div>
    </router-link>

    <router-link to="/management/categories/list">
      <div
          :class="linkIsActive('/management/categories/') ? 'nav-left-items-active' : ''"
          class="col-11 nav-left-items mt-2"
      >
        <div class="d-flex nav-left-items-container">
          <div class="pm-categories-icons mr-3"></div>
          <p class="pt-1 text-dark pb-0 mb-0">{{ $t('mngmt.categories') }}</p>
        </div>
      </div>
    </router-link>

    <!-- TODO: hide for first release
    <router-link v-if="user_type === 'admin' || user_type === 'sub_admin'" to="/management/coupons/list">
      <div
        class="col-11 nav-left-items mt-2"
        :class="linkIsActive('/management/coupons/') ? 'nav-left-items-active' : ''"
      >
        <div class="d-flex nav-left-items-container">
          <van-icon name="coupon-o" size="25" class="mr-3" color="black" />
          <p class="pt-1 text-dark pb-0 mb-0">{{ $t('mngmt.coupons') }}</p>
        </div>
      </div>
    </router-link>
    -->

    <van-divider class="mb-0 mt-0" content-position="left">
      <small>{{ $t('mngmt.contacts') }}</small>
    </van-divider>

    <router-link v-if="user_type === 'admin'" to="/management/admins/list">
      <div
          :class="linkIsActive('/management/admins/') ? 'nav-left-items-active' : ''"
          class="col-11 nav-left-items mt-2"
      >
        <div class="d-flex nav-left-items-container">
          <div class="pm-all-admins-icons mr-3"></div>
          <p class="pt-1 text-dark pb-0 mb-0">{{ $t('mngmt.admins') }}</p>
        </div>
      </div>
    </router-link>

    <router-link to="/management/suppliers/list">
      <div
          :class="linkIsActive('/management/suppliers/') ? 'nav-left-items-active' : ''"
          class="col-11 nav-left-items mt-2"
      >
        <div class="d-flex nav-left-items-container">
          <div class="pm-all-suppliers-icons mr-3"></div>
          <p class="pt-1 text-dark pb-0 mb-0">{{ $t('mngmt.suppliers') }}</p>
        </div>
      </div>
    </router-link>

    <router-link to="/management/customers/list">
      <div
          :class="linkIsActive('/management/customers/') ? 'nav-left-items-active' : ''"
          class="col-11 nav-left-items mt-2"
      >
        <div class="d-flex nav-left-items-container">
          <div class="pm-all-customers-icons mr-3"></div>
          <p class="pt-1 text-dark pb-0 mb-0">{{ $t('mngmt.customers') }}</p>
        </div>
      </div>
    </router-link>

    <router-link v-if="user_type === 'admin' || user_type === 'sub_admin'" to="/management/employees/list">
      <div
          :class="linkIsActive('/management/employees/') ? 'nav-left-items-active' : ''"
          class="col-11 nav-left-items mt-2"
      >
        <div class="d-flex nav-left-items-container">
          <svg class="mr-3" fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.0799 8.58003V15.42C21.0799 16.54 20.4799 17.58 19.5099 18.15L13.5699 21.58C12.5999 22.14 11.3999 22.14 10.4199 21.58L4.47991 18.15C3.50991 17.59 2.90991 16.55 2.90991 15.42V8.58003C2.90991 7.46003 3.50991 6.41999 4.47991 5.84999L10.4199 2.42C11.3899 1.86 12.5899 1.86 13.5699 2.42L19.5099 5.84999C20.4799 6.41999 21.0799 7.45003 21.0799 8.58003Z"
                stroke="#292D32"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
            <path
                d="M11.9999 11.0001C13.2867 11.0001 14.3299 9.95687 14.3299 8.67004C14.3299 7.38322 13.2867 6.34009 11.9999 6.34009C10.7131 6.34009 9.66992 7.38322 9.66992 8.67004C9.66992 9.95687 10.7131 11.0001 11.9999 11.0001Z"
                stroke="#292D32"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
            <path
                d="M16 16.6601C16 14.8601 14.21 13.4001 12 13.4001C9.79 13.4001 8 14.8601 8 16.6601"
                stroke="#292D32"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
          </svg>
          <p class="pt-1 text-dark pb-0 mb-0">{{ $t('mngmt.employees') }}</p>
        </div>
      </div>
    </router-link>
    <router-link to="/management/referrals/">
      <div
          :class="linkIsActive('/management/referrals/') ? 'nav-left-items-active' : ''"
          class="col-11 nav-left-items mt-2"
      >
        <div class="d-flex nav-left-items-container">
          <div class="pm-referral-icons mr-3"></div>
          <p class="pt-1 text-dark pb-0 mb-0">{{ $t('mngmt.referrals') }}</p>
        </div>
      </div>
    </router-link>

    <van-divider v-if="user_type === 'admin' || user_type === 'sub_admin'" class="mb-0 mt-0" content-position="left">
      <small>{{ $t('mngmt.finance') }}</small>
    </van-divider>

    <router-link v-if="user_type === 'admin' || user_type === 'sub_admin'" to="/management/payment-voucher/list">
      <div
          :class="linkIsActive('/management/payment-voucher') ? 'nav-left-items-active' : ''"
          class="col-11 nav-left-items mt-2"
      >
        <div class="d-flex nav-left-items-container">
          <svg class="mr-3" fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20 7.04V16.96C20 18.48 19.86 19.56 19.5 20.33C19.5 20.34 19.49 20.36 19.48 20.37C19.26 20.65 18.97 20.79 18.63 20.79C18.1 20.79 17.46 20.44 16.77 19.7C15.95 18.82 14.69 18.89 13.97 19.85L12.96 21.19C12.56 21.73 12.03 22 11.5 22C10.97 22 10.44 21.73 10.04 21.19L9.02002 19.84C8.31002 18.89 7.05999 18.82 6.23999 19.69L6.22998 19.7C5.09998 20.91 4.10002 21.09 3.52002 20.37C3.51002 20.36 3.5 20.34 3.5 20.33C3.14 19.56 3 18.48 3 16.96V7.04C3 5.52 3.14 4.44 3.5 3.67C3.5 3.66 3.50002 3.65 3.52002 3.64C4.09002 2.91 5.09998 3.09 6.22998 4.3L6.23999 4.31C7.05999 5.18 8.31002 5.11 9.02002 4.16L10.04 2.81C10.44 2.27 10.97 2 11.5 2C12.03 2 12.56 2.27 12.96 2.81L13.97 4.15C14.69 5.11 15.95 5.18 16.77 4.3C17.46 3.56 18.1 3.21 18.63 3.21C18.97 3.21 19.26 3.36 19.48 3.64C19.5 3.65 19.5 3.66 19.5 3.67C19.86 4.44 20 5.52 20 7.04Z"
                stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path d="M8 10.25H16" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path d="M8 13.75H14" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          </svg>
          <p class="pt-1 text-dark pb-0 mb-0">{{ $t('mngmt.paymentVoucher') }}</p>
        </div>
      </div>
    </router-link>

    <router-link v-if="user_type === 'admin' || user_type === 'sub_admin'" to="/management/bank-accounts/list">
      <div
          :class="linkIsActive('/management/bank-accounts') ? 'nav-left-items-active' : ''"
          class="col-11 nav-left-items mt-2"
          pos-tooltip-step="10"
          @click="$events.fire('nextStep')"
      >
        <div class="d-flex nav-left-items-container">
          <div class="pm-bank-accounts-icons mr-3"></div>
          <p class="pt-1 text-dark pb-0 mb-0">{{ $t('mngmt.accounts') }}</p>
        </div>
      </div>
    </router-link>

    <router-link v-if="user_type === 'admin' || user_type === 'sub_admin'" to="/management/payrolls/list">
      <div
          :class="linkIsActive('/management/payrolls') ? 'nav-left-items-active' : ''"
          class="col-11 nav-left-items mt-2"
      >
        <div class="d-flex nav-left-items-container">
          <svg class="mr-3" fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.73 19.7C7.55 18.82 8.8 18.89 9.52 19.85L10.53 21.2C11.34 22.27 12.65 22.27 13.46 21.2L14.47 19.85C15.19 18.89 16.44 18.82 17.26 19.7C19.04 21.6 20.49 20.97 20.49 18.31V7.04C20.5 3.01 19.56 2 15.78 2H8.22C4.44 2 3.5 3.01 3.5 7.04V18.3C3.5 20.97 4.96 21.59 6.73 19.7Z"
                stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path d="M8.09607 11H8.10505" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="2"/>
            <path d="M10.8984 11H16.3984" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="1.5"/>
            <path d="M8.09607 7H8.10505" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="2"/>
            <path d="M10.8984 7H16.3984" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="1.5"/>
          </svg>
          <p class="pt-1 text-dark pb-0 mb-0">{{ $t('mngmt.payroll') }}</p>
        </div>
      </div>
    </router-link>

    <!--TODO: hide for first release

    <van-divider class="mb-0 mt-0" content-position="left">
      <small>{{ $t('mngmt.accounting') }}</small>
    </van-divider> -->

    <!-- <router-link to="/management/coa/list">
      <div class="col-11 nav-left-items mt-2" :class="linkIsActive('/management/coa') ? 'nav-left-items-active' : ''">
        <div class="d-flex nav-left-items-container">
          <van-icon name="description" size="25" class="mr-3" color="black" />
          <p class="pt-1 text-dark pb-0 mb-0">{{ $t('mngmt.chartOfAccount') }}</p>
        </div>
      </div>
    </router-link>

    <router-link to="/management/gl_formula/list">
      <div
        class="col-11 nav-left-items mt-2"
        :class="linkIsActive('/management/gl_formula') ? 'nav-left-items-active' : ''"
      >
        <div class="d-flex nav-left-items-container">
          <van-icon name="free-postage" size="25" class="mr-3" color="black" />
          <p class="pt-1 text-dark pb-0 mb-0">{{ $t('mngmt.glFormula') }}</p>
        </div>
      </div>
    </router-link> -->
  </div>
</template>
<script>
export default {
  methods: {
    linkIsActive(link) {
      const paths = Array.isArray(link) ? link : [link]
      let res = paths.some((path) => {
        // current path starts with this path string
        return this.$route.path.indexOf(path) === 0
      })
      if (res) {
        return true
      }
      return false
    },
  },
  computed: {
    user_type: function () {
      return this.$auth.user().user_type
    },
  },
}
</script>
<style scoped>
.pm-left {
  min-width: 250px;
  max-width: 250px;
  border-radius: 10px;
  padding: 10px 0px;
  margin-right: 15px;
}

.nav-left-items {
  height: 45px;
  margin: auto;
  position: relative;
  cursor: pointer;
}

.nav-left-items-container {
  position: absolute;
  top: 21%;
}

.nav-left-items-active,
.nav-left-items:hover {
  background: rgba(var(--theme-rgba), 0.07);
  border-radius: 10px;
}

.pm-all-products-icons {
  height: 25px;
  width: 25px;
  background-image: url('/image/dashboard-pm/box.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.pm-categories-icons {
  height: 25px;
  width: 25px;
  background-image: url('/image/dashboard-pm/categories-icon.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.pm-discounts-icons {
  height: 25px;
  width: 25px;
  background-image: url('/image/dashboard-pm/discount-shape.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.pm-bank-accounts-icons {
  height: 25px;
  width: 25px;
  background-image: url('/image/settings/bank-accounts.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.pm-all-suppliers-icons {
  height: 25px;
  width: 25px;
  background-image: url('/image/settings/suppliers.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.pm-all-admins-icons {
  height: 25px;
  width: 25px;
  background-image: url('/image/settings/admins.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.pm-all-customers-icons {
  height: 25px;
  width: 25px;
  background-image: url('/image/settings/customers.svg');
  background-repeat: no-repeat;
  background-size: contain;
}


.pm-referral-icons {
  height: 25px;
  width: 25px;
  background-image: url('/image/settings/referral.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
