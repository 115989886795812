<template>
  <div class="p-4">
    <div class="d-flex justify-content-between my-2">
      <custom-search v-model="params.keyword" placeholder="Search by invoice number" @search="search()"/>
      <MyDateRangePicker :params="dateParams" @select="selectDate"/>
    </div>
    <table class="table my-border-table">
      <thead>
      <tr>
        <th>Product Name</th>
        <th>Invoice Number</th>
        <th class="text-center">Quantity</th>
        <th class="text-center">Unit Price</th>
        <th class="text-center">Unit Sale Price</th>
        <th class="text-center">Total Sale Price</th>
        <th>Created at</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(report,index) in reports" :key="index" @click="$refs.inventoryDetail.open(report.id)">
        <td class="name-container">
          <div class="img-container">
            <img :src="report.images[0]"/>
          </div>
          <div>{{ report.name }}</div>
        </td>
        <td><u>{{ report.invoice_number }}</u></td>
        <td class="text-center">{{ report.quantity }}</td>
        <td class="text-center">{{ report.unit_price }}</td>
        <td class="text-center">{{ report.unit_sale_price }}</td>
        <td class="text-center">{{ report.total_sale_price }}</td>
        <td>{{ moment(report.created_at).format('YYYY-MM-DD') }}</td>
      </tr>
      </tbody>
    </table>
    <b-pagination
        v-if="can_load_more || total > params.limit"
        :per-page="params.limit"
        :total-rows="total"
        :value="params.page"
        align="center"
        class="mt-4"
        first-number
        last-number
        pills
        @change="changePage"
    ></b-pagination>
    <InventoryDetail ref="inventoryDetail"/>
  </div>
</template>

<script>
import moment from 'moment'
import InventoryDetail from './InventoryDetail.vue'

export default {
  components: {InventoryDetail},
  data() {
    return {
      moment,
      params: {
        keyword: '',
        page: 1,
        limit: 10,
        date_from: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        date_to: moment().format('YYYY-MM-DD'),
      },
      dateParams: {
        start_date: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
      },
      reports: [],
      total: 0,
      can_load_more: false
    }
  },
  methods: {
    changePage(page) {
      this.params.page = page
      this.getData()
    },
    search() {
      this.params.page = 1
      this.getData()
    },
    async getData() {
      let res = await axios.get('inventories', {params: this.params})
      if (res) {
        this.reports = res.data.data.data
        this.total = res.data.data.total
        this.can_load_more = res.data.data.can_load_more
      }
    },
    selectDate(dates) {
      this.params.date_from = moment(dates.startDate).format('YYYY-MM-DD')
      this.params.date_to = moment(dates.endDate).format('YYYY-MM-DD')
      this.params.page = 1
      this.getData()
    },
  },
  mounted() {
    this.getData()
  },
}
</script>

<style lang="scss" scoped>
.table.my-border-table {
  thead {
    th {
      font-weight: 400;
      font-size: 12px;
    }
  }
}

.name-container {
  display: flex;
  align-items: center;
  gap: 10px;

  .img-container {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    overflow: hidden;
    border: 1.5px solid #eaeaea;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}


</style>

