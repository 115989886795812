<template>
  <tbody>
    <tr>
      <td>
        <div class="d-flex">
          <div
            @click="product.show_option = !product.show_option"
            :class="[
              product.show_option && product.optionList && product.optionList.length > 0 ? 'active' : '',
              'pointer product-drop',
            ]"
          >
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="23" height="23" rx="5" fill="#E2E2E2" />
              <path d="M7 10L11.5 14L16 10" stroke="black" stroke-width="2" stroke-linecap="round" />
            </svg>
          </div>
          <ProductSelect
            :productOptions="productOptions"
            :value="product"
            :index="index"
            @input="setProduct"
            class="ml-4 flex-grow-1"
          />
        </div>
      </td>
      <td style="width: 200px">
        <ProductUnitSelect @change="product.is_manual = false" :product="product" />
      </td>
      <td class="text-center" pos-tooltip-step="18">
        <input type="number" @change="validateQty" id="product-qty" v-model="product.quantity" />
      </td>
      <td pos-tooltip-step="19">
        <input
          type="number"
          id="product-price"
          class="text-right"
          @keyup="changeProductPriceManually(index, $event)"
          :value="productPrice"
        />
        <button
          v-if="product.is_manual"
          @click="product.is_manual = false"
          class="text-underline btn-clear text-danger"
        >
          {{ $t('invoice.reset') }}
        </button>
      </td>
      <td>
        <input type="number" id="product-discount" class="text-right" v-model="product.discount" />
      </td>
      <td>
        <input
          type="text"
          id="product-price"
          class="text-right"
          disabled
          :value="$cast(product.quantity * productPrice - (product.discount || 0))"
        />
      </td>
      <td class="text-right">
        <img @click="removeProduct(index)" src="/image/dashboard/trash.svg" class="delete-img" />
      </td>
    </tr>
  </tbody>
</template>

<script>
import ProductUnitSelect from './ProductUnitSelect'
import ProductSelect from './ProductSelect'

export default {
  props: ['showOptions', 'productOptions', 'product', 'index'],
  components: {
    ProductUnitSelect,
    ProductSelect,
  },
  computed: {
    productPrice: function () {
      return this.getProductPrice()
    },
  },
  methods: {
    setProduct(product) {
      this.product.show_option = true
      this.$emit('change', product)
    },

    getProductPrice() {
      let product = this.product
      console.log(product)
      var price = product.price
      if (!product.is_manual) {
        let priceFromUnit = 0

        // get price by checking sale or purchase
        if (this.$route.params.type == 'sale') {
          priceFromUnit = product.unit && product.unit.sale_price ? product.unit.sale_price : 0
        } else {
          priceFromUnit = product.unit && product.unit.purchase_price ? product.unit.purchase_price : 0
        }

        // if price is to add just add else set package_price = product price
        if (product.unit && product.unit.add_price) {
          price += priceFromUnit
        } else {
          price = priceFromUnit
        }

        this.product.price = price
        return this.product.price
      } else {
        price = product.price
        return price
      }
    },

    changeProductPriceManually(index, event) {
      this.product.is_manual = true
      this.product.price = +event.target.value
    },

    removeProduct(index) {
      this.$emit('remove', index)
    },
    validateQty(e) {
      let inputQty = +e.target.value
      if (inputQty < 1) {
        this.product.quantity = 1
      }
    },
  },
}
</script>

<style scoped>
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  display: none;
}
</style>