<template>
  <div>
    <div class="text-center mb-5">
      <ImageUploader :value="uploader" @input="getImage"/>
      <br/>
      <small v-if="errors.image" class="text-danger mt-2">
        {{ errors.image }}
      </small>
    </div>

    <div class="mt-3 ml-3">
      <h2>{{ $t('mngmt.customer.personalInformation') }}</h2>
      <div class="row">
        <div class="col-12">
          <Input :params="data" :errors="errors" field="name" :label="$t('mngmt.customer.name')"/>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <Input :params="data" :errors="errors" field="company_name" :label="$t('mngmt.customer.companyName')"/>
        </div>
        <div class="col-lg-6">
          <Input :params="data" :errors="errors" field="tax_id" :label="$t('mngmt.customer.taxId')"/>
        </div>
        <div class="col-lg-6">
          <MultiInput :params="data" :errors="errors" field="phone" :label="$t('phoneNumber')"
                      :addText="$t('mngmt.customer.addPhone')"/>
        </div>
        <div class="col-lg-6">
          <MultiInput
              :params="data"
              :add="false"
              :errors="errors"
              type="email"
              field="email"
              :label="$t('email')"
              :addText="$t('mngmt.customer.addEmail')"
          />
        </div>
      </div>
      <div v-if="editId" class="row">
        <div class="col-12">
          <!--          <Input :params="data" :errors="errors" field="address" :label="$t('mngmt.customer.address')"/>-->
          <label class="label">{{ $t('mngmt.customer.addresses') }}</label>
          <div class="position-relative address-cont">
            <van-address-list
                v-model="address.chosenAddressId"
                :list="data.address_list"
                default-tag-text="Default"
                :add-button-text="'+ ' + $t('mngmt.customer.addNewAddress')"
                @add="showAddressPopup"
                @edit="showAddressPopup"
            >
              <template #item-bottom="{ icon , id, detail}">
                <div class="custom-elem">
                  <div class="detail-badge">
                    <img v-if="icon" :src="require(`@/assets/icons/address/${icon}.svg`)"/>
                    <span v-if="detail">{{ detail }}</span>
                  </div>

                  <van-icon @click="removeAddress(id)" class="text-danger" name="delete-o"/>
                </div>

              </template>
            </van-address-list>
          </div>
          <van-popup v-model="address.showAddressPopup" style="padding: 20px 28px; border-radius: 15px">
            <div style="max-width: 80rem; margin: 0 auto">
              <h3 class="text-bold">
                {{
                  this.address.mode === 'create' ? $t('mngmt.customer.addNewAddress') : $t('mngmt.customer.editAddress')
                }}</h3>
              <div class="row">
                <div class="col-6">
                  <Input field="name" :errors="address.errors" :params="address.placeholder"
                         :label="$t('mngmt.customer.addressName')"/>
                </div>
                <div class="col-6">
                  <label class="label"> {{ $t('mngmt.customer.icon') }}</label>
                  <van-radio-group v-model="address.placeholder.icon"
                                   style="font-size: 25px; display: flex; align-items: center; gap: 10px; margin-top: 7px;"
                                   direction="horizontal">
                    <van-radio v-for="icon in address.icons" :key="icon" :name="icon" icon-size="7">
                      <img :src="require(`@/assets/icons/address/${icon}.svg`)"/>
                    </van-radio>
                  </van-radio-group>
                </div>
              </div>

              <Input field="detail" :errors="address.errors" :params="address.placeholder"
                     :label="$t('mngmt.customer.description')"/>
              <!--              <Input field="address" :errors="errors" :params="address.placeholder"-->
              <!--                     :label="$t('mngmt.customer.address')" :autocomplete="places" @keydown="searchPlaces"/>-->
              <label class="label">{{ $t('mngmt.customer.address') }}</label>
              <small v-if="address.errors.address" class="text-danger ml-1">
                {{ address.errors.address }}
              </small>
              <v-select
                  v-model="address.placeholder.address"
                  :options="places"
                  label="label"
                  placeholder="Address"
                  @search="searchPlaces"
                  @select="getPlaceDetails"
                  @input="getPlaceDetails"
              >
              </v-select>
              <label class="label mt-4">{{ $t('mngmt.customer.location') }}</label>
              <GmapMap
                  :center="center"
                  :zoom="18"
                  map-style-id="roadmap"
                  :options="mapOptions"
                  style="min-width: 50rem; width: 100%; min-height: 30rem; "
                  ref="mapRef"
                  @click="handleMapClick"
              >
                <GmapMarker
                    :position="marker.position"
                    :clickable="true"
                    :draggable="true"
                    @drag="handleMarkerDrag"
                    @click="panToMarker"
                />
              </GmapMap>
              <button @click="geolocate" class="btn btn-outline-primary">{{ $t('mngmt.customer.myLocation') }}</button>
              <!--              <p>Selected Position: {{ marker.position }}</p>-->
              <button @click="submitAddress" class="btn btn-primary w-100">
                {{
                  this.address.mode === 'create' ? $t('mngmt.customer.addNewAddress') : $t('mngmt.customer.editAddress')
                }}
              </button>
            </div>
            <div id="render-detail"></div>
          </van-popup>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {gmapApi} from 'vue2-google-maps'
import vSelect from "vue-select";

export default {
  computed: {
    google: gmapApi,
    isAddressFormValid: function () {
      return this.address.placeholder.address.label && this.address.placeholder.name
    }
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    isNew: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: Object,
    },
    editId: {
      type: String,
    }
  },
  data() {
    return {
      uploader: this.isNew ? [] : [{url: this.data.image}],
      marker: {position: {lat: 10, lng: 10}},
      center: {lat: 10, lng: 10},
      mapOptions: {
        disableDefaultUI: true,
      },
      timer: null,
      places: [],
      currentPlace: null,
      address: {
        errors: {},
        placeholder: {
          name: '',
          icon: '',
          tel: '',
          address: '',
          lat: 0,
          lng: 0,
          detail: '',
        },
        mode: '',
        showAddressPopup: false,
        chosenAddressId: '1',
        list: [],
        icons: [
          'apartment',
          'home',
          'house',
          'office',
        ]
      }
    }
  },
  watch: {
    data() {
      this.uploader = this.isNew ? [] : [{url: this.data.image}]
    },
  },
  methods: {
    async getAddress() {
      if (this.editId) {
        this.data.address_list = []
        let res = await axios.get('addresses', {params: {customer_id: +this.editId}})
        this.data.address_list.push(...res.data.data.map(addr => ({...addr, tel: '', lat: +addr.lat, lng: +addr.lng})))
      }
    },
    async submitAddress() {
      this.address.errors = {}
      if (!this.address.placeholder.name) {
        this.address.errors = {...this.address.errors, name: 'Name is required'}
      }
      if (!this.address.placeholder.address) {
        this.address.errors = { ...this.address.errors, address : 'Address is required'}
      }
      if (!this.isAddressFormValid) {
        return;
      }
      if (this.address.mode === 'create') {
        let res = await axios.post('addresses', {
          ...this.address.placeholder,
          address: this.address.placeholder.address.label,
          customer_id: +this.editId
        })
      } else if (this.address.mode === 'edit') {
          let res = await axios.put(`addresses/${this.address.placeholder.id}`, {...this.address.placeholder})
      }
      this.address.showAddressPopup = false
      this.getAddress()
    },
    async removeAddress(id) {
      if (this.editId) {
        let res = await axios.delete(`addresses/${id}`);
        this.getAddress();
      }
    },
    searchPlaces(keyword) {
      clearTimeout(this.timer)
      if (!keyword) {
        return;
      }
      this.timer = setTimeout(async () => {
        let req = new google.maps.places.AutocompleteService();
        let predictions = await req.getPlacePredictions({input: keyword})
        this.places = predictions.predictions.map(pred => ({
          label: pred.description,
          value: pred.description,
          place_id: pred.place_id
        }))
        console.log(predictions)
      }, 600)

    },
    async getPlaceDetails(val) {
      let req = new google.maps.places.PlacesService(document.getElementById('render-detail'))
      req.getDetails({placeId: val.place_id}, (result) => {
        this.currentPlace = result
        this.moveMarker();
      })

    },
    showAddressPopup(e) {
      this.address.mode = e ? 'edit' : 'create';
      this.address.placeholder = e || ({
        name: '',
        icon: '',
        tel: '',
        address: '',
        lat: 0,
        lng: 0,
        detail: '',
      });
      this.address.showAddressPopup = true;
      setTimeout(() => {
        if (e) {
          this.moveMarker(+e.lat, +e.lng)
        } else {
          this.geolocate()
        }
      }, 500)
    },
    getImage(value) {
      this.uploader = value
      if (this.uploader[0]) {
        this.data.image = this.uploader[0].file
      }
    },
    moveMarker(lat, lng) {
      this.marker.position = {
        lat: lat || this.currentPlace.geometry.location.lat(),
        lng: lng || this.currentPlace.geometry.location.lng(),
      };
      this.center = this.marker.position;
      // this.currentPlace = null;
      this.panToMarker()
      this.address.placeholder = {...this.address.placeholder, ...this.marker.position}
    },
    //detects location from browser
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.panToMarker();
      });
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = {lat: e.latLng.lat(), lng: e.latLng.lng()};
    },

    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      // this.$refs.mapRef.setZoom(18);
    },

    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = {lat: e.latLng.lat(), lng: e.latLng.lng()};
    },
  },
  mounted() {
    this.getAddress()
  },
  components: {
    vSelect
  }
}
</script>
<style lang="scss">
.address-cont {
  .van-address-list {
    padding: 0;
  }
  .van-address-list__bottom {
    position: static;
    max-width: 20rem;
    margin-top: 20px;
  }

  .van-tag--danger {
    background-color: var(--theme);
  }

  .van-button--danger {
    background-color: transparent;
    border-color: transparent;
    color: #2b2b2b;
    font-weight: bold;
    margin-left: -49px;
  }
  .van-address-item {
    border: 2px solid #f4f4f4;
  }
  .van-address-item .custom-elem {
    display: flex;
    justify-content: space-between;
    font-size: 23px !important;
    padding: 3px 15px 0 30px;
    gap: 10px;

    .detail-badge {
      font-size: 15px;
      padding: 5px 10px;
      margin-top: 7px;
      border: 2px solid #f4f4f4;
      border-radius: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 50rem;

      span {
        font-size: 13px;
        margin-left: 5px;

      }
    }
  }
}
</style>
