import { render, staticRenderFns } from "./Accountant.vue?vue&type=template&id=cca8daa4&scoped=true&"
var script = {}
import style0 from "./Accountant.vue?vue&type=style&index=0&id=cca8daa4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cca8daa4",
  null
  
)

export default component.exports