<template>
  <div>
    <FrontendNav />
    <main class="bg-white">
      <section class="landing-section-wrapper">
        <section class="container text-center landing-section">
          <div class="white-space"></div>
          <div class="title-container">
            <h3>Tools to make your</h3>
            <h1 class="text-bold hero-title">Business Succeed</h1>
            <h5>
              All in one POS, Accounting, Invoices, Inventory software.
              <br />Save your time & money.
            </h5>
            <div class="download-container d-flex gap-5">
              <router-link class="btn-web-primary" to="/register">Get Started For Free</router-link>
              <a
                target="blank"
                href="https://play.google.com/store/apps/details?id=org.bilions.manawstore"
                class="download-btn text-black"
              >
                <span>Android</span>
                <svg width="39" height="25" viewBox="0 0 39 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.85 17.7259V29.6205C5.85 31.2024 4.62109 32.4658 3.05703 32.4658C1.50312 32.4658 0 31.1921 0 29.6205V17.7259C0 16.1749 1.50312 14.9012 3.05703 14.9012C4.59062 14.9012 5.85 16.1749 5.85 17.7259ZM6.94688 33.8524C6.94688 35.537 8.2875 36.8929 9.95312 36.8929H11.9742L12.0047 43.1689C12.0047 46.9591 17.6109 46.9283 17.6109 43.1689V36.8929H21.3891V43.1689C21.3891 46.9386 27.0258 46.9488 27.0258 43.1689V36.8929H29.0773C30.7227 36.8929 32.0633 35.537 32.0633 33.8524V15.4251H6.94688V33.8524V33.8524ZM32.1344 14.4287H6.83516C6.83516 10.0324 9.43516 6.21136 13.2945 4.21866L11.3547 0.592757C11.0703 0.089445 11.7914 -0.228977 12.0352 0.202434L14.0055 3.85915C17.55 2.26704 21.6227 2.34921 25.0047 3.85915L26.9648 0.212705C27.2188 -0.228977 27.9297 0.0997165 27.6453 0.603029L25.7055 4.21866C29.5344 6.21136 32.1344 10.0324 32.1344 14.4287V14.4287ZM14.7977 8.72793C14.7977 8.14244 14.3305 7.6494 13.7312 7.6494C13.1523 7.6494 12.6953 8.14244 12.6953 8.72793C12.6953 9.31341 13.1625 9.80645 13.7312 9.80645C14.3305 9.80645 14.7977 9.31341 14.7977 8.72793ZM26.3148 8.72793C26.3148 8.14244 25.8477 7.6494 25.2789 7.6494C24.6797 7.6494 24.2125 8.14244 24.2125 8.72793C24.2125 9.31341 24.6797 9.80645 25.2789 9.80645C25.8477 9.80645 26.3148 9.31341 26.3148 8.72793ZM35.943 14.9012C34.4094 14.9012 33.15 16.1441 33.15 17.7259V29.6205C33.15 31.2024 34.4094 32.4658 35.943 32.4658C37.507 32.4658 39 31.1921 39 29.6205V17.7259C39 16.1441 37.4969 14.9012 35.943 14.9012Z"
                    fill="black"
                  />
                </svg>
              </a>
              <!-- 
              <a
                target="blank"
                href="https://manaw-store-live.s3.amazonaws.com/download/Manaw%20Store%20Setup%201.0.0.exe"
                class="download-btn text-black"
              >
                <span>Windows</span>
                <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.936 0.405884L9.41657 1.94572V9.7484L19.936 9.66479V0.405884ZM0.411815 10.595L0.412286 17.2348L8.38677 18.3282L8.38049 10.6467L0.411815 10.595ZM9.33289 10.7015L9.34765 18.4469L19.9261 19.9358L19.9286 10.7189L9.33289 10.7015ZM0.406006 3.17114L0.413385 9.80759L8.38787 9.76234L8.38426 2.08727L0.406006 3.17114Z"
                    fill="black"
                  />
                </svg>
              </a>
              <a
                target="blank"
                href="https://manaw-store-live.s3.amazonaws.com/download/manaw-store-1.0-mac-arm64.dmg"
                class="download-btn text-black"
              >
                <span>Mac</span>
                <svg fill="none" height="23" viewBox="0 0 19 23" width="19" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M18.2314 17.3162C17.9062 18.0676 17.5213 18.7591 17.0751 19.3951C16.4672 20.2619 15.9694 20.8619 15.5858 21.1952C14.9911 21.7421 14.3539 22.0221 13.6716 22.0381C13.1817 22.0381 12.591 21.8988 11.9034 21.616C11.2135 21.3345 10.5795 21.1952 9.99975 21.1952C9.39176 21.1952 8.73971 21.3345 8.04225 21.616C7.34373 21.8988 6.78101 22.046 6.35077 22.0607C5.69647 22.0884 5.04428 21.8004 4.39327 21.1952C3.97777 20.8327 3.45808 20.2114 2.83548 19.3314C2.1675 18.3915 1.61833 17.3016 1.18809 16.0591C0.727319 14.717 0.496338 13.4174 0.496338 12.1592C0.496338 10.718 0.807767 9.47495 1.43155 8.43328C1.92179 7.59658 2.57397 6.93655 3.39024 6.45204C4.20651 5.9675 5.08848 5.72058 6.03829 5.70478C6.558 5.70478 7.23952 5.86555 8.08645 6.18149C8.931 6.49848 9.47326 6.65925 9.71102 6.65925C9.88876 6.65925 10.4912 6.47127 11.5124 6.09653C12.4782 5.749 13.2932 5.6051 13.9609 5.66178C15.7703 5.8078 17.1297 6.52105 18.0336 7.80606C16.4154 8.78654 15.615 10.1598 15.6309 11.9215C15.6455 13.2937 16.1433 14.4356 17.1217 15.3423C17.565 15.7631 18.0602 16.0883 18.6111 16.3193C18.4917 16.6658 18.3654 16.9976 18.2314 17.3162ZM14.0817 0.986939C14.0817 2.06247 13.6888 3.0667 12.9056 3.9962C11.9604 5.10118 10.8172 5.73971 9.57748 5.63896C9.56168 5.50992 9.55252 5.37412 9.55252 5.23141C9.55252 4.19891 10.002 3.09391 10.8002 2.19043C11.1987 1.73298 11.7056 1.35262 12.3202 1.0492C12.9335 0.750303 13.5136 0.585008 14.0592 0.556702C14.0751 0.700484 14.0817 0.844275 14.0817 0.986925V0.986939Z"
                    fill="black"
                  />
                </svg>
              </a> -->
            </div>
          </div>

          <div class="img-container">
            <img src="@/assets/images/landing-1.png" />
          </div>
        </section>
      </section>

      <section class="feature-section-wrapper" id="features">
        <div class="container text-center">
          <h2 class="text-bold">Our Features</h2>
          <div class="features-container">
            <div v-for="feature in features" :key="feature.title" class="feature">
              <img :src="feature.icon" />
              <h4>{{ feature.title }}</h4>
              <p>{{ feature.description }}</p>
            </div>
          </div>
        </div>
      </section>
      <section class="container text-center feature-showcase feature-1">
        <div>
          <h2>All The Features You Need</h2>
          <h5>MaNaw Store is based on a smart interface that any retail company can use without difficulty.</h5>
        </div>
        <div class="feat-img-container">
          <img src="@/assets/images/feature-1.png" />
        </div>
      </section>
      <section class="container text-center feature-showcase feature-1">
        <div class="feat-img-container">
          <img src="@/assets/images/feature-2.png" />
        </div>
        <div>
          <h2>Customize your POS</h2>
          <h5>Easy to customize your appearance</h5>
        </div>
      </section>
      <section class="container text-center feature-showcase feature-1">
        <div>
          <h2>Fit For Every Business</h2>
          <h5>It's extremely flexible, you can configure MaNaw Store to meet your precise needs.</h5>
        </div>
        <div class="feat-img-container">
          <img src="@/assets/images/feature-3.png" />
        </div>
      </section>
      <section class="container text-center process-section">
        <h2 class="text-bold">Just 3 steps to Growth Your Business</h2>
        <h5>It really is that easy!</h5>
        <div class="row process-container d-flex align-items-end justify-content-center">
          <div class="col col-lg-4">
            <img src="@/assets/images/illustrations/register.svg" />
            <h5 class="text-bold process-text"><span class="number-block">1</span> Register</h5>
          </div>
          <div class="col col-lg-4">
            <img src="@/assets/images/illustrations/create-company.svg" />
            <h5 class="text-bold process-text"><span class="number-block">2</span> Create a Company</h5>
          </div>
          <div class="col col-lg-4">
            <img src="@/assets/images/illustrations/ready-to-use.svg" />
            <h5 class="text-bold process-text"><span class="number-block">3</span> Ready To Use</h5>
          </div>
        </div>
      </section>

      <section class="container">
        <div class="section-2">
          <div>
            <h2>Its time to start New Things</h2>
            <p class="my-2">No installation required. Save your time & money.</p>
            <router-link class="btn-web-primary getstart-btn" to="/"> Get Started For Free </router-link>
          </div>
          <div class="image-container">
            <img src="@/assets/images/pos-mockup-1.png" />
          </div>
        </div>
      </section>
    </main>
    <FrontendFooter />
  </div>
</template>

<script>
import FrontendNav from '../layouts/FrontendNav'
import FrontendFooter from '../layouts/FrontendFooter'

export default {
  name: 'Home',
  components: {
    FrontendNav,
    FrontendFooter,
  },
  data() {
    return {
      showHeroLanding: true,
      features: [
        {
          icon: require('@/assets/icons/features/bag.svg'),
          title: 'Point of sale',
          description: 'Fit for every retail business for success',
        },
        {
          icon: require('@/assets/icons/features/calc.svg'),
          title: 'Free Accounting',
          description: 'Do not require accounting knowledge',
        },
        {
          icon: require('@/assets/icons/features/invoice.svg'),
          title: 'Invoicing',
          description: 'Fews click to create invoices and share PDF',
        },

        {
          icon: require('@/assets/icons/features/api.svg'),
          title: 'API & SDK',
          description: 'Support API and SDK for Developers',
        },
        {
          icon: require('@/assets/icons/features/custom.svg'),
          title: 'Customization',
          description: 'Customize your favourite color and logo',
        },
      ],
      featOptions: {
        pagination: false,
      },
      firstPage: true,
      lastPage: false,
    }
  },
  methods: {
    slide(e) {
      this.firstPage = e.currentPage == 0 ? true : false
      this.lastPage = this.items.length - e.currentPage < 5 ? true : false
    },
    prev() {
      this.$refs.serviceSlider.$emit('slidePre')
    },
    next() {
      if (!this.lastPage) {
        this.$refs.serviceSlider.$emit('slideNext')
      }
    },
  },
}
</script>

<style>
.highlight {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, rgba(240, 255, 68, 0.6) 40%);
  padding: 1px 5px;
}
</style>

<style lang="scss" scoped>
main {
  overflow: hidden;
  letter-spacing: 0;
}

.container h2 {
  margin-bottom: 10px;
}

/*hero section */
.landing-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  gap: 5px;

  .white-space {
    height: 50px;
  }

  .title-container {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  .hero-title {
    font-size: 4rem;
  }

  h5 {
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 20px;
  }

  .download-container {
    gap: 10px;
    a {
      font-weight: bold;
      padding: 15px 25px;
    }
    .download-btn {
      background-color: #f3f3f3;
      border-radius: 10px;
      cursor: pointer;
    }

    .download-btn:hover {
      background-color: #eeeeee;
    }

    .btn-web-primary,
    .download-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    }
  }

  .img-container {
    width: 80%;
  }

  .img-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media screen and (max-width: 570px) {
    .title-container {
      gap: 5px;
    }
    .hero-title {
      font-size: 3rem;
    }
    h5 {
      font-size: 14px;
      max-width: 70%;
      margin: 5px auto 10px;
    }
    .download-container {
      flex-direction: column;
    }
    .download-container {
      a,
      .download-btn {
        padding: 18px;
        margin: 0 10px;
      }
    }
    .img-container {
      width: 105%;
    }
  }
}

/*customize section */
.feature-section-wrapper {
  padding: 6rem 0 8rem;
  background-color: #f9f9ff;

  h2 {
    margin-bottom: 4.5rem;
  }
  .features-container {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    align-items: center;
    justify-content: center;
    max-width: 900px;
    margin: 0 auto;

    .feature {
      background-color: #fff;
      text-align: left;
      max-width: 20rem;
      padding: 30px;
      border-radius: 20px;

      img {
        margin: 0 0 20px;
      }
      h4 {
        font-weight: 700;
      }
      p {
        font-size: 1.25rem;
      }
    }
    @media screen and (max-width: 430px) {
      .feature {
        max-width: 80%;
      }
    }
  }
}
.feature-showcase {
  display: flex;
  align-items: center;
  padding: 4rem 1rem;
  gap: 30px;
  justify-content: space-between;

  h2,
  h5 {
    text-align: left;
    max-width: 40rem;
  }
  h2 {
    font-weight: 700;
  }
  h5 {
    font-weight: 400;
    line-height: 25px;
  }

  .feat-img-container {
    max-width: 50rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
@media screen and (max-width: 990px) {
  .feature-showcase {
    flex-wrap: wrap;

    .feat-img-container {
      max-width: 100%;
    }
  }
}

/*process section */
.process-section {
  padding: 6rem 0 3rem;

  .process-text {
    margin: 15px 0;
  }

  .number-block {
    padding: 6px 11px;
    margin-right: 5px;
    background-color: #e9e9ff;
    border-radius: 8px;
  }
}

.process-container > div {
  margin: 30px 0;
}
</style>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
  display: inline-block;
}

.section-1 {
  margin-top: 30px;
  padding: 3rem 0;
  background-color: white;

  .item-card {
    flex-direction: column;
    margin: 0 10px;
    background-color: #f5f5f5;
    color: black;
    width: calc(25% - 15px);
    padding: 30px;
    height: 280px;
    border-radius: 10px;

    h3 {
      font-size: 16px;
      font-weight: 700;
      margin-top: 20px;
    }

    p {
      font-size: 14px;
      margin-top: 20px;
    }
  }

  .slider-container {
    white-space: pre-wrap;
  }

  .controls {
    display: flex;
    position: absolute;
    left: -40px;
    right: -40px;
    justify-content: space-between;
    top: 50%;
    transform: translateY(-50%);

    .slide-next,
    .slide-prev {
      cursor: pointer;

      &.disabled path {
        opacity: 0.1;
      }
    }
  }
}

.section-2 {
  display: flex;
  align-items: center;
  padding: 3rem 7rem;
  margin: 0 auto 5rem;
  background-color: #f9f9ff;
  justify-content: space-between;
  max-width: 85rem;
  border-radius: 30px;
  flex-wrap: wrap;
  gap: 40px;

  h2 {
    font-weight: 700;
  }
  .image-container {
    max-width: 300px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  p {
    font-size: 14px;
  }
  .getstart-btn {
    display: inline-block;
    margin-top: 12px;
    font-size: 14px;
    font-weight: 600;
    padding: 18px 25px;
    text-align: center;
  }
}
@media screen and (max-width: 992px) {
  .section-2 {
    justify-content: center;
    padding: 5rem 3rem;

    .getstart-btn {
      display: block;
    }
  }
}
.text-black {
  color: black;
}
</style>