<template>
  <div class="bg-white">
    <div v-if="!editId || loaded">
      <h1 @click="$router.back()">
        <van-icon class="mr-3" name="arrow-left" size="15px" />
        <span v-if="editId">{{ data.owner_name }}</span>
        <span v-else>{{ $t('mngmt.bankAccount.title')}}</span>
      </h1>

      <div class="col-lg-9 mx-auto">
        <div pos-tooltip-step="12">
          <h2 class="mt-5">{{ $t('mngmt.bankAccount.accountDetail')}}</h2>
          <div class="mt-3">
            <div class="row">
              <div class="col-12">
                <Input :params="data" :errors="errors" field="owner_name" :label="$t('mngmt.bankAccount.accountTitle')" />
              </div>
              <div class="col-12">
                <Input :params="data" :errors="errors" field="account_number" :label="$t('mngmt.bankAccount.accNumber')" />
              </div>
              <div class="col-12">
                <Input :params="data" :errors="errors" field="bank_name" :label="$t('mngmt.bankAccount.bankName')" />
              </div>
              <div class="col-12">
                <Input :params="data" :errors="errors" type="number" field="initial_amount" :label="$t('mngmt.bankAccount.initialBalance')" />
              </div>
            </div>
          </div>
        </div>

        <!-- ==== delete and save button ==== -->
        <div class="mt-2">
          <Button :type="editId ? 'edit' : 'create'" :loading="loading" @click.native="saveData(); $events.fire('nextStep')" name="Account" pos-tooltip-step="13"/>
          <Button v-if="editId" type="delete" :dloading="dloading" name="Account" @click.native="deleteData()" />
        </div>
      </div>
    </div>
    <div v-else class="loading">
      <van-loading type="spinner" />
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant'

export default {
  props: ['id'],
  data() {
    return {
      loading: false,
      loaded: false,
      dloading: false,
      data: {},
      errors: {},
      editId: null,
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.editId = this.$route.params.id
      this.bankData()
    }
  },
  methods: {
    async bankData() {
      const res = await axios.get('/accounts/' + this.editId)
      if (res) {
        this.data = res.data.data
        this.loaded = true
      }
    },

    saveData() {
      if (this.editId) {
        this.update()
      } else {
        this.create()
      }
    },

    async create() {
      this.loading = true
      try {
        const res = await axios.post('/accounts', this.data)
        if (res.data.success) {
          Toast.success(this.$t('state.created'))
          this.loading = false
          this.$router.back()
        } else if (res.data.code == 422) {
          this.loading = false
          this.errors = res.data.error
        }
      } catch (err) {
        this.loading = false
      }
    },

    async update() {
      this.loading = true
      try {
        const res = await axios.post('/accounts/' + this.editId, this.data)
        if (res.data.success) {
          Toast.success(this.$t('state.edited'))
          this.loading = false
          this.$router.back()
        } else if (res.data.code == 422) {
          this.loading = false
          this.errors = res.data.error
        }
      } catch (err) {
        this.loading = false
      }
    },

    async deleteData() {
      this.dloading = true
      await Dialog.confirm({
        title: this.$t('action.areYouSure'),
        cancelButtonText: this.$t('action.cancel'),
        confirmButtonText: this.$t('action.del'),
      })
      try {
        const res = await axios.delete(`/accounts/${this.editId}`)
        if (res) {
          Toast.success(this.$t('state.deleted'))
          this.$router.back()
        }
      } catch (error) {
        Toast.fail(this.$t('state.delFail'))
      }
      this.dloading = false
    },
  },
}
</script>

<style scoped>
h1 {
  cursor: pointer;
}
</style>
