<template>
  <div>
    <div pos-tooltip-step="1" style="position : absolute; left : 50%; bottom : 75%"></div>
    <v-tour name="posTooltips" :steps="steps"></v-tour>
  </div>
</template>

<script>
export default {
  data() {
    return {
      steps: [
        {
          target : '[pos-tooltip-step="1"]',
          isSticky : true,
          theme : 'white',
          width : '440px',
          header : {
            title : 'Welcome to MaNaw Store',
            image : 'pos-step-1.png'
          },
          showOverlay : true,
          showNext : true,
          content : `Welcome to your new Point of sale! We will introduce how to use <strong>MaNaw Store</strong> to make your business better.<br/><br/>Please follow this tour to get to know.`
        },
        {
          target : '[pos-tooltip-step="2"]',
          params : { placement : 'right' },
          content : `Firstly, we need to <strong>create a product</strong> in Management. Click the <strong>MGNT.</strong>`
        },
        { 
          target : '[pos-tooltip-step="3"]',
          params : { placement : 'left'},
          content: `This is a <strong>product list panel</strong>. You can find the products being sold here.`,
        },
        // { 
        //   target : '[pos-tooltip-step="4"]',
        //   params : { placement : 'left'},
        //   content: `On this modal, you will see <strong>two options</strong> to create a new product. Let's go with <strong>Single Product</strong>`,
        // },
        // { 
        //   target : '[pos-tooltip-step="5"]',
        //   params : { placement : 'left'},
        //   content: `Click <strong>Create</strong> to create <strong>Single Product.</strong>`,
        // },
        {
          target : '[pos-tooltip-step="6"]',
          params : { placement : 'top'},
          content: `Write a name in <strong>Product Name</strong> input.`,
          showNext : true,
        },
        {
          target : '[pos-tooltip-step="7"]',
          params : { placement : 'top'},
          content : `Write a price in <strong>Sale Price</strong> input.`,
          showNext : true,
        },
        {
          target : '[pos-tooltip-step="8"]',
          params : { placement : 'top'},
          content : `Write here to create a category, or if you already <strong>created a category</strong>, you can choose this directly.`,
          showNext : true,
        },
        {
          target : '[pos-tooltip-step="9"]',
          params : { placement : 'top'},
          content : `Click <strong>Create</strong> to create a new product`
        },
        {
          target : '[pos-tooltip-step="10"]',
          params : { placement : 'right'},
          content : `To create an account, please go to the <strong>Accounts / Assets.</strong>`
        },
        {
          target : '[pos-tooltip-step="11"]',
          params : { placement : 'left'},
          content : `Click <strong>Add Custom Assets</strong> button.`,
          waitStepData : true
        },
        {
          target : '[pos-tooltip-step="12"]',
          content : `Fill these <strong>Account Title, Account Number, Bank Name</strong> and </strong>Initial Balance.</strong>`,
          showNext : true
        },
        {
          target : '[pos-tooltip-step="13"]',
          content : `Click a <strong>Create Account</strong> button.`
        },
        {
          target : '[pos-tooltip-step="14"]',
          params : { placement : 'right'},
          content : `After creating an account, you can finally <strong>add a stock</strong> to your product. Click <strong>Products</strong> to add a stock.`
        },
        {
          target : '[pos-tooltip-step="15"]',
          content : `Please <strong>select the product</strong> that you want to add stock.`,
          waitStepData : true
        },
        {
          target : '[pos-tooltip-step="16"]',
          params : { placement : 'left'},
          content : `And click <strong>Add Stock</strong> button.`
        },
        {
          target : '[pos-tooltip-step="17"]',
          params : { placement : 'top'},
          content : `Click the <strong>Account</strong> and select the <strong>Account</strong> that you created in earlier.`,
          showNext : true
        },
        {
          target : '[pos-tooltip-step="18"]',
          params : { placement : 'top'},
          content : `Write an <strong>number</strong> of your product to add a stock.`,
          showNext : true
        },
        {
          target : '[pos-tooltip-step="19"]',
          params : { placement : 'top' },
          content : `And fill the <strong>price</strong> of your product cost.`,
          showNext : true
        },
        {
          target : '[pos-tooltip-step="20"]',
          params : { placement : 'right'},
          content : `And click <strong>Create</strong> Button`
        },
        {
          target : '[pos-tooltip-step="1"]',
          isSticky : true,
          content : `Now you will see the <strong>Purchase Invoice</strong> of your product. You can start sell your product in <strong>POS</strong>.`,
          showNext : true,
        },
        {
          target : '[pos-tooltip-step="22"]',
          params : { placement : 'right' },
          content : `To start selling your product, please go to the <strong>POS</strong> page.`
        },
        {
          target : '[pos-tooltip-step="23"]',
          params : { placement : 'left' },
          content : `This is a <strong>product list panel</strong.. You can find the products being sold here.`,
          showNext : true
        },
        {
          target : '[pos-tooltip-step="24"]',
          params : { placement : 'right' },
          content : `Click the <strong>Cart icon</strong> to sell this product.`
        },
        {
          target : '[pos-tooltip-step="25"]',
          params : { placement : 'left' },
          content : `Click the <strong>Checkout</strong> button to finalize the transaction.`
        },
        {
          target : '[pos-tooltip-step="1"]',
          isSticky : true,
          content : `Click the Checkout button to make sure that you want to sell.`,
          showNext : true,
        },
        {
          target : '[pos-tooltip-step="27"]',
          params : { placement : 'right' },
          content : `In <strong>Dashboard</strong>, you can see and manage all your products, sales, accounts, customers and suppliers.
            Click the Dashboard to see your business growing data.`
        },
        {
          target : '[pos-tooltip-step="1"]',
          isSticky : true,
          theme : 'white',
          width : '440px',
          header : {
            image : 'pos-step-1.png'
          },
          showOverlay : true,
          content : `That's how you can use <strong>MaNaw Store!</strong> You can also ask us questions in <strong>Customer Support</strong> in <strong>Support</strong> page.`
        }
      ],
    }
  },
  methods : {
    run() {
      this.$tours['posTooltips'].start()
    }
  },
  events : {
    'runTooltip'() {
      this.run()
    }
  }
}
</script>