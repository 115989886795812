<template>
  <div class="report px-3">
    <div class="tabs">
      <div :class="[active('all')]" class="tab" @click="changeTab('all')">{{ $t('all') }}</div>
      <div :class="[active('assets')]" class="tab" @click="changeTab('assets')">{{ $t('report.assets') }}</div>
      <div :class="[active('revenue')]" class="tab" @click="changeTab('revenue')">{{ $t('revenue') }}</div>
      <div :class="[active('expense')]" class="tab" @click="changeTab('expense')">{{ $t('expense') }}</div>
      <div :class="[active('liability')]" class="tab" @click="changeTab('liability')">{{ $t('report.liability') }}</div>
      <!-- <div class="tab" @click="changeTab('dividends')" :class="[active('dividends')]">Dividends</div> -->
    </div>
    <div :class="[getSuitableClass()]" class="content-report p-3">
      <div class="d-flex mb-3 justify-content-between align-items-center">
        <div class="d-flex">
          <custom-search v-model="keyword" placeholder="Search by invoice number" @search="search()" />
          <div class="ml-2" >
            <div class="filter-icon" @click="$refs.filterCard.show = !$refs.filterCard.show">
              <van-icon name="filter-o" />
              <span>{{ $t('action.filter') }}</span>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center align-items-center">
          <div class="mr-5">
            <button class="btn excel-btn" @click="exportExcel">
              <img height="20" src="@/assets/icons/excel-icon.svg" />
              {{ $t('action.export_excel')}}
            </button>
          </div>
          <span class="mr-4">{{ config.data.length }} / {{ total }}</span>
          <my-date-range-picker :params="dates" @select="selectDate" />
        </div>
      </div>
      <div>
        <FilterCard @select="filterCoa" ref="filterCard" :params="filter" @filter="search()" />
      </div>
      <div v-if="config.data.length > 0">
        <hot-table :settings="config"></hot-table>
      </div>
      <div v-else>
        <van-empty :description="$t('report.emptyRecord')" />
      </div>

      <div v-if="showLoadMore" class="text-center">
        <button class="load-more" @click="more()">Load More</button>
      </div>
    </div>
  </div>
</template>

<script>
import FilterCard from './components/FilterCard'
import { HotTable } from '@handsontable/vue'
import config from './config'
import moment from 'moment'

let start_date = moment().subtract(1, 'month').format('YYYY-MM-DD')
let end_date = moment().format('YYYY-MM-DD')
export default {
  data: function () {
    return {
      activeTab: 'all',
      config,
      showLoadMore: false,
      page: 1,
      keyword: null,
      limit: 50,
      total: 0,
      dates: { start_date, end_date },
      filter: {
        coas: [],
      },
      showExportError: false,
      errorMessage: '',
    }
  },
  watch: {
    $route: function () {
      this.config.data = []
    },
  },
  mounted() {
    this.config.data = []
    this.getData()
  },
  methods: {
    getType(tab) {
      let data = {
        all: 'all',
        assets: 1,
        revenue: 4,
        expense: 5,
        liability: 2,
        dividends: 3,
      }
      return data[tab]
    },

    search() {
      this.config.data = []
      this.page = 1
      this.getData()
    },

    selectDate(dates) {
      this.dates.start_date = moment(dates.startDate).format('YYYY-MM-DD')
      this.dates.end_date = moment(dates.endDate).format('YYYY-MM-DD')
      this.config.data = []
      this.page = 1
      this.getData()
    },

    async getData() {
      let res = await axios.get('/transactions', {
        params: {
          type: this.getType(this.activeTab),
          page: this.page,
          limit: this.limit,
          keyword: this.keyword,
          coas: this.filter.coas.map(c => c.coa),
          ...this.dates,
        },
      })
      if (res) {
        let data = res.data.data
        data.data.forEach((element) => {
          this.config.data.push(element)
        })
        this.showLoadMore = data.can_load_more
        this.total = data.total
      }
    },

    getSuitableClass() {
      if (this.activeTab == 'all') {
        return 'left-top-0'
      }
      if (this.activeTab == 'dividends') {
        return 'right-top-0'
      }
      return ''
    },

    active(tab) {
      return tab == this.activeTab ? 'active' : ''
    },

    changeTab(tab) {
      this.activeTab = tab
      this.config.data = []
      this.page = 1
      this.getData()
    },

    more() {
      this.page = this.page + 1
      this.getData()
    },
    async exportExcel() {
      let params = {
        type: this.getType(this.activeTab),
        page: 1,
        limit: 100,
        keyword: this.keyword,
        coas: this.filter.coas,
        token: this.$auth.token(),
        ...this.dates,
      }
      let res = await axios.get('/transactions/export_request/', { params })
      if (res.data.data) {
        let file_url = res.data.data.redirect_url
        window.open(file_url)
      }
    },
    filterCoa() {
      this.config.data = []
      this.page = 1
      this.getData();
    }
  },
  components: {
    HotTable,
    FilterCard,
  },
}
</script>

<style scoped lang="scss">
.export-error {
  border-radius: 15px;
  padding: 20px 25px;

  .alert-wrapper {
    max-width: 40rem;
    margin: auto;
    text-align: center;

    svg {
      margin: 15px 0 8px;
    }
    h3 {
      font-weight: bold;
      margin: 10px 0;
    }
    p {
      max-width: 30rem;
    }
    .actions {
      text-align: right;
      margin-top: 20px;

      button {
        margin-left: 15px;
      }
    }
  }
}
</style>
<style lang="scss">
.excel-btn {
  background-color: #1d6f42;
  color: #fff;
  padding: 0px 17px 0px 13px;
  height: 38px !important;

  img {
    margin-right: 5px;
  }

  &:hover {
    background-color: #289256;
    color: #fff;
  }
}

.repo MyDateRangePickerrt {
  width: 100%;
  margin-top: -3px;
}

.text-black {
  color: black !important;
}

.tabs {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 2px;
}

.tab {
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 12px;
  color: black;
  text-transform: capitalize;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
}

.tab.active {
  background: white;
  font-weight: 700;
  color: black;
  border-radius: 10px 10px 0px 0px;
}

.content-report {
  background: white;
  border-radius: 10px 10px 10px 10px;
  min-height: 500px;
}

.load-more {
  margin-top: 10px;
  border-radius: 10px;
  border: 2px solid #f4f4f4;
  padding: 3px 14px;
}

.left-top-0 {
  border-top-left-radius: 0px !important;
}

.right-top-0 {
  border-top-right-radius: 0px !important;
}

.filter-btn {
  padding: 0 30px;
  height: 38px !important;
}
.filter-icon {
  padding: 8px 12px ;
  cursor: pointer;
  background-color: #f8f8f8;
  border-radius: 10px;

  i {
    font-size: 20px;
    margin-right: 2px;
  }
}
</style>