import Vue from 'vue'
import MyDateRangePicker from '@/layouts/MyDateRangePicker'
import Sidebar from '@/layouts/Sidebar'
import Input from './Input'
import MultiInput from './MultiInput'
import Uploader from './Uploader'
import Button from './Button'
import ErrorMessage from './ErrorMessage'
import helpers from './helpers'
import Svg from './Svg'
import Select from './Select'
import FrontendNav from '@/layouts/FrontendNav'
import FrontendFooter from '@/layouts/FrontendFooter'
import PreviewSvg from '@/layouts/PreviewSvg'
import ColorPicker from '@/layouts/ColorPicker'
import ImageUploader from './ImageUploader'
import Search from './Search'
import OtpInput from './otp-input'
import AlertPopup from './alert-popup'
import './vue-tour/main'
import vSelect from 'vue-select'

window.getErrors = helpers.getErrors
window.$helpers = helpers
Vue.component('v-select', vSelect)
Vue.component('LeftSidebar', Sidebar)
Vue.component('MyDateRangePicker', MyDateRangePicker)
Vue.component('Input', Input)
Vue.component('MultiInput', MultiInput)
Vue.component('Uploader', Uploader)
Vue.component('Button', Button)
Vue.component('ErrorMessage', ErrorMessage)
Vue.component('b-svg', Svg)
Vue.component('FrontendNav', FrontendNav)
Vue.component('FrontendFooter', FrontendFooter)
Vue.component('PreviewSvg', PreviewSvg)
Vue.component('ColorPicker', ColorPicker)
Vue.component('Select', Select)
Vue.component('ImageUploader', ImageUploader)
Vue.component('custom-search', Search)
Vue.component('otp-input', OtpInput)
Vue.component('alert-popup', AlertPopup)

Vue.prototype.$cast = (value) => {
  value = value * 1
  return value.toLocaleString('en-US', { maximumFractionDigits: 2 })
}
Vue.prototype.$toFixed = (value, number) => Number(value).toFixed(number)
window.lowerCase = (text) => {
  if (text) {
    return text.toLowerCase()
  }
  return text
}

Vue.prototype.$short = (value) => {
  if (value) {
    return value.replace(/(.{48})..+/, '$1…')
  }
  return ''
}

window.popupWindow = (url, w, h) => {
  const y = window.top.outerHeight / 2 + window.top.screenY - h / 2
  const x = window.top.outerWidth / 2 + window.top.screenX - w / 2
  return window.open(
    url,
    'manaw-store',
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`,
  )
}
