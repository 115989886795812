<template>
  <div class="card px-4 py-2">
    <van-tabs v-model="activeName">
      <van-tab title="Summary" name="summary">
        <!--  -->
        <div class="item mt-2">
          <div class="left">
            <van-icon name="hotel-o" size="17" class="mr-2" />
            <span> {{ $t('dashboard.capital') }}</span>
          </div>
          <div class="right" :value-class="[capital() <= 0 ? 'red' : 'green']">
            {{ $currency }} {{ $cast(capital()) }}
          </div>
        </div>

        <div class="item">
          <div class="left">
            <van-icon name="balance-o" size="17" class="mr-2" />
            <span>{{ $t('dashboard.assets') }}</span>
          </div>
          <div class="right">{{ $currency }} {{ $cast(summary.assets) }}</div>
        </div>

        <div class="item">
          <div class="left">
            <van-icon name="peer-pay" size="17" class="mr-2" />
            <span>{{ $t('dashboard.totalLiability') }}</span>
          </div>
          <div class="right">{{ $currency }} {{ $cast(summary.liability) }}</div>
        </div>

        <div class="item">
          <div class="left">
            <van-icon name="circle" size="17" class="mr-2 ml-4" />
            <span>{{ $t('dashboard.saleTax') }}</span>
          </div>
          <div class="right">{{ $currency }} {{ $cast(summary.sale_tax) }}</div>
        </div>

        <div class="item">
          <div class="left">
            <van-icon name="circle" size="17" class="mr-2 ml-4" />
            <span>{{ $t('dashboard.accountPayable') }}</span>
          </div>
          <div class="right">{{ $currency }} {{ $cast(summary.account_payable) }}</div>
        </div>

        <div class="item">
          <div class="left">
            <van-icon name="circle" size="17" class="mr-2 ml-4" />
            <span>{{ $t('dashboard.withholdingTax') }}</span>
          </div>
          <div class="right">{{ $currency }} {{ $cast(summary.wht) }}</div>
        </div>

        <!--  -->
      </van-tab>

      <!-- taxes -->
      <van-tab title="Taxes" name="b">
        <!--  -->
        <div class="item no-border">
          <div class="left">
            <van-icon name="newspaper-o" size="17" class="mr-2" />
            <span>{{ $t('dashboard.taxes') }}</span>
          </div>
        </div>

        <div class="item">
          <div class="left">
            <van-icon name="circle" size="17" class="mr-2" />
            <span>{{ $t('dashboard.saleTax') }}</span>
          </div>
          <div class="right">{{ $currency }} {{ $cast(summary.sale_tax) }}</div>
        </div>

        <div class="item">
          <div class="left">
            <van-icon name="circle" size="17" class="mr-2" />
            <span>{{ $t('dashboard.purchaseTax') }}</span>
          </div>
          <div class="right">{{ $currency }} {{ $cast(summary.purchase_tax) }}</div>
        </div>

        <div class="item">
          <div class="left">
            <van-icon name="circle" size="17" class="mr-2" />
            <span>{{ $t('dashboard.withholdingTax') }}</span>
          </div>
          <div class="right">{{ $currency }} {{ $cast(summary.wht) }}</div>
        </div>
        <!--  -->
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  props: ['summary'],
  data() {
    return {
      activeName: 'summary',
    }
  },
  methods: {
    capital() {
      let { assets, liability, equity } = this.summary
      return assets - liability
    },
  },
}
</script>

<style lang="scss" scoped>
.red {
  color: red !important;
}
.green {
  color: green !important;
}

.item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: 2px solid #f4f4f4;

  .left {
    display: flex;
  }
}
.no-border {
  border-top: none;
}
</style>