<template>
  <section>
    <FrontendNav />
    <section class="payment container">
      <div class="status-container" :class="{ success: isSuccess }">
        <div class="img-container">
          <img v-if="isSuccess" src="@/assets/images/illustrations/pay-success.svg" />
          <img v-else src="@/assets/images/illustrations/pay-fail.svg" />
        </div>
        <div class="content-container">
          <svg
            v-if="isSuccess"
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.2422 37.4844C29.5932 37.4844 37.9844 29.0932 37.9844 18.7422C37.9844 8.39117 29.5932 0 19.2422 0C8.89116 0 0.5 8.39117 0.5 18.7422C0.5 29.0932 8.89116 37.4844 19.2422 37.4844Z"
              fill="#4ADB61"
            />
            <path
              d="M11.8269 18.7141L17.2441 24.1313L28.571 12.8044"
              stroke="white"
              stroke-width="2.95485"
              stroke-linecap="round"
            />
          </svg>
          <svg v-else width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.2422 37.4844C29.5932 37.4844 37.9844 29.0932 37.9844 18.7422C37.9844 8.39117 29.5932 0 19.2422 0C8.89116 0 0.5 8.39117 0.5 18.7422C0.5 29.0932 8.89116 37.4844 19.2422 37.4844Z"
              fill="#DB4A4A"
            />
            <path d="M13.5787 24.4055L24.9057 13.0786" stroke="white" stroke-width="2.95485" stroke-linecap="round" />
            <path d="M24.9056 24.4055L13.5787 13.0786" stroke="white" stroke-width="2.95485" stroke-linecap="round" />
          </svg>

          <h3 v-if="isSuccess">Payment Successful</h3>
          <h3 v-else>
            Oh no! <br />
            Something went wrong
          </h3>
          <p v-if="isSuccess">Your payment was successful! You can now continue using MaNaw Store.</p>
          <p v-else>We aren’t able to process your payment. <br />Please try again.</p>
          <router-link v-if="isSuccess" to="/settings/my-accounts">
            <button class="btn btn-primary dashboard-btn">Go to Plan Detail</button>
          </router-link>
          <a href="#" v-else>
            <button
              class="btn btn-outline-primary dashboard-btn outline-btn"
              @click="$router.push('/choose_payment_method')"
            >
              Try Again
            </button>
          </a>
        </div>
      </div>
    </section>
    <FrontendFooter />
  </section>
</template>

<script>
export default {
  name: 'Payment',
  data: () => ({
    status: null,
  }),
  computed: {
    isSuccess: function () {
      return this.status === 'success'
    },
  },
  mounted() {
    this.status = this.$route.params.status
  },
}
</script>

<style lang="scss" scoped>
.payment {
  margin-top: 3rem;
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .success {
    padding: 27px 20px;
  }
  .status-container {
    background-color: #fff;
    padding: 34px 20px;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    @media screen and (min-width: 770px) {
      .img-container {
        flex: 1;
      }
      .content-container {
        flex: 1;
      }
    }
    .img-container {
      img {
        width: 80%;
        display: block;
        margin: 0 auto;
      }
    }

    .content-container {
      text-align: center;
      max-width: 25rem;
      display: flex;
      flex-direction: column;
      gap: 6px;
      align-items: center;

      svg {
        margin-bottom: 10px;
      }
      h3 {
        font-weight: 600;
      }
      .dashboard-btn {
        background-color: var(--web-theme-secondary);
        padding: 0 20px;
        font-weight: normal;

        &:hover {
          background-color: #443adb;
        }
      }
      .outline-btn {
        background-color: transparent;
        border: 2px solid var(--web-theme-secondary);
        color: var(--web-theme-secondary);
        padding: 0 40px;

        &:hover {
          background-color: #d8d6ff;
        }
      }
      @media screen and (max-width: 770px) {
        a {
          display: block;
          width: 100%;

          .dashboard-btn {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>