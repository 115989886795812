<template>
  <van-popup v-model="popupData.show" class="export-error">
    <div class="alert-wrapper">
      <div class="icon-container">
        <slot></slot>
      </div>
      <h3>{{ popupData.title }}</h3>
      <p>{{ popupData.message }}</p>
      <div class="actions">
        <button v-for="(action,idx) in (popupData.actions || actions)" :key="idx"
                :class="`btn ${action.type === 'ok' ? 'btn-web-primary': ''}`"
                @click="popupData.show=false; action.route && $router.push(action.route)">
          {{
            action.display
          }}
        </button>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'PopupMessage',
  props: ['popupData'],
  data: ()=>({
    actions: [
      {
        type: 'ok',
        display: 'OK',
      }
    ],
  })
}
</script>

<style lang="scss" scoped>
.export-error {
  border-radius: 15px;
  padding: 20px 25px;
  min-width: 30rem;

  .alert-wrapper {
    max-width: 40rem;
    margin: auto;
    text-align: center;

    .icon-container {
      margin: 15px 0 8px;
    }

    h3 {
      font-weight: bold;
      margin: 10px 0;
    }

    p {
      max-width: 30rem;
    }

    .actions {
      text-align: right;
      margin-top: 20px;
      display: flex;
      align-items: center;

      button {
        width: 100%;
      }
    }
  }
}
</style>