<template>
  <div class="profile-container">
    <div class="bg-white">
      <h1>{{ $t('setting.myProfile') }}</h1>

      <div class="form-card mt-5">
        <div class="d-flex justify-content-center mb-3">
          <div class="position-relative">
            <ImageUploader :value="uploader" radius="50%" @input="setImage" />
            <span v-if="uploader.length > 0 && !uploader[0].url" class="save-image" @click="changeImage">Save</span>
          </div>
        </div>

        <div class="position-relative">
          <Input :errors="errors" :params="params" field="full_name" :label="$t('name')" />
          <span v-if="params.full_name != $auth.user().full_name" class="change text-success" @click="changeName()">{{
            $t('action.save')
          }}</span>
        </div>

        <div class="position-relative">
          <Input :disabled="true" :errors="errors" :params="params" field="email" :label="$t('email')" type="email" />
          <span class="change" @click="$refs.changeEmailPopup.open()">{{ $t('action.change') }}</span>
        </div>

        <div class="position-relative">
          <Input
            :disabled="true"
            :errors="errors"
            :params="params"
            field="password"
            :label="$t('password')"
            type="password"
          />
          <span class="change" @click="$refs.changePasswordPopup.open()">{{ $t('action.change') }}</span>
        </div>
      </div>
      <div>
        <ChangeEmailPopup ref="changeEmailPopup" :params="params" />
        <ChangePasswordPopup ref="changePasswordPopup" :params="params" />
        <alert-popup ref="nameAlert" :autoCloseTime="2000" message="Name Changed Successfully" />
        <alert-popup ref="imageAlert" :autoCloseTime="2000" message="Image Changed Successfully" />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import ChangeEmailPopup from './components/ChangeEmailPopup'
import ChangePasswordPopup from './components/ChangePasswordPopup'
// import PaymentLog from './components/PaymentLog'
import moment from 'moment'

export default {
  components: { ChangeEmailPopup, ChangePasswordPopup },
  data() {
    return {
      uploader: [],
      params: {},
      errors: {},
      plan: {
        start: moment(this.$auth.user().start_date, 'YYYY-MM-DD').format('DD MMM YY'),
        expire: moment(this.$auth.user().expired_date, 'YYYY-MM-DD').format('DD MMM YY'),
      },
    }
  },
  methods: {
    languageChange(e) {
      window.localStorage.setItem('manaw_locale', e)
    },
    setImage(value) {
      this.uploader = value
      if (this.uploader[0]) {
        this.params.image = this.uploader[0].file
      }
    },
    async changeImage() {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        duration: 0,
      })
      let fd = new FormData()
      fd.append('profile_image', this.params.image)
      let res = await axios.post('change/profile_image', fd)
      if (res) {
        this.uploader = [{ url: res.data.data }]
        this.$refs.imageAlert.open()
        Toast.clear()
      }
    },
    async changeName() {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        duration: 0,
      })
      let params = { full_name: this.params.full_name }
      let res = await axios.post('change/name', params)
      if (res) {
        if (res.data.code == 422) {
          this.errors = res.data.error
        } else {
          this.$auth.user().full_name = this.params.full_name
          this.$refs.nameAlert.open()
        }
        Toast.clear()
      }
    },
  },
  mounted() {
    this.params = { ...this.$auth.user() }
    this.params.password = 'password'
    if (this.params.profile_image) {
      this.uploader = [{ url: this.params.profile_image }]
    }
  },
}
</script>

<style lang="scss" scoped>
h3 {
  font-size: 13px;
  font-weight: 700;
  color: #000;
}

.form-card {
  max-width: 430px;
  margin: auto;
}

.change {
  position: absolute;
  right: 15px;
  top: 58%;
  font-weight: 600;
  cursor: pointer;
}

.bg-white {
  padding-bottom: 100px;
}

.save-image {
  position: absolute;
  right: 20px;
  bottom: 15px;
  background: var(--theme);
  border-radius: 10px;
  color: white;
  padding: 3px 7px;
  font-size: 10px;
  cursor: pointer;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.profile-container {
  background: #f4f4f4;
  width: calc(100% + 50px);
  margin: -20px -25px -30px -25px;

  .bg-white {
    padding: 20px 25px 30px 25px;
    border-radius: 10px;
  }
}
</style>