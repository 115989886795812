<template>
  <div class="bg-white">
    <div class="d-flex justify-content-between align-items-center">
      <custom-search v-model="params.keyword" @search="fetchData()"/>
      <van-button @click="$refs.formPopup.openForm()" class="add-button" type="default">
        <van-icon name="add-o" size="20" class="mr-2"/>
        {{ $t('mngmt.coa.newCoa') }}
      </van-button>
    </div>

    <div v-if="loaded">
      <div v-if="coas.length > 0">
        <table class="table my-border-table mt-4">
          <tbody>
          <tr v-for="(coa, index) in coas" :key="index">
            <td>
              <div class="d-flex">
                <div class="ml-4">
                  <p class="mb-0 text-bold">{{ coa.name }}</p>
                  <p class="mb-0 text-muted text-sm">{{ coa.coa }}, {{ coa.group }}</p>
                </div>
              </div>
            </td>
            <td class="text-right">
              <van-popover
                  v-model="coa.show_menu"
                  placement="bottom-end"
                  :close-on-click-outside="true"
                  trigger="click"
                  @open="menuOpen(index)"
                  :actions="coa.is_manual ? actions : actionOnlyClone"
                  @select="menuSelect($event, coa.id, index)"
              >
                <template #reference>
                  <van-icon name="ellipsis" size="20" class="pointer"/>
                </template>
              </van-popover>
            </td>
          </tr>
          </tbody>
        </table>

        <b-pagination
            :value="params.page"
            :per-page="params.limit"
            :total-rows="total"
            @change="changePage"
            first-number
            last-number
            pills
            align="center"
            class="mt-4"
        ></b-pagination>
      </div>
      <van-empty image="/image/empty-lists/empty-coa.svg" v-else :description="$t('mngmt.coa.emptyCoa')"/>
    </div>

    <div v-else class="loading">
      <van-loading type="spinner"/>
    </div>

    <FormPopup ref="formPopup" @done="fetchData()"/>
  </div>
</template>

<script>
import {Dialog, Toast} from 'vant'
import FormPopup from './components/FormPopup'

export default {
  components: {FormPopup},
  data() {
    return {
      actions: [
        {text: this.$t('mngmt.coa.edit'), icon: 'edit', value: 'edit'},
        {text: this.$t('mngmt.coa.clone'), icon: 'cluster-o', value: 'clone'},
        {text: this.$t('mngmt.coa.del'), icon: 'delete-o', value: 'delete'},
      ],
      actionOnlyClone: [{text: 'Clone COA', icon: 'cluster-o', value: 'clone'}],
      params: {
        keyword: '',
        page: 1,
        limit: 10,
      },
      coas: [],
      total: 0,
      loaded: false,
    }
  },
  methods: {
    menuOpen(index) {
      this.coas = this.coas.map((coa) => {
        coa.show_menu = false
        return coa
      })
      this.coas[index].show_menu = true
    },
    menuSelect(e, id, index) {
      switch (e.value) {
        case 'edit':
          this.$refs.formPopup.openForm(this.coas[index])
          break
        case 'delete':
          this.deleteCoa(id)
          break
        case 'clone':
          this.$refs.formPopup.openForm(this.coas[index], true)
          break
      }
    },
    async deleteCoa(id) {
      await Dialog.confirm({
        title: this.$t('action.areYouSure'),
        cancelButtonText: this.$t('action.cancel'),
        confirmButtonText: this.$t('action.del'),
      })
      let res = await axios.delete(`/chart_of_accounts/${id}`)
      if (res) {
        Toast.success(this.$t('state.deleted'))
        this.fetchData()
      }
    },
    async fetchData() {
      let res = await axios.get('chart_of_accounts', {params: this.params})
      if (res) {
        this.coas = res.data.data.data.map((coa) => {
          coa.show_menu = false
          return coa
        })
        this.total = res.data.data.total
        this.loaded = true
      }
    },
    changePage(page) {
      this.params.page = page
      this.fetchData()
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>

<style lang="scss" scoped>
.my-border-table {
  td {
    padding-left: 0 !important;
  }
}
</style>