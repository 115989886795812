<template>
  <div class="bg-white">
    <h1 @click="$router.back()" class="pointer">
      <van-icon class="mr-3" name="arrow-left" size="15px" />
      <span v-if="$route.params.id">{{ params.full_name }}</span>
      <span v-else> {{ $t('mngmt.admin.addNewAdmin') }}</span>
    </h1>
    <div v-if="loaded" class="form-card mt-5">
      <Input :params="params" :errors="errors" field="full_name" :label="$t('name')" />
      <Input :params="params" :errors="errors" field="email" :label="$t('email')" type="email" />
      <Input :params="params" :errors="errors" field="phone" :label="$t('phoneNumber')" />
      <Input :params="params" :errors="errors" field="password" :label="$t('password')" />

      <SelectBox
        align="left"
        v-model="params.user_type"
        :options="roles"
        :label="$t('mngmt.admin.role')"
        field="user_type"
        :errors="errors"
      />
      <Button :type="$route.params.id ? 'edit' : 'create'" :loading="loading" name="Admin" @click.native="saveData()" />
    </div>
    <div v-else class="loading">
      <van-loading type="spinner" />
    </div>
  </div>
</template>

<script>
import {  Toast } from 'vant'
import SelectBox from './components/SelectBox'
export default {
  components: { SelectBox },
  data() {
    return {
      loaded: false,
      loading: false,
      roles: [],
      params: {
        full_name: null,
        email: null,
        phone: null,
        password: Math.random().toString(36).slice(-10),
        user_type: '',
      },
      errors: {},
    }
  },
  methods: {
    async saveData() {
      this.loading = true
      let res =
        this.$route.params.submitType == 'edit'
          ? await axios.put(`/admins/${this.$route.params.id}`, this.params)
          : await axios.post('/admins', this.params)
      if (res) {
        this.loading = false
        if (res.data.code == 422) {
          this.errors = res.data.error
        } else if (res.data.success) {
          let message =
            this.$route.params.submitType == 'edit'
              ? 'Edited'
              : this.$route.params.submitType == 'clone'
              ? 'Cloned'
              : 'Created'
          Toast.success(message)
          this.$router.back()
        }
      }
    },
    async fetchData() {
      let res = await axios.get(`admins/${this.$route.params.id}`)
      if (res) {
        let data = res.data.data
        this.params.full_name = data.full_name
        this.params.email = data.email
        this.params.phone = data.phone
        this.params.user_type = data.user_type
        if (this.$route.params.submitType == 'edit') {
          this.params.password = null
        }
        this.loaded = true
      }
    },
    async fetchRoles() {
      let res = await axios.get('/roles')
      if (res) {
        this.roles = res.data.data
      }
    },
  },
  mounted() {
    this.fetchRoles()
    if (this.$route.params.id) {
      this.fetchData()
    } else {
      this.loaded = true
    }
  },
}
</script>

<style lang="scss" scoped>
.form-card {
  max-width: 540px;
  margin: auto;
}
</style>