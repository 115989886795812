<template>
  <date-range-picker
    :value="{ startDate : value, end}"
    lang="en"
    type="date"
    :showWeekNumbers="false"
    :autoApply="true"
    :ranges="false"
    opens="left"
    :locale-data="locale"
    :singleDatePicker="true"
  >
    <template v-slot:input="picker">
      <div class="calendar-box-2 d-flex justify-content-center">
        <i class="dashboard-calendar-icon"></i>{{ dateFormat(picker.startDate) | date }}
      </div>
    </template>
  </date-range-picker>
</template>

<script>
export default {
  props : ['value'],
  data() {
    return {
      invoiceDate: {
        startDate: new Date(),
      },
      dueDate: {
        startDate: new Date(),
      },
      locale: {
        format: 'mm/dd/yyyy',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
    }
  },
  methods: {
    dateFormat(date) {
      return date ? moment(date).format('DD MMM, YYYY') : null
    },
  },
  filters: {
    date(val) {
      return val ? moment(val).format('LL') : ''
    },
  },
}
</script>