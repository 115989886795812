<template>
  <div>
    <div class="language mt-4">
      <h1 class="mb-4">{{ $t('language') }}</h1>
      <label class="label">{{ $t('setting.changeLanguage')}}</label>
      <div class="col-3 p-0">
        <v-select
            :options="[
                  { label: 'English', code: 'en' },
                  { label: 'မြန်မာ', code: 'mm' },
                ]"
            v-model="$root.$i18n.locale"
            :reduce="(lang) => lang.code"
            @input="languageChange"
            :clearable="false"
        ></v-select>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {vSelect},
  methods: {
    languageChange(e) {
      window.localStorage.setItem('manaw_locale', e)
    },
  }
}
</script>

<style lang="scss" scoped>

</style>