<template>
  <div>
    <vSelect
      :value="params.account"
      :options="accounts"
      :getOptionLabel="optionLabel"
      :clearable="false"
      @input="pushData"
      :taggable="true"
      placeholder="Select Account"
    >
      <template #list-header>
        <label class="label">Account</label>
      </template>
      <template #option="{ owner_name }">
        <div class="data">
          <div>{{ owner_name }}</div>
        </div>
      </template>
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <svg width="15" height="14" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.7704 6.64453L10.6088 11.485C9.99921 12.0567 9.00171 12.0567 8.39213 11.485L3.23047 6.64453"
              stroke="rgba(0,0,0,0.2)"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </template>
    </vSelect>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: { vSelect },
  props: ['value', 'params'],
  data() {
    return {
      accounts: [],
      dataParams: {
        page: 1,
        limit: 10,
      },
    }
  },
  methods: {
    async fetchData() {
      let res = await axios.get('accounts', { params: this.dataParams })
      if (res) {
        this.accounts = res.data.data.filter(acc => acc.id !== 0)
      }
    },
    pushData(e) {
      this.$emit('change', e)
    },

    optionLabel({ account_number, owner_name }) {
      return `${owner_name}`
    },
  },
  mounted() {
      this.fetchData()
  },
}
</script>

<style lang="scss" scoped>
.data {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}
</style>