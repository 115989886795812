var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.type == 'categories')?_c('div',{attrs:{"id":"v-select-to-create"}},[_c('vSelect',{attrs:{"value":_vm.value,"options":_vm.categories,"reduce":function (category) { return category.id; },"label":"name","taggable":true,"clearable":false,"placeholder":"Type here to create or search category"},on:{"option:created":_vm.optionCreated,"input":_vm.pushData},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [_vm._v(" "+_vm._s(name)+" ")]}},{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('svg',{attrs:{"width":"15","height":"14","viewBox":"0 0 19 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M15.7704 6.64453L10.6088 11.485C9.99921 12.0567 9.00171 12.0567 8.39213 11.485L3.23047 6.64453","stroke":"black","stroke-width":"2","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}})])])]}}],null,false,904909400)})],1):(_vm.type === 'currency')?_c('div',[_c('vSelect',{attrs:{"value":_vm.value,"options":_vm.currencies,"reduce":function (currency) { return currency.code; },"label":"currency","placeholder":"Choose Category"},on:{"input":_vm.pushData},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var currency = ref.currency;
var code = ref.code;
return [_vm._v(" "+_vm._s(code)+" ("+_vm._s(currency)+") ")]}}])})],1):_c('div',{attrs:{"id":"v-select-to-create"}},[_c('vSelect',{attrs:{"value":_vm.value,"options":_vm.options,"reduce":function (option) { return option.id; },"label":"name"},on:{"input":_vm.pushData},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [_vm._v(" "+_vm._s(name)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }