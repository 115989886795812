<template>
  <div class="custom-select-vue">
    <v-select 
      :value="value" 
      @input="$emit('input', $event)"
      :options="accounts" 
      :reduce="(account) => account.id"
      :clearable="false"
      :placeholder="$t('mngmt.payrolls.payFrom')"
      label="owner_name"
    >
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <svg width="12" height="7" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L7 7L13 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
      </template>
      <template #option="{ owner_name, account_number }">
        <div class="list-card">
          <div>{{ owner_name }}</div>
          <span>{{ account_number }}</span>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  props : ['value'],
  components : { vSelect },
  data() {
    return {
      accounts : [],
      params : {
        page : 1,
        limit : 10
      }
    }
  },
  methods: {
    async getAccounts() {
      let res = await axios.get('/accounts', { params : this.params })
      if (res.data) {
        this.accounts = res.data.data.filter(acc => acc.id !== 0)
      }
    }
  },
  mounted() {
    this.getAccounts()
  },
}
</script>

<style lang="scss" scoped>
  .list-card {
    display: flex;
    flex-direction: column;

    div {
      font-size: 11px;
    }

    span {
      color : rgba(0,0,0,0.5);
      font-size : 10px;
      letter-spacing: 1px;
    }
  }
</style>

<style lang="scss">
  .custom-select-vue {
    min-width : 120px;
    height: 30px;

    .v-select {
      height: 38px;
    }

    .vs--searchable .vs__dropdown-toggle {
      height: 38px;
      margin-top : -1px;
    }
  }
</style>
