import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vant from 'vant'
import VueApexCharts from 'vue-apexcharts'
import Verte from 'verte'
import './firebase'
import VueBarcodeScanner from 'vue-barcode-scanner'
import VueDragscroll from 'vue-dragscroll'
import VueEvents from 'vue-events'
Vue.use(VueEvents)

Vue.component('verte', Verte)
Vue.use(VueApexCharts)
Vue.use(Vant)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueDragscroll)

let options = {
  sound: true,
  soundSrc: '/beep.wav',
  sensitivity: 100,
}

Vue.use(VueBarcodeScanner, options)
