import Vue from 'vue'
import Vuex from 'vuex'
import alasql from 'alasql'

Vue.use(Vuex)

let cartItems
let xCartItems = window.localStorage.getItem('x_cart_items')
if (xCartItems) {
  cartItems = JSON.parse(xCartItems)
} else {
  cartItems = []
}

let groupItems = (items) => {
  let newItems = alasql('SELECT id, SUM(quantity) AS quantity FROM ? GROUP BY id', [items])
  return newItems.map((item) => {
    let originalItem = items.find((i) => i.id == item.id)
    originalItem.quantity = item.quantity
    return originalItem
  })
}

const store = new Vuex.Store({
  state: {
    theme: window.$themeColor,
    cart_items: cartItems,
    pick_customer: false,
    optionChanged: false,
    popup: {
      code: 0,
      title: 'Error',
      message: 'Something went wrong!',
      show: false,
    },
  },
  mutations: {
    setPopup(state, value) {
      state.popup = value
    },
    setOptionChanged(state, value) {
      state.optionChanged = value
    },
    setTheme(state, value) {
      state.theme = value
    },
    pickCustomer(state, status) {
      state.pick_customer = status
    },
    addItem(state, item) {
      state.cart_items.push(item)
      console.log(item)
      window.localStorage.setItem('x_cart_items', JSON.stringify(state.cart_items))
    },
    deleteItem(state, index) {
      let item = state.cart_items[index]
      if (item.quantity <= 1) {
        state.cart_items.splice(index, 1)
      } else {
        state.cart_items[index].quantity = state.cart_items[index].quantity - 1
      }
      window.localStorage.setItem('x_cart_items', JSON.stringify(state.cart_items))
    },

    groupCartItems(state, index) {
      let newState = groupItems(state.cart_items)
      state.cart_items = newState
      window.localStorage.setItem('x_cart_items', JSON.stringify(state.cart_items))
    },

    clearItem(state) {
      state.cart_items = []
      window.localStorage.setItem('x_cart_items', JSON.stringify(state.cart_items))
    },
  },

  getters: {
    cartItemSubTotal: (state) => {
      return parseFloat(state.cart_items.reduce((sum, { price, quantity }) => sum + quantity * price, 0))
    },

    cartTax: (state, getters) => {
      return parseFloat(((getters.cartItemSubTotal * window.$company.tax) / 100).toFixed(2))
    },

    cartItemGrandTotal: (state, getters) => {
      return getters.cartItemSubTotal + getters.cartTax
    },

    totalCartItems: (state) => {
      return state.cart_items.reduce((sum, { quantity }) => sum + quantity, 0)
    },
    cartItems: (state) => {
      return state.cart_items
    },
    pickCustomer: (state) => state.pick_customer,
    getPopup: (state) => state.popup,
  },
})

export default store
