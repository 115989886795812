<template>
  <div>
    <!-- ===== start category columns container ===== -->
    <div class="product-container mt-3">
      <div v-for="(category, index) in categories" :key="index" class="product-item">
        <div class="pm-layout-categories">
          <div class="img-title"
               @click="
                $router.push({
                  path: `/management/categories/edit/${category.id}`,
                })
              "
          >
            <div
                class="category-image"

            >
              <img :src="category.image" class="mx-auto my-auto cat-image"/>
            </div>
            <h6>{{ category.name }}</h6>
          </div>

          <div class="d-flex justify-content-end" style="height: auto">
            <div class="pt-2" style="cursor: pointer; width: 15px; height: 30px "
            >
              <!-- ==== popover options ==== -->
              <van-popover
                  :id="category.id"
                  v-model="category.selected"
                  :actions="actions"
                  placement="bottom-end"
                  trigger="click"
                  @select="onSelect($event, category.id, index)"
              >
                <template #reference>
                  <svg fill="none" height="3" viewBox="0 0 12 3" width="12" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="1.51094" cy="1.50648" fill="#202020" r="1.48207"/>
                    <circle cx="5.96198" cy="1.50648" fill="#202020" r="1.48207"/>
                    <circle cx="10.413" cy="1.50648" fill="#202020" r="1.48207"/>
                  </svg>
                </template>
              </van-popover>
              <!-- ==== end popover options ==== -->
            </div>
          </div>
        </div>
      </div>
      <div v-for="i in 10" :key="'v' + i" class="product-item invisible"></div>
    </div>
  </div>
</template>


<script>
import {Dialog, Toast} from 'vant'

export default {
  props: {
    categories: {
      type: Array,
    },
  },
  data() {
    return {
      actions: [
        {text: this.$t('action.edit'), className: 'popoverEdit', action: 'edit'},
        {text: this.$t('action.remove'), className: 'popoverDelete', action: 'delete'},
      ],
    }
  },
  methods: {
    onSelect(event, categoryId, index) {
      switch (event.action) {
        case 'delete':
          this.deleteCategory(categoryId, index)
          break
        case 'edit':
          this.$router.push({
            path: `/management/categories/edit/${categoryId}`,
          })
          break
        case 'clone':
          // this.deletedCategory(id);
          break

        default:
          break
      }
    },

    //  ==== to delete category ====
    async deleteCategory(categoryId, index) {
      await Dialog.confirm({
        title: this.$t('action.areYouSure'),
        cancelButtonText: this.$t('action.cancel'),
        confirmButtonText: this.$t('action.del'),
      })
      this.categories.splice(index, 1)
      try {
        const res = axios.delete(`/categories/${categoryId}`)
        Toast.success(this.$t('state.deleted'))
      } catch (error) {
        Toast.fail(this.$t('state.fail'))
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.pm-layout-categories-bottom {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 100%;
}

.pm-layout-categories {
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #f4f4f4;
  padding: 1px 20px 1px 10px;
}

.danger {
  color: red;
}

.img-title {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;

  h6 {
    margin: 0;
  }
}

.product-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.product-item {
  flex: 1 1 auto;

}

.category-image {
  width: 50px;
  height: 50px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

h6 {
  font-size: 13px;
  font-weight: 700;
}

.product-count {
  font-size: 11px;
}
</style>
