import Vue from 'vue'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'
import auth from '@websanova/vue-auth/src/v2.js'
import driverAuthBearer from '@websanova/vue-auth/src/drivers/auth/bearer.js'
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x.js'
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js'

import './@plugins'
import './@components'
import store from './@plugins/store'

import axios from 'axios'
import * as $ from 'jquery'
import VueAxios from 'vue-axios'
import App from './pages/App'
import routes from './routes'
import env from './env'
import '../sass/app.scss'
import * as VueGoogleMaps from "vue2-google-maps";

import messages from './lang/_index'

Vue.use(VueAxios, axios)
Vue.use(VueRouter)
window.axios = axios
window.$ = $
window.userTZ = Intl.DateTimeFormat().resolvedOptions().timeZone
axios.defaults.baseURL = env.baseURL
window.baseURL = env.baseURL
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['X-TZ'] = window.userTZ

const router = new VueRouter({
  mode: 'history',
  routes,
  linkExactActiveClass: 'active',
})

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router,
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    staySignedIn: true,
    stores: ['storage'],
    loginData: {
      url: 'auth/login',
      method: 'POST',
    },
    registerData: {
      url: 'auth/login',
      method: 'POST',
    },
    fetchData: {
      url: 'auth/user',
      method: 'GET',
    },
    refreshData: {
      url: 'auth/refresh',
      method: 'GET',
    },
    authRedirect: '/redirect-main-login',
    notFoundRedirect: {
      path: '/pos',
    },
  },
})

// locales
Vue.use(VueI18n)
const storedLocale = window.localStorage.getItem('manaw_locale');
let locale = storedLocale || 'en';
const i18n = new VueI18n({
  locale,
  messages,
  // silentFallbackWarn: true,
})
// end locales

// Google Map
Vue.use(VueGoogleMaps, {
  load: {
    libraries: "places",
    key: env.googleApiKey ,
  },
  installComponents: true,
});
//end google map

const app = new Vue({
  el: '#app',
  i18n,
  store,
  router,
  render: (h) => h(App),
})

window.alertFail = (key) => {
  app.$toast({
    type: 'html',
    position: 'top',
    message: '<span class="text-red"><img src="/image/info.svg" class="mr-2" />' + app.$t(key) + '</span>',
  })
}

import companyMiddleware from './@middleware/companyMiddleware'
window.callCompanyMiddleware = false

router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  if (window.callCompanyMiddleware && to && to.matched.some((record) => record.meta.requireCompany)) {
    companyMiddleware(to, from, next)
  } else {
    next()
  }
})

if (env.appMode == 'production') {
  console.warn = () => {}
  console.log = () => {}
}

axios.interceptors.response.use((res) => {

  if(res.status == '401') {
    window.location.href = '/login'
  }

  app.$toast.clear()
  app.$store.commit('setPopup', {})
  if (!res.data.hasOwnProperty('success') && !res.data.hasOwnProperty('status')) {
    return res
  }
  if (!res.data.success || res.data.status !== 'success') {
    // validation error
    if (res.data.code == 422) {
      return res
    }
    // upgrade error
    if (res.data.code == 403) {
      app.$store.commit('setPopup', {
        code: 403,
        title: app.$t('upgradeError'),
        message: res.data.error,
        icon: '/image/popups/lock.svg',
        actions: [
          {
            type: 'cancel',
            display: app.$t('action.notNow'),
          },
          {
            type: 'ok',
            route: '/pricing',
            display: app.$t('action.upgrade'),
          },
        ],
        show: true,
      })
    } else {
      // < 310 are middleware error
      if (res.data.code >= 310) {
        // normal validation error
        app.$store.commit('setPopup', {
          code: res.data.code,
          title: app.$t('error'),
          icon: '/image/popups/error.svg',
          message: res.data.error,
          show: true,
        })
      }
    }

    return res
  }
  return res
})
