<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h5 class="text-bold d-flex text-uppercase align-items-center">
        <van-icon
          :color="type == 'sale' ? '#00A651' : '#e9585e'"
          :name="type == 'sale' ? 'balance-o' : 'cart-o'"
          class="mr-2"
          size="30"
        />
        {{ type == 'sale' ? $t('sale_invoices') : $t('purchase_invoices') }}
      </h5>
      <MyDateRangePicker :params="params" @select="dateFilter" />
    </div>

    <div class="card p-4 mt-3">
      <div class="d-flex justify-content-between align-items-center">
        <custom-search v-model="params.keyword" class="mr-3" @input="getInvoice()" />
        <van-button class="add-button" type="default" @click="$router.push(`/invoices/${type}/create`)">
          <van-icon class="mr-2" name="add-o" size="20" />
          {{ type == 'sale' ? $t('invoice.create_sale_invoice') : $t('invoice.create_purchase_invoice') }}
        </van-button>
      </div>

      <van-checkbox-group v-if="items.length > 0" ref="invoiceTable" v-model="selectedItem">
        <table class="table my-border-table">
          <thead>
            <tr v-if="selectedItem.length > 0">
              <th colspan="6">
                <div class="d-flex justify-content-between">
                  <div>
                    <span class="mr-4">{{ $t('action.selected', { count: selectedItem.length }) }}</span>
                    <span @click="$refs.invoiceTable.toggleAll(true)">{{ $t('action.selectAll') }}</span>
                  </div>
                  <div>
                    <span class="text-success mr-4" @click="printInvoice()">{{ $t('action.printAll') }}</span>
                    <span @click="$refs.invoiceTable.toggleAll(false)">{{ $t('action.cancel') }}</span>
                  </div>
                </div>
              </th>
            </tr>
            <tr v-else>
              <th>{{ $t('invoice.invoiceNumber') }}</th>
              <th class="text-center">{{ $t('invoice.createdDate') }}</th>
              <th>{{ type == 'sale' ? $t('customer') : $t('supplier') }}</th>
              <th class="text-center">{{ $t('invoice.account') }}</th>
              <th class="text-center">{{ $t('invoice.paidByTotal') }}</th>
              <th class="text-right">{{ $t('invoice.leftAmount') }}</th>
              <th></th>
            </tr>
          </thead>
          <!--  -->
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <div class="d-flex">
                <van-checkbox :name="item.id" icon-size="17px" shape="square"></van-checkbox>
                <van-icon name="eye-o" @click="openPopup(item.id)" size="20" class="ml-3" />
                <div class="ml-4" @click="$router.push(`/invoices/${type}/edit/${item.id}`)">
                  <p class="mb-0 text-bold">{{ item.invoice_number }}</p>
                  <p class="mb-0 text-muted text-sm">{{ item.reference_number }}</p>
                </div>
              </div>
            </td>
            <td class="text-center">
              <span :style="`background: ${type == 'sale' ? '#00A651' : '#e9585e'}`" class="badge-custom">{{
                moment(item.created_at).format('DD MMM, YYYY')
              }}</span>
            </td>
            <td>
              <p class="mb-0">
                <van-icon name="contact" />
                {{ item.receiver.receiver_name }}
              </p>
              <p class="mb-0 text-muted text-sm">
                <van-icon name="phone-circle-o" />
                {{ item.receiver.receiver_phone }}
              </p>
            </td>
            <td class="text-center">
              <p v-if="item.account" class="mb-0">{{ item.account.owner_name }}</p>
              <p v-if="item.account" class="mb-0 text-muted text-sm">{{ item.account.account_number }}</p>
              <p v-if="!item.account" class="mb-0">-</p>
            </td>
            <td class="text-center">
              <div>{{ $currency }} {{ $cast(item.paid_amount) }}</div>
              <div class="line"></div>
              <div>{{ $currency }} {{ $cast(item.grand_total) }}</div>
            </td>
            <td class="text-right">
              <span v-if="item.grand_total - item.paid_amount == 0" class="badge badge-success">
                {{ $t('invoice.fullyPaid') }}
              </span>
              <span v-else> {{ $currency }} {{ $cast(item.grand_total - item.paid_amount) }} </span>
            </td>
            <td class="text-right">
              <van-popover
                v-model="item.show_menu"
                :actions="actions"
                :close-on-click-outside="true"
                placement="bottom-end"
                trigger="click"
                @open="menuOpen(index)"
                @select="menuSelect($event, item.id)"
              >
                <template #reference>
                  <van-icon class="pointer" name="ellipsis" size="20" />
                </template>
              </van-popover>
            </td>
          </tr>
          <!--  -->
        </table>
      </van-checkbox-group>
      <div v-else>
        <van-empty :description="$t('invoice.emptyInvoice')" image="/image/empty-lists/empty-invoice.svg" />
      </div>
      <b-pagination
        v-if="items.length > 0 && total >= params.limit"
        :per-page="params.limit"
        :total-rows="total"
        :value="params.page"
        align="center"
        class="mt-4"
        first-number
        last-number
        pills
        @change="changePage"
      ></b-pagination>
      <InvoicePopup v-if="popup.id" :popup="popup" @print="(id) => openPrint(id)" @printpdf="(id) => pdfPrint(id)" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { Dialog, Toast } from 'vant'
import InvoicePopup from '@/pages/invoices/components/InvoicePopup'

export default {
  components: { InvoicePopup },
  data() {
    return {
      printUrl: '',
      type: this.$route.params.type,
      moment,
      showPopover: false,
      actions: [
        { text: this.$t('invoice.edit'), icon: 'edit', value: 'edit' },
        { text: this.$t('invoice.clone'), icon: 'cluster-o', value: 'clone' },
        { text: this.$t('invoice.del'), icon: 'delete-o', value: 'delete' },
        // { text: 'Print Invoice', icon: 'description', value: 'print' },
      ],
      items: [],
      params: {
        page: 1,
        limit: 10,
        keyword: '',
        start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        type: this.$route.params.type,
      },
      total: 0,
      selectedItem: [],
      popup: { id: '', show: false },
    }
  },
  methods: {
    async openPopup(id) {
      this.popup = { id, show: true }
    },
    changePage(page) {
      this.params.page = page
      this.getInvoice()
    },
    menuOpen(index, data) {
      this.items = this.items.map((item) => {
        item.show_menu = false
        return item
      })
      this.items[index].show_menu = true
    },
    menuSelect(e, id) {
      switch (e.value) {
        case 'edit':
          this.$router.push(`/invoices/${this.type}/edit/${id}`)
          break
        case 'delete':
          this.deleteInvoice(id)
          break
        case 'clone':
          this.$router.push(`/invoices/${this.type}/clone/${id}`)
          break
        // case 'print':
        //   this.openPrint(id)
        //   break
      }
    },
    async pdfPrint(id) {
      Toast.loading({
        message: 'Preparing',
        forbidClick: true,
        duration: 0,
      })
      let res = await axios.get(`/invoice/${id}/print_request`)
      if (res.data.code == 200) {
        let printUrl = res.data.data.redirect_url
        window.open(printUrl, '_blank')
      }
      Toast.clear()
    },

    async openPrint(id) {
      let res = await axios.get(`/invoice/${id}/print_request?print_type=pos`)
      if (res.data.code == 200) {
        this.printUrl = res.data.data.redirect_url
        window.popupWindow(this.printUrl, 700, 720)
      }
    },
    async deleteInvoice(id) {
      await Dialog.confirm({
        title: this.$t('action.areYouSure'),
        cancelButtonText: this.$t('action.cancel'),
        confirmButtonText: this.$t('action.del'),
      })
      let res = await axios.delete('/invoices/' + id)
      if (res) {
        if(res.data.code != 404) {
          Toast.success(this.$t('state.deleted'))
          this.getInvoice()
        }
      }
    },
    async getInvoice() {
      let res = await axios.get('invoices', { params: this.params })
      if (res) {
        this.items = res.data.data.data.map((item) => {
          item.show_menu = false
          return item
        })
        this.total = res.data.data.total
      }
    },
    async printInvoice() {
      Toast.loading({
        message: 'Preparing',
        forbidClick: true,
        duration: 0,
      })
      let invoice_ids = this.selectedItem
      if (invoice_ids.length >= 20) {
        Toast.fail('Max 20')
        return false
      }
      let res = await axios.get('invoice/multiple_invoices_request', { params: { invoice_ids } })
      if (res.data.success) {
        window.open(res.data.data.redirect_url, '_blank')
        Toast.clear()
      }
    },

    dateFilter(date) {
      let st_date = moment(date.startDate)
      let ed_date = moment(date.endDate)
      this.params.start_date = st_date.format('YYYY-MM-DD')
      this.params.end_date = ed_date.format('YYYY-MM-DD')
      this.getInvoice()
    },
  },
  mounted() {
    this.getInvoice()
  },
  watch: {
    $route() {
      this.type = this.$route.params.type
      this.params.type = this.$route.params.type
      this.getInvoice()
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  border: none;
  border-radius: 10px;
  min-height: 550px;
}

.text-sm {
  font-size: 12px;
  font-weight: 300 !important;
}

.line {
  width: 60px;
  margin: 0 auto;
  border-top: 2px solid #e7e6e6;
}

.badge-custom {
  border-radius: 10px;
  padding: 4px 14px;
  font-weight: 600;
  font-size: 11px !important;
  text-align: center;
  color: white;
}

.custom-line {
  border: 3px solid red;
}
</style>