<template>
  <div>
    <FrontendNav />
    <main class="container blog-container">

      <div class="img-container">
        <img :src="blog.image" />
      </div>
      <h3>{{blog.title}}</h3>
      <p>{{blog.content}}</p>
    </main>
    <FrontendFooter />
  </div>
</template>

<script>
export default  {
  name: 'BlogDetail',
  data: () => ({
    blog: {
      title: '',
      content: '',
      image: '',
    }
  }),
  created() {
    let id = this.$route.params.blogId;
    let lang = this.$route.params.lang;
    import(`./blog/${lang}/${id}.json`).then(res => {
      this.blog = {
        title: res.title,
        content: res.content,
        image: res.image,
      }
    });
  }
}
</script>

<style scoped lang="scss">
.blog-container {
  margin-top: 7rem;
  max-width: 50rem;
  padding-bottom: 20px;


  h3 {
    font-weight: bold;
    margin: 24px 0;
  }

  .img-container {
    max-width: 30rem;
    margin: 0 auto;
    padding: 30px 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  p {
    white-space: pre-wrap;
    font-size: 13px;
    line-height: 22px;
    color: #000;
  }

  /* TODO: Optional drop cap */
  p:first-letter {
    float: left;
    font-size: 6rem;
    line-height: 0.5;
    margin: 0.2em 0.1em 0.2em 0;
  }
}
</style>