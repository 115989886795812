<template>
  <div class="dashboard-total-div d-flex" v-if="data">
    <div class="col-lg-6 col-md-6 dashboard-total-div-left mt-3">
      <div class="total-div-top mt-4">
        <van-icon :name="data.icon" size="30" color="white" />
      </div>
      <h5 class="font-weight-bold mt-4" :class="[data.amount < 0 ? 'text-danger' : successTextColor]">
        {{ $currency }} {{ $cast(data.amount) }}
        <p class="font-weight-normal amount-text">{{ data.name }}</p>
      </h5>
    </div>
    <div class="col-lg-6 col-md-6 dashboard-total-div-right">
      <p class="mt-4 percentage-text" :class="[data.percentage < 0 ? 'text-danger' : 'text-success']">
        <span v-if="data.percentage != 0">
          <van-icon name="arrow-down" aria-hidden="true" v-if="data.percentage < 0"></van-icon>
          <van-icon name="arrow-down" aria-hidden="true" v-else></van-icon>
          {{ $cast(data.percentage) }}% <br />
          <small class="text-muted text-capitalize"> {{ data.type }}</small>
        </span>
        <span v-else> &nbsp; </span>
      </p>
      <div class="total-div-left-diagram-1" v-if="series">
        <!-- data chart -->
        <apexchart
          type="area"
          :options="options"
          :series="series"
          height="110"
          style="padding: 0px; margin-top: -40px"
        ></apexchart>
        <!-- data chart -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data', 'successTextColor'],
  watch: {
    data: function () {
      if (this.data.chartData) {
        this.series = [this.data.chartData]
      }
    },
  },
  data() {
    return {
      options: {
        stroke: {
          width: 1,
        },
        chart: {
          id: 'expense-chart',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          width: '100%',
        },
        xaxis: {
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },

        yaxis: {
          show: false,
          labels: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        colors: [this.$store.state.theme],
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      series: null,
    }
  },
}
</script>

<style scoped>
.amount-text {
  font-size: 12px;
}
.percentage-text {
  font-size: 12px;
  text-align: right;
}
.dashboard-total-div-left {
  align-items: center;
}
.total-div-left-diagram-1 {
  height: 70px;
}
.total-div-top {
  height: 45px;
  width: 45px;
  display: flex;
  border-radius: 10px;
  background-color: var(--theme);
  align-items: center;
  justify-content: center;
}

.dashboard-total-div {
  height: 150px;
  background-color: white;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
</style>
