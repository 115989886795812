<template>
  <div class="d-flex flex-column content-wrapper" style="background: #e4e4e4" v-if="loaded">
    <FrontendNav />
    <StepOne @step="getStep" :params="params" v-show="step === 'one'" />
    <StepTwo @step="getStep" :params="params" v-show="step === 'two'" @submit="submit" />
    <FrontendFooter />
  </div>
</template>

<script>
import StepOne from './components/StepOne'
import StepTwo from './components/StepTwo'
import { Toast } from 'vant'

export default {
  components: { StepOne, StepTwo },
  data() {
    return {
      step: 'one',
      plan: '',
      loaded: false,
      params: {
        color: this.$store.state.theme,
        category: {},
      },
    }
  },
  created() {
    this.checkHasPlan()
  },
  methods: {
    async checkHasPlan() {
      let res = await axios.get('/auth/company')
      if (res.data.success) {
        let plan = window.localStorage.getItem('selected_plan')
        if (plan) {
          this.$router.push('/choose_payment_method')
        } else {
          this.$router.push('/pos')
        }
      }
      this.loaded = true
    },

    getStep(value) {
      this.step = value
    },

    async submit(plan) {
      Toast.loading({
        message: 'Creating...',
        forbidClick: true,
        duration: 0,
      })
      try {
        let fd = this.getFd()
        fd.append('plan', plan)
        let res = await axios.post('/companies', fd)
        Toast.clear()
        if (res.data.success) {
          let company = res.data.data
          console.log(company)
          if (company) {
            let res = await this.$router.push('/pos')
            if(res) {
              this.$events.fire('runTooltip')
            }
          }
        }
      } catch (error) {
        Toast.clear()
      }
    },

    getFd() {
      let fd = new FormData()
      let { name, address, logo, currency, color, domain, company_category_id } = this.params
      if (name) {
        fd.append('name', name)
      }
      if (name) {
        fd.append('currency', currency)
      }
      if (company_category_id) {
        fd.append('company_category_id', company_category_id)
      }
      if (address) {
        fd.append('address', address)
      }
      if (domain) {
        fd.append('domain', domain)
      }
      if (logo) {
        fd.append('logo', logo)
      }
      if (color) {
        fd.append('color', color)
      }
      return fd
    },
  },
}
</script>

<style scoped>
.content-wrapper {
  padding-top: 8rem;
  justify-content: space-between;
  height: 100vh;
}
</style>
