<template>
  <div>
    <small v-if="errors.products && errors.products.product_id" class="text-danger">{{
      errors.products.product_id
    }}</small>
    <div class="prodcut-detail mt-2">
      <table>
        <thead>
          <tr>
            <th style="width: 350px" class="th-product">{{ $t('invoice.invoiceNumber')}}</th>
            <th class="text-center">{{ $t('invoice.unit')}}</th>
            <th class="text-center th-qty">{{ $t('invoice.qty')}}</th>
            <th class="text-center th-price">{{ $t('invoice.price')}}</th>
            <th class="text-center th-discount">{{ $t('discount.discount') }}</th>
            <th class="text-center th-totalprice">{{ $t('invoice.totalPrice')}}</th>
            <th style="width: 20px"></th>
          </tr>
        </thead>
        <template>
          <ProductCard
            v-for="(product, index) in params.products"
            :key="index"
            @remove="removeProduct"
            :showOptions="showOptions"
            :product="product"
            :index="index"
          />
        </template>
      </table>
    </div>
    <div @click="addProduct" class="add-product-main  mt-2">
      <div class="add-product-icon"></div>
      <div class="add-product">{{ $t('invoice.addProduct')}}</div>
    </div>
  </div>
</template>

<script>
import Button from '../../../@components/Button.vue'
import ProductCard from './Product.vue'

export default {
  props: ['params', 'showOptions', 'errors'],
  components: {
    ProductCard,
    Button,
  },
  data() {
    return {
      products: [],
    }
  },
  methods: {
    addProduct() {
      this.params.products.push({
        unitList: [],
        unit: null,
        optionList: [],
        options: [],
        is_manual: false,
        quantity: null,
        discount : 0,
        discount_type : 'fixed',
        price: null,
        show_option: false,
      })
    },

    removeProduct(index) {
      if (this.params.products.length > 1) {
        this.params.products.splice(index, 1)
      }
    },
  },
}
</script>
