<template>
  <div class="header-container">
    <div class="header container">
      <input id="click" type="checkbox"/>
      <router-link class="pointer" to="/">
        <img alt="manaw-store-logo" class="logo" src="/logo-icon.svg"/>
      </router-link>
      <label class="menu-btn" for="click">
        <img alt="" class="menu-img" src="@/assets/utils/menu.svg"/>
      </label>
      <div class="center">
        <a class="pointer" href="/#features">Feature</a>
        <router-link to="/pricing">Pricing</router-link>
        <router-link to="/support">Support</router-link>
      </div>
      <div v-if="!$auth.check()" class="right">
        <button class="login mr-2 text-bold" @click="$router.push({ path: '/login' })">Login</button>
        <button class="btn-web-primary register" @click="$router.push({ path: '/register' })">Register</button>
      </div>

      <div v-else class="right">
        <button class="btn-web-primary mr-2 text-bold" @click="$router.push({ path: '/pos' })">Dashboard</button>
        <button class="login active" @click="logout()">Logout</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    async logout() {
      await this.$auth.logout()
      this.$router.push('/redirect-main-login')
    },
  },
}
</script>

<style scoped>
.header-container {
  background: #ffffffbb;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  backdrop-filter: blur(15px) saturate(100%);
}

.header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.center a {
  margin-left: 90px;
  text-decoration: none;
  color: black;
  font-size: 13px;
}

.center a:hover {
  font-size: 12.35px;
  font-weight: bold;
  color: black;
}

.right a {
  text-decoration: none;
  color: black;
}

.right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.right .register {
  font-weight: bold;
  font-size: 13px;
}

.right .login {
  border-radius: 9px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background: transparent;
}

.right .login:hover {
  color: #7b7b7b;
}

.right .login.active {
  background: #1c0606;
  color: white;
}

.logo {
  padding: 5px 0;
  width: 60px;
  height: 100%;
}

#click {
  display: none;
}

.menu-btn {
  display: none;
}

.menu-btn img {
  width: 30px;
  height: 30px;
}

/* start mobile view */
@media (max-width: 991px) {
  .menu-btn {
    display: block;
  }

  #click:checked ~ .menu-btn:before {
    content: url(../assets/utils/menu-close.svg);
  }

  #click:checked ~ .menu-btn img {
    display: none;
  }

  .center {
    position: absolute;
    top: 60px;
    flex-direction: column;
    width: 100%;
    text-align: center;
    background: #ffffff;
    left: 0;
    display: none;
    z-index: 1;
  }

  .right {
    margin-top: -2.2rem;
    border-width: 80%;
    position: absolute;
    top: 260px;
    width: 100%;
    left: 0;
    display: none;
    flex-direction: column;
    text-align: center;
    background: #ffffff;
    padding-bottom: 28px;
    z-index: 1;
  }

  .center a {
    margin: 20px 0;
  }

  .right .login {
    margin-right: 0px;
    margin: 10px 0;
  }

  .line {
    width: 80%;
    height: 2px;
    position: absolute;
    top: 259px;
    background: #f1f1f1;
    display: none;
    left: 10%;
    justify-content: center;
    align-items: center;
    justify-items: center;
    text-align: center;
    z-index: 1;
  }

  #click:checked ~ .center,
  #click:checked ~ .right,
  #click:checked ~ .line {
    display: flex;
  }
}

/* end mobile view */
</style>
