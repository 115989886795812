<template>
  <div class="custom-date-picker mt-2 mb-3">
    <label class="label">{{ label }}</label>

    <date-range-picker
      v-model="dateRange"
      lang="en"
      type="date"
      :showWeekNumbers="false"
      :autoApply="false"
      :ranges="false"
      opens="left"
      @update="selectDate()"
      :locale-data="locale"
      :singleDatePicker="true"
    >
      <template v-slot:input="picker">
        <div class="calendar-box-2 d-flex justify-content-center">
          <i class="dashboard-calendar-icon"></i>{{ dateFormat(picker.startDate ? picker.startDate : value)}}
        </div>
      </template>
    </date-range-picker>

    <span v-if="dateRange.startDate" @click="selectDate(true)" class="clear">clear</span>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'

export default {
  components : {DateRangePicker},
  props : ['value', 'label'],
  data() {
    return {
      moment,
      dateRange: { startDate: null},
      locale: {
        format: 'dd/mmm/yyyy',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
    }
  },
  watch: {
    value: function () {
      this.dateRange.startDate = this.value
    },
  },
  mounted() {
    if (this.value) {
      this.dateRange.startDate = this.value
    }
  },
  filters: {
    date(val) {
      return val ? moment(val).format('LL') : ''
    },
  },
  methods: {
    dateFormat(date) {
      return date ? moment(date).format('DD MMM, YYYY') : null
    },
    selectDate(clear = false) {
      this.$emit('input', clear ? null : moment(this.dateRange.startDate).format('YYYY-MM-DD'))
    },
  },
}
</script>

<style lang="scss">
  .custom-date-picker {
    display: flex;
    flex-direction: column;
    position: relative;

    .reportrange-text {
      height:  43px;
    }

    .calendar-box-2 {
      margin-top : -7px;
    }

    .clear {
      position: absolute;
      right: 10px;
      top: 41px;
      border-radius: 10px;
      padding: 2px 8px;
      font-size: 10px;
      background-color : var(--theme);
      color : white;
      cursor: pointer;
    }

  }
</style>