<template>
  <div class="row mt-5 px-lg-0">
    <div class="right col-lg-12" ref="right">
      <div>
        <div ref="general" class="text-center mb-5">
          <ImageUploader :multiple="true" :value="uploader" :max="6" @input="getImage" @delete="getImage" />
          <br />
          <small v-if="errors.images" class="text-danger mt-2">
            {{ errors.images }}
          </small>
        </div>

        <div class="row mb-3">
          <div class="col-md-6">
            <h6>{{ $t('mngmt.product.selling') }}</h6>
            <van-switch v-model="params.enable_selling" size="20" />
          </div>
          <div class="col-md-6">
            <h6>{{ $t('mngmt.product.allowNegativeStock') }}</h6>
            <van-switch v-model="params.n_stock" size="20" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <Input
              :params="params"
              :errors="errors"
              field="name"
              :label="$t('mngmt.product.productName')"
              pos-tooltip-step="6"
            />
          </div>
          <div class="col-md-6">
            <Input :params="params" :errors="errors" field="barcode" :label="$t('mngmt.product.barcode')" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <Input :params="params" :errors="errors" field="sku" :label="$t('mngmt.product.productSKU')" />
          </div>
          <div class="col-md-6">
            <label class="label">Category</label>
            <small v-if="errors.category_id" class="text-danger">{{ errors.category_id }}</small>
            <Select
              v-model="params.category_id"
              type="categories"
              :taggable="true"
              :errors="errors"
              pos-tooltip-step="8"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <Input
              :params="params"
              :errors="errors"
              className="text-right"
              type="number"
              field="retail_price"
              :label="$t('mngmt.product.salePrice')"
              pos-tooltip-step="7"
            />
          </div>
          <div class="col-md-6">
            <Input
              :params="params"
              :errors="errors"
              className="text-right"
              field="original_price"
              :label="$t('mngmt.product.originalPrice')"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <Input
              :params="params"
              :errors="errors"
              type="number"
              className="text-right"
              field="buy_price"
              :label="$t('mngmt.product.buyPrice')"
            />
          </div>
          <div class="col-md-6">
            <Input :params="params" :errors="errors" field="unit" :label="$t('mngmt.product.unit')" />
          </div>
          <!--          <div class="col-md-6">-->
          <!--            <Input-->
          <!--                :params="params"-->
          <!--                :errors="errors"-->
          <!--                type="number"-->
          <!--                className="text-right"-->
          <!--                field="instock"-->
          <!--                :label="$t('mngmt.product.instock')" />-->
          <!--          </div>-->
        </div>
      </div>

      <!-- <div
        v-if="
          ($route.params.id && $route.params.type == 'single') || ($route.params.subId && $route.params.type == 'group')
        "
        ref="unit"
        class="my-3"
      > -->
      <div class="my-3" ref="unit">
        <UnitsCard :params="params" :errors="errors"/>
      </div>
      <!-- </div> -->

      <!-- <div class="mt-5 mb-3">
        <van-checkbox shape="square" v-model="params.apo_obs" size="20">
          <h6 class="mb-0">{{$t('mngmt.product.autoPickup') }}</h6>
        </van-checkbox>
      </div>

      <div ref="option" class="my-3">
        <OptionsCard :params="params" />
      </div> -->

      <div class="floated-bottom">
        <Button
          @click.native="
            submit()
            $events.fire('nextStep')
          "
          :type="$route.params.id ? 'edit' : 'create'"
          :loading="loading"
          pos-tooltip-step="9"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UnitsCard from './UnitsCard'
import OptionsCard from './OptionsCard'
export default {
  components: { UnitsCard, OptionsCard },
  props: ['params', 'errors', 'loading'],
  data() {
    return {
      scrollTabs: [
        { name: 'General', element: 'general', active: true },
        { name: 'Product Units', element: 'unit', active: false },
        { name: 'Product Options', element: 'option', active: false },
      ],
      uploader: [],
      imageMounted: false,
    }
  },
  watch: {
    params() {
      if (!this.imageMounted) {
        this.uploader = this.params.images.map((image) => {
          return { url: image }
        })
        this.imageMounted = true
      }
    },
  },
  mounted() {
    this.uploader = this.params.images.map((image) => {
      return { url: image }
    })
  },
  methods: {
    newTagCreated(event) {
      console.log(event)
    },

    getImage(value) {
      this.uploader = value
      if (this.uploader.length > 0) {
        this.params.images = this.uploader.map((image) => {
          return image.file ?? image.url
        })
        this.imageMounted = true
      }
    },
    submit() {
      this.$emit('submit')
    },
  },
}
</script>

<style lang="scss" scoped>
.left {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .step {
    cursor: pointer;
  }
  .step.active {
    font-weight: 700;
  }
}
.right {
  margin: 0 auto;
  padding-bottom: 60px;
}

.floated-bottom {
  position: fixed;
  bottom: 0;
  margin-bottom: 10px;
}
.end::placeholder {
  text-align: end;
}
</style>
