<template>
  <div>
    <label class="label">{{ $t('mngmt.product.optionTitle') }}</label>
    <div v-for="(option, index) in params.options" :key="'option_' + index" class="option">
      <div class="option-name">
        <div class="tbody">
          <div class="tr">
            <div class="td"><div @click="addOption()" class="add">+</div></div>
            <div class="td">
              <input
                @keyup="optionChanged()"
                v-model="option.title"
                type="text"
                class="empty-input"
                :placeholder="$t('mngmt.product.optionName')"
              />
              <van-icon
                @click="option.showSetting = !option.showSetting"
                name="setting-o"
                size="25"
                style="position: absolute; right: 30px; cursor: pointer"
              />
              <div class="remove">
                <svg
                  @click="removeOption(index)"
                  width="21"
                  height="21"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="21" height="21" rx="5" fill="#FFDCDC" />
                  <path d="M6.76855 11L15.2301 11" stroke="#ED1C24" stroke-width="2" stroke-linecap="round" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="option-setting" v-show="option.showSetting">
        <van-cell class="cell" :title="$t('mngmt.product.requiredOne')">
          <template #right-icon>
            <van-switch @change="optionChanged()" v-model="option.required" size="15" />
          </template>
        </van-cell>

        <van-cell class="cell" :title="$t('mngmt.product.enableMulti')">
          <template #right-icon>
            <van-switch @change="optionChanged()" v-model="option.is_multiple" size="15" />
          </template>
        </van-cell>
      </div>

      <div class="option-items mt-2">
        <div class="thead">
          <div class="tr">
            <div class="th">
              <div @click="addOptionItem(index)" class="add-black">+</div>
            </div>
            <div class="th">{{ $t('mngmt.product.item') }}</div>
            <div class="th">{{ $t('mngmt.product.salePrice') }}</div>
            <div class="th">{{ $t('mngmt.product.purchasePrice') }}</div>
            <div class="th text-center">{{ $t('mngmt.product.autoPick') }}</div>
            <div class="th text-center">{{ $t('mngmt.product.active') }}</div>
          </div>
        </div>

        <div class="tbody">
          <draggable v-model="option.product_option_items" @change="optionChanged()">
            <transition-group>
              <div class="tr" v-for="(item, itemIndex) in option.product_option_items" :key="'option_item' + itemIndex">
                <div class="td text-center">{{ itemIndex + 1 }}.</div>

                <div class="td">
                  <input
                    @keyup="optionChanged()"
                    v-model="item.title"
                    type="text"
                    class="empty-input"
                    placeholder="Name"
                  />
                </div>

                <div class="td">
                  <input
                    @keyup="optionChanged()"
                    v-model="item.sale_price"
                    type="number"
                    class="empty-input"
                    placeholder="$"
                  />
                </div>

                <div class="td">
                  <input
                    @keyup="optionChanged()"
                    v-model="item.purchase_price"
                    type="number"
                    class="empty-input"
                    placeholder="$"
                  />
                </div>

                <div class="td text-center">
                  <van-switch
                    v-model="item.auto_select"
                    @change="autoPickChanged(index, itemIndex)"
                    size="17"
                    style="margin: 0 auto"
                  ></van-switch>

                  <div class="remove">
                    <svg
                      @click="removeOptionItem(index, itemIndex)"
                      width="21"
                      height="21"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="21" height="21" rx="5" fill="#FFDCDC" />
                      <path d="M6.76855 11L15.2301 11" stroke="#ED1C24" stroke-width="2" stroke-linecap="round" />
                    </svg>
                  </div>
                </div>

                <div class="td">
                  <van-checkbox
                    @change="optionChanged()"
                    class="justify-content-center"
                    v-model="item.active"
                    shape="square"
                    checked-color="#5FD60E"
                  />
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  components: { draggable },
  props: ['params'],
  methods: {
    addOption() {
      if (this.params.options.length == 7) {
        this.$toast.fail('Max 7')
        return false
      }
      this.optionChanged()
      let option = {
        is_multiple: true,
        title: null,
        sort: 1,
        showSetting: false,
        required: true,
        product_option_items: [
          {
            title: null,
            sort: 1,
            price: 0,
            active: 0,
            auto_select: false,
          },
        ],
      }
      this.params.options.push(option)
    },
    optionChanged() {
      // console.log('changed')
      this.$store.commit('setOptionChanged', true)
    },

    removeOption(index) {
      if (this.params.options.length > 1) {
        this.optionChanged()
        this.params.options.splice(index, 1)
      }
    },

    autoPickChanged(index, itemIndex) {
      this.optionChanged()
      let option = this.params.options[index]
      if (!option.is_multiple) {
        this.params.options[index].product_option_items.map((item) => {
          item.auto_select = false
        })
        this.params.options[index].product_option_items[itemIndex].auto_select = true
      }
    },

    addOptionItem(index) {
      if (this.params.options[index].product_option_items.length == 7) {
        this.$toast.fail('Max 7')
        return false
      }
      this.optionChanged()
      let optionItem = {
        title: null,
        sort: 1,
        sale_price: 0,
        purchase_price: 0,
        active: 0,
        auto_select: false,
      }
      this.params.options[index].product_option_items.push(optionItem)
    },
    removeOptionItem(index, itemIndex) {
      if (this.params.options[index].product_option_items.length > 1) {
        this.optionChanged()
        this.params.options[index].product_option_items.splice(itemIndex, 1)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
hr {
  border: 1px solid #f4f4f4;
}
.option-setting {
  border: 2px solid #f4f4f4;
  border-radius: 7px;
  margin-top: 6px;
  .cell {
    border-radius: 7px;
  }
}
.empty-input {
  border: none;
  outline: none;
  width: 100%;
}
.empty-input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.add {
  background: var(--theme);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 21px;
  cursor: pointer;
  color: white;
  font-weight: 400;
  font-size: 28px;
}
.add-black {
  background: black;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 21px;
  cursor: pointer;
  color: white;
  font-weight: 400;
  font-size: 28px;
}
.remove {
  cursor: pointer;
  position: absolute;
  right: -14px;
  margin-top: -20px;
}

.option-name {
  .tr {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    background: white !important;
  }
  .td {
    font-size: 13px;
    background: white !important;
    color: rgb(0, 0, 0);
    border: 2px solid #f4f4f4;
    padding: 0.75rem;
  }
  .td:last-child {
    flex: 1 1 300px;
    flex-shrink: 0;
    border-left: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .td:first-child {
    flex: 0 0 43px;
    flex-shrink: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

.option-items {
  .tbody {
    .tr {
      cursor: all-scroll;
      background: white !important;
    }
    .tr:last-child {
      .td:first-child {
        border-bottom-left-radius: 8px;
      }
      .td:last-child {
        border-bottom-right-radius: 8px;
      }
      .td {
        border-bottom: 2px solid #f4f4f4;
      }
    }
    .icon {
      background: var(--theme);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 21px;
      height: 21px;
      cursor: pointer;
      color: white;
      font-weight: 400;
      font-size: 28px;
    }
    .empty-input {
      border: none;
      outline: none;
      width: 100%;
    }
    .empty-input::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
    .empty-input.end::placeholder {
      text-align: end;
    }
  }
  .remove-option-item {
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
  .thead {
    .th:first-child {
      border-top-left-radius: 8px;
    }
    .th:last-child {
      border-top-right-radius: 8px;
    }
  }
  .tr {
    display: flex;
    flex-wrap: nowrap;
    gap: 0px;
    .td,
    .th {
      font-size: 13px;
      color: rgb(0, 0, 0);
      border: 2px solid #f4f4f4;
      padding: 0.75rem;
      border-left: none;
      border-bottom: none;
      flex: 1 1 150px;
      flex-shrink: 0;
    }

    .th {
      font-weight: 700;
      color: rgba(0, 0, 0, 0.5);
    }

    .th:first-child,
    .td:first-child {
      flex: 0 0 43px;
      flex-shrink: 0;
      border-left: 2px solid #f4f4f4;
    }

    .th:last-child,
    .td:last-child {
      flex: 0 0 75px;
      flex-shrink: 0;
    }
  }
}
</style>