<template>
  <div class="bg-white">
    <div v-if="!editId || loaded">
      <h1 @click="$router.push({ path: '/management/suppliers/list' })">
        <van-icon class="mr-3" name="arrow-left" size="15px"/>
        <span v-if="editId">{{ data.name }}</span>
        <span v-else>{{ $t('mngmt.supplier.newSupplier') }}</span>
      </h1>
      <div class="col-lg-9 mx-auto">
        <FormCard :data="data" :errors="errors" :isNew="editId ? false : true"/>

        <div class="ml-3">
          <Button :type="editId ? 'edit' : 'create'" :loading="loading" name="Supplier" @click.native="saveData()"/>
          <Button v-if="editId" type="delete" :dloading="dloading" name="Supplier" @click.native="deleteData()"/>
        </div>
      </div>
    </div>
    <div v-else class="loading">
      <van-loading type="spinner"/>
    </div>
  </div>
</template>

<script>
import {Dialog, Toast} from 'vant'
import FormCard from './components/FormCard'

export default {
  components: {FormCard},
  data() {
    return {
      loaded: false,
      loading: false,
      dloading: false,
      uploader: [],
      data: {
        company_name: null,
        tax_id: null,
        name: null,
        phone: [''],
        address: null,
        email: [''],
        image: null,
        status: 'active',
        billing_address: '',
      },
      errors: {},
      editId: null,
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.editId = this.$route.params.id
      this.fetchData()
    }
  },
  methods: {
    saveData() {
      if (this.editId) {
        this.update()
      } else {
        this.create()
      }
    },
    async create() {
      let formData = this.appendFormData()
      this.loading = true
      try {
        const res = await axios.post(`/suppliers`, formData)
        if (res.data.success) {
          this.clearData()
          Toast.success(this.$t('state.created'))
          this.loading = false
          this.$router.back()
        } else if (res.data.code == 422) {
          this.loading = false
          this.errors = res.data.error
        }
      } catch (err) {
        console.log(err)
        this.loading = false
      }
    },
    async update() {
      let formData = this.appendFormData()
      formData.append('_method', 'PUT')
      this.loading = true
      try {
        const res = await axios.post(`/suppliers/${this.editId}`, formData)
        if (res.data.success) {
          this.clearData()
          Toast.success(this.$t('state.edited'))
          this.loading = false
          this.$router.back()
        }
        if (res.data.code == 422) {
          this.loading = false
          this.errors = res.data.error
        }
      } catch (err) {
        console.log(err)
        this.loading = false
      }
    },
    async deleteData() {
      this.dloading = true
      await Dialog.confirm({
        title: this.$t('action.areYouSure'),
        cancelButtonText: this.$t('action.cancel'),
        confirmButtonText: this.$t('action.del'),
      })
      try {
        const res = await axios.delete(`/suppliers/${this.editId}`)
        Toast.success(this.$t('state.deleted'))
        this.$router.back()
      } catch (error) {
        Toast.fail(this.$t('state.delFail'))
      }
      this.dloading = false
    },
    appendFormData() {
      let fd = new FormData()
      if (this.data.name) {
        fd.append('name', this.data.name)
      }
      if (this.data.company_name) {
        fd.append('company_name', this.data.company_name)
      }
      if (this.data.tax_id) {
        fd.append('tax_id', this.data.tax_id)
      }
      this.data.phone.forEach((item) => {
        if (item) {
          fd.append('phone[]', item)
        }
      })
      this.data.email.forEach((item) => {
        if (item) {
          fd.append('email[]', item)
        }
      })
      if (this.data.address) {
        fd.append('address', this.data.address)
      }
      if (this.data.billing_address) {
        fd.append('billing_address', this.data.billing_address)
      }
      if (typeof this.data.image != 'string') {
        fd.append('image', this.data.image)
      }
      fd.append('status', this.data.status)
      return fd
    },
    async fetchData() {
      try {
        const res = await axios.get(`/suppliers/${this.editId}`)
        if (res) {
          let p = res.data.data
          // console.log('supplier data : ', p)
          let data = {
            ...p,
            phone: p.phone && p.phone.length > 0 ? p.phone : [''],
            email: p.email && p.email.length > 0 ? p.email : [''],
          }
          this.data = data
          this.loaded = true
        }
      } catch (error) {
        console.log(error)
      }
    },
    clearData() {
      this.data = {
        name: null,
        email: [''],
        phone: [''],
        address: null,
        image: null,
      }
      this.uploader = []
    },
  },
}
</script>

<style scoped>
h1 {
  cursor: pointer;
}
</style>
