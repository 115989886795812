<template>
  <div>
    <div class="calendar-container">
      <!-- ==== start date range picker ==== -->
      <date-range-picker
        v-model="dateRange"
        lang="en"
        type="date"
        class="date-picker"
        :showWeekNumbers="true"
        :autoApply="false"
        opens="left"
        @update="selectDate"
        :locale-data="locale"
        v-if="isCalendar"
      >
        <template v-slot:input="picker">
          <div class="pl-4 pr-4 pt-3 pb-3 calendar-box d-flex">
            <i class="dashboard-calendar-icon"></i>
            <div class="d-flex align-items-center">
              {{ dateFormat(picker.startDate) }} <van-icon name="exchange" class="mx-2" />
              {{ dateFormat(picker.endDate) }}
            </div>
          </div>
        </template>
      </date-range-picker>
    </div>
  </div>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'

export default {
  props: {
    isCalendar: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
    },
  },
  components: { DateRangePicker },
  data() {
    return {
      loaded: false,
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      locale: {
        format: 'dd/mmm/yyyy',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
    }
  },
  watch: {
    params: function () {
      if (!this.loaded) {
        this.dateRange.startDate = this.params.start_date
        this.dateRange.endDate = this.params.end_date
        this.loaded = true
      }
    },
  },
  mounted() {
    if (this.params) {
      this.dateRange.startDate = this.params.start_date
      this.dateRange.endDate = this.params.end_date
    }
  },
  filters: {
    date(val) {
      return val ? moment(val).format('LL') : ''
    },
  },
  methods: {
    dateFormat(date) {
      return date ? moment(date).format('DD MMM, YYYY') : null
    },
    selectDate(dates) {
      this.$emit('select', dates)
    },
  },
}
</script>

<style scoped>
.calendar-container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.dashboard-calendar {
  height: 50px;
  border-radius: 10px;
  align-items: center;
  width: 250px;
}

.dashboard-calendar-time {
  font-size: 14px;
  height: auto;
  padding-left: 10px;
}

.dashboard-calendar-icon {
  background-image: url('/image/dashboard-2/calendar.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: auto;
  width: 25px;
}

.dashboard-calendar-time p {
  margin-left: -13px;
}
.calendar-box {
  z-index: 20;
  border-radius: 10px;
}
</style>


<style>
.daterangepicker .btn-secondary {
  height: 28px;
  border-radius: 3px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--theme);
}
.daterangepicker .btn-primary,
.daterangepicker .btn-success {
  background-color: var(--theme);
}

.daterangepicker .drp-buttons .btn {
  height: 35px !important;
  border-radius: 8px !important;
}
.main-dashboard .reportrange-text {
  border: 2px solid white !important;
}
</style>