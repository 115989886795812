<template>
  <div v-if="items.length > 0">
    <label class="label">Specific {{type}}s</label>
    <div class="card-list-container">
      <div v-for="(item, index) in items" :key="index" class="card-list">
        <div class="d-flex">
          <img v-if="type == 'product'" :src="item.images[0]" :alt="item.name" style="border-radius : 5px">
          <img v-else-if="item.image" :src="item.image" :alt="item.name" style="border-radius : 50%">
          <div class="ml-3">
            <h5 v-if="item.name">{{ item.name }}</h5>
            <h5 v-else> {{ item }}</h5>
            <div v-if="type == 'product'" class="info">$ {{ $cast(item.retail_price) }}</div>
            <div v-else-if="item.email.length && item.phone.length" class="info">{{ item.email[0] }}, {{ item.phone[0] }}</div>
          </div>
        </div>
        <div @click="remove(index)" class="pointer">
          <svg width="18" height="18" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.375 5.23242C15.4613 4.94367 12.53 4.79492 9.6075 4.79492C7.875 4.79492 6.1425 4.88242 4.41 5.05742L2.625 5.23242" stroke="#FF3333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.4375 4.34875L7.63 3.2025C7.77 2.37125 7.875 1.75 9.35375 1.75H11.6463C13.125 1.75 13.2387 2.40625 13.37 3.21125L13.5625 4.34875" stroke="#FF3333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.4934 7.99805L15.9246 16.8093C15.8284 18.183 15.7496 19.2505 13.3084 19.2505H7.69086C5.24961 19.2505 5.17086 18.183 5.07461 16.8093L4.50586 7.99805" stroke="#FF3333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.03906 14.4375H11.9528" stroke="#FF3333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.3125 10.9375H12.6875" stroke="#FF3333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props : ['items', 'type'],
  methods : {
    remove(index) {
      this.items.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
  .card-list-container {
    display: flex;
    flex-direction: column;
    gap : 20px;
    margin : 1rem 0;

    .card-list {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width : 35px;
        height: 35px;
        object-fit: cover;
      }

      h5 { 
        font-size : 13px;
        font-weight: 700;
        margin-bottom : 2px;
      }

      .info {
        font-size : 12px;
        color : rgba(0,0,0,0.6);
      }
    }
  }
</style>