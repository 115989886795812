<template>
  <div class="company-profile-two pb-5">
    <div class="profile-two-box p-4">
      <p>Step 2 of 2</p>
      <h4>Your Business Profile setup is almost done</h4>
      <p style="width: 70%">Upload your Company logo and choose the theme color you like.</p>
      <div class="image-container">
        <div class="preview-image mt-4" v-show="image">
          <img :src="image" alt="" class="logo-image" />
          <div @click="removeImage" class="delete">
            <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line
                  x1="5.56558"
                  y1="5.36328"
                  x2="12.6366"
                  y2="12.4343"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
              />
              <line
                  x1="5.36328"
                  y1="12.4344"
                  x2="12.4343"
                  y2="5.36335"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
              />
            </svg>
          </div>
        </div>

        <label for="upload" class="upload-image-container" v-show="!image">
          <div class="upload-image">
            <input type="file" id="upload" @change="uploadImage" />
            <img src="/image/businessprofile/arrow-up.svg" alt="" />
            <p>Upload Logo</p>
          </div>
        </label>
      </div>

      <div class="text-danger mt-3">
        {{ error.logo }}
      </div>
      <div class="line"></div>
      <!-- ==== preview mini card ==== -->
      <PreviewSvg :color="params.color" />

      <div class="d-flex justify-content-start mt-3 mb-2">
        <verte
          v-model="params.color"
          display="widget"
          id="just-recent-color"
          model="hex"
          :colorHistory.sync="list"
        ></verte>

        <ColorPicker model="hex" @color="getColor" />
      </div>

      <div class="text-danger mt-3">
        {{ error.color }}
      </div>

      <div class="buttons-group d-flex justify-content-between">
        <button class="d-flex back" @click="backStep">
          <img class="my-auto" src="/image/businessprofile/arrow-left.svg" alt="" />
          <p class="my-auto back-text">Back</p>
        </button>
        <div class="d-flex">
          <button class="ml-3 next" @click="nextStep">Create Store</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorThief from 'colorthief'

export default {
  props: ['params'],
  data() {
    return {
      image: '',
      selected: false,
      error: {},
      list: [
        'hsl(260, 93%, 71%)',
        'hsl(171, 66%, 61%)',
        'hsl(43, 100%, 83%)',
        'hsl(213, 95%, 75%)',
        'hsl(301, 75%, 75%)',
        'hsl(240, 14%, 14%)',
      ],
    }
  },
  methods: {
    removeImage() {
      this.image = null
    },

    showUploader() {
      let ele = document.getElementById('upload')
      ele.click()
    },

    uploadImage(e) {
      var self = this
      const file = e.target.files[0]
      this.params.logo = file
      const reader = new FileReader()
      reader.onloadend = () => {
        this.image = reader.result
        const container = document.querySelector('.image-container')
        let img = container.querySelector('.logo-image')
        img.addEventListener('load', function () {
          self.getColorFromImage(img)
        })
      }
      reader.readAsDataURL(file)
    },
    getColorFromImage(img) {
      const colorThief = new ColorThief()
      let [r, g, b] = colorThief.getColor(img)
      let colors = colorThief.getPalette(img)
      console.log(r, g, b)
      this.params.color = `rgb(${r}, ${g}, ${b})`
      this.list = colors.map(([x, y, z]) => {
        return `rgb(${x}, ${y}, ${z})`
      })
    },
    getColor(value) {
      this.params.color = value
    },
    async nextStep() {
      await this.validate()
      this.$emit('submit')
    },
    backStep() {
      this.$emit('step', 'one')
    },

    validate() {
      this.error = {}
      if (!this.params.logo) {
        this.error.logo = 'Required logo'
      }
      if (!this.params.color) {
        this.error.color = 'Pick a Color'
      }
      if (Object.keys(this.error).length != 0) {
        throw 'Validation error'
      }
    },
  },
  watch: {
    'params.color': function () {
      document.documentElement.style.setProperty('--theme', this.params.color)
      this.$store.commit('setTheme', this.params.color)
    },
  },
}
</script>

<style>
.upload-image-container {
  cursor: pointer;
}
.back-text:hover {
  text-decoration: underline;
}
.skip {
  cursor: pointer;
}
.skip:hover {
  text-decoration-line: underline;
}
.buttons-group {
  width: 90%;
  justify-content: space-between;
  margin-top: 10px;
}
.buttons-group .back {
  background: #ffffff;
  border: none;
}
.buttons-group .back img {
  width: 20px;
  height: 20px;
  margin-left: -4px;
}
.buttons-group .next {
  background: var(--theme);
  border-radius: 9px;
  color: #ffffff;
  width: 120px;
  height: 40px;
  border: none;
}
.next:hover {
  background: white;
  border: 2px solid var(--theme);
  color: var(--theme);
  font-weight: bold;
}
.option-create-button {
  border-radius: 10px;
  width: 200px;
  background-color: black;
  color: white;
}
.color-picker-modal-button {
  margin-left: 3px;
  border: 1px solid #e8e8e8;
  height: 30px;
  border-radius: 50%;
  width: 30px;
  margin-top: 3px;
  background-color: #e8e8e8;
  cursor: pointer;
}
.company-profile-two {
  background: #e4e4e4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile-two-box {
  width: 450px;
  height: 600px;
  background: #ffffff;
  border-radius: 10px;
  margin: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}
.profile-two-box h4 {
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  max-width: 400px;
}
.profile-two-box h5 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  max-width: 300px;
}

.upload-image {
  width: 150px;
  height: 150px;
  margin: 10px 0 0 0;
  background: #ffffff;
  border: 2px solid #dedede;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.upload-image:hover {
  border: 2px solid var(--theme);
}
.preview-image {
  width: 150px;
  height: 150px;
  margin: 10px 0 0 0;
  position: relative;
  border-radius: 10px;
}
.preview-image img {
  width: 150px;
  height: 150px;
  border-radius: 10px;
}
.preview-image i {
  position: absolute;
  right: 5px;
  top: 10px;
  background: black;
  color: white;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}
.upload-image input {
  display: none;
}
.upload-image p {
  margin: 0;
}
.profile-two-box .line {
  margin: 20px auto;
  width: 350px;
  border: 1px solid #f3f3f3;
}
@media (max-width: 375px) {
  .profile-one-box,
  .profile-two-box {
    width: 340px;
    height: 450px;
  }
}
</style>
