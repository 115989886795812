<template>
  <div class="left-parent">
    <div class="slider-container">
      <div class="content-container">
        <router-link to="/">
          <img alt="manaw-store-logo" src="/logo-icon.svg"/>
        </router-link>

        <h1>No Installation Required.</h1>
        <h5>
          Just create an account and start build your<br/>
          products and sell!
        </h5>
      </div>
      <div class="img-container">
        <img src="/image/auth/register/register-image1.png"/>
      </div>
    </div>
  </div>
</template>

<script>
import {slider, slideritem} from 'vue-concise-slider'

export default {
  data() {
    return {
      sliding: null,
      options: {
        autoplay: '4000',
        loop: true,
      },
    }
  },
  methods: {
    prev() {
      this.$refs.slider.$emit('slidePre')
    },
    next() {
      if (!this.lastPage) {
        this.$refs.slider.$emit('slideNext')
      }
    },
    goHome() {
      window.location.href = '/'
    },
  },
  components: {
    slider,
    slideritem,
  },
}
</script>

<style scoped>
.slider-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f9f9ff;
}

.img-container {
  max-width: 50rem;
  margin: 15rem auto 0;
}

.img-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.content-container {
  position: absolute;
  top: 4rem;
  left: 12%;
  text-align: left;
  color: #181818;
}

.content-container img {
  width: 80px;
}

.content-container h1 {
  margin: 40px 0 20px;
  font-weight: 900;
  line-height: 35px;
}

.content-container h5 {
  line-height: 22px;
}

.left-parent {
  position: relative;
  overflow: hidden;
  height: 100vh;
}
</style>

