<template>
  <div>
    <div class="d-flex">
      <input type="text form-control" :value="value.name" readonly @click="showModal()" />
      <van-icon name="arrow-down" size="17" class="arrow" />
    </div>

    <van-popup v-model="showPicker" class="currency-picker" :style="{ width: '450px' }">
      <div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="label">{{ $t('invoice.searchProduct') }}</div>
          <div class="pointer font-weight-bold" @click="showQuickProduct = !showQuickProduct">
            {{ $t(showQuickProduct ? 'action.cancel' : 'invoice.createProduct') }}
          </div>
        </div>
        <div v-if="showQuickProduct" class="quick-product my-2">
          <div class="d-flex align-items-end" style="gap: 10px">
            <div>
              <label class="label">{{ $t('mngmt.product.productName') }}</label>
              <input class="mt-0" type="text" v-model="quickProduct.name" />
            </div>
            <div>
              <label class="label">{{ $t('invoice.price') }}</label>
              <input class="mt-0" type="number" v-model="quickProduct.price" />
            </div>
            <div>
              <button
                :disabled="!quickProduct.price || !quickProduct.name"
                class="btn btn-primary px-4"
                @click="createProduct"
              >
                {{ $t('action.create') }}
              </button>
            </div>
          </div>
        </div>
        <input placeholder="Search" ref="searchInput" type="text" class="search-currency" @keyup="searchProduct" />
        <div class="list">
          <ul>
            <li v-for="(product, index) in productOptions" :key="index" @click="setProduct(product)">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center mr-2">
                  <img class="image mr-3" :src="product.images[0]" alt="" />
                  <span>{{ product.name }}</span>
                </div>
                <span class="float-right"> <van-icon name="circle" size="17" /> </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: { vSelect },
  props: ['value', 'index'],
  data() {
    return {
      showPicker: false,
      productOptions: [],
      params: {
        keyword: null,
      },
      timer: null,
      showQuickProduct: false,
      quickProduct: {
        name: '',
        price: '',
      },
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async createProduct() {
      let res = await axios.post('products', {
        name: this.quickProduct.name,
        retail_price: +this.quickProduct.price,
      })
      if (res.data.success) {
        console.log('res : ', res.data)
        this.fetchData()
        this.quickProduct = { name: '', price: '' }
        this.showQuickProduct = false
      }
    },
    searchProduct(event) {
      clearInterval(this.timer)
      this.timer = setTimeout(() => {
        this.params.keyword = event.target.value
        this.fetchData()
      }, 500)
    },

    showModal() {
      this.showPicker = true
      setTimeout(() => {
        this.$refs.searchInput.focus()
      }, 100)
    },

    setProduct(e) {
      console.log(e)
      this.value.product_id = e.id
      this.value.name = e.name
      this.value.retail_price = e.retail_price
      this.value.quantity = 1
      this.value.unitList = e.units
      this.value.unit = e.units.filter((unit) => unit.is_default)[0]
      this.value.optionList = JSON.parse(JSON.stringify(e.options))
      this.$emit('input', this.value)
      this.showPicker = false
    },

    async fetchData() {
      let res = await axios.get('/product_picker', { params: this.params })
      if (res) {
        this.productOptions = res.data.data
      }
    },
  },
}
</script>

<style scoped>
.image {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 10px;
}

input {
  height: 38px;
  border-radius: 10px;
  border: 2px solid #f4f4f4;
  width: 100%;
  padding: 10px;
  padding-right: 28px;
  background: #fbfbfb;
}

.arrow {
  font-size: 17px;
  position: relative;
  right: 27px;
  top: 11px;
  color: #d1cece;
  width: 0px;
}
</style>
