<template>
  <div class="bg-white">
    <div class="d-flex justify-content-between align-items-center">
      <custom-search v-model="params.keyword" @search="fetchData()" />
      <van-button @click="$router.push('/management/coupons/create')" class="add-button" type="default">
        <van-icon name="add-o" size="20" class="mr-2" />
        {{$t('mngmt.coupon.newCoupon')}}
      </van-button>
    </div>

    <div v-if="loaded">
      <div v-if="coupons.length > 0">
        <van-checkbox-group v-model="selectedCoupons" ref="couponTable">
          <table class="table my-border-table mt-0">
            <thead>
              <tr v-if="selectedCoupons.length > 0">
                <th colspan="6">
                  <div class="d-flex justify-content-between">
                    <div>
                      <span class="mr-4">{{ $t('action.selected', {count: selectedCoupons.length}) }} Selected</span>
                      <span @click="$refs.couponTable.toggleAll(true)">{{$t('action.selectAll')}}</span>
                    </div>
                    <div>
                      <span @click="deleteCoupon()" class="text-danger mr-4">{{$t('action.remove')}}</span>
                      <span @click="$refs.couponTable.toggleAll(false)">{{$t('action.cancel')}}</span>
                    </div>
                  </div>
                </th>
              </tr>
              <tr v-else>
                <th>{{$t('name')}}</th>
                <th class="text-center">{{$t('mngmt.coupon.code')}}</th>
                <th class="text-center">{{$t('createdAt')}}</th>
                <th class="text-center">{{$t('amount')}}</th>
                <th class="text-center">{{$t('status')}}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(coupon, index) in coupons" :key="index">
                <td>
                  <div class="d-flex">
                    <van-checkbox :name="coupon.id" shape="square" icon-size="17px"></van-checkbox>
                    <div class="ml-4">
                      <p class="mb-0 text-bold">{{ coupon.name }}</p>
                      <p class="mb-0 text-muted text-sm">{{ getValidTime(coupon) }}</p>
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <span class="badge-custom">{{ coupon.coupon_code }}</span>
                </td>
                <td class="text-center">
                  {{ moment(coupon.created_at).format('MMM D') }}
                </td>
                <td class="text-center">
                  <span v-if="coupon.type == 'fixed'">${{ $cast(coupon.amount) }}</span>
                  <span v-else>{{ coupon.percent }}%</span>
                </td>
                <td class="text-center">
                  <van-switch
                    class="enable-switch m-auto"
                    v-model="coupon.status"
                    @change="changeStatus(coupon.id)"
                    size="15"
                  />
                </td>
                <td class="text-right">
                  <van-popover
                    v-model="coupon.show_menu"
                    placement="bottom-end"
                    :close-on-click-outside="true"
                    trigger="click"
                    @open="menuOpen(index)"
                    :actions="actions"
                    @select="menuSelect($event, coupon.id)"
                  >
                    <template #reference>
                      <van-icon name="ellipsis" size="20" class="pointer" />
                    </template>
                  </van-popover>
                </td>
              </tr>
            </tbody>
          </table>
        </van-checkbox-group>
        <b-pagination
          :value="params.page"
          :per-page="params.limit"
          :total-rows="total"
          @change="changePage"
          first-number
          last-number
          pills
          align="center"
          class="mt-4"
        ></b-pagination>
      </div>
      <van-empty image="/image/empty-lists/empty-coupon.svg" v-else :description="$t('mngmt.coupon.emptyCoupon')" />
    </div>

    <div v-else class="loading">
      <van-loading type="spinner" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { Toast, Dialog } from 'vant'
export default {
  data() {
    return {
      moment,
      selectedCoupons: [],
      actions: [
        { text: 'Edit Coupon', icon: 'edit', value: 'edit' },
        { text: 'Clone Coupon', icon: 'cluster-o', value: 'clone' },
        { text: 'Delete Coupon', icon: 'delete-o', value: 'delete' },
      ],
      params: {
        keyword: '',
        page: 1,
        limit: 10,
      },
      total: 0,
      coupons: [],
      loaded: false,
    }
  },
  methods: {
    changePage(page) {
      this.params.page = page
      this.fetchData()
    },
    menuOpen(index, data) {
      this.coupons = this.coupons.map((coupon) => {
        coupon.show_menu = false
        return coupon
      })
      this.coupons[index].show_menu = true
    },
    menuSelect(e, id) {
      switch (e.value) {
        case 'edit':
          this.$router.push(`/management/coupons/edit/${id}`)
          break
        case 'delete':
          this.deleteCoupon(id)
          break
        case 'clone':
          this.$router.push(`/management/coupons/clone/${id}`)
          break
      }
    },
    async changeStatus(id) {
      await axios.put(`/discounts/toggle_enable/${id}`)
    },
    async deleteCoupon(id = false) {
      await Dialog.confirm({
        title: this.$t('action.areYouSure'),
        cancelButtonText: this.$t('action.cancel'),
        confirmButtonText: this.$t('action.del'),
      })
      let discount_ids = id ? [id] : this.selectedCoupons
      let res = await axios.post('/discounts/delete', { discount_ids })
      if (res) {
        Toast.success(this.$t('state.deleted'))
        this.fetchData()
      }
    },
    async fetchData() {
      let res = await axios.get('discounts', { params: this.params })
      if (res) {
        this.coupons = res.data.data.data.map((coupon) => {
          coupon.status = coupon.status ? true : false
          coupon.show_menu = false
          return coupon
        })
        this.total = res.data.data.total
        this.loaded = true
      }
    },
    getValidTime(coupon) {
      if (coupon.valid_from && coupon.valid_to) {
        if (moment(coupon.valid_from).isBefore(moment())) {
          let relative_time = moment(coupon.valid_to).fromNow()
          return this.$t('mngmt.coupon.validEnds', {time: relative_time})
        } else {
          let relative_time = moment(coupon.valid_from).fromNow()
          return this.$t('mngmt.coupon.valid', {time: relative_time})
        }
      } else {
        return this.$t('mngmt.coupon.validUnlimited')
      }
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>

<style lang="scss" scoped>
.card {
  border: none;
  border-radius: 10px;
}
.text-sm {
  font-size: 12px;
  font-weight: 300 !important;
}
.line {
  width: 60px;
  margin: 0 auto;
  border-top: 2px solid #e7e6e6;
}
.badge-custom {
  border-radius: 10px;
  padding: 8px 14px;
  font-weight: 600;
  font-size: 11px !important;
  text-align: center;
  background-color: #e9e9e9;
}
.custom-line {
  border: 3px solid red;
}
</style>