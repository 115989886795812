<template>
  <div class="bg-white">
    <div class="d-flex justify-content-between align-items-center">
      <custom-search v-model="params.keyword" @search="fetchData()" />
      <van-button @click="$router.push('/management/customers/create')" class="add-button" type="default">
        <van-icon name="add-o" size="20" class="mr-2" />
        {{$t('mngmt.customer.newCustomer')}}
      </van-button>
    </div>

    <DataView type="customer" :data="data" />

    <div v-if="!loaded" class="loading">
      <van-loading type="spinner" />
    </div>

    <b-pagination
      :disabled="!loaded"
      :value="params.page"
      :per-page="params.limit"
      :total-rows="total"
      @change="changePage"
      first-number
      last-number
      pills
      align="center"
      class="mt-4"
    ></b-pagination>
  </div>
</template>

<script>
import DataView from '../components/DataView'

export default {
  components: { DataView },
  data() {
    return {
      loaded: false,
      data: {},
      total: 0,
      params: {
        page: 1,
        limit: 8,
        keyword: '',
      },
    }
  },
  methods: {
    /**
     * GET api/customers
     * fetch customers from server with pagination and sorting
     */
    async fetchData() {
      this.loaded = false
      try {
        const res = await axios.get('/customers', { params: this.params })
        if (res) {
          this.loaded = true
          this.data = res.data.data.customers
          this.total = res.data.data.total
          console.log(this.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    changePage(page) {
      this.params.page = page
      this.loaded = false
      this.fetchData()
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>
