<template>
  <div>
    <div class="mb-4 products-management-container container-fluid">
      <LeftNavBar/>
      <div class="right-container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import LeftNavBar from './components/LeftNavBar'

export default {
  components: {LeftNavBar},
}
</script>
<style lang="scss" scoped>
.products-management-container {
  height: auto;
  align-items: flex-start;
  display: flex;
  width: 100%;
  margin-bottom: 0px !important;
}

.right-container {
  width: 100%;
  padding: 20px 25px 30px 25px;
  background: white;
  border-radius: 10px;
  min-height: 70vh;
  position: relative;

  ::v-deep {
    h1 {
      font-size: 15px;
      font-weight: 700;
      color: #000;
    }

    h2 {
      font-size: 14px;
      font-weight: 700;
      color: #000;
    }
  }
}

@media (max-width: 992px) {
  .right-container {
    width: calc(100% - 170px);
    padding: 14px;
  }
}
</style>
