<template>
  <div>
    <FrontendNav />
    <div class="container-box container">
      <!-- box list -->
      <div class="box-list">
        <template v-for="(plan, index) in plans">
          <div
            v-if="plan.key !== 'bronze'"
            class="box"
            @click="pickPlan(plan)"
            :key="index"
            :class="[plan.key == selectedPlan.plan ? 'active' : '']"
          >
            <img width="30" :src="plan.images[1]" alt="" />
            <h5 class="mb-3 mt-4">{{ plan.name }}</h5>
            <van-popover
              v-model="plan.showPopover"
              @select="onSelectPeriod($event, index)"
              trigger="click"
              @open="popoverOpened(index)"
              :actions="actions"
            >
              <template #reference>
                <van-button>
                  <span class="text-capitalize">{{ plan.selectedPeriod }}</span>
                  <van-icon class="i-c" name="arrow-down" />
                </van-button>
              </template>
            </van-popover>
          </div>
        </template>
      </div>
      <!--  -->

      <div class="mt-5 row">
        <div class="col-lg-8 mb-4">
          <div class="card p-4">
            <p>Plan Detail</p>
            <h4>{{ plan.name }}</h4>
            <ul class="mt-3">
              <li v-for="(feature, idx) in plan.features" :key="idx" class="mb-3">
                <img alt="" src="/image/businessprofile/tick-square.svg" />
                {{ feature }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card">
            <!--  -->
            <div class="p-4">
              <p class="mb-3">Payment Method</p>
              <div class="method" v-for="(method, index) in methods" :key="index" @click="selectPaymentMethod(index)">
                <div class="d-flex flex-column">
                  <div class="method-title">
                    <img :src="method.image" alt="" v-if="method.image" />
                    <span>{{ method.description }}</span>
                  </div>
                  <div v-if="method.images && method.images.length > 0" class="images">
                    <img :src="img" v-for="(img, i) in method.images" :key="i" />
                  </div>
                </div>

                <svg
                  v-if="method.selected"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle r="8.5" transform="matrix(1 0 0 -1 9.5 9.5)" stroke="black" stroke-width="2" />
                  <circle r="4.5" transform="matrix(1 0 0 -1 9.5 9.5)" fill="black" />
                </svg>

                <svg v-else width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle r="8.5" transform="matrix(1 0 0 -1 9.5 9.5)" stroke="#D3D3D3" stroke-width="2" />
                </svg>
              </div>
            </div>
            <!--  -->
            <div class="price p-4">
              <p>Amount Due <span class="cur">(MMK)</span></p>
              <h3>K {{ plan.price[plan.selectedPeriod] }} <span class="cur">(Tax incl)</span></h3>

              <button class="blue" @click="pay()">Pay Now</button>

              <p class="cur text-center mt-3">
                By confirming your pay, you agree to MaNaw Store
                <router-link to="/terms-and-conditions">Payment Term.</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FrontendFooter />
  </div>
</template>

<script>
import FrontendFooter from '@/layouts/FrontendFooter.vue'
import FrontendNav from '@/layouts/FrontendNav.vue'
import plans from './pricing.json'

export default {
  components: {
    FrontendFooter,
    FrontendNav,
  },
  data() {
    return {
      selectedPlan: {
        plan: 'bronze',
      },
      plans,
      actions: [
        { text: 'Monthly', value: 'monthly' },
        { text: 'Yearly', value: 'yearly' },
      ],
      methods: [],
    }
  },
  computed: {
    plan: function () {
      return this.plans[this.selectedPlan.plan]
    },
  },
  mounted() {
    let plan = window.localStorage.getItem('selected_plan')
    if (plan) {
      this.selectedPlan = JSON.parse(plan)
      this.plans[this.selectedPlan.plan].selectedPeriod = this.selectedPlan.payment
    }
    this.getPaymentMethods()
  },
  methods: {
    async pay() {
      await this.$dialog.confirm({
        title: 'Confirm',
        message: `By confirming your pay, you agree to MaNaw Store <a href="/terms-and-conditions">Payment Term.</a> <br> Are you sure you want to continue?`,
        cancelButtonText: 'No',
        confirmButtonText: 'Proceed',
      })
      let method = this.methods.find((m) => m.selected)
      let res = await axios.get('/payment_url', {
        params: {
          plan: this.plan.key,
          period: this.plan.selectedPeriod,
          payment_channel: method.channel,
        },
      })
      if (res.data.success) {
        window.location.href = res.data.data
      }
    },

    pickPlan(plan) {
      this.selectedPlan = {
        plan: plan.key,
        payment: plan.selectedPeriod,
      }
    },

    selectPaymentMethod(index) {
      this.methods = this.methods.map((m) => {
        m.selected = false
        return m
      })
      this.methods[index].selected = true
    },

    async getPaymentMethods() {
      let res = await axios.get('/payment_methods')
      this.methods = res.data
    },

    popoverOpened(index) {
      for (let p in this.plans) {
        this.plans[p].showPopover = false
      }
      this.plans[index].showPopover = true
    },

    onSelectPeriod(period, index) {
      this.plans[index].selectedPeriod = period.value
    },
  },
}
</script>

<style lang="scss" scoped>
.price {
  border-top: 2px solid #f9f9ff;

  .cur {
    color: grey;
    font-size: 11px;
    font-weight: normal;
  }
  .blue {
    background: #4c40f7;
    width: 100%;
    margin-top: 10px;
    height: 40px;
    border-radius: 10px;
    border: none;
    color: white;
  }
}
.container-box {
  padding-top: 8rem;
  padding-bottom: 7rem;
}
.box-list {
  display: flex;
  justify-content: center;
  gap: 20px;

  .box {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    border: none;
    background: white;
    border-radius: 10px;
    width: 160px;
    height: 154px;
    justify-content: flex-end;
    align-items: center;

    button {
      width: 160px;
      border: none;
      background: none;
      border-top: 2px solid #f9f9ff;
      border-radius: 0px 0px 8px 8px;
      .i-c {
        margin-left: 20px;
      }
    }
  }
  .box.active {
    border: 2px solid #4c40f7;

    button {
      background: #4c40f7;
      border-top: 2px solid #4c40f7;
      color: white;
    }
  }
}

.card {
  border: none;
  border-radius: 10px;
}
.method {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid #e8e8f6;
  border-radius: 10px;
  min-height: 50px;
  padding: 10px 15px;

  .method-title {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      border-radius: 10px;
      margin-right: 15px;
    }
  }

  .images {
    margin-top: 7px;
    display: flex;
    flex-direction: row;

    img {
      width: 20px;
      height: 20px;
      object-fit: cover;
      margin-right: 5px;
      border-radius: 5px;
    }
  }
}
</style>
