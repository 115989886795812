<template>
  <div>
    <label class="label">
      {{ label }}
      <span class="ml-1" style="color: red" v-if="important">*</span>
    </label>
    <div>
      <div v-for="index in params[field].length" :key="index" class="multiple-input">
        <b-form-input
          v-model="params[field][index - 1]"
          :type="type"
          :placeholder="placeholder"
          :disabled="disabled"
          class="custom-input"
          debounce="500"
        />
        <div class="minus-icon" @click="params[field].splice(index - 1, 1)" v-if="index > 1 && !disabled"></div>
        <ErrorMessage :errors="errors" :field="field + '.' + (index - 1)" />
      </div>
    </div>
    <div v-if="add" class="add-input" @click="params[field].push('')">
      <van-icon name="plus" class="mr-1"></van-icon>{{ addText }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    addText: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    important: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    add: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style scoped>
.custom-input {
  border-radius: 10px;
  border: 2px solid #f4f4f4;
  font-size: 13px;
  color: #000;
  padding: 10px 15px;
  height: auto;
}
.custom-input:disabled {
  background-color: #fdfdfd;
}
.label {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  text-transform: capitalize;
}
.multiple-input:not(:first-child) {
  margin-top: 10px;
}
.minus-icon {
  width: 20px;
  height: 20px;
  background-image: url('/image/settings/minus.svg');
  background-repeat: no-repeat;
  background-size: contain;
  float: right;
  margin-top: -31px;
  margin-right: 10px;
  z-index: 10;
  cursor: pointer;
  border-radius: 50%;
}
.minus-icon:hover {
  border: 1px solid red;
}
.add-input {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);
  float: right;
  margin-top: 4px;
  cursor: pointer;
}
.fa-plus {
  font-size: 10px;
}
</style>
