<template>
  <div>
    <van-popup v-model="show" :close-on-click-overlay="false" class="custom-popup">
      <h1>Please verify your email</h1>
      <div class="text-center pt-3 my-4">
        <div class="text-muted">A code has been sent to</div>
        <h3>{{ params.email }}</h3>
      </div>
      <div class="text-center">
        <div v-if="errors.code" class="text-danger mb-3">{{ errors.code }}</div>
        <div class="d-flex justify-content-center">
          <otp-input
            :input-classes="errors.code ? 'code error' :  'code'"
            :num-inputs="4"
            :should-auto-focus="true"
            @on-complete="handleOnComplete"
          />
        </div>
        <div class="d-flex justify-content-center mt-4">
          <div class="text-muted">Didn't received?</div>
          <div @click="$emit('resend')" class="text-bold ml-2 pointer"> Send Again</div>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <button @click="verifyEmail()" class="btn btn-primary">Verify</button>
      </div>
    </van-popup>

    <alert-popup message="Your new email address is" :data="params.email" :autoCloseTime="2000" ref="emailAlert"/>
  </div>
</template>

<script>
import { Toast } from 'vant'

export default {
  props : ['params'],
  data() {
    return {
      show : false,
      code : null,
      errors : {}
    }
  },
  methods: {
    async verifyEmail() {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        duration: 0,
      })
      let params = {
        full_name : this.params.full_name,
        email : this.params.email,
        password : this.params.password,
        code : this.code
      }
      let res = await axios.post('auth/verify-email', params)
      if(res) {
        if(res.data.code == 200) {
          this.show = false
          this.$refs.emailAlert.open()
          this.$auth.user(res.data.data);
          this.$router.push('/pos')
        } else {
           this.errors = { code : 'Your code is wrong. Please try again.'}
        }
        Toast.clear()
      }
    },
    open() {
      this.code = null
      this.errors = {}
      this.show = true
    },
    handleOnComplete(value) {
      this.code = value
    },
  }
}
</script>

<style lang="scss" scoped>
  .custom-popup {
    width : 480px;
    border-radius: 10px;
    padding : 28px;
    font-size: 13px;
  }
  h1 {
    font-size : 19px;
    font-weight: 700;
  }
  h3 {
    margin-top : 5px;
    font-size : 13px;
    font-weight: 600;
  }
  .text-muted {
    color : rgba(0,0,0,0.5);
  }
  .btn-primary {
    font-weight: 400;
    padding : 0 45px;
    height: 40px;

    &:hover {
      font-weight: 400;
      height: 40px;
    }
  }
  .btn-cancel {
    font-weight: 400;
    padding-right : 0 20px;
  }

</style>
