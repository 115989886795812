<template>
  <div>
    <FrontendNav />
    <main>
      <div class="title-container container">
        <h4>Frequently Ask Questions</h4>
        <h2>Some of our frequently asked questions</h2>
      </div>
      <div class="img-container">
        <img src="@/assets/images/illustrations/support.svg" />
      </div>
      <div class="search-faq container">
        <div class="search">
          <span class="search-icon">
            <svg fill="none" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
              <path
                clip-rule="evenodd"
                d="M9.27138 0.25C13.9701 0.25 17.7921 4.072 17.7921 8.77075C17.7921 10.9876 16.9414 13.0095 15.5492 14.5269L18.2886 17.2606C18.545 17.517 18.5459 17.9317 18.2895 18.1881C18.1617 18.3176 17.9929 18.3815 17.8249 18.3815C17.6577 18.3815 17.4897 18.3176 17.3611 18.1899L14.5886 15.4251C13.1302 16.5931 11.281 17.2924 9.27138 17.2924C4.57263 17.2924 0.749756 13.4695 0.749756 8.77075C0.749756 4.072 4.57263 0.25 9.27138 0.25ZM9.27138 1.5625C5.29626 1.5625 2.06226 4.79562 2.06226 8.77075C2.06226 12.7459 5.29626 15.9799 9.27138 15.9799C13.2456 15.9799 16.4796 12.7459 16.4796 8.77075C16.4796 4.79562 13.2456 1.5625 9.27138 1.5625Z"
                fill="black"
                fill-rule="evenodd"
              />
            </svg>
          </span>
          <input placeholder="Search questions" type="text" @keyup="searchFaq" />
          <span class="go-arrow">
            <svg fill="none" height="13" viewBox="0 0 15 13" width="15" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.5 6.75997C0.5 6.42773 0.746885 6.15316 1.0672 6.10971L1.15625 6.10372L12.6923 6.10424L8.52464 1.95366C8.2678 1.69793 8.26691 1.28241 8.52264 1.02558C8.75512 0.792095 9.11965 0.77013 9.37697 0.960199L9.45071 1.02358L14.7445 6.29458C14.7783 6.32829 14.8077 6.36477 14.8327 6.4033C14.8397 6.4149 14.8469 6.42676 14.8537 6.43886C14.86 6.44924 14.8656 6.46001 14.871 6.4709C14.8784 6.48674 14.8856 6.50311 14.8922 6.51982C14.8975 6.5327 14.902 6.54523 14.906 6.55785C14.9109 6.57351 14.9156 6.59019 14.9196 6.60713C14.9226 6.61896 14.925 6.63034 14.9271 6.64177C14.93 6.65877 14.9325 6.67637 14.9342 6.69417C14.9358 6.70775 14.9367 6.7212 14.9372 6.73467C14.9373 6.74283 14.9375 6.75138 14.9375 6.75997L14.9372 6.78539C14.9367 6.79827 14.9358 6.81114 14.9345 6.82398L14.9375 6.75997C14.9375 6.80138 14.9337 6.8419 14.9263 6.88118C14.9246 6.89057 14.9226 6.9002 14.9204 6.90979C14.9157 6.92954 14.9103 6.94857 14.9041 6.96722C14.9011 6.97648 14.8975 6.98639 14.8937 6.99621C14.8861 7.01596 14.8777 7.03478 14.8685 7.05312C14.8643 7.06174 14.8595 7.07077 14.8545 7.0797C14.8463 7.09428 14.8379 7.10813 14.8289 7.12163C14.8226 7.13119 14.8157 7.14113 14.8084 7.15092L14.8027 7.15848C14.785 7.18158 14.7658 7.20347 14.7453 7.22401L14.7445 7.22462L9.45075 12.4965C9.19394 12.7522 8.77843 12.7514 8.52268 12.4946C8.29018 12.2611 8.26975 11.8965 8.4609 11.64L8.5246 11.5665L12.6905 7.41674L1.15625 7.41622C0.793813 7.41622 0.5 7.12241 0.5 6.75997Z"
                fill="black"
              />
            </svg>
          </span>
        </div>
        <div class="faqs">
          <FAQ v-for="(faq, idx) in faqData" :key="idx" :faq="faq" />
        </div>
      </div>

      <div class="contact-us text-center">
        <h4 class="mb-5">Customer Support</h4>
        <a href="mailto:contact@bilions.org"> <img src="@/assets/utils/mail.svg" /> contact@bilions.org </a>
        <a href="https://www.facebook.com/bilions.org">
          <img src="@/assets/social/facebook-icon.svg" /> Bilions Facebook Page
        </a>
        <a href="tel:+959764475708"> <img src="@/assets/utils/phone.svg" /> +959764475708 </a>
      </div>
    </main>
    <FrontendFooter />
  </div>
</template>

<script>
import FrontendFooter from '@/layouts/FrontendFooter'
import FrontendNav from '@/layouts/FrontendNav'
import FAQ from '../../@components/Faq'
import faqs from './data.json'

export default {
  data: () => ({
    faqData: faqs,
    timer: null,
  }),
  components: {
    FAQ,
    FrontendFooter,
    FrontendNav,
  },
  mounted() {
    // this.initiateCall()
  },
  beforeRouteLeave(to, from, next) {
    // window.fcWidget.destroy()
    next()
  },
  methods: {
    initFreshChat() {
      console.log(this.$auth.user())
      if (this.$auth.check()) {
        window.fcWidget.setExternalId(this.$auth.user().id + '-' + this.$auth.user().plan)
        window.fcWidget.user.setFirstName(this.$auth.user().full_name)
        window.fcWidget.user.setEmail(this.$auth.user().email)
      }
      window.fcWidget.init({
        token: '50248cb8-1349-40db-b4c6-b34a97d53e73',
        host: 'https://wchat.freshchat.com',
      })
    },
    initialize(i, t) {
      var e
      i.getElementById(t)
        ? this.initFreshChat()
        : (((e = i.createElement('script')).id = t),
          (e.async = !0),
          (e.src = 'https://wchat.freshchat.com/js/widget.js'),
          (e.onload = this.initFreshChat),
          i.head.appendChild(e))
    },
    initiateCall() {
      this.initialize(document, 'Freshdesk Messaging-js-sdk')
    },

    searchFaq(e) {
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        let searchQuery = e.target.value.toLowerCase().trim()
        if (searchQuery) {
          this.faqData = faqs.filter(
            (faq) => faq.question.toLowerCase().includes(searchQuery) || faq.answer.toLowerCase().includes(searchQuery),
          )
        } else {
          this.faqData = faqs
        }
      }, 800)
    },
  },
}
</script>

<style lang="scss" scoped>
main {
  padding: 10rem 0;
  background-color: #f8f8f8;

  .title-container {
    padding: 3rem 0;
    text-align: center;

    h4 {
      color: #ff4d4d;
      margin-bottom: 15px;
    }

    h2 {
      font-weight: bold;
    }
  }

  .img-container {
    max-width: 40rem;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .search-faq {
    .search {
      position: relative;
      margin: 30px auto;
      max-width: 60rem;

      input {
        outline: none;
        padding: 15px 50px;
        border-radius: 10px;
        font-size: 15px;
        width: 100%;
        transition: all 0.1s ease;
        border: 2px solid transparent;
      }

      input:focus {
        border: 2px solid var(--web-theme);
        box-shadow: 0px 0px 0px 3px #ffb9c5;
      }

      .search-icon,
      .go-arrow {
        position: absolute;
        top: 17px;
      }

      .search-icon {
        left: 15px;
      }

      .go-arrow {
        top: 18px;
        right: 15px;
      }
    }

    .faqs {
      max-width: 60rem;
      margin: 50px auto;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
}
.contact-us {
  a {
    color: black;
    margin-bottom: 20px;
    margin-right: 30px;
  }
}
</style>