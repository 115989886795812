<template>
  <div class="button-section p-3 mt-2">
    <div class="btn-left d-flex flex-column">
      <button :loading="loading" class="btn btn-primary" pos-tooltip-step="20"
              @click="submit(); $events.fire('nextStep')">
        {{ $route.params.submitType == 'edit' ? $t('action.update') : $t('action.create') }}
      </button>
      <button
          v-if="$route.params.submitType == 'edit'"
          :dloading="dloading"
          class="btn btn-danger mt-3"
          type="delete"
          @click="deleteInvoice()"
      >
        {{ $t('action.del') }}
      </button>

      <button v-if="$route.params.submitType == 'edit'" class="btn btn-print mt-3" @click="requestPrint()">
        <van-icon class="mr-2" name="description"></van-icon>
        {{ $t('action.print') }}
      </button>
    </div>
  </div>
</template>

<script>
import {Dialog, Toast} from 'vant'

export default {
  props: ['params', 'type'],
  data() {
    return {
      loading: false,
      dloading: false,
    }
  },
  methods: {
    async requestPrint() {
      let id = this.$route.params.id
      let res = await axios.get(`/invoice/${id}/print_request`)
      if (res.data.code == 200) {
        this.printUrl = res.data.data.redirect_url
        window.open(this.printUrl)
      }
    },

    async deleteInvoice() {
      await Dialog.confirm({
        title: this.$t('action.areYouSure'),
        cancelButtonText: this.$t('action.cancel'),
        confirmButtonText: this.$t('action.del'),
      })
      this.dloading = true
      let res = await axios.delete('/invoices/' + this.$route.params.id)
      if (res.data.success) {
        Toast.success(this.$t('state.deleted'))
        this.$router.push(`/invoices/${this.type}/list`)
      }
      this.dloading = false
    },
    async submit() {
      this.loading = true
      this.params.invoice_date = this.params.invoiceDate.startDate
      this.params.due_date = this.params.dueDate.startDate
      this.params.products.map((product) => {
        product.quantity = product.quantity || 1
        product.options = product.optionList
            .filter((option) => !(!option.selected || option.selected.length < 1))
            .map((option) => {
              let result = {
                product_option_id: option.id,
                items: option.selected.length ? option.selected : [option.selected],
              }
              return result
            })
        return product
      })
      let res =
          this.$route.params.id && this.$route.params.submitType == 'edit'
              ? await axios.put(`/invoices/${this.type}/${this.$route.params.id}`, this.params) // for edit
              : await axios.post(`/invoices/${this.type}`, this.params) // for create and clone

      if (res) {
        if (res.data.success) {
          Toast.success(this.$route.params.id ? this.$t('state.updated') : this.$t('state.created'))
          this.$router.push(`/invoices/${this.type}/list`)
        } else if (res.data.code == 422) {
          this.$emit('errors', res.data.error)
        }
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.btn-save-draft {
  text-align: center;
  padding: 8px;
  background-color: #ffffff;
  border-radius: 10px;
  width: 120px;
  font-size: 14px;
  font-weight: 500;
  color: var(--theme);
  border: none;
}

.btn-save {
  text-align: center;
  padding: 8px;
  background-color: var(--theme);
  border-radius: 10px;
  color: white;
  width: 120px;
  font-size: 12px;
  font-weight: 500;
  border: none;
}

.btn-save:active,
.btn-save-draft:active {
  box-shadow: 0 0 0 3px var(--theme);
  background: white;
  color: var(--theme);
}

.btn-print {
  text-align: center;
  padding: 8px;
  border-radius: 10px;
  font-weight: bold;
  border: none;
  background: #f4f4f4;
  border: 2px solid #ededed;
}
</style>
