<template>
  <van-popup v-model="show" class="alert-popup">
    <div @click="show = false" class="close-popup">
      <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="6.6232" y1="6.62891" x2="17.9944" y2="18.0001" stroke="black" stroke-width="2" stroke-linecap="round"/>
        <path d="M6.15625 17.9937L17.9946 6.15531" stroke="black" stroke-width="2" stroke-linecap="round"/>
      </svg>
    </div>
    <div class="text-center">
      <svg width="53" height="53" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle class="icon-primary" cx="32.5" cy="32.5" r="32.5" fill="#171717"/>
        <path d="M23 33L30 40L44 26" stroke="white" stroke-width="6" stroke-linecap="round"/>
      </svg>
      <div class="content">
        <h1 class="title">{{ title }}</h1>
        <div class="message">{{ message }}</div>
        <h3 class="data">{{ data }}</h3>
      </div>
      <button @click="show = false" class="btn btn-primary">Done</button>
    </div>
  </van-popup>
</template>

<script>
export default {
  props : {
    title : {
      default : 'Completed'
    },
    message : {
      default : 'Success'
    },
    data : {},
    autoCloseTime : {}
  },
  data() {
    return {
      show : false,
    }
  },
  methods: {
    open() {
      this.show = true
      if(this.autoCloseTime) {
        setTimeout(() => this.show = false, this.autoCloseTime);
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .alert-popup {
    width : 380px;
    border-radius: 8px;
    padding : 28px;

    .close-popup {
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;

      path, line{
        stroke: rgba(0,0,0,0.8);
      }

      &:hover {
        path, line{
          stroke: #000;
        }
      }
    }

    .icon-primary {
      fill : var(--theme);
    }
    .content {
      margin-top : 15px;
      margin-bottom : 20px;
    }
    .title {
      font-size : 15px;
      font-weight: 700;
      color : #000;
    }
    .message {
      color : rgba(0,0,0,0.5);
      font-size : 13px;
      margin-top : 15px;
    }
    .data {
      font-size : 13px;
      font-weight: 600;
      color : #000;
      margin-top : 5px;
    }
    .btn-primary {
      font-weight: 400;
      padding : 0 40px;
      font-size : 13px;
    }
  }
</style>