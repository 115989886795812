<template>
  <div>
    <van-popup v-model="popup.show" position="right" style="border-radius: 10px 0px 0px 10px">
      <div v-if="invoice.id" class="popup-container">
        <div class="close-btn" @click="popup.show = false">
          <van-icon name="cross"></van-icon>
        </div>
        <section>
          <h3>{{ invoice.invoice_number }}</h3>
          <div class="sec-container">
            <div v-if="invoice.receiver.receiver_name" class="account">
              <div class="img-container">
                <img src="/image/placeholder.png" />
              </div>
              <div>
                <b>{{ invoice.receiver.receiver_name }}</b>
                <div>{{ invoice.receiver.receiver_email }}</div>
              </div>
            </div>
            <div class="print-container mt-2">
              <div class="print-btn">
                <div class="print" @click="$emit('print', invoice.id)">
                  <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.75 12.75V8.25L5.25 9.75"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      d="M6.75 8.25L8.25 9.75"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      d="M16.5 7.5V11.25C16.5 15 15 16.5 11.25 16.5H6.75C3 16.5 1.5 15 1.5 11.25V6.75C1.5 3 3 1.5 6.75 1.5H10.5"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      d="M16.5 7.5H13.5C11.25 7.5 10.5 6.75 10.5 4.5V1.5L16.5 7.5Z"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </svg>
                  <span>
                    {{$t('action.print')}}
                  </span>
                </div>

                <div class="print-opt">
                  <van-popover
                    v-model="showPrintOpt"
                    :actions="actions"
                    :close-on-click-outside="true"
                    placement="bottom"
                    trigger="click"
                    @select="menuSelect($event)"
                  >
                    <template #reference>
                      <van-icon name="arrow-down" />
                    </template>
                  </van-popover>
                </div>
              </div>
            </div>
          </div>
          <div class="info-container">
            <table>
              <tr>
                <td>{{ $t('invoice.invoiceDate') }}</td>
                <td>{{ this.formatDate(invoice.invoice_date, false) }}</td>
              </tr>
              <tr>
                <td>{{ $t('invoice.createdDateTime') }}</td>
                <td>{{ this.formatDate(invoice.created_at) }}</td>
              </tr>
            </table>
          </div>
          <hr />
          <div class="product-container">
            <table>
              <tr v-for="(product, index) in invoice.products" :key="index">
                <td>
                  <span>{{ product.quantity }} x </span>
                  {{ product.name }} 
                  <span v-if="product.unit && product.unit.id != null">
                    ({{ product.unit.unit }})
                  </span>
                </td>
                <td class="text-right">{{ $currency }}{{ product.price }}</td>
              </tr>
            </table>
          </div>
          <hr />
        </section>

        <div class="total-container">
          <table>
            <tr>
              <td>{{$t('subtotal')}}</td>
              <td class="text-right">{{ $currency }}{{ invoice.total }}</td>
            </tr>
            <tr>
              <td>{{$t('tax')}}</td>
              <td class="text-right">{{ $currency }}{{ invoice.tax_value }}</td>
            </tr>
            <tr>
              <td>{{$t('discount.discount')}}</td>
              <td class="text-right">{{ $currency }}{{ invoice.discount }}</td>
            </tr>
            <tr>
              <th>{{$t('grandTotal')}}</th>
              <th class="text-right">{{ grandTotal }}</th>
            </tr>
          </table>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'InvoicePopup',
  props: ['popup'],
  watch: {
    popup: {
      immediate: true,
      handler() {
        this.fetchData()
      },
    },
  },
  data: () => ({
    invoice: {},
    showPrintOpt: false,
  }),
  computed: {
    actions: function () {
      return  [
        { text: this.$t('action.printReceipt'), value: 'print' },
        { text: this.$t('action.printPdf'), value: 'print-pdf' },
      ]
    },
    grandTotal: function () {
      return `${this.$currency}${this.invoice.total + this.invoice.tax_value - this.invoice.discount}`
    },
    productTotal: function () {
      return this.invoice.products.reduce((p, c) => p.price * p.quantity + c.price * c.quantity, {
        price: 0,
        quantity: 0,
      })
    },
  },
  methods: {
    async fetchData() {
      let res = await axios.get(`/invoices/${this.popup.id}`)
      if (res.data) {
        this.invoice = res.data.data
      }
    },
    menuSelect(e) {
      switch (e.value) {
        case 'print':
          this.$emit('print', this.invoice.id)
          break
        case 'print-pdf':
          this.$emit('printpdf', this.invoice.id)
          break
      }
    },
    formatDate(date, time = true) {
      if (time) {
        return moment(date).format('MMMM DD, YYYY - h:mm a')
      }
      return moment(date).format('MMMM DD, YYYY')
    },
  },
}
</script>

<style lang="scss" scoped>
.popup-container {
  height: 100vh;
  padding: 50px 30px 40px;
  width: 35vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .close-btn {
    position: absolute;
    right: 20px;
    top: 10px;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
  }

  .sec-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px 0;

    .account {
      display: flex;
      align-items: center;
      gap: 10px;

      .img-container {
        width: 40px;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
  }

  .print-container {
    max-width: 180px;
    width: fit-content;
    .print-btn {
      background-color: var(--theme);
      color: #fff;
      padding: 10px 15px;
      font-size: 15px;
      display: flex;
      align-items: center;
      gap: 10px;
      border-radius: 10px 0 0 10px;
      position: relative;
      cursor: pointer;

      .print {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .van-popover__wrapper {
        border-left: 1px solid #333;
        border-radius: 0 10px 10px 0;
        background-color: var(--theme);
        position: absolute;
        right: -37px;
        top: 0;
        padding: 10px 15px;
      }
    }
  }

  .info-container {
    table {
      width: 100%;

      td {
        padding: 3px 0;
      }

      td:first-child {
        width: 35%;
        color: #727272;
      }
    }
  }

  .product-container {
    table {
      width: 100%;

      td {
        padding: 3px 0;
      }

      td {
        font-weight: 500;
        font-size: 15px;

        span {
          color: #727272;
          font-size: 13px;
        }
      }
    }
  }

  .total-container {
    table {
      width: 100%;

      td {
        padding: 3.5px 0;
      }

      th {
        padding: 10px 0 0;
      }
    }
  }
}
</style>