<template>
  <div class="dashboard-income-overview pb-2">
    <div class="col-12 text-nowrap income-overview-top d-flex justify-content-between pt-4">
      <div class="col-3 p-2">
        <span style="line-height: 7px">
          <p style="font-size: 14px">{{ $t('dashboard.revenueOverview') }}</p>
          <h4 class="font-weight-bold">{{ $currency }} {{ $cast(income) }}</h4>
          <p v-if="percentage < 0" class="text-danger text-capitalize" style="font-size: 12px">
<!--            Down {{ $cast(percentage) }}% Previous {{ percentageType }}-->
            {{ $t('dashboard.overviewSummary', {status: 'Down', percentage: $cast(percentage), percentageType}) }}
          </p>
          <p v-if="percentage > 0" class="text-success text-capitalize" style="font-size: 12px">
<!--            Up {{ $cast(percentage) }}% Previous {{ percentageType }}-->
           {{ $t('dashboard.overviewSummary', {status: 'Up', percentage: $cast(percentage), percentageType}) }}
          </p>
          <p v-if="percentage == 0" class="text-capitalize" style="font-size: 12px">
<!--            {{ $cast(percentage) }}% Previous {{ percentageType }}-->
            {{ $t('dashboard.overviewSummary', {status: '', percentage: $cast(percentage), percentageType}) }}

          </p>
        </span>
      </div>

      <!-- === start sort button === -->
      <div>
        <van-popover v-model="showPopover" :actions="actions" placement="bottom-end" trigger="click" @select="onSelect">
          <template #reference>
            <van-button v-if="isBarChart" type="primary">
              <!--              <van-icon name="chart-trending-o" class="mr-2"></van-icon>-->
              <svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M1.35278 1.6817V13.1796C1.35278 14.3023 2.25908 15.2086 3.38182 15.2086H14.8797"
                      stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path
                    d="M3.38184 11.8269L6.48626 8.20172C7.00028 7.60654 7.91334 7.56595 8.46794 8.12731L9.11047 8.76985C9.66507 9.32445 10.5781 9.29062 11.0922 8.69544L14.2033 5.06348"
                    stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                    stroke-linejoin="round"/>
              </svg>
              <span>{{ $t('dashboard.barChart') }}</span>
              <van-icon class="ml-2" name="arrow-down"></van-icon>
            </van-button>
            <van-button v-if="isColumnChart" type="primary">
              <!--              <van-icon name="bar-chart-o" class="mr-2"></van-icon>-->
              <svg class="mr-2" width="19" height="20" viewBox="0 0 19 20" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M1.58325 17.7457H17.4166" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10"
                      stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M7.71875 3.49569V17.7457H11.2813V3.49569C11.2813 2.62485 10.925 1.91235 9.85625 1.91235H9.14375C8.075 1.91235 7.71875 2.62485 7.71875 3.49569Z"
                    stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M2.375 8.24569V17.7457H5.54167V8.24569C5.54167 7.37485 5.225 6.66235 4.275 6.66235H3.64167C2.69167 6.66235 2.375 7.37485 2.375 8.24569Z"
                    stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M13.4583 12.2041V17.7457H16.6249V12.2041C16.6249 11.3332 16.3083 10.6207 15.3583 10.6207H14.7249C13.7749 10.6207 13.4583 11.3332 13.4583 12.2041Z"
                    stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span>{{ $t('dashboard.colChart') }}</span>
              <van-icon class="ml-2" name="arrow-down"></van-icon>
            </van-button>
          </template>
        </van-popover>
      </div>
      <!-- === end sort button === -->
    </div>
    <div v-if="series" class="col-12 income-overview-bottom">
      <!-- data chart -->
      <apexchart :options="options" :series="series" :type="chartType" height="200"></apexchart>
      <!-- data chart -->
    </div>
  </div>
</template>

<script>
export default {
  props: ['start_date', 'end_date'],
  data() {
    return {
      showPopover: false,
      actions: [{ text: 'Column Chart' }, { text: 'Bar Chart' }],
      isBarChart: true,
      isColumnChart: false,
      chartType: 'area',
      series: null,
      income: 0,
      percentage: 0,
      percentageType: 0,
      options: {
        stroke: {
          width: 1,
        },
        chart: {
          id: 'income-chart',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          width: '100%',
        },
        xaxis: {
          labels: {
            show: true,
          },
        },
        yaxis: {
          labels: {
            formatter(val, index) {
              let formatter = Intl.NumberFormat('en', { notation: 'compact' })
              return window.$company.currencySymbol + ' ' + formatter.format(val)
            },
          },
        },
        grid: {
          show: true,
        },
        colors: [this.$store.state.theme],
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          formatter(val, index) {
            let formatter = Intl.NumberFormat('en', { notation: 'compact' })
            return window.$company.currencySymbol + ' ' + formatter.format(val)
          },
        },
        tooltip: {
          enabled: true,
        },
      },
    }
  },

  mounted() {
    this.getData()
  },

  watch: {
    start_date: function () {
      this.getData()
    },
    end_date: function () {
      this.getData()
    },
  },

  methods: {
    onSelect(action) {
      if (action.text === 'Column Chart') {
        this.isBarChart = false
        this.isColumnChart = true
        this.chartType = 'bar'
      } else {
        this.isBarChart = true
        this.isColumnChart = false
        this.chartType = 'area'
      }
    },

    async getData() {
      let res = await axios.get('/dashboard/graph/income', {
        params: {
          start_date: this.start_date,
          end_date: this.end_date,
        },
      })
      this.series = [{ data: res.data.data.graph }]
      this.income = res.data.data.income
      this.percentage = res.data.data.percentage
      this.percentageType = res.data.data.percentageType
    },
  },
}
</script>
<style scoped>
.dashboard-income-overview {
  height: auto;
  background-color: white;
  border-radius: 10px;
}

.income-overview-top {
  height: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.income-overview-bottom {
  height: auto;
}

.income-filter-container {
  border: 2px solid #f1f1f1;
  height: 40px;
  border-radius: 10px;
  align-items: center;
}

.van-popover__content {
  border: 1px solid red;
}

.van-popover__wrapper button {
  border-radius: 10px;
  background: none;
  color: black;
  font-size: 12px;
  height: 38px;
  border: 2px solid #f1f1f1;
}
</style>
