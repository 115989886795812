<template>
  <div class="main d-flex flex-column justify-content-between px-4 py-3" pos-tooltip-step="23">
    <div>
      <van-popup v-model="showDiscountPopup" style="width: 30rem; height: fit-content">
        <div class="px-4 py-3 discount-popup">
          <h5 class="text-bold mb-3">{{$t('discount.discount')}}</h5>
          <label class="label">{{$t('discount.type')}}</label>
          <v-select
              :options="[
                  { label: $t('discount.fixed'), code: 'fixed' },
                  { label: $t('discount.percent'), code: 'percent' },
                ]"
              v-model="params.discount_type"
              :reduce="(type) => type.code"
              :clearable="false"
          ></v-select>
          <label class="label">{{$t('amount')}}</label>
          <input v-model="params.discount" class="input-box w-100" type="number" />
          <button @click="toggleDiscountPopup" class="btn btn-primary w-100 mt-4 mb-2">{{$t('action.apply')}}</button>
        </div>
      </van-popup>
      <div v-if="!customer" class="d-sm-flex align-items-center justify-content-between">
        <div class="rs_name"></div>
        <div>
          <img alt="" @click="toggleDiscountPopup" class="discount_img" src="/image/dashboard/discount-shape.svg" />
          <img
              id="popover-for-add-person"
              alt=""
              class="customer-image"
              src="/image/dashboard/profile-add.svg"
              @click="addPeople()"
          />
        </div>
      </div>

      <div v-else class="d-sm-flex align-items-center justify-content-between mb-2">
       <div @click="toggleDiscountPopup">
         <img alt="" class="discount_img" src="/image/dashboard/discount-shape.svg"/>
       </div>
        <div class="d-sm-flex customer_space justify-content-between">
          <!--  -->
          <div class="d-flex">
            <img :src="customer.image" alt="" class="my-auto"/>

            <div class="customer-detail my-auto">
              <h6 class="m-0 p-0 font-weight-bold">{{ truncateText(customer.name, 24) }}</h6>
              <span class="text-muted">{{
                  customer.email && customer.email[0] ? truncateText(customer.email[0], 30) : ''
                }}</span>
            </div>
            <!--  -->
          </div>

          <div class="customer_close my-auto">
            <van-icon class="ml-2 pointer" name="cross" size="18" @click="customerChanged(null)"/>
          </div>
          <!--  -->
        </div>
      </div>

      <div v-if="$store.getters.totalCartItems > 0" class="d-flex justify-content-end p-0">
        <h5 class="rs_clear mt-1 pt-1 pointer" @click="clearAllCartItems()">
          {{ $t('pos.clearAll', {count:$store.getters.totalCartItems })}}
        </h5>
      </div>
    </div>
    <!-- items -->
    <div v-if="$store.state.cart_items.length > 0" class="item-container">
      <div v-for="(item, index) in $store.state.cart_items" :key="item.index" class="item-detail row">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="rs_image d-flex">
            <template v-if="item.images && item.images[0]">
              <img :src="item.images[0]" alt="" class="mr-2"/>
            </template>

            <template v-else>
              <img alt="" class="mr-2" src="image/empty-lists/no-product-image.svg"/>
            </template>

            <div class="rs_content ml-1">
              <p class="m-0 p-0 product-name">{{ item.name }}
                <span v-if="item.unit &&  item.unit != null">
                  ({{item.unit.unit}})
                </span>
              </p>
              <p class="m-0 p-0 font-weight-bold">
                <span> {{ item.quantity }}</span>
                <span class="text-muted"> x </span>
                <span class="p-0 m-0 my-auto">{{ $currency }}{{ $cast(item.price) }} </span>
              </p>
            </div>
          </div>

          <div class="rs_price d-flex align-items-center">
            <van-icon class="ml-2 trash pointer" name="cross" @click="deleteItem(index)"/>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="item-empty-container">
      <van-empty :description="$t('pos.emptyCart')" image="/image/empty-lists/empty-cart-item.svg"/>
    </div>
    <!-- items -->
    <div v-if="$store.state.cart_items.length > 0" >
      <label class="label">{{$t('pos.bankAccount')}}</label>
      <div @click="openAccountSelect" class="d-flex align-items-center mb-4 bankacc-cont">
        <img src="/image/settings/accounts/bank-account-logo.svg" class="bank-icon"/>
        <div v-if="selectedAccount" class="ml-2">
          <div class="text-bold">{{ selectedAccount.owner_name}}</div>
          <small>{{$currency}} {{ selectedAccount.current_balance || selectedAccount.initial_amount }}</small>
        </div>
        <div class="ml-3" v-else>
          <small class="text-danger">{{$t('pos.selectAccountInfo')}}</small>
        </div>
      </div>
    </div>

    <van-popup v-model="showAccountSelect" style="min-height: 20rem; max-height: 40rem">
      <div class="px-4 py-3">
        <div class="label mb-3">{{$t('pos.selectAccount')}}</div>
        <!--        <input placeholder="Search" type="text" class="search-currency" @keyup="searchCurrency" />-->
        <div class="list">
          <ul>
            <li v-for="(account, index) in bankAccounts" :key="index" @click="setBankAccount(account)"
                class="d-flex justify-content-between my-3 px-4 py-3">
              <div>
                <h5 class="mb-0"> {{  account.owner_name }}</h5>
                <div class="my-1">{{$currency}} {{ account.current_balance || account.initial_amount }}</div>
                <div class="text-muted">{{ account.account_number }}</div>
              </div>

              <span class="float-right"> <van-icon name="circle" size="17"/> </span>
            </li>
          </ul>
        </div>
      </div>
    </van-popup>
    <div v-if="$store.getters.totalCartItems > 0" class="rs_bottom">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h6 class="text-muted">{{ $t('subtotal') }}</h6>
        </div>
        <div class="col-md-6 d-flex justify-content-end">
          <h6 class="text-muted">{{ $currency }} {{ $cast($store.getters.cartItemSubTotal) }}</h6>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-md-6">
          <h6 class="text-muted my-auto">{{ $t('tax') }} ({{ getTax }})</h6>
        </div>
        <div class="col-md-6 d-flex justify-content-end">
          <h6 class="text-muted ">{{ $currency }} {{ $cast($store.getters.cartTax) }}</h6>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-md-6">
          <h6 class="text-muted my-auto">{{ $t('discount.discount') }}</h6>
        </div>
        <div class="col-md-6 d-flex justify-content-end">
          <h6 class="text-muted">{{ $currency }} {{ $cast(getDiscount) }}</h6>
        </div>
      </div>
      <hr/>
      <div class="row align-items-center">
        <div class="col-md-6">
          <h6>{{ $t('total') }}</h6>
        </div>
        <div class="col-md-6 d-flex justify-content-end">
          <h6>{{ $currency }} {{ $cast($store.getters.cartItemGrandTotal - getDiscount) }}</h6>
        </div>
      </div>
      <div class="text-center mt-3 rs_button" >
        <button @click="submit(); $events.fire('nextStep')" pos-tooltip-step="25">{{ $t('pos.checkOut') }}</button>
      </div>
    </div>
    <div v-else></div>
    <!--  -->
    <van-popup v-model="pickCustomer">
      <ChooseCustomers @closeBox="pickCustomer = false" @pick="customerChanged"/>
    </van-popup>
    <!--  -->
  </div>
</template>

<script>
import {Dialog, Toast} from 'vant'
import ChooseCustomers from './ChooseCustomers.vue'
import isElectron from 'is-electron'
import vSelect from "vue-select";

const {ipcRenderer} = isElectron() ? require('electron') : {}

export default {
  components: {ChooseCustomers, vSelect},
  data() {
    return {
      customer: null,
      showPopover: false,
      showAccountSelect: false,
      showDiscountPopup: false,
      selectedAccount: null,
      accountParams: {
        page: 1,
        limit: 10,
      },
      bankAccounts: [],
      actions: [{text: 'Cation A'}, {text: 'Log Out'}],
      discount: {
        type: '',
        amt: 0,
      },
      params: {
        invoice_date: '',
        receiver_name: null,
        receiver_id: null,
        receiver_email: null,
        receiver_phone: null,
        shipping_address: null,
        billing_address: null,
        account_id: null,
        products: [],
        discount: 0,
        discount_type: '',
        tax: window.$company.tax,
        paid_amount: 0,
        from: 'pos',
        vat_type : "exclusive"
      },
      printerName: null,
    }
  },
  mounted() {
    const printerName = window.localStorage.getItem('PrinterName')
    this.printerName = printerName
    try {
      if (isElectron()) {
        this.getPrinters()
        ipcRenderer.on('printer-list', this.listPrinters)
      }
    } catch (error) {
    }
    this.fetchAccounts();
  },
  computed: {
    pickCustomer: {
      get: function () {
        return this.$store.getters.pickCustomer && !this.customer
      },
      set: function (val) {
        this.$store.commit('pickCustomer', val)
      },
    },
    getTax() {
      return window.$company.tax + '%'
    },
    getDiscount() {
      if (this.params.discount_type  === 'fixed') {
        return this.params.discount;
      }else {
        let percent = +this.params.discount
        return this.$store.getters.cartItemGrandTotal * (percent/100)
      }
    }
  },
  methods: {
    async fetchAccounts() {
      let res = await axios.get('accounts', { params: this.accountParams })
      this.bankAccounts = res.data.data.filter((acc) => acc.id !== 0);
      let stored_data = +window.localStorage.getItem('manaw_bank_account')
      if (this.bankAccounts.length > 0) {
        if (stored_data > 0) {
          this.selectedAccount = this.bankAccounts.filter(acc => acc.id === stored_data)[0]
        }else {
          this.selectedAccount = this.bankAccounts[0]
        }
        this.params.account_id = this.selectedAccount.id
      }
    },
    setBankAccount(account) {
      this.selectedAccount = account;
      window.localStorage.setItem('manaw_bank_account', +account.id) //account.id
      this.params.account_id = +account.id
      this.showAccountSelect = false
    },
    openAccountSelect() {
      if (this.bankAccounts.length == 0) {
        Dialog.confirm({
          title: this.$t('pos.noAccountAlert'),
          cancelButtonText: this.$t('action.cancel'),
          confirmButtonText: this.$t('action.add'),
        }).then(( ) => {
          this.$router.push('management/bank-accounts/create')
        })
      }else {
        this.showAccountSelect = true
      }
    },
    toggleDiscountPopup() {
      this.showDiscountPopup = !this.showDiscountPopup;
    },
    truncateText(s, count) {
      if (s.length <= count) {
        return s
      }
      return s.substr(0, count) + '...'
    },
    listPrinters(event, arg) {
      this.printers = JSON.parse(arg)
      // if (!this.printerName) {
      //   if (this.printers.length > 0) {
      //     this.printerName = this.printer[0].name
      //     window.localStorage.setItem('PrinterName', this.printerName)
      //   }
      // }
    },

    getPrinters() {
      ipcRenderer.send('getPrinters')
    },

    deleteItem(index) {
      this.$store.commit('deleteItem', index)
    },

    clearAllCartItems() {
      this.$store.commit('clearItem')
    },

    customerChanged(customer) {
      this.customer = customer
    },

    addPeople() {
      this.pickCustomer = true
    },

    async printReceipt(id) {
      await Dialog.confirm({
        title: this.$t('pos.printConfirm'),
        cancelButtonText: this.$t('action.cancel'),
        confirmButtonText: this.$t('pos.print'),
      })
      let res = await axios.get(`/invoice/${id}/print_pos`)
      const data = {
        printer: this.printerName,
        html: res.data,
      }
      ipcRenderer.send('printDocs', JSON.stringify(data))
      Toast.success(this.$t('state.success'))
    },
    async submit() {
      // confirm alert
      if (!this.params.account_id) {
        Dialog.alert({message: 'Please select an account', confirmButtonText: 'OK'})
        return
      }
      await Dialog.confirm({
        title: this.$t('pos.checkoutConfirm'),
        cancelButtonText: this.$t('action.cancel'),
        confirmButtonText: this.$t('pos.checkOut'),
      })

      Toast.loading({
        message: this.$t('pos.checkingOut'),
        forbidClick: true,
        duration: 0,
      })
      let date = new Date()
      let dueDate = new Date()
      dueDate.setDate(date.getDate() + 7)
      this.params.invoice_date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      this.params.due_date = `${dueDate.getFullYear()}-${dueDate.getMonth() + 1}-${dueDate.getDate()}`
      if (this.customer) {
        this.params.receiver_email = this.customer.email && this.customer.email[0] ? this.customer.email[0] : null
        this.params.receiver_id = this.customer.id
        this.params.receiver_tax_id = this.customer.tax_id ?? null
        this.params.receiver_name = this.customer.name
        this.params.receiver_phone = this.customer.phone && this.customer.phone[0] ? this.customer.phone[0] : null
        this.params.billing_address = this.customer.address
        this.params.shipping_address = this.customer.address
        this.params.paid_amount = 0
        this.params.discount = +this.params.discount || 0
      }

      let products = this.$store.getters.cartItems

      products.map((product) => {
        product.unit = product.unit || null
        product.product_id = product.id
        product.quantity = product.quantity || 1

        let selectedOptions = []
        product.options.forEach((option) => {
          let selected = []
          if (option.is_multiple) {
            selected = option.selected
          } else {
            selected = [option.selected]
          }

          let items = []

          option.product_option_items.forEach((item) => {
            if (selected.includes(item.id)) {
              items.push(item.id)
            }
          })

          if (items.length > 0) {
            selectedOptions.push({
              product_option_id: option.id,
              items,
            })
          }
        })
        product.options = selectedOptions
        return product
      })

      this.params.products = products
      let res = await axios.post(`/invoices/sale`, this.params)
      if (res && res.data.success) {
        this.clearAllCartItems()
        this.customer = null
        Toast.clear()
        try {
          let invoice = res.data.data
          if (isElectron()) {
            this.printReceipt(invoice.id)
          } else {
            Toast.success(this.$t('state.success'))
          }
        } catch (error) {
          console.log(error)
        }
      } else {
        Toast.clear()
        Dialog.alert({
          title: 'Error',
          message: res.data.error,
          confirmButtonText: 'OK',
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
#popover-for-add-person {
  cursor: pointer;
}

.item-container {
  overflow: auto;
  border-radius: 10px;
  padding: 0px 20px 20px 20px;
  margin: 0px -10px;
  height: 100%;
}

.item-empty-container {
  overflow: auto;
  border-radius: 10px;
  padding: 0px 20px 20px 20px;
  margin: 0px -10px;
}

.trash {
  font-size: 12px;
  background: #f95953;
  border-radius: 50px;
  padding: 4px;
  color: white;
}

.product-name {
  width: 100%;
  font-size: 11px;
  line-height: 12px;
  min-height: 24px;
  max-height: 24px;
  overflow: hidden;
}

.profile-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 0px;
}

.rs_noti {
  width: 25px;
  margin-right: 15px;
  height: 25px;
}

.rs_logo {
  height: 30px;
  width: 30px;
}

.add-person-title {
  font-size: 11px;
  padding: 8px 10px;
}

.item-detail {
  display: flex;
  padding: 8px 12px;
  justify-content: space-between;
  background: rgba(var(--theme-rgba), 0.07);
  margin: 5px -12px;
  border-radius: 14px;
}

.add-person-body {
  font-size: 14px;
  padding: 8px 10px;
  width: 250px;
  cursor: pointer;
}

.add-person-body:hover {
  background-color: #f4f4f4;
  border-radius: 7px;
}

.main {
  padding: 5px 15px;
  width: 300px;
  height: 100vh;
}

.customer_space {
  border: 2px solid #f4f4f4;
  padding: 6px 12px;
  border-radius: 10px;
  width: 100%;
}

.customer_space img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.customer-detail {
  margin-left: 10px;
}

.customer-detail h6 {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: black;
  line-height: 13px;
  padding-top: 3px;
}

.customer-detail span {
  font-style: normal;
  font-size: 11px;
  line-height: 20px;
}

.customer_close i {
  cursor: pointer;
}

.customer_close i:hover {
  color: red;
}

.discount_img {
  width: 19.97px;
  height: 19.97px;
  margin-left: 0px;
  margin-right: 12px;
  cursor: pointer;
}

.customer-image {
  width: 25px;
  height: 25px;
  margin-left: 10px;
}

.rs_name h4 {
  font-size: 16px;
  font-weight: 700;
}

.rs_clear {
  color: red;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}

.rs_clear:hover {
  text-decoration: underline;
}

.rs_image img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  object-fit: cover;
}

h6,
.rs_price span {
  font-size: 12px;
  font-weight: 700;
}

.rs_price img {
  margin-left: 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.rs_bottom {
  margin-top: 10px;
}

.rs_bottom h5 {
  font-size: 18px;
  font-weight: 700;
}

.rs_button button {
  width: 100%;
  height: 45px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  background: var(--theme);
  border-radius: 10px;
  border: none;
}

.rs_button button:active {
  background: rgba(var(--theme-rgba), 0.5);
}

.van-popup {
  top: 50%;
  width: 537px;
  height: 400px;
  border-radius: 10px;
}
.discount-popup {
  select {
    font-size: 14px;
    border: 2px solid #f6f6f6;
    border-radius: 10px;
  }
}

.bankacc-cont {
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
  border: 2px solid #f6f6f6;
}
.bank-icon {
  width: 35px;
  cursor: pointer;
}

.list li {
  cursor: pointer;
  padding: 6px 9px;
  border: 2px solid #f6f6f6;
  align-items: center;
  border-radius: 10px;
}
</style>
