<template>
  <div class="faq-wrapper">
    <div @click="toggleFaq" class="title">
      <h4>{{ faq.question }}</h4>
      <span>
        <svg  :class="{'rotated': expanded}" fill="none" height="8" viewBox="0 0 17 8" width="17" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1.00006L8.5 6.50006L16 1.00006" stroke="black" stroke-linecap="round" stroke-width="1.8"/>
        </svg>
      </span>
    </div>
    <div class="collapsible" ref="collapse">
      <hr />
      <p>
        {{ faq.answer }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQ',
  props: ['faq'],
  data: () => ({
    expanded: false,
  }),
  methods: {
    toggleFaq() {
      let collapse = this.$refs['collapse'];
      if (!this.expanded) {
       this.expandSection(collapse) ;
       this.expanded = true;
      } else {
        this.collapseSection(collapse)
        this.expanded = false;
      }
    },
    collapseSection(element) {
      let sectionHeight = element.scrollHeight;
      let elementTransition = element.style.transition;
      element.style.transition = '';
      requestAnimationFrame(function () {
        element.style.height = sectionHeight + 'px';
        element.style.transition = elementTransition;
        requestAnimationFrame(function () {
          element.style.height = 0 + 'px';
        });
      });
    },

    expandSection(element) {
      let sectionHeight = element.scrollHeight;
      element.style.height = sectionHeight + 'px';
        function listener (e) {
          element.style.height = null;
        }
        element.addEventListener('transitionend', listener );
        element.removeEventListener('transitionend', listener);
    },

  }
}
</script>

<style lang="scss" scoped>

.faq-wrapper {
  padding: 15px 20px 10px;
  border-radius: 10px;
  background-color: #fff;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    h4 {
      font-weight: bold;
      font-size: 16px;
      padding-right: 20px;
    }

    svg {
      transition: all 0.3s ease;
    }
    .rotated {
      transform: rotate(180deg);
    }
  }

  .collapsible {
    overflow: hidden;
    transition: height 0.24s ease-out;
    height: 0;
    white-space: pre-line;
    font-size: 13px;
  }

}
</style>