<template>
  <!-- === for edit === -->
  <button v-if="type === 'edit'" :disabled="loading" class="mt-5 mb-5 save-button mr-3" variant="primary">
    <div v-if="loading">
      <b-spinner small type="grow"></b-spinner>
      {{ $t('state.saving') }} ...
    </div>
    <div v-else>{{ $t('action.saveChange') }}</div>
  </button>

  <!-- === for create === -->
  <button
      v-else-if="type === 'create'"
      :disabled="loading"
      class="mt-4 mb-4 save-button font-weight-bold"
      variant="primary"
  >
    <div v-if="loading">
      <b-spinner small type="grow"></b-spinner>
      {{ $t('state.creating') }} ...
    </div>
    <div v-else>{{ $t('action.create') }} {{ name }}</div>
  </button>

  <!-- === for delete === -->
  <b-button v-else :disabled="dloading" class="mt-5 mb-5 delete-button" variant="danger">
    <div v-if="dloading">
      <b-spinner small type="grow"></b-spinner>
      {{ $t('state.deleting') }} ...
    </div>
    <div v-else>{{ $t('action.del') }} {{ name }}</div>
  </b-button>
</template>

<script>
export default {
  props: ['type', 'loading', 'dloading', 'name'],
}
</script>

<style scoped>
.delete-button {
  padding: 10px 35px;
  color: red;
  border: none;
  background-color: #fce6e6;
  border-radius: 10px;
  font-size: 13px;
}

.save-button {
  padding: 10px 35px;
  border-radius: 10px;
  font-size: 13px;
  background-color: var(--theme);
  color: white;
  border: none;
}

.save-button:active {
  box-shadow: 0 0 0 3px var(--theme);
  color: var(--theme);
  background-color: white;
}

button:focus {
  box-shadow: 0 0 0 3px rgba(var(--theme-rgba), 0.4);
}
</style>
