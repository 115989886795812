<template>
  <div class="panel" id="inv-payments">
    <div class="d-flex align-items-center">
      <van-switch size="15" v-model="params.partial_paid" />
      <h5 class="mb-0 ml-2">{{$t('invoice.payments')}} </h5>
    </div>

    <div class="alert alert-info mt-3" role="alert">
      <strong>
        {{$t('invoice.payInfo')}}
      </strong>
    </div>

    <template v-if="params.partial_paid">
      <!-- form -->
      <div class="row mt-3">
        <div class="mb-2 col-lg-3">
          <input type="text" placeholder="Payer Name" v-model="p.payer_name" />
        </div>

        <div class="mb-2 col-lg-3">
          <AccountSelect @change="accountChanged" :params="p" />
        </div>

        <div class="mb-2 col-lg-3">
          <date-range-picker
            v-model="payment_date"
            lang="en"
            type="date"
            :showWeekNumbers="false"
            :autoApply="true"
            :ranges="false"
            opens="left"
            :locale-data="locale"
            :singleDatePicker="true"
          >
            <template v-slot:input="picker">
              <div class="d-flex justify-content-center align-items-center">
                {{ dateFormat(picker.startDate) }}
              </div>
            </template>
          </date-range-picker>
        </div>

        <div class="mb-2 col-lg-2">
          <input type="number" v-model="p.amount" :placeholder="$t('amount')" class="text-right" />
        </div>

        <div class="mb-2 col-lg-1">
          <button class="submit" @click="create()">
            <van-icon name="add-o" size="20" />
          </button>
        </div>
      </div>
      <!-- form -->
      <table class="table my-border-table" v-if="params.payments.length > 0">
        <tr>
          <th> {{$t('invoice.payer')}} </th>
          <th> {{$t('invoice.account')}} </th>
          <th class="text-center">{{$t('date')}}</th>
          <th class="text-right"> {{$t('amount')}}</th>
          <th style="width: 150px"></th>
        </tr>
        <tr v-for="(payment, index) in params.payments" :key="index">
          <td>{{ payment.payer_name ? payment.payer_name : '-' }}</td>
          <td>{{ payment.account ? payment.account.owner_name : '-' }}</td>
          <td class="text-center">
            {{ moment(payment.payment_date).format('DD MMM, YYYY') }}
          </td>
          <td class="text-right">{{ $currency }} {{ $cast(payment.amount) }}</td>
          <td class="text-right delete-icon">
            <van-icon name="delete-o" class="text-danger" @click="params.payments.splice(index, 1)"></van-icon>
          </td>
        </tr>
      </table>
    </template>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import { Dialog } from 'vant'
import AccountSelect from './PaymentAccountSelect'
export default {
  props: ['params'],
  components: {
    DateRangePicker,
    AccountSelect,
  },
  data() {
    return {
      moment,
      payment_date: { startDate: new Date() },
      p: {},
      locale: {
        format: 'mm/dd/yyyy',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
    }
  },
  methods: {
    accountChanged(e) {
      this.p.account = e
    },
    async create() {
      if (!this.p.amount) {
        await Dialog.alert({
          title: 'Validation',
          message: 'Required amount!',
          confirmButtonText: 'OK',
        })
        return false
      }
      if (!this.p.account) {
        await Dialog.alert({
          title: 'Validation',
          message: 'Required Account!',
          confirmButtonText: 'OK',
        })
        return false
      }
      let p = { ...this.p, payment_date: this.payment_date.startDate }
      this.params.payments.push(p)
      this.p = {}
    },
    dateFormat(date) {
      return date ? moment(date).format('DD MMM, YYYY') : null
    },
  },
}
</script>

<style scoped>
.panel {
  min-height: 400px;
}
.b-none {
  border: none !important;
}
.h-none:hover {
  background: transparent !important;
}
.pr-0 {
  padding-right: 0px !important;
}
input {
  height: 45px;
  width: 100%;
  border-radius: 10px;
  border: 2px solid var(--secondary-theme);
  padding: 10px;
}
.submit {
  width: 100%;
  height: 45px;
  border-radius: 10px;
  text-align: center;
  background: var(--theme);
  border: none;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style scoped>
#inv-payments .vue-daterange-picker {
  width: 100%;
}
.delete-icon {
  font-size: 23px;
}
</style>