<template>
  <!-- ==== for categories ==== -->
  <div v-if="type == 'categories'" id="v-select-to-create">
    <vSelect
      :value="value"
      :options="categories"
      :reduce="(category) => category.id"
      label="name"
      :taggable="true"
      @option:created="optionCreated"
      :clearable="false"
      placeholder="Type here to create or search category"
      @input="pushData"
    >
      <template #option="{ name }">
        {{ name }}
      </template>
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <svg width="15" height="14" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.7704 6.64453L10.6088 11.485C9.99921 12.0567 9.00171 12.0567 8.39213 11.485L3.23047 6.64453"
              stroke="black"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </template>
    </vSelect>
  </div>

  <!-- ==== for currency selection ==== -->
  <div v-else-if="type === 'currency'">
    <vSelect
      :value="value"
      :options="currencies"
      :reduce="(currency) => currency.code"
      label="currency"
      @input="pushData"
      placeholder="Choose Category"
    >
      <template #option="{ currency, code }"> {{ code }} ({{ currency }}) </template>
    </vSelect>
  </div>

  <!-- ==== simple options ==== -->
  <div v-else id="v-select-to-create">
    <vSelect :value="value" :options="options" :reduce="(option) => option.id" label="name" @input="pushData">
      <template #option="{ name }">
        {{ name }}
      </template>
    </vSelect>
  </div>
</template>

<script>
import cc from 'currency-codes/data'
import vSelect from 'vue-select'
export default {
  props: ['options', 'type', 'defaultCategory', 'value', 'taggable'],
  components: {
    vSelect,
  },
  data() {
    return {
      currencies: cc,
      categories: [],
      data: '',
    }
  },
  methods: {
    async optionCreated({ name }) {
      if (name) {
        const res = await axios.post('/categories', {
          name: name,
        })
        let newCat = res.data.data
        this.categories.unshift(newCat)
        this.$emit('input', newCat.id)
      }
    },
    /**
     * GET api/categories
     * Fetch categories
     */
    async fetchData() {
      try {
        const res = await axios.get('/categories', {
          params: {
            page: 1,
            limit: 10,
          },
        })
        this.categories = res.data.data.data
        if (this.categories.length == 0) {
          this.categories.unshift({
            name: '',
            id: null,
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    setData() {
      if (this.type === 'categories')
        if (this.defaultCategory) {
          return (this.data = this.defaultCategory)
        } else return (this.data = 'Choose Category')
      else return (this.data = '')
    },
    pushData(value) {
      console.log(value)
      this.$emit('input', value)
    },
  },
  mounted() {
    this.fetchData()
    this.setData()
  },
}
</script>

<style lang="scss" scoped>
.label {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  text-transform: capitalize;
}
</style>
