<template>
  <div class="login-rightside">
    <div class="back-icon text-left" @click="$router.back()">
      <svg fill="none" height="26" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.75033 23.8333H16.2503C21.667 23.8333 23.8337 21.6666 23.8337 16.25V9.74996C23.8337 4.33329 21.667 2.16663 16.2503 2.16663H9.75033C4.33366 2.16663 2.16699 4.33329 2.16699 9.74996V16.25C2.16699 21.6666 4.33366 23.8333 9.75033 23.8333Z"
            stroke="#292D32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
        />
        <path
            d="M14.3651 16.8241L10.5518 12.9999L14.3651 9.17578"
            stroke="#292D32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
        />
      </svg>
    </div>
    <div class="title my-3">Register</div>
    <div v-if="error" class="error">Fail to Register! Please Try Again</div>
    <template v-if="!electron">
      <div class="signup-facebook">
        <button @click="continueWithFacebook()">
          <img alt="" class="mr-1" src="/image/auth/facebook.svg"/>
          Register with Facebook
        </button>
      </div>
      <div class="signup-google">
        <button @click="continueWithGoogle()">
          <img alt="" class="mr-1" src="/image/auth/google.svg"/>
          Register with Google
        </button>
      </div>

      <p id="text">or Register with Email</p>
    </template>

    <div class="form d-flex">
      <div class="email-icon">
        <svg
            aria-hidden="true"
            height="1.4em"
            preserveAspectRatio="xMidYMid meet"
            role="img"
            viewBox="0 0 24 24"
            width="1.4em"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g fill="none" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7">
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
            <circle cx="12" cy="7" r="4"/>
          </g>
        </svg>
      </div>
      <input v-model="params.full_name" placeholder="Your Name" type="text"/>
    </div>
    <small class="text-danger">{{ errors.full_name }}</small>

    <div class="form d-flex">
      <div class="email-icon">
        <svg fill="none" height="24" viewBox="0 0 24 24" width="21" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="1.5"
          />
          <path
              d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="1.5"
          />
        </svg>
      </div>
      <input v-model="params.email" placeholder="Email" type="email"/>
    </div>
    <small class="text-danger">{{ errors.email }}</small>
    <div class="form">
      <div class="passowrd-icon">
        <svg fill="none" height="24" viewBox="0 0 24 24" width="21" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M19.7901 14.93C17.7301 16.98 14.7801 17.61 12.1901 16.8L7.48015 21.5C7.14015 21.85 6.47015 22.06 5.99015 21.99L3.81015 21.69C3.09015 21.59 2.42015 20.91 2.31015 20.19L2.01015 18.01C1.94015 17.53 2.17015 16.86 2.50015 16.52L7.20015 11.82C6.40015 9.22001 7.02015 6.27001 9.08015 4.22001C12.0301 1.27001 16.8201 1.27001 19.7801 4.22001C22.7401 7.17001 22.7401 11.98 19.7901 14.93Z"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="1.5"
          />
          <path
              d="M6.89014 17.49L9.19014 19.79"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="1.5"
          />
          <path
              d="M14.5 11C15.3284 11 16 10.3284 16 9.5C16 8.67157 15.3284 8 14.5 8C13.6716 8 13 8.67157 13 9.5C13 10.3284 13.6716 11 14.5 11Z"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
          />
        </svg>
      </div>
      <input
          v-model="params.password"
          :type="password['isPasswordHidden'] ? 'password' : 'text'"
          placeholder="Password"
      />
      <div class="toggle-password" @click="togglePassword('isPasswordHidden')">
        <svg
            v-if="password['isPasswordHidden']"
            fill="none"
            height="20"
            viewBox="0 0 22 20"
            width="22"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M13.5299 7.46998L8.46992 12.53C7.81992 11.88 7.41992 10.99 7.41992 10C7.41992 8.02 9.0199 6.41998 10.9999 6.41998C11.9899 6.41998 12.8799 6.81998 13.5299 7.46998Z"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
          />
          <path
              d="M16.8198 3.76998C15.0698 2.44998 13.0698 1.72998 10.9998 1.72998C7.46984 1.72998 4.17984 3.80998 1.88984 7.40998C0.989844 8.82 0.989844 11.19 1.88984 12.6C2.67984 13.84 3.59984 14.91 4.59984 15.77"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
          />
          <path
              d="M7.41992 17.53C8.55992 18.01 9.7699 18.27 10.9999 18.27C14.5299 18.27 17.8199 16.19 20.1099 12.59C21.0099 11.18 21.0099 8.81 20.1099 7.39999C19.7799 6.87999 19.4199 6.38999 19.0499 5.92999"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
          />
          <path
              d="M14.5104 10.7C14.2504 12.11 13.1004 13.26 11.6904 13.52"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
          />
          <path
              d="M10.1004 10.8854L2.63037 18.3554"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
          />
          <path
              d="M19.2888 1.7265L11.8188 9.1965"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
          />
        </svg>
        <svg v-else fill="none" height="19" viewBox="0 0 21 19" width="21" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_7_22)">
            <path
                d="M16.3157 3.80717C14.6522 2.54684 12.6146 1.85793 10.515 1.84601C6.99677 1.84601 3.71771 3.88567 1.43532 7.41577C0.994553 8.19377 0.763184 9.06965 0.763184 9.96035C0.763184 10.851 0.994553 11.7269 1.43532 12.5049C2.17316 13.6678 3.07976 14.7183 4.12636 15.6232C4.96592 16.3417 5.91848 16.9213 6.94692 17.3393C8.07506 17.8133 9.28834 18.06 10.515 18.0649C14.0333 18.0649 17.3124 16.0253 19.5948 12.4952C20.0355 11.7172 20.2669 10.8412 20.2669 9.95053C20.2669 9.05984 20.0355 8.18395 19.5948 7.40595C19.2702 6.90647 18.9175 6.4252 18.5383 5.96446C17.8521 5.1928 17.1093 4.47175 16.3157 3.80717Z"
                stroke="#292D32"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
            <path
                d="M8.03794 12.3971C7.68821 12.0755 7.40781 11.688 7.21348 11.2576C7.01914 10.8271 6.91484 10.3626 6.90676 9.89173C6.89868 9.42082 6.987 8.95313 7.16645 8.5165C7.34591 8.07987 7.61281 7.68324 7.95131 7.35021C8.28981 7.01717 8.69295 6.75452 9.13675 6.57796C9.58055 6.40141 10.0559 6.31457 10.5346 6.32252C11.0132 6.33047 11.4853 6.43309 11.9228 6.62429C12.3603 6.81548 12.7542 7.09129 13.0811 7.43537C13.4819 7.85347 13.7801 8.35629 13.9529 8.90529C14.1256 9.45429 14.1684 10.0349 14.0778 10.6027C13.9434 11.287 13.6039 11.916 13.103 12.4088C12.6021 12.9017 11.9627 13.2357 11.2672 13.3679C10.6894 13.454 10.0993 13.4115 9.54044 13.2435C8.98157 13.0754 8.46814 12.7862 8.03794 12.3971Z"
                stroke="#292D32"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
          </g>
          <defs>
            <clipPath id="clip0_7_22">
              <rect fill="white" height="19" width="21"/>
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
    <small class="text-danger">{{ errors.password }}</small>
    <div class="form">
      <div class="passowrd-icon">
        <svg fill="none" height="24" viewBox="0 0 24 24" width="21" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M19.7901 14.93C17.7301 16.98 14.7801 17.61 12.1901 16.8L7.48015 21.5C7.14015 21.85 6.47015 22.06 5.99015 21.99L3.81015 21.69C3.09015 21.59 2.42015 20.91 2.31015 20.19L2.01015 18.01C1.94015 17.53 2.17015 16.86 2.50015 16.52L7.20015 11.82C6.40015 9.22001 7.02015 6.27001 9.08015 4.22001C12.0301 1.27001 16.8201 1.27001 19.7801 4.22001C22.7401 7.17001 22.7401 11.98 19.7901 14.93Z"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="1.5"
          />
          <path
              d="M6.89014 17.49L9.19014 19.79"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="1.5"
          />
          <path
              d="M14.5 11C15.3284 11 16 10.3284 16 9.5C16 8.67157 15.3284 8 14.5 8C13.6716 8 13 8.67157 13 9.5C13 10.3284 13.6716 11 14.5 11Z"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
          />
        </svg>
      </div>
      <input
          v-model="params.password_confirmation"
          :type="password['isConfirmPassHidden'] ? 'password' : 'text'"
          placeholder="Confrim"
          @keyup.enter="registerWithEmail()"
      />
      <div class="toggle-password" @click="togglePassword('isConfirmPassHidden')">
        <svg
            v-if="password['isConfirmPassHidden']"
            fill="none"
            height="20"
            viewBox="0 0 22 20"
            width="22"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M13.5299 7.46998L8.46992 12.53C7.81992 11.88 7.41992 10.99 7.41992 10C7.41992 8.02 9.0199 6.41998 10.9999 6.41998C11.9899 6.41998 12.8799 6.81998 13.5299 7.46998Z"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
          />
          <path
              d="M16.8198 3.76998C15.0698 2.44998 13.0698 1.72998 10.9998 1.72998C7.46984 1.72998 4.17984 3.80998 1.88984 7.40998C0.989844 8.82 0.989844 11.19 1.88984 12.6C2.67984 13.84 3.59984 14.91 4.59984 15.77"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
          />
          <path
              d="M7.41992 17.53C8.55992 18.01 9.7699 18.27 10.9999 18.27C14.5299 18.27 17.8199 16.19 20.1099 12.59C21.0099 11.18 21.0099 8.81 20.1099 7.39999C19.7799 6.87999 19.4199 6.38999 19.0499 5.92999"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
          />
          <path
              d="M14.5104 10.7C14.2504 12.11 13.1004 13.26 11.6904 13.52"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
          />
          <path
              d="M10.1004 10.8854L2.63037 18.3554"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
          />
          <path
              d="M19.2888 1.7265L11.8188 9.1965"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
          />
        </svg>
        <svg v-else fill="none" height="19" viewBox="0 0 21 19" width="21" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_7_22)">
            <path
                d="M16.3157 3.80717C14.6522 2.54684 12.6146 1.85793 10.515 1.84601C6.99677 1.84601 3.71771 3.88567 1.43532 7.41577C0.994553 8.19377 0.763184 9.06965 0.763184 9.96035C0.763184 10.851 0.994553 11.7269 1.43532 12.5049C2.17316 13.6678 3.07976 14.7183 4.12636 15.6232C4.96592 16.3417 5.91848 16.9213 6.94692 17.3393C8.07506 17.8133 9.28834 18.06 10.515 18.0649C14.0333 18.0649 17.3124 16.0253 19.5948 12.4952C20.0355 11.7172 20.2669 10.8412 20.2669 9.95053C20.2669 9.05984 20.0355 8.18395 19.5948 7.40595C19.2702 6.90647 18.9175 6.4252 18.5383 5.96446C17.8521 5.1928 17.1093 4.47175 16.3157 3.80717Z"
                stroke="#292D32"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
            <path
                d="M8.03794 12.3971C7.68821 12.0755 7.40781 11.688 7.21348 11.2576C7.01914 10.8271 6.91484 10.3626 6.90676 9.89173C6.89868 9.42082 6.987 8.95313 7.16645 8.5165C7.34591 8.07987 7.61281 7.68324 7.95131 7.35021C8.28981 7.01717 8.69295 6.75452 9.13675 6.57796C9.58055 6.40141 10.0559 6.31457 10.5346 6.32252C11.0132 6.33047 11.4853 6.43309 11.9228 6.62429C12.3603 6.81548 12.7542 7.09129 13.0811 7.43537C13.4819 7.85347 13.7801 8.35629 13.9529 8.90529C14.1256 9.45429 14.1684 10.0349 14.0778 10.6027C13.9434 11.287 13.6039 11.916 13.103 12.4088C12.6021 12.9017 11.9627 13.2357 11.2672 13.3679C10.6894 13.454 10.0993 13.4115 9.54044 13.2435C8.98157 13.0754 8.46814 12.7862 8.03794 12.3971Z"
                stroke="#292D32"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
          </g>
          <defs>
            <clipPath id="clip0_7_22">
              <rect fill="white" height="19" width="21"/>
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
    <small class="text-danger">{{ errors.password_confirmation }}</small>

    <div class="form d-flex">
      <div class="email-icon">
        <svg fill="none" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
          <path clip-rule="evenodd" d="M14.1552 2.2677C13.3848 2.2677 12.7675 2.885 12.7675 3.65545C12.7675 4.39595 13.3357 4.99255 14.0556 5.04134C14.1189 5.03596 14.1836 5.03596 14.2469 5.04134C14.9655 4.9926 15.5365 4.39686 15.543 3.65315C15.5417 2.88999 14.9233 2.2677 14.1552 2.2677ZM11.4785 3.65545C11.4785 2.17314 12.6729 0.97876 14.1552 0.97876C15.6392 0.97876 16.8319 2.18264 16.8319 3.65545V3.66006L16.8319 3.66006C16.8216 5.10561 15.6835 6.27977 14.2494 6.33173C14.2166 6.33291 14.1838 6.3316 14.1513 6.32779C14.1187 6.3316 14.0859 6.33291 14.0531 6.33173C12.6198 6.27979 11.4785 5.10539 11.4785 3.65545Z"
                fill="#292D32"
                fill-rule="evenodd"/>
          <path clip-rule="evenodd" d="M13.28 6.96701C14.4882 6.75534 15.8683 6.95635 16.8746 7.62408L16.8758 7.62484C17.5654 8.08464 17.9957 8.75527 17.9957 9.50801C17.9957 10.2608 17.5654 10.9314 16.8758 11.3912C15.8768 12.0598 14.5131 12.26 13.3081 12.0577C12.9571 11.9987 12.7203 11.6664 12.7792 11.3154C12.8381 10.9644 13.1705 10.7276 13.5215 10.7865C14.4743 10.9465 15.4888 10.7688 16.1596 10.3195L16.1608 10.3187C16.5817 10.0381 16.7068 9.732 16.7068 9.50801C16.7068 9.28414 16.5818 8.97822 16.1614 8.69771C15.482 8.2472 14.4521 8.07025 13.5024 8.23662C13.1518 8.29803 12.8178 8.06361 12.7564 7.71302C12.6949 7.36243 12.9294 7.02843 13.28 6.96701Z"
                fill="#292D32"
                fill-rule="evenodd"/>
          <path clip-rule="evenodd" d="M2.14453 3.65545C2.14453 2.18264 3.33728 0.97876 4.82123 0.97876C6.30354 0.97876 7.49792 2.17314 7.49792 3.65545C7.49792 5.10539 6.35662 6.27979 4.92333 6.33173C4.89054 6.33291 4.85772 6.3316 4.82516 6.32779C4.7926 6.3316 4.75979 6.33291 4.727 6.33173C3.2929 6.27977 2.15487 5.10561 2.14455 3.66006L2.14453 3.65545ZM3.43347 3.65316C3.43995 4.39687 4.01097 4.9926 4.7295 5.04134C4.79283 5.03596 4.8575 5.03596 4.92083 5.04134C5.64075 4.99255 6.20898 4.39595 6.20898 3.65545C6.20898 2.885 5.59168 2.2677 4.82123 2.2677C4.05316 2.2677 3.43471 2.89 3.43347 3.65316Z"
                fill="#292D32"
                fill-rule="evenodd"/>
          <path clip-rule="evenodd" d="M2.81482 8.69769C3.49417 8.2472 4.52412 8.07025 5.47381 8.23662C5.8244 8.29803 6.1584 8.06361 6.21982 7.71302C6.28123 7.36243 6.04681 7.02843 5.69622 6.96701C4.48793 6.75534 3.10788 6.95635 2.10158 7.62408L2.10158 7.62407L2.10043 7.62485C1.41073 8.08464 0.980469 8.75527 0.980469 9.50801C0.980469 10.2605 1.41049 10.931 2.09984 11.3908C3.09888 12.0597 4.46285 12.26 5.66809 12.0577C6.01911 11.9987 6.25589 11.6664 6.19696 11.3154C6.13803 10.9644 5.8057 10.7276 5.45468 10.7865C4.5019 10.9465 3.48736 10.7688 2.81657 10.3195L2.8154 10.3187C2.39446 10.0381 2.26941 9.732 2.26941 9.50801C2.26941 9.28413 2.39435 8.97821 2.81482 8.69769Z"
                fill="#292D32"
                fill-rule="evenodd"/>
          <path clip-rule="evenodd" d="M9.42864 8.15173C8.65819 8.15173 8.04089 8.76903 8.04089 9.53949C8.04089 10.28 8.60912 10.8766 9.32904 10.9254C9.39334 10.9199 9.45903 10.92 9.52329 10.9256C10.2352 10.8827 10.8099 10.2854 10.8164 9.53719C10.8152 8.77403 10.1967 8.15173 9.42864 8.15173ZM6.75195 9.53949C6.75195 8.05717 7.94633 6.86279 9.42864 6.86279C10.9126 6.86279 12.1053 8.06667 12.1053 9.53949V9.54409L12.1053 9.54409C12.095 10.9855 10.9601 12.1741 9.51821 12.2159C9.48697 12.2168 9.45572 12.2154 9.42471 12.2118C9.39215 12.2156 9.35933 12.2169 9.32654 12.2158C7.89325 12.1638 6.75195 10.9894 6.75195 9.53949Z"
                fill="#292D32"
                fill-rule="evenodd"/>
          <path clip-rule="evenodd" d="M9.50254 12.7822C10.431 12.7822 11.3942 13.0133 12.1492 13.5167C12.8389 13.9765 13.2692 14.6471 13.2692 15.3998C13.2692 16.1526 12.8389 16.8232 12.1492 17.283L11.7917 16.7468L12.15 17.2825C11.3918 17.7896 10.4287 18.0234 9.49958 18.0234C8.57062 18.0234 7.6078 17.7897 6.84966 17.2828C6.16012 16.823 5.72998 16.1525 5.72998 15.3998C5.72998 14.6471 6.16024 13.9765 6.84994 13.5167L6.85149 13.5156L6.85149 13.5156C7.60965 13.0134 8.57391 12.7822 9.50254 12.7822ZM7.56416 14.5896C7.14383 14.8701 7.01892 15.176 7.01892 15.3998C7.01892 15.6238 7.14397 15.9299 7.56491 16.2106L7.56568 16.2111C8.06776 16.5468 8.76674 16.7344 9.49958 16.7344C10.2324 16.7344 10.9314 16.5468 11.4335 16.2111L11.4343 16.2106C11.8552 15.9299 11.9803 15.6238 11.9803 15.3998C11.9803 15.1759 11.8552 14.8698 11.4343 14.5891C10.9369 14.2576 10.2381 14.0712 9.50254 14.0712C8.76758 14.0712 8.06624 14.2573 7.56416 14.5896Z"
                fill="#292D32"
                fill-rule="evenodd"/>
        </svg>

      </div>
      <input v-model="params.referral_code" placeholder="Referral Code (Optional)" type="text"/>
    </div>
    <small class="text-danger">{{ errors.referral_code }}</small>

    <div class="mt-4 mb-4">
      By clicking register, you're agreeing to our
      <router-link class="font-weight-bold" to="terms-and-conditions"> privacy</router-link>
      and
      <router-link class="font-weight-bold" to="terms-and-conditions"> terms</router-link>
      of service.
    </div>
    <button class="btn-signin text-bold" @click="registerWithEmail()">Register</button>
    <p class="mb-4 mt-3 text-center">
      Already have an account?
      <router-link class="text-bold" to="/login"> Sign In</router-link>
    </p>
    <EmailVerifyPopup ref="emailVerifyPopup" :params="params" @resend="registerWithEmail()"/>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import isElectron from 'is-electron'
import EmailVerifyPopup from './EmailVerifyPopup'
import {Toast} from 'vant'

export default {
  components: {EmailVerifyPopup},
  data() {
    return {
      electron: isElectron(),
      error: false,
      errors: {},
      params: {
        full_name: null,
        email: null,
        password: null,
        password_confirmation: null,
        referral_code: null,
      },
      password: {
        isPasswordHidden: true,
        isConfirmPassHidden: true,
      },
    }
  },
  methods: {
    /**
     * register with
     */
    async registerWithEmail() {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        duration: 0,
      })
      let res = await axios.post('auth/register-email', this.params)
      if (res) {
        if (res.data.code == 422) {
          this.errors = res.data.error
        } 
        else if(res.data.code == 500) {
          this.errors = { referral_code : res.data.error }
        }
        else if(res.data.code == 200) {
          this.errors = {}
          this.$refs.emailVerifyPopup.open()
        }
        Toast.clear()
      }
    },
    /**
     * Google
     */
    async continueWithGoogle() {
      try {
        const provider = new firebase.auth.GoogleAuthProvider()
        await firebase.auth().signInWithPopup(provider)
        const token = await firebase.auth().currentUser.getIdToken(true)
        if (token) {
          this.vueLogin('google', token)
        }
      } catch (err) {
        this.error = true
        console.log(err)
      }
    },

    /**
     * facebok
     */
    async continueWithFacebook() {
      try {
        const provider = new firebase.auth.FacebookAuthProvider()
        provider.setCustomParameters({
          display: 'popup',
        })
        await firebase.auth().signInWithPopup(provider)
        const token = await firebase.auth().currentUser.getIdToken(true)
        if (token) {
          this.vueLogin('facebook', token)
        }
      } catch (err) {
        this.error = true
        console.log(err)
      }
    },

    /**
     * final login to backend
     */
    async vueLogin(type, token) {
      this.$auth.login({
        data: {
          token,
          type,
        },
        redirect: '/company/registration',
        staySignedIn: true,
        autoLogin: true,
      })
    },
    togglePassword(password) {
      this.password[password] = !this.password[password]
    },
  },
}
</script>

<style scoped>
a {
  text-decoration: none;
  color: black;
}

.login-rightside {
  padding: 0px 70px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.company {
  padding: 10px 0;
  display: flex;
  align-items: center;
  width: 100%;
  display: none;
}

.logo {
  width: 30px;
  height: 30px;
  background: #000000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  height: 100%;
  width: 100%;
  padding: 6px;
}

.name {
  margin-left: 5px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
}

.back-icon {
  padding: 0 0 10px 0;
}

.back-icon img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  width: 100%;
  margin-bottom: 5px;
}

.signup-facebook,
.signup-google {
  width: 100%;
}

.signup-facebook button,
.signup-google button {
  padding: 8px;
  margin: 3px;
  height: 50px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 10px;
  font-weight: 700;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

#text {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #cdcbcb;
  text-align: center;
}

#text::before {
  content: '';
  position: absolute;
  border-top: 2px solid #e0e0e0;
  width: 30%;
  height: 1px;
  top: 10px;
  left: 0px;
}

#text::after {
  content: '';
  position: absolute;
  border-top: 2px solid #e0e0e0;
  width: 30%;
  height: 1px;
  top: 10px;
  right: 0px;
}

.form {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  padding-left: 10px;
  margin: 3px;
}

.email-icon img,
.passowrd-icon img {
  height: 20px;
  width: 20px;
  margin-right: 3px;
}

.form input {
  padding: 2px 3px 3px 10px;
  width: 90%;
  border: none;
  height: 50px;
}

.forgot-password {
  text-align: right;
  margin-top: 10px;
  width: 100%;
}

.btn-signin {
  padding: 15px;
  margin: 3px 3px 8px 3px;
  width: 100%;
  background: #000000;
  border: 1px solid #efefef;
  border-radius: 10px;
  color: #ffffff;
}

@media (max-width: 767px) {
  .company {
    display: flex;
  }
}

.error {
  margin-bottom: 13px;
  height: 40px;
  display: flex;
  align-items: center;
  color: red;
  border: 1px solid red;
  border-radius: 8px;
  padding: 0px 15px;
  background: #ff000014;
  font-weight: bold;
}
</style>
<style>
.toggle-password {
  cursor: pointer;
  margin-right: 13px;
}
</style>
