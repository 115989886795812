<template>
  <div class="bg-white">
    <div v-if="!$route.params.id || loaded">
      <h1 @click="$router.push({ path: '/management/coupons/list' })">
        <van-icon class="mr-3" name="arrow-left" size="15px"/>
        <span v-if="$route.params.id">{{ params.name }}</span>
        <span v-else>{{$t('mngmt.coupon.newCoupon')}}</span>
      </h1>
      <div class="col-lg-8 mx-auto">

        <Input
            :errors="errors"
            :params="params"
            field="name"
            :label="$t('mngmt.coupon.couponName')"
        />

        <div class="my-3">
          <label class="label">Type</label>
          <SelectType v-model="params.type" align="center" type="type"/>
        </div>

        <div class="row">
          <div class="col-6 pr-2">
            <Input
                :errors="errors"
                :params="params"
                field="coupon_code"
                :label="$t('mngmt.coupon.couponCode')"
            />
          </div>
          <div class="col-6 pl-2">
            <Input
                v-if="params.type == 'fixed'"
                :errors="errors"
                :params="params"
                field="amount"
                :label="$t('mngmt.coupon.amountOff')"
                type="number"
            />
            <Input
                v-else
                :errors="errors"
                :params="params"
                field="percent"
                :label="$t('mngmt.coupon.percentOff')"
                type="number"
            />
          </div>
        </div>

        <div class="row align-items-end">
          <div class="col-9 pr-5">
            <div class="my-3">
              <van-checkbox v-model="use_useable_time" shape="square" size="15px">{{$t('mngmt.coupon.usableTime')}}</van-checkbox>
              <div class="mt-2 text-muted">
                {{ $t('mngmt.coupon.timeLimitInfo')}}
              </div>
            </div>
          </div>
          <div v-if="use_useable_time" class="col-3">
            <Input
                :errors="errors"
                :params="params"
                field="useable_time"
                type="number"
            />
          </div>
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-6">
            <van-checkbox v-model="use_valid_date" shape="square" size="15px">{{$t('mngmt.coupon.validDateRange')}}</van-checkbox>
          </div>
          <div v-if="use_valid_date" class="col-6">
            <MyDateRangePicker :params="dateParams" @select="selectDate"/>
          </div>
        </div>

        <div class="my-3">
          <label class="label">{{$t('mngmt.coupon.applyTo', {target: 'Products'})}} </label>
          <SelectType v-model="productType" align="left" type="product"/>
          <div v-if="productType != 'all'" class="add" @click="$refs.listPopup.open('product')">
            <svg fill="none" height="10" viewBox="0 0 10 10" width="10" xmlns="http://www.w3.org/2000/svg">
              <line stroke="black" stroke-linecap="round" stroke-width="2" x1="5" x2="5" y1="1" y2="9"/>
              <path d="M1 5.0625L9 5.0625" stroke="black" stroke-linecap="round" stroke-width="2"/>
            </svg>
            {{$t('mngmt.coupon.add', {target: 'Product'})}}
          </div>
          <SpecificItemList :items="params.products" type="product"/>
        </div>

        <div class="my3">
          <label class="label">{{$t('mngmt.coupon.applyTo', {target: 'Customers'})}}</label>
          <SelectType v-model="customerType" align="left" type="customer"/>
          <div v-if="customerType != 'all'" class="add" @click="$refs.listPopup.open('customer')">
            <svg fill="none" height="10" viewBox="0 0 10 10" width="10" xmlns="http://www.w3.org/2000/svg">
              <line stroke="black" stroke-linecap="round" stroke-width="2" x1="5" x2="5" y1="1" y2="9"/>
              <path d="M1 5.0625L9 5.0625" stroke="black" stroke-linecap="round" stroke-width="2"/>
            </svg>
            <span>
             {{$t('mngmt.coupon.add', {target: 'Customer'})}}
            </span>

          </div>
          <SpecificItemList :items="params.customers" type="customer"/>
        </div>

        <SelectListPopup ref="listPopup" :params="params"/>

        <Button :loading="loading" :type="$route.params.id ? 'edit' : 'create'" name="Coupon"
                @click.native="saveData()"/>
        <Button v-if="$route.params.submitType == 'edit'" :dloading="dloading" name="Coupon" type="delete"
                @click.native="deleteCoupon()"/>
      </div>
    </div>
    <div v-else class="loading">
      <van-loading type="spinner"/>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {Dialog, Toast} from 'vant'
import SelectType from './components/SelectBox'
import SelectListPopup from './components/SelectListPopup'
import SpecificItemList from './components/SpecificItemList'

export default {
  components: {SelectType, SelectListPopup, SpecificItemList},
  data() {
    return {
      use_useable_time: false,
      use_valid_date: false,
      productType: 'all',
      customerType: 'all',
      params: {
        name: null,
        coupon_code: null,
        type: 'fixed',
        amount: null,
        percent: null,
        useable_time: null,
        valid_from: moment().format('YYYY-MM-DD'),
        valid_to: moment().add(1, 'month').format('YYYY-MM-DD'),
        products: [],
        customers: [],
        status: 1,
      },
      dateParams: {
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().add(1, 'month').format('YYYY-MM-DD')
      },
      errors: {},
      loaded: false,
      loading: false,
      dloading: false,
    }
  },
  methods: {
    async saveData() {
      this.loading = true
      let params = {...this.params}
      params.products = params.products.length > 0
          ? params.products.map(product => product.id)
          : []
      params.customers_emails = params.customers.length > 0
          ? params.customers.map(customer => customer.email[0])
          : []
      if (!this.use_useable_time) {
        params.useable_time = null
      }
      if (!this.use_valid_date) {
        params.valid_from = null
        params.valid_to = null
      }
      if (params.type == 'fixed') {
        params.percent = null
      } else {
        params.amount = null
      }
      let res = (this.$route.params.id && this.$route.params.submitType == 'edit')
          ? await axios.put(`/discounts/${this.$route.params.id}`, params)
          : await axios.post('/discounts', params)
      if (res) {
        console.log(res)
        if (res.data.code == 422) {
          this.errors = res.data.error
          this.loading = false
        } else {
          let submitType = this.$route.params.submitType
          let message = submitType == 'edit' ? 'Updated' : submitType == 'clone' ? 'Cloned' : 'Created'
          Toast.success(message)
          this.loading = false
          this.$router.back()
        }
      }
    },
    async deleteCoupon() {
      await Dialog.confirm({
        title: this.$t('action.areYouSure'),
        cancelButtonText: this.$t('action.cancel'),
        confirmButtonText: this.$t('action.del'),
      })
      this.dloading = true
      let discount_ids = [this.$route.params.id]
      let res = await axios.post('/discounts/delete', {discount_ids})
      if (res) {
        Toast.success(this.$t('state.deleted'))
        this.dloading = false
        this.$router.back()
      }
    },
    selectDate(dates) {
      this.params.valid_from = moment(dates.startDate).format('YYYY-MM-DD')
      this.params.valid_to = moment(dates.endDate).format('YYYY-MM-DD')
    },
    async fetchData() {
      let res = await axios.get(`/discounts/${this.$route.params.id}`)
      if (res) {
        let data = res.data.data
        this.params.name = data.name
        if (this.$route.params.submitType == 'edit') {
          this.params.coupon_code = data.coupon_code
        }
        this.params.type = data.type
        this.params.amount = data.amount
        this.params.percent = data.percent
        if (data.useable_time) {
          this.use_useable_time = true
          this.params.useable_time = data.useable_time
        }
        if (data.valid_from || data.valid_to) {
          this.use_valid_date = true
          this.dateParams.start_date = data.valid_from
          this.dateParams.end_date = data.valid_to
          this.params.valid_from = data.valid_from
          this.params.valid_to = data.valid_to
        }
        if (data.customer_emails?.length > 0) {
          this.customerType = 'choice'
          this.params.customers = data.customer_emails?.map(email => ({name: '', image: '', email: [email]})) || []
        }
        if (data.discount_products.length > 0) {
          this.productType = 'choice'
          this.params.products = data.discount_products.map(dp => dp.product)
        }
        this.loaded = true
      }
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.fetchData()
    }
  },
  watch: {
    $route() {
      if (this.$route.params.id) {
        this.fetchData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add {
  font-weight: 700;
  cursor: pointer;
  font-size: 13px !important;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  gap: 5px;
  margin: 20px 0;
}
</style>