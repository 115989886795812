<template>
  <van-popup
    v-model="show"
    position="right"
    :style="style"
    closeable
    close-icon-position="top-right"
    class="report-detail"
  >
    <div v-if="!loading">
      <h1 v-if="reportDetail.product">{{ reportDetail.product.name }}</h1>
      <h3 v-if="reportDetail.invoice.invoice_number">{{ reportDetail.invoice.invoice_number }}</h3>

      <div class="d-flex mt-4 pt-3">
        <div class="w-25">
          <p class="text-muted">Created at</p>
          <p class="text-muted">Updated at</p>
        </div>
        <div class="ml-5">
          <p>{{ dateFormat(reportDetail.created_at) }}</p>
          <p>{{ dateFormat(reportDetail.updated_at) }}</p>
        </div>
      </div>

      <div class="mt-4">
        <h4>Invoice</h4>
        <div class="d-flex">
          <div class="w-25">
            <p class="text-muted">ID Number</p>
            <p class="text-muted">Invoice Number</p>
            <p class="text-muted">Invoice Date</p>
            <p class="text-muted">Total Price</p>
          </div>
          <div class="ml-5">
            <p>{{ reportDetail.invoice.id }}</p>
            <p>
              <u>{{ reportDetail.invoice.invoice_number }}</u>
            </p>
            <p>{{ dateFormat(reportDetail.invoice.invoice_date) }}</p>
            <p>{{ reportDetail.invoice.total_price }}</p>
          </div>
        </div>
        <hr />
      </div>

      <div class="mt-4">
        <h4>Customer</h4>
        <div class="d-flex">
          <div class="w-25">
            <p class="text-muted">ID Number</p>
            <p class="text-muted">Name</p>
            <p class="text-muted">Email</p>
            <p class="text-muted">Phone</p>
          </div>
          <div class="ml-5">
            <p>{{ reportDetail.receiver.id ? reportDetail.receiver.id : '-' }}</p>
            <p>{{ reportDetail.receiver.receiver_name ? reportDetail.receiver.receiver_name : '-' }}</p>
            <p>{{ reportDetail.receiver.receiver_email ? reportDetail.receiver.receiver_email : '-' }}</p>
            <p>{{ reportDetail.receiver.receiver_phone ? reportDetail.receiver.receiver_phone : '-' }}</p>
          </div>
        </div>
        <hr />
      </div>

      <div class="mt-4">
        <h4>Account</h4>
        <div class="d-flex">
          <div class="w-25">
            <p class="text-muted">ID Number</p>
            <p class="text-muted">Bank Name</p>
            <p class="text-muted">Owner Name</p>
          </div>
          <div class="ml-5">
            <p>{{ reportDetail.account.id }}</p>
            <p>{{ reportDetail.account.bank_name }}</p>
            <p>{{ reportDetail.account.owner_name }}</p>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between mt-5">
        <div>
          <p>Quantity</p>
          <p>Unit Price</p>
          <p>Sale Price</p>
          <h2>Total Sale Price</h2>
        </div>
        <div class="d-flex flex-column align-items-end">
          <p>{{ reportDetail.quantity }}</p>
          <p>{{ reportDetail.unit_price }}</p>
          <p>{{ reportDetail.unit_sale_price }}</p>
          <h2>{{ reportDetail.total_sale_price }}</h2>
        </div>
      </div>
    </div>
    <div v-else class="loading">
      <van-loading type="spinner" />
    </div>
  </van-popup>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      moment,
      show: false,
      loading: false,
      reportDetail: {
        product: {},
        receiver: {},
        invoice: {},
        account: {},
      },
    }
  },
  methods: {
    async open(id) {
      this.show = true
      this.loading = true
      let res = await axios.get(`/inventories/${id}`)
      if (res) {
        this.reportDetail = res.data.data
        this.loading = false
      }
    },
    dateFormat(string) {
      return moment(string).format('YYYY-MM-DD')
    },
  },
  computed: {
    style() {
      return {
        width: '500px',
        height: '100%',
        padding: '50px 30px 40px 30px',
        border: '2px solid #F4F4F4',
        'border-radius': '8px 0px 0px 8px',
        'box-shadow': '-10px 4px 34px rgba(0, 0, 0, 0.09)',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 19px;
  font-weight: 700;
  letter-spacing: 1px;
}
h2 {
  font-size: 14.5px;
  font-weight: 700;
}
h3 {
  font-size: 14px;
}
h4 {
  font-size: 13px;
  margin-bottom: 16px;
}
p {
  line-height: 12px;
}
</style>

<style lang="scss">
.report-detail {
  .van-popup__close-icon {
    font-size: 20px !important;
  }
}
</style>