export default {
    all: 'အားလုံး',
    amount: 'ပမာဏ',
    total: 'စုစုပေါင်း',
    subtotal: 'အစုတစ်ခု၏စုစုပေါင်း',
    grandTotal: 'နောက်ဆုံးစုစုပေါင်း',
    discount: {
        discount: 'လျှော့စျေး',
        fixed: 'ပုံသေ',
        percent: 'ရာခိုင်နှုန်း',
        type: 'လျှော့ဈေးပုံစံ',
    },
    tax: 'အခွန်',
    chooseCustomer: 'ဖောက်သည် ရွေးပါ',
    chooseSupplier: 'ပေးသွင်းသူ ရွေးပါ',
    revenue: 'ဝင်ငွေ',
    expense: 'ထွက်ငွေ',
    sale: 'အရောင်း',
    purchase: 'အဝယ်',
    customer: 'ဖောက်သည်',
    supplier: 'ပေးသွင်းသူ',
    name: 'အမည်',
    email: 'အီးမေးလ်',
    password: 'စကားဝှက်',
    phoneNumber: 'ဖုန်းနံပါတ်',
    address: 'နေရပ် လိပ်စာ',
    date: 'ရက်စွဲ',
    taxIdNum: 'အခွန် ID နံပါတ်',
    shippingAddress: 'ပစ္စည်းပို့ဆောင်ရမည့် လိပ်စာ',
    billingAddress: 'ငွေတောင်းခံရန် လိပ်စာ',
    sale_invoice: 'အရောင်း ပြေစာ',
    purchase_invoice: 'အဝယ် ပြေစာ',
    sale_invoices: 'အရောင်း ပြေစာများ',
    purchase_invoices: 'အဝယ် ပြေစာများ',
    createdAt: 'ဖန်တီးသည့်ရက်စွဲ',
    status: 'အခြေအနေ',
    language: 'ဘာသာစကား',
    //image uploader
    addImage: 'ပုံထည့် ရန်',
    uploadFile: 'ဖိုင် ပုံထည့် ရန်',
    upgradeError: 'အပြည့်အဝ ဝင်ရောက်ခွင့်ရရန် အဆင့်မြှင့်ပါ။',
    error: 'အမှား ရှိသည်!',
    sidebar: {
        pos: 'ပီအိုအက်စ်',
        dashboard: 'စာရင်းဇယား',
        revenue: 'ဝင်ငွေ',
        expense: 'ထွက်ငွေ',
        report: 'မှတ်တမ်း',
        mngmt: 'ထုတ်ကုန်',
        setting: 'ပြင်ဆင်',
        logout: 'ထွက်မည်',
    },
    action: {
        cancel: 'မလုပ်တော့ပါ',
        filter: 'စစ်ထုတ်မည်',
        export_excel: 'Excel ဒေါင်းလုဒ်',
        selected: '{count} ခု ရွေးချယ်ထားသည်။',
        selectAll: 'အားလုံး ရွေးချယ်မည်။',
        unselectAll: 'Unselect All',
        printAll: 'အကုန် စာရွက်ထုတ်မည်',
        create: 'အသစ်ပြုလုပ်မည်',
        del: 'ဖျက်မည်',
        saveChange: 'ပြင်ဆင်မှုများကိုသိမ်းမည်',
        print: 'စာရွက်ထုတ်မည်',
        remove: 'ဖြုတ်မည်',
        edit: 'ပြင်မည်',
        save: 'သိမ်းမည်',
        change: 'ပြောင်းမည်',
        areYouSure: 'သေချာပါသလား',
        printReceipt: 'လက်ခံရရှိကြောင်းစာရွက်ထုတ်မည်',
        printPdf: 'PDF ဖိုင်ထုတ်ပါ',
        notNow: 'အခုမလုပ်သေးပါ',
        renewPlan: 'အစီအစဉ်အသစ်ပြန်လုပ်မည်',
        upgrade: 'အဆင့်မြှင့်မည်',
        update: 'ပြင်ဆင်မည်',
        switchTo: '{company} ကိုပြောင်းမည်',
        add: 'ထည့်မည်',
        apply: 'ပြုလုပ်မည်',
        search: 'ရှာဖွေပါ',
        back: 'နောက်သို့',
    },
    state: {
        loading: 'လုပ်ဆောင်နေသည်',
        creating: 'အသစ်ပြုလုပ်နေသည်',
        saving: 'သိမ်းနေသည်',
        deleting: 'ဖျက်နေသည်',
        deleted: 'ဖျက်ပြီးပြီ',
        delFail: 'ဖျက်၍မရပါ',
        fail: 'မအောင်မြင်ပါ',
        edited: 'ပြင်ပြီးပြီ',
        restored: 'ပြန်ထားပြီးပြီ',
        success: 'အောင်မြင်သည်',
        requiredFields: 'အားလုံးဖြည့်ပေးရန်လိုအပ်သည်',
        updated: 'ပြင်ပြီးပြီ',
        created: 'ပြုလုပ်ပြီးပြီ',
        switched: 'ပြောင်းပြီးပြီ',
        yes: 'ဟုတ်ပါသည်',
        addingAddress: 'လိပ်စာထည့်နေသည်',
    },
    pos: {
        createProduct: 'ပစ္စည်းအသစ်ထည့်ရန်ဤနေရာကိုနှိပ်ပါ',
        loadMore: 'နောက်ထပ်ရယူမည်',
        productEmpty: 'ပစ္စည်းများကို ဤနေရာတွင်ပြလိမ့်မည်',
        searchNotFound: '{input} ကိုရှာမတွေ့ပါ',
        emptyCart: 'ခြင်းထဲတွင် ပစ္စည်းမရှိသေးပါ',
        checkOut: 'ငွေရှင်းမည်',
        printConfirm: 'စာရွက်ထုတ်မှာသေချာပါသလား',
        print: 'စာရွက်ထုတ်မည်',
        checkoutConfirm: 'ငွေရှင်းမှာသေချာပါသလား',
        checkingOut: 'ငွေရှင်းနေသည်',
        noAccountAlert: 'ဘဏ်အကောင့်မရှိသေးပါ။ အကောင့်တစ်ခုထည့်ချင်ပါသလား။',
        bankAccount: 'ဘဏ်အကောင့်',
        selectAccountInfo: 'ဘဏ်အကောင့်တစ်ခုရွေးပေးပါ',
        selectAccount: 'ဘဏ်အကောင့်ရွေးပါ',
        clearAll: 'ပစ္စည်း {count} ခုကိုရှင်းပါ',
    },
    dashboard: {
        dashboard: 'စရင်းဇယား',
        //income
        revenueOverview: 'ဝင်ငွေစုစုပေါင်းကြည့်မည်',
        overviewSummary: 'ဝင်ငွေစုစုပေါင်းရာခိုင်နှုန်း',
        barChart: 'အတက်အကျပြဇယား',
        colChart: 'ဒေါင်လိုက်ပြဇယား',
        incomeVsExpense: 'ဝင်ငွေ နှင့် အသုံးစရိတ် (ရောင်းကုန်ပစ္စည်းကုန်ကျစရိတ်)',
        accountOverview: 'ငွေစာရင်း/ပိုင်ဆိုင်မှု',
        noAccount: 'အကောင့်မရှိသေးပါ',
        noProduct: 'ကုန်ပစ္စည်းမရှိသေးပါ',
        noCustomer: 'ဖောက်သည်မရှိသေးပါ',
        //Summary Card
        capital: 'အရင်းအနှီး',
        assets: 'ပိုင်ဆိုင်မှု',
        totalLiability: 'အကြွေး စုစုပေါင်း',
        saleTax: 'ရောင်းခွန်',
        accountPayable: 'ပေးရန် အကြွေး',
        withholdingTax: 'နုတ်ခွန်',
        taxes: 'အခွန်အခများ',
        purchase: 'အဝယ်',
        purchaseTax: 'ဝယ်ယူခွန်',
        //top product
        topProducts: 'ထိပ်တန်း ရောင်းကုန်များ',
        productName: 'ကုန် အမည်',
        totalOrder: 'စုစုပေါင်း',
        percentage: 'ရာခိုင်နှုန်း (%)',
        //top customer
        topCustomers: 'ထိပ်တန်းဖောက်သည်များ',
        cusName: 'ဖောက်သည်အမည်',
        totalPurchase: 'စုစုပေါင်းဝယ်စရိတ်',
        //low stock
        inventoryAlert: 'သတိပေးချက်',
        lowStockAlert: 'ကုန်ပစ္စည်းနည်းကြောင်း သတိပေးချက်',
        lowStockInfo: 'ကျန်သော ကုန်ပစ္စည်းအရေအတွက်သည် နည်းနေပါသည်',
        stockLeft: '{stock} ခုသာကျန်သည်',
        seeAll: 'အားလုံးကိုပြပါ',
        seeLess: 'အနည်းငယ်သာပြပါ',
        noLowStock: 'လက်ကျန်လျော့နေသော ပစ္စည်းမရှိပါ',
    },
    invoice: {
        create_sale_invoice: 'အရောင်းပြေစာအသစ်',
        create_purchase_invoice: 'အဝယ်ပြေစာအသစ်',
        invoiceNumber: 'ပြေစာနံပါတ်',
        createdDate: 'ပြုလုပ်ခဲ့သောရက်စွဲ',
        account: 'ဘဏ်အကောင့်',
        paidByTotal: 'ပေးချေပြီး/စုစုပေါင်း',
        leftAmount: 'ပေးရန်ကျန်သောပမာဏ',
        fullyPaid: 'ပေးချေပြီး',
        emptyInvoice: 'ပြေစာများမရှိသေးပါ',
        //create edit
        general: 'အထွေထွေ',
        payment: 'ပေးချေမှု',
        //customer form
        autoCreate: '{person} ကိုအလိုလျောက်ဖန်တီးမည်',
        referenceNumber: 'အညွှန်းဂဏန်း',
        invoiceDate: 'ပြေစာရက်စွဲ',
        createdDateTime: 'ပြုလုပ်ခဲ့သောရက်စွဲ၊ အချိန်',
        dueDate: 'သတ်မှတ်ထားသောကာလနောက်ဆုံးရက်',
        //product
        productDetail: 'ပစ္စည်းအကြောင်းအသေးစိတ်',
        unit: 'ရေတွက်ပုံ',
        qty: 'အရေအတွက်',
        price: 'ဈေးနှုန်း',
        totalPrice: 'စုစုပေါင်းကျသင့်ငွေ',
        addProduct: 'ကုန်ပစ္စည်းထပ်ထည့်မည်',
        reset: 'မူလအတိုင်းထာမည်',
        searchProduct: 'ကုန်ပစ္စည်းရှာဖွေပါ',
        createProduct: 'ကုန်ပစ္စည်းအသစ်ပြုလုပ်မည်',
        //payments
        payments: 'ပေးချေမှုများ',
        payInfo: 'ပေးချေမှုများကို ရက်စွဲဖြင့်ခွဲပြီး တွက်ချက်မှုပုံသေနည်းများအသုံးပြုသွားမည်',
        payer: 'ပေးသူ',
        //actions
        edit: 'ပြင်မည်',
        clone: 'ပွားမည်',
        del: 'ဖျက်မည်',
    },
    report: {
        assets: 'ပိုင်ဆိုင်မှု',
        liability: 'ပေးရန်တာဝန်',
        emptyRecord: 'မှတ်တမ်းမရှိပါ',
    },
    mngmt: {
        inventory: 'ကုန်ပစ္စည်းစီမံခြင်း',
        products: 'ကုန်ပစ္စည်း',
        categories: 'ပစ္စည်းအမျိုးအစား',
        coupons: 'ကူပွန်',
        contacts: 'အဆက်အသွယ်များ',
        admins: 'စီမံခန့်ခွဲနိုင်သူများ',
        suppliers: 'ရောင်းသူ',
        customers: 'ဖောက်သည်များ',
        employees: 'ဝန်ထမ်းများ',
        finance: 'ဘဏ္ဍာရေး',
        paymentVoucher: 'ငွေပေးချေမှုဘောက်ချာများ',
        accounts: 'ငွေစာရင်းများ/ပိုင်ဆိုင်မှု',
        payroll: 'လုပ်ခလစာ',
        accounting: 'စာရင်းကိုင်ပညာ/စာရင်းတွက်ချက်ခြင်း',
        chartOfAccount: 'စာရင်းခေါင်းစဉ်',
        glFormula: 'စာရင်းပုံသေနည်း',
        referrals: 'ညွှန်းထားပေးမှုများ',
        referral: {
            yourReferral: 'သင့်အညွှန်းကုဒ်',
            shareReferral: 'အကျိုးခံစားမှုများရရှိရန် သင့်အညွှန်းကုဒ်ကိုအခြားသူများအားမျှဝေပါ',
        },
        admin: {
            createAdmin: 'စီမံခန့်ခွဲသူဖန်တီခြင်း',
            emptyAdmin: 'စီမံခန့်ခွဲသူမရှိပါ',
            addNewAdmin: 'စီမံခန့်ခွဲသူအသစ် ထပ်ထည့်',
            role: 'အဆင့်သတ်မှတ်ချက်',
            //actions
            edit: 'ပြင်မည်',
            clone: 'ပွားမည်',
            del: 'ဖျက်မည်',
        },
        category: {
            newCategory: 'အမျိုးအစားအသစ်ဖန်တီးမည်/Category အသစ်ဖန်တီးမည်',
            emptyCategory: 'အမျိုးအစားမရှိသေးပါ',
            editCategory: 'အမျိုးအစားကိုပြုပြင်မည်',
        },
        coa: {
            newCoa: 'စာရင်းခေါင်းစဉ်အသစ်ဖန်တီးမည်',
            emptyCoa: 'စာရင်းခေါင်းစဉ်မရှိပါ',
            //actions
            edit: 'ပြင်မည်',
            clone: 'ပွားမည်',
            del: 'ဖျက်မည်',
        },
        coupon: {
            newCoupon: 'ကူပွန်အသစ်ဖန်တီးမည်',
            emptyCoupon: 'ကူပွန်များမရှိသေးပါ',
            code: 'ကုန်ပစ္စည်းနံပါတ်သတ်မှတ်ခြင်း',
            //create edit
            couponName: 'ကူပွန်အမည်',
            couponCode: 'ကူပွန်ကုဒ်',
            amountOff: 'လျော့ပေးမည့်ပမာဏ',
            percentOff: 'လျော့ပေးမည့်ရာခိုင်နှုန်း',
            timeLimitInfo: `အချိန်အကန့်အသတ်သည် လျှော့ဈေး/ကူပွန်ကုဒ်၏ သုံးနိုင်သောကာလကိုသတ်မှတ်ရန်ဖြစ်သည်။ အမြဲတမ်းအသုံးပြုနိုင်စေလိုပါက ဖြည့်ရန်မလိုပါ။`,
            usableTime: 'သုံးနိုင်သော အချိန်အကန့်အသတ်',
            validDateRange: 'တရားဝင်ချိန်ကာလသတ်မှတ်',
            applyTo: '{target} အတွက်ပြုလုပ်မည်',
            add: '{target} ထည့်ထပ်မည်',
            validEnds: 'ပစ္စည်းအားလုံး၊ {time} အထိအကျုံးဝင်',
            valid: 'ပစ္စည်းအားလုံး၊ {time} မှစတင်အကျုံးဝင်',
            validUnlimited: 'ပစ္စည်းအားလုံး၊ အချိန်မကန့်သတ်ထား',
        },
        product: {
            addPrice: 'Add price',
            newProduct: 'ကုန်ပစ္စည်းအသစ်ပြုလုပ်မည်',
            gpProduct: 'ကုန်ပစ္စည်းအုပ်စုအသစ်ဖန်တီး',
            singleProduct: 'ကုန်ပစ္စည်းတစ်ခုဖန်တီး',
            addStock: 'ကုန်ပစ္စည်း ထပ်ထည့်မည်',
            productName: 'ပစ္စည်းအမည်',
            instock: 'လက်ရှိကုန်ပစ္စည်း(စုစုပေါင်း)',
            retailPrice: 'လက္ကားဈေး',
            selling: 'ရောင်းနိုင်ခြင်း',
            outOfStock: 'ပစ္စည်းမရှိတော့ပါ',
            printBarcode: 'Barcode ထုတ်မည်',
            quantity: 'အရေအတွက်',
            barcodePerRow: 'တစ်တန်းတွင်ပါဝင်မည့် Barcode',
            barcodeSize: 'Barcode အရွယ်',
            barcodeHeight: 'Barcode အမြင့်',
            pageWidth: 'စာရွက်အကျယ်(မီလီမီတာ)',
            pageHeight: 'စာရွက်အမြင့်(မီလီမီတာ)',
            gap: 'အကွာအဝေး',
            rememberSetting: 'နောက်တစ်ကြိမ်အတွက်မှတ်ထားပါ',
            notFound: 'ကုန်ပစ္စည်းများရှာမတွေ့ပါ',
            noProduct: 'ကုန်ပစ္စည်းများမရှိသေးပါ',
            subProduct: 'Sub-Product',
            groupName: 'Group Name',
            subProductImage: 'ကုန်ပစ္စည်းရုပ်ပုံ-အစု',
            noBarcode: 'Barcode မရှိပါ',
            noBarcodeMessage: 'ဤကုန်ပစ္စည်းတွင် Barcode မရှိပါ (သို့) Barcode သည်မှားယွင်းနေသည်',
            //create edit
            allowNegativeStock: 'လက်ကျန်မရှိသော ကုန်ပစ္စည်းအားရောင်းခွင့်ပြု',
            barcode: 'Barcode',
            productSKU: 'ကုန်ပစ္စည်း စတိုးတွင်ရေတွက်ပုံ',
            category: 'အမျိုးအစား',
            originalPrice: 'မူလရောင်းဈေး',
            unitBuyPrice: 'ကုန်ပစ္စည်းတစ်ခုအတွက်ဝယ်ဈေး',
            initialStock: 'ကနဦးရှိသောကုန်ပစ္စည်းအရေအတွက်',
            unit: 'ရေတွက်ပုံ',
            //create eidt => units
            unitsTitle: 'အစုလိုက်ရောင်းရန်သတ်မှတ်ထားသောကုန်ပစ္စည်းများ',
            unitTitle: 'အစုလိုက်ရောင်းရန်သတ်မှတ်ထားသောကုန်ပစ္စည်းများ',
            coefficient: 'ကိန်းဂဏန်း',
            sellPrice: 'ရောင်းဈေး',
            buyPrice: 'ဝယ်ဈေး',
            active: 'လက်ရှိအသုံးပြုနိုင်သည်',
            inactive: 'Inactive',
            autoPickup: 'အလိုလျောက်ကုန်ပစ္စည်းနံပါတ်စစ်ဆေးပေးသည်',
            variations: 'Variations',
            variationsWarningInfo: 'Create product first to add variations',
            actions: 'Actions',
            addProductUnit: 'Add Product Unit',
            unitName: 'Unit Name',
            Coefficient: 'coefficient',
            //create edit -> options
            optionTitle: 'ခေါင်းစဉ်ရွေးချယ်ခြင်း',
            optionName: 'အမည်ရွေးချယ်ခြင်း',
            requiredOne: 'အနည်းဆုံးတစ်ကြိမ်းရွေးချယ်ရန်',
            enableMulti: 'အမျိုးအစားများစွာရွေးချယ်နိုင်ခြင်း',
            item: 'အမျိုးအစား',
            salePrice: 'ရောင်းဈေး',
            purchasePrice: 'ဝယ်ဈေး',
            autoPick: 'အလိုအလျောက်ကောက်ယူခြင်း',
            //actions
            edit: 'ပြင်မည်',
            clone: 'ပွားမည်',
            del: 'ဖျက်မည်',
            pleaseSelectProducts: 'ပစ္စည်းထပ်ထည့်ရန် ကုန်ပစ္စည်းရွေးပါ',
        },
        voucher: {
            newVoucher: 'ဘောက်ချာအသစ်',
            emptyVoucher: 'ဘောက်ချာများမရှိသေးပါ',
            cashPayment: 'ငွေသားပေးချေခြင်း',
            cashReceived: 'ငွေသားလက်ခံခြင်း',
            accountCode: 'ကောင့်ကုဒ်',
            voucherNo: 'ဘောက်ချာနံပါတ်',
            accountName: 'အကောင့်အမည်',
            bankBalance: 'ဘဏ်အကောင့်လက်ကျန်ငွေ',
            date: 'နေ့စွဲ',
            account: 'ဘဏ်အကောင့်',
            paymentTo: 'ငွေလက်ခံမည့်သူ',
            paymentBy: 'ပေးဆောင်မည့်သူ',
            customer: 'ဖောက်သည်',
            supplier: 'ရောင်းသူ',
            employee: 'ဝန်ထမ်း',
            loading: 'ရှာဖွေနေသည်...',
            taxExclusive: 'အခွန်မပါဝင်သေး',
            description: 'ဖော်ပြချက်',
            addVoucher: 'ဘောက်ချာထပ်ထည့်ပါ',
            netTotal: 'အသားတင် စုစုပေါင်းငွေ',

            //actions
            edit: 'Edit Voucher',
            clone: 'Clone Voucher',
            del: 'Delete Voucher',
        },
        customer: {
            newCustomer: 'ဖောက်သည်အသစ်ဖန်တီးခြင်း',
            emptyCustomer: 'ဖောက်သည်များ မရှိသေးပါ',
            //create edit
            title: 'ဖောက်သည်အသစ်',
            personalInformation: 'ကိုယ်ရေးအချက်လက်များ',
            name: 'အမည်',
            companyName: 'ကုမ္ပဏီအမည်',
            taxId: 'အခွန်နံပါတ်',
            addEmail: 'အီးမေးလ်ထပ်ထည့်မည်',
            addPhone: 'ဖုန်းနံပါတ်ထပ်ထည့်မည်',
            address: 'လိပ်စာ',
            addresses: 'လိပ်စာများ',
            addressName: 'အမည်',
            icon: 'ပစ္စည်းသင်္ကေံတ ရုပ်ပုံ',
            description: 'အကြောင်းအရာ',
            myLocation: 'မိမိ၏လိပ်စာ',
            location: 'တည်နေရာ',
        },
        supplier: {
            newSupplier: 'ရောင်းသူအသစ်ဖန်တီး',
            emptySupplier: 'ရောင်းသူမရှိပါ',
            name: 'ရောင်းသူအမည်',
            address: 'လိပ်စာ',
            billingAddress: 'ကုန်ပစ္စည်းပြေစာပို့ရန်လိပ်စာ',
        },
        employee: {
            employees: 'ဝန်းထမ်းများ',
            newEmployee: 'ဝန်ထမ်းအသစ်',
            emptyEmployee: 'ဝန်ထမ်းများမရှိသေးပါ',
            currentEmployee: 'လက်ရှိဝန်ထမ်း',
            exEmployee: 'ယခင်ဝန်ထမ်း',
            //create edit
            addNewEmployee: 'ဝန်ထမ်းအသစ်ထည့်မည်',
            employeeName: 'ဝန်ထမ်းအမည်',
            employeeId: 'ဝန်ထမ်း Id',
            workTimeFrom: 'အလုပ်စချိန်',
            workTimeTo: 'အလုပ်သိမ်းချိန်',
            socialSecFund: 'လူမှုဖူလုံကြေး',
            baseSalary: 'လစာ',
            socialSecNumber: 'လူမှုဖူလုံးရေးနံပါတ်',
            address: 'လိပ်စာ',
            wht: 'နုတ်ခွန် (%)',
            netIncome: 'အသားတင်ဝင်ငွေ',
            otherIncome: 'အခြားဝင်ငွေ (Optional)',
            otherDeduction: 'ကုန်ပစ္စည်းပြန်နှုတ်မည်',

            //actions
            edit: 'ပြင်မည်',
            clone: 'ပွားမည်',
            del: 'ဖျက်မည်',
            restore: 'ပြန်ထားမည်',
        },
        bankAccount: {
            addAssets: 'ပိုင်ဆိုင်မှုထပ်ပေါင်းမည်',
            balance: 'လက်ကျန်ငွေ',
            accountNumber: 'အကောင့်နံပါတ် : {accountNumber}',
            defaultAccount: 'ပုံသေအကောင့်',
            emptyAccount: 'အကောင့်များမရှိသေးပါ',
            //create edit
            title: 'စိတ်ကြိုက်အကောင့်ထည့်ပါ',
            accountDetail: 'အကောင့်အချက်အလက်များ',
            accountTitle: 'အကောင့်အမည်',
            accNumber: 'အကောင့်နံပါတ်',
            bankName: 'ဘဏ်အမည်',
            initialBalance: 'စာရင်းရှိငွေ',
        },
        payrolls: {
            newPayroll: 'လုပ်ခလစာ အသစ်ဖန်တီးမည်',
            emptyPayroll: 'လုပ်ခလစာ မရှိပါ',
            sent: 'ပို့သည်',
            backToPayroll: 'လုပ်ခလစာ သို့ပြန်သွားမည်',
            payroll: 'လုပ်လစာ',
            dateRange: '{from} မှ - {to} အထိ',
            payFrom: 'မှ ပေးချေမည်',
            paid: 'ပေးချေပြီး',
            notPaid: 'မပေးချေရသေး',
            //actions
            edit: 'ပြင်မည်',
            clone: 'ပွားမည်',
            del: 'ဖျက်မည်',
            restore: 'မူလအတိုင်းပြန်ထားမည်',
            print: 'လုပ်လစာ စာရွက်ထုတ်မည်',
        },
    },
    setting: {
        myProfile: 'ကိုယ်ရေးအချက်လက်',
        appearance: 'အသွင်ပြင်',
        posPrinter: 'အရောင်း စာရွက်ထုတ်စက်',
        myStore: 'ကျွန်န်ုပ်၏ဆိုင်',
        store: 'ဆိုင်',
        account: 'အကောင့်',
        changeLanguage: 'ဘာသာစကားပြောင်းရန်',
        myPlan: 'အစီအစဉ်',
        developer: 'Developer',
        apiKey: 'API Keys',
        doc: 'Documentation',
        appear: {
            info: 'အရောင်များကို စိတ်ကြိုက်ပြောင်းလဲအသုံးပြုနိုင်သည်။',
            chooseColor: 'အရောင်ရွေးပါ',
        },
        profile: {
            currentPlan: 'လက်ရှိအစီအစဉ်',
            lifetimeFree: 'တစ်သက်တာ အခမဲ့',
            startAt: 'စတင်ချိန်',
            expireAt: 'ကုန်ဆုံးချိန်',
            changePlan: 'အစီအစဉ်ပြောင်းလဲမည်',
        },
        company: {
            newStore: 'ဆိုင်အသစ်ပြုလုပ်မည်',
            storeInformation: 'ဆိုင်အချက်အလက်များ',
            storeBanner: 'ဆိုင်နောက်ခံပုံ',
            storeLogo: 'ဆိုင်အမှတ်တံဆိပ်',
            storeName: 'ဆိုင်အမည်',
            currency: 'ငွေကြေး',
            taxId: 'အခွန် ID နံပါတ်',
            defaultTax: 'အခွန် ( % )',
            storeType: 'ဆိုင်အမျိုးအစား',
            purchaseInvoicePrefix: 'အဝယ်ပြေစာနံပါတ် ရှေ့စာလုံး',
            saleInvoicePrefix: 'အရောင်းပြေစာနံပါတ် ရှေ့စာလုံး',
            selectCurrency: 'ငွေကြေးရွေးပါ',
        },
        paymentLog: {
            title: 'ပေးချေမှုမှတ်တမ်း',
            id: 'သတ်မှတ်ထားကုဒ်နံပါတ်',
            amt: 'ပမာဏ',
            invoiceNumber: 'ပြေစာနံပါတ်',
            period: 'အချိန်ကာလ',
            status: 'အခြေအနေ',
            validFrom: 'စတင်ရက်',
            validTo: 'ကုန်ဆုံးရက်',
        },
    },
    vatType: 'Vat Type',
    exclusive: 'မပါဝင်',
    inclusive: 'ပါဝင်',
}
