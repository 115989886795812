<template>
  <div>
    <van-popup v-model="show" class="custom-popup">
      <h1>Change Password</h1>
      <div class="py-3 mt-4 ml-5">
        <div class="mb-4">
          <div class="d-flex align-items-center">
            <Input
              :params="formData"
              :errors="{}"
              field="password"
              label="New Password"
              type="password"
              placeholder="Enter your new password"
              class="flex-grow-1"
              :borderError="errors.password"
            />
            <div class="check-icon">
              <div v-if="errors.password">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.99935 1.66699C5.40768 1.66699 1.66602 5.40866 1.66602 10.0003C1.66602 14.592 5.40768 18.3337 9.99935 18.3337C14.591 18.3337 18.3327 14.592 18.3327 10.0003C18.3327 5.40866 14.591 1.66699 9.99935 1.66699ZM12.7993 11.917C13.041 12.1587 13.041 12.5587 12.7993 12.8003C12.6743 12.9253 12.516 12.9837 12.3577 12.9837C12.1993 12.9837 12.041 12.9253 11.916 12.8003L9.99935 10.8837L8.08268 12.8003C7.95768 12.9253 7.79935 12.9837 7.64102 12.9837C7.48268 12.9837 7.32435 12.9253 7.19935 12.8003C6.95768 12.5587 6.95768 12.1587 7.19935 11.917L9.11602 10.0003L7.19935 8.08366C6.95768 7.84199 6.95768 7.44199 7.19935 7.20033C7.44102 6.95866 7.84102 6.95866 8.08268 7.20033L9.99935 9.11699L11.916 7.20033C12.1577 6.95866 12.5577 6.95866 12.7993 7.20033C13.041 7.44199 13.041 7.84199 12.7993 8.08366L10.8827 10.0003L12.7993 11.917Z" fill="#ED1C24"/>
                </svg>
              </div>
              <div v-if="checkPasswordLength()">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.99935 1.6665C5.40768 1.6665 1.66602 5.40817 1.66602 9.99984C1.66602 14.5915 5.40768 18.3332 9.99935 18.3332C14.591 18.3332 18.3327 14.5915 18.3327 9.99984C18.3327 5.40817 14.591 1.6665 9.99935 1.6665ZM13.9827 8.08317L9.25768 12.8082C9.14102 12.9248 8.98268 12.9915 8.81602 12.9915C8.64935 12.9915 8.49102 12.9248 8.37435 12.8082L6.01602 10.4498C5.77435 10.2082 5.77435 9.80817 6.01602 9.5665C6.25768 9.32484 6.65768 9.32484 6.89935 9.5665L8.81602 11.4832L13.0993 7.19984C13.341 6.95817 13.741 6.95817 13.9827 7.19984C14.2243 7.4415 14.2243 7.83317 13.9827 8.08317Z" fill="#11DCB2"/>
                </svg>
              </div>
            </div>
          </div>
          <div v-if="!checkPasswordLength()" :class="errors.password ? 'text-danger' : 'text-muted'">Must be at least 8 characters.</div>
        </div>
        
        <div class="mt-4">
          <div class="d-flex align-items-center">
            <Input
              :params="formData"
              :errors="{}"
              field="confirm_password"
              label="Confirm Password"
              type="password"
              placeholder="Confirm your new password"
              class="flex-grow-1 border-danger"
              :borderError="errors.confirm_password"
            />
            <div class="check-icon">
              <div v-if="errors.confirm_password">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.99935 1.66699C5.40768 1.66699 1.66602 5.40866 1.66602 10.0003C1.66602 14.592 5.40768 18.3337 9.99935 18.3337C14.591 18.3337 18.3327 14.592 18.3327 10.0003C18.3327 5.40866 14.591 1.66699 9.99935 1.66699ZM12.7993 11.917C13.041 12.1587 13.041 12.5587 12.7993 12.8003C12.6743 12.9253 12.516 12.9837 12.3577 12.9837C12.1993 12.9837 12.041 12.9253 11.916 12.8003L9.99935 10.8837L8.08268 12.8003C7.95768 12.9253 7.79935 12.9837 7.64102 12.9837C7.48268 12.9837 7.32435 12.9253 7.19935 12.8003C6.95768 12.5587 6.95768 12.1587 7.19935 11.917L9.11602 10.0003L7.19935 8.08366C6.95768 7.84199 6.95768 7.44199 7.19935 7.20033C7.44102 6.95866 7.84102 6.95866 8.08268 7.20033L9.99935 9.11699L11.916 7.20033C12.1577 6.95866 12.5577 6.95866 12.7993 7.20033C13.041 7.44199 13.041 7.84199 12.7993 8.08366L10.8827 10.0003L12.7993 11.917Z" fill="#ED1C24"/>
                </svg>
              </div>
              <div v-if="checkPasswordMatch()">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.99935 1.6665C5.40768 1.6665 1.66602 5.40817 1.66602 9.99984C1.66602 14.5915 5.40768 18.3332 9.99935 18.3332C14.591 18.3332 18.3327 14.5915 18.3327 9.99984C18.3327 5.40817 14.591 1.6665 9.99935 1.6665ZM13.9827 8.08317L9.25768 12.8082C9.14102 12.9248 8.98268 12.9915 8.81602 12.9915C8.64935 12.9915 8.49102 12.9248 8.37435 12.8082L6.01602 10.4498C5.77435 10.2082 5.77435 9.80817 6.01602 9.5665C6.25768 9.32484 6.65768 9.32484 6.89935 9.5665L8.81602 11.4832L13.0993 7.19984C13.341 6.95817 13.741 6.95817 13.9827 7.19984C14.2243 7.4415 14.2243 7.83317 13.9827 8.08317Z" fill="#11DCB2"/>
                </svg>
              </div>
            </div>
          </div>
          <div v-if="!checkPasswordMatch()"  :class="errors.confirm_password ? 'text-danger' : 'text-muted'">Both password must match.</div>
        </div>
        <div class="d-flex justify-content-end mt-4 pt-2 mr-5">
          <button @click="show = false" class="btn btn-cancel">Cancel</button>
          <button @click="changePassword()" class="btn btn-primary">Save</button>
        </div>
      </div>
    </van-popup>
    <alert-popup message="Password Successfully Changed" :autoCloseTime="2000" ref="passwordAlert"/>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  props : ['params'],
  data() {
    return {
      show : false,
      formData : {
        password : null,
        confirm_password : null,
      },
      errors : {
        password : null,
        confirm_password : null
      }
    }
  },
  methods: {
    checkPasswordLength() {
      if(this.formData.password && this.formData.password.length >= 8) {
        this.errors.password = null
        return true
      }
    },
    checkPasswordMatch() {
      if(this.formData.password && this.formData.password.length >= 8 && this.formData.password == this.formData.confirm_password) {
        this.errors.confirm_password = null
        return true
      }
    },
    async changePassword() {
      if(!this.checkPasswordLength()) {
        this.errors.password = true
      }
      if(!this.checkPasswordMatch()) {
        this.errors.confirm_password = true
      }
      else {
        Toast.loading({
          message: 'Loading...',
          forbidClick: true,
          duration: 0,
        })
        let res = await axios.post('change/password', this.formData)
        if(res) {
          this.show = false
          this.$refs.passwordAlert.open()
          Toast.clear()
        }
      }
    },
    open() {
      this.formData = {
        password : null,
        confirm_password : null,
      }
      this.errors = {
        password : null,
        confirm_password : null
      }
      this.show = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .custom-popup {
    width : 480px;
    border-radius: 10px;
    padding : 28px;
    font-size: 13px;
  }
  .text-muted {
    color : rgba(0,0,0,0.5) !important;
  }
  .check-icon {
    width : 20px;
    margin : 15px 0 0 15px;
  }
  .btn-primary {
    font-weight: 400;
    padding : 0 45px;
    height: 40px;

    &:hover {
      font-weight: 400;
      height: 40px;
    }
  }
  .btn-cancel {
    font-weight: 400;
    padding-right : 0 20px;
  }
</style>
