<template>
  <div class="left container-fluid">
    <CreateNavbar>
      {{ $route.params.submitType }} {{ type == 'sale' ? $t('sale_invoice') : $t('purchase_invoice') }}
    </CreateNavbar>
    <div class="d-flex">
      <!-- Sidebar -->
      <div class="invoice-left pr-3 pt-3">
        <div class="sale-invoice-body p-1">
          <van-cell :title="$t('invoice.general')" :icon="step == 'general' ? 'passed' : 'circle'" is-link @click="step = 'general'" />
          <van-cell :title="$t('invoice.payment')" :icon="step == 'payment' ? 'passed' : 'circle'" is-link @click="step = 'payment'" />
          <!-- <van-cell title="Accountant" icon="circle" is-link @click="step = 'accountant'" /> -->
          <CreateFooter :params="params" @errors="setError" :type="type" />
        </div>
      </div>
      <!--  -->
      <div class="invoice-right">
        <!--  General -->
        <div v-show="step == 'general'" id="invoice-page">
          <CustomerForm :params="params" :errors="errors">
            {{ this.type == 'sale' ? 'Customer' : 'Supplier' }} Name
          </CustomerForm>
          <div class="sale-invoice-body mt-4">
            <ProductDetail :params="params" :showOptions="showOptions" :errors="errors" />
            <TotalAmount :params="params" />
          </div>
        </div>

        <!-- Accountant -->
        <div class="sale-invoice-body mt-3" v-show="step == 'accountant'">
          <Accountant :params="params" :errors="errors" />
        </div>

        <!-- Payment -->
        <div class="sale-invoice-body mt-3" v-show="step == 'payment'">
          <Payments :params="params" :errors="errors" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import CreateNavbar from './components/CreateNavbar'
import CustomerForm from './components/CustomerForm'
import CreateFooter from './components/CreateFooter'
import TotalAmount from './components/TotalAmount'
import ProductDetail from './components/ProductDetail'
import Accountant from './components/Accountant'
import Payments from './components/Payments'
import { Toast } from 'vant'

export default {
  components: {
    CreateNavbar,
    CustomerForm,
    CreateFooter,
    TotalAmount,
    ProductDetail,
    Accountant,
    Payments,
  },
  data() {
    return {
      step: 'general',
      type: this.$route.params.type,
      errors: {},
      moment,
      showOptions: [],
      params: {
        invoice_number: null,
        reference_number: null,
        account_id: null,
        partial_paid: false,
        payments: [],
        invoiceDate: {
          startDate: null,
        },
        dueDate: {
          startDate: null,
        },
        receiver_name: null,
        receiver_auto_create: true,
        receiver_id: null,
        receiver_email: null,
        receiver_phone: null,
        shipping_address: null,
        billing_address: null,
        products: [
          {
            unitList: [],
            unit: null,
            optionList: [],
            options: [],
            is_manual: false,
            quantity: null,
            show_option: false,
            price: null,
            discount : 0,
            discount_type : 'fixed'
          },
        ],
        discount_type: 'fixed',
        discount: 0,
        tax: window.$company.tax,
        paid_amount: 0,
        vat_type : 'exclusive'
      },
    }
  },
  methods: {
    selectOption(product) {
      let selectedOption = {}
      product.options.forEach((option) => {
        selectedOption[option.product_option_id] = option.items.map((item) => item.product_option_item_id)
      })
      return product.selected_product.options.map((option) => {
        if (selectedOption[option.id]) {
          let selectedIds = selectedOption[option.id]
          option.selected = option.is_multiple ? selectedIds : selectedIds[0] ? selectedIds[0] : null
        }
        return option
      })
    },
    async fetchData() {
      let res = await axios.get(`/invoices/${this.$route.params.id}`)
      if (res) {
        let data = res.data.data
        this.params.invoice_number = data.invoice_number
        this.params.reference_number = data.reference_number
        this.params.invoiceDate.startDate = new Date(data.invoice_date)
        this.params.dueDate.startDate = new Date(data.due_date)
        this.params.receiver_name = data.receiver.receiver_name
        this.params.receiver_tax_id = data.receiver.tax_id
        this.params.receiver_email = data.receiver.receiver_email
        this.params.receiver_phone = data.receiver.receiver_phone
        this.params.receiver_id = data.receiver.receiver_id
        this.params.shipping_address = data.receiver.shipping_address
        this.params.billing_address = data.receiver.billing_address
        this.params.account_id = data.account_id
        this.params.products = data.products
        this.params.tax = data.tax
        this.params.discount_type = data.discount_type
        this.params.discount = data.discount
        this.params.paid_amount = data.paid_amount
        this.params.partial_paid = data.partial_paid
        this.params.payments = data.payments
        this.params.vat_type = data.vat_type
      }
    },
    setError(value) {
      this.errors = window.$helpers.validationObject(value)
    },
    setProduct(e) {
      let newProduct = { ...e }
      newProduct.price = e.retail_price
      newProduct.quantity = 1
      newProduct.product_id = e.id
      newProduct.unitList = e.units
      newProduct.discount = 0
      newProduct.unit = e.units.filter((unit) => unit.is_default)[0]
      newProduct.optionList = JSON.parse(JSON.stringify(e.options))
      this.params.products.push(newProduct)
    },
    async preSelectProducts(products) {
      Toast.loading({ message: 'Adding Products' })
      let params = { productIds: products }
      const res = await axios.get('multiple_product_stock', { params })
      let defProducts = res.data?.data
      if (defProducts.length) {
        this.params.products = []
        defProducts.forEach((e) => this.setProduct(e))
      }
      Toast.clear()
    },
  },
  mounted() {
    let products = this.$route.query.products
    if (products) {
      this.preSelectProducts(products.split(','))
    }
    if (this.$route.params.id) {
      this.fetchData()
    } else {
      this.params.invoiceDate.startDate = new Date()
      this.params.dueDate.startDate = new Date()
    }
  },
}
</script>

<style scoped>
.invoice-left {
  max-width: 250px;
  min-width: 250px;
}

.invoice-right {
  width: 100%;
}

.left {
  background-color: #f4f4f4;
  width: 100%;
}

.sale-invoice-body {
  background: #ffffff;
  border-radius: 10px;
  height: auto;
  width: 100%;
  padding: 20px;
}

.actions-container {
  margin: 1.5rem 0;
  text-align: right;
}

.actions-container > a {
  margin-left: 1rem;
}

.disabled-action {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.outline-action {
  font-weight: bold;
  padding: 8px 20px;
  border: 2px solid #d1d1d1;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #2b2b2b;
  gap: 10px;
  cursor: pointer;
}

.outline-action:hover {
  border: 2px solid #4b4b4b;
}

.disabled-action:hover {
  border: 2px solid #d1d1d1;
}
</style>
