<template>
  <van-popup :value="value" @input="$emit('input', false)" class="custom-popup" closeable>
    <h2 class="text-center mb-5">{{ $t('mngmt.product.addProductUnit') }}</h2>

    <div class="d-flex justify-content-center">
      <ImageUploader :value="unit.uploader" @input="getImage" @delete="getImage" />
    </div>

    <Input :params="unit" :errors="errors" field="unit" :label="$t('mngmt.product.unitName')" />

    <Input :params="unit" :errors="errors" field="sale_price" :label="$t('mngmt.product.sellPrice')" />

    <Input :params="unit" :errors="errors" field="purchase_price" :label="$t('mngmt.product.buyPrice')" />

    <div class="mb-4 mt-4 d-flex align-items-center">
      <van-switch v-model="unit.active" size="20" class="mr-2" />
      <label class="label">{{ unit.active ? $t('mngmt.product.active') : $t('mngmt.product.inactive') }}</label>
    </div>

    <!-- <div class="mb-2 d-flex">
      <van-switch v-model="unit.add_price" size="20" class="mr-2" />
      <label class="label">{{ $t('mngmt.product.addPrice') }} (Add price to original product price)</label>
    </div> -->

    <Button @click.native="submit()" :loading="loading" :type="editIndex != null ? 'edit' : 'create'" />
  </van-popup>
</template>

<script>
import { Toast } from 'vant'
export default {
  props: ['value', 'unit', 'editIndex', 'units'],
  data() {
    return {
      loading: false,
      errors: {},
    }
  },
  methods: {
    getImage(value) {
      if (value && value[0]) {
        this.unit.image = value[0].file
      } else {
        this.unit.image = null
      }
    },
    async submit() {
      this.loading = true
      let fd = this.getFormData()
      let res =
        this.editIndex != null
          ? await axios.post(`/products/${this.unit.product_id}/product_units/${this.unit.id}?_method=PUT`, fd)
          : await axios.post(`/products/${this.unit.product_id}/product_units`, fd)
      if (res) {
        if (res.data.code == 422) {
          this.errors = res.data.error
        } else {
          if (this.editIndex != null) {
            this.units[this.editIndex] = res.data.data
            Toast.success(this.$t('state.updated'))
          } else {
            this.units.push(res.data.data)
            Toast.success(this.$t('state.created'))
          }
          this.$emit('input', false)
        }
        this.loading = false
      }
    },
    getFormData() {
      let fd = new FormData()
      let keywords = ['company_id', 'unit', 'coefficient', 'sale_price', 'purchase_price', 'add_price']
      keywords.forEach((keyword) => {
        if (this.unit[keyword]) {
          fd.append(keyword, this.unit[keyword])
        }
      })
      fd.append('active', this.unit.active ? 1 : 0)
      fd.append('add_price', this.unit.add_price ? 1 : 0)
      if (this.unit.image) {
        if (typeof this.unit.image != 'string') {
          fd.append('image', this.unit.image)
        }
      }
      return fd
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-popup {
  width: 540px;
  border-radius: 10px;
  padding: 1.5rem 2rem 0 2rem;
  border: 2px solid #f4f4f4;
}
</style>