<template>
  <van-popup v-model="show" :style="style" closeable close-icon-position="top-right" class="report-detail">
    <h3>{{ name }} ({{ coa }})</h3>
    <div class="d-flex justify-content-between my-4">
      <custom-search v-model="params.keyword" placeholder="Search" @search="search()" />
      <div class="d-flex">
        <button class="btn excel-btn mr-3" @click="exportExcel">
          <img height="20" src="@/assets/icons/excel-icon.svg" />
          {{ $t('action.export_excel')}}
        </button>
        <MyDateRangePicker :params="params" @select="selectDate"/>
      </div>
    </div>
    <table v-if="!loading" class="table my-border-table">
      <thead>
        <tr>
          <th width="50"></th>
          <th>Date</th>
          <th>Time</th>
          <th>Invoice/Ref No.</th>
          <th>Info</th>
          <th>Tax ID</th>
          <th>COA</th>
          <th>Name</th>
          <th>Type</th>
          <th>Debit</th>
          <th>Credit</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(report,index) in reports" :key="index">
          <td class="text-center">{{ index + 1 }}</td>
          <td>{{ moment(report.created_at).format('DD MMM, YYYY') }}</td>
          <td>{{ moment(report.created_at).format('hh:ss A') }}</td>
          <td>{{ report.ref1 }}</td>
          <td>{{ report.info }}</td>
          <td>{{ report.tax_id }}</td>
          <td>{{ report.coa }}</td>
          <td>{{ report.coa_name}}</td>
          <td>{{ report.type }}</td>
          <td>{{ report.debit }}</td>
          <td>{{ report.credit }}</td>
        </tr>
      </tbody>
    </table>
    <div v-else class="loading">
      <van-loading type="spinner" />
    </div>
  </van-popup>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      moment,
      show : false,
      loading : false,
      params : {
        keyword : '',
        type : 'all',
        page : 1,
        limit : 50,
        start_date : moment().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date : moment().format('YYYY-MM-DD'),
      },
      name : null,
      coa : null,
      reports : []
    }
  },
  methods : {
    search() {
      this.params.page = 1
      this.getData()
    },
    selectDate(dates) {
      this.params.start_date = moment(dates.startDate).format('YYYY-MM-DD')
      this.params.end_date = moment(dates.endDate).format('YYYY-MM-DD')
      this.params.page = 1
      this.getData()
    },
    async getData() {
      this.loading = true;
      let res = await axios.get('/transactions', { params : this.params })
      if(res) {
        this.reports = res.data.data.data
        this.loading = false;
      }
    },
    open(code) {
      this.name = code.name
      this.coa = code.coa
      this.show = true;
      this.params.coas = [code.coa]
      this.getData()
    },
    async exportExcel() {
      let res = await axios.get('/transactions/export_request/', { params : this.params })
      if (res.data.data) {
        let file_url = res.data.data.redirect_url
        window.open(file_url)
      }
    },
  },
  computed: {
    style() {
      return {
        width: '90%', 
        height : '90%',
        padding : '20px',
        border: '2px solid #F4F4F4',
        'border-radius' : '8px',
        'box-shadow' : '-10px 4px 34px rgba(0, 0, 0, 0.09)',
      };
    }
  }
}
</script>

<style lang="scss" scoped>
  h3 {
    font-size : 14px;
    font-weight: 700;
  }
  .excel-btn {
    background-color: #1d6f42;
    color: #fff;
    padding: 0px 17px 0px 13px;
    height: 37px !important;
    font-weight: 400;

    img {
      margin-right: 5px;
    }

    &:hover {
      background-color: #289256;
      color: #fff;
    }
  }
  .table.my-border-table {
    margin-bottom : 0;
    border-spacing: 0;
    thead {
      th {
        background-color: #F3F3F3;
        border-radius : 0;
        font-weight: 400;
        padding : 11px 20px;
        font-size : 12px;
        &:first-child {
          border-top-left-radius: 10px;
        }
        &:last-child {
          border-top-right-radius: 10px;
        }
      }
    };
    tbody {
      td {
        border-radius : 0;
        border: 1px solid #F3F3F3;
        &:first-child {
          background-color : #F3F3F3;;
        }
        &:last-child {
          border-right-width: 2px;
        }
      }
      tr:last-child {
        td {
          border-bottom-width: 2px;
          &:first-child {
            border-bottom-left-radius: 10px;
          }
          &:last-child {
            border-bottom-right-radius: 10px;
          }
        }
      }
    }
  }
</style>

<style lang="scss">
.report-detail {
  .van-popup__close-icon {
    font-size: 20px !important;
  }
}
</style>