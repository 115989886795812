<template>
  <div>
    <vSelect
        :value="value"
        :options="getOptions()"
        label="label"
        :reduce="(option) => option.value"
        :clearable="false"
        @input="pushData"
    >
      <template #selected-option="{ label }">
        <div :class="['data', `text-${align}`]">{{ label }}</div>
      </template>
      <template #option="{ label }">
        <div :class="['data', `text-${align}`]">{{ label }}</div>
      </template>
      <template #open-indicator="{ attributes }">
      <span v-bind="attributes">
        <svg width="15" height="14" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M15.7704 6.64453L10.6088 11.485C9.99921 12.0567 9.00171 12.0567 8.39213 11.485L3.23047 6.64453"
              stroke="rgba(0,0,0,0.2)"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
        </svg>
      </span>
      </template>
    </vSelect>
  </div>

</template>

<script>
import vSelect from 'vue-select'
export default {
  components: { vSelect },
  props: ['value', 'align', 'type'],
  data() {
    return {
      typeOptions : [
        { label : 'Fix Amount Discount', value : 'fixed'},
        { label : 'Percentage Discount', value : 'percent'},
      ],
      productOptions : [
        { label : 'All Products', value : 'all'},
        { label : 'Specific Product', value : 'choice'},
      ],
      customerOptions : [
        { label : 'All Customers', value : 'all'},
        { label : 'Specific Customer', value : 'choice'},
      ],
    }
  },
  methods: {
    pushData(e) {
      this.$emit('input', e)
    },
    getOptions() {
      return this.type == 'type' ? this.typeOptions 
        : this.type == 'product' ? this.productOptions 
        : this.customerOptions
    }
  },
}
</script>

<style lang="scss" scoped>
.data {
  width: 100%;
}
</style>