<template>
  <div style="min-width: 500px; max-height: 600px">
    <div v-if="product.type === 'group'" class="opt-group">
      <h4 class="text-bold">{{ selectedProduct.name }}</h4>
      <section class="sub-product-cont">
        <div
          v-for="subProd in subProducts"
          :key="subProd.id"
          class="img-cont"
          :class="{ 'active-prod': subProd.id === selectedProduct.id }"
          @click="selectProduct(subProd)"
        >
          <img :src="subProd.images[0]" />
          <h6 class="mt-2 mb-4">{{ $currency }} {{ subProd.price }}</h6>
        </div>
      </section>
    </div>

    <div v-if="selectedProduct.has_units" class="opt-group">
      <h4 class="text-bold">{{ selectedProduct.name }}</h4>
      <h5 class="mt-3">Variations</h5>
      <van-radio-group v-model="selectedUnit">
        <div v-for="(unit, idx) in selectedProduct.units" :key="'option-item' + idx">
          <div class="package align-items-center">
            <div class="package-wrapper">
              <div class="package-title">
                <h6 class="text-bold mb-0">{{ unit.unit }}</h6>
                <p class="mt-1 mb-0" v-if="unit.description">
                  {{ unit.description }}
                </p>
              </div>

              <div class="d-flex align-items-center">
                <h6 class="text-bold mr-3 mb-0">
                  <span v-if="unit.add_price">+</span>
                  <span> {{ $currency }} {{ $cast(unit.sale_price) }}</span>
                </h6>
                <p class="mb-0">
                  <van-radio :name="unit" label-position="right"></van-radio>
                </p>
              </div>
            </div>
          </div>
        </div>
      </van-radio-group>
    </div>
    <div class="modal-foot">
      <div>
        <h6 class="gray">Total Price</h6>
        <h4 class="text-bold">{{ $currency }} {{ $cast(totalPrice * quantity) }}</h4>
      </div>
      <van-button class="btn btn-primary text-white add-to-pay" @click="addToCart">Add To Pay</van-button>
      <div>
        <div :style="{ width: '5rem', height: '5rem' }"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'product-modal',
  props: ['product', 'quantity'],
  data: () => ({
    options: {},
    selectedProduct: {},
    selectedUnit: {},
    subProducts: null,
  }),
  computed: {
    totalPrice: function () {
      return this.getProductPrice()
    },
  },

  mounted() {
    if (this.product.type === 'group') {
      this.fetchData()
    }
    this.setDefaultSelectedOptions(this.product)
  },

  methods: {
    async selectProduct(prod) {
      this.selectedProduct = prod
      this.selectedUnit = prod.units[0] ?? null
    },

    async fetchData() {
      let res = await axios.get(`pos/products/${this.product.id}/sub_products`)
      if (res.data.data) {
        this.subProducts = res.data.data
        let product = this.subProducts.length > 0 ? this.subProducts[0] : null
        this.selectedProduct = product
        this.selectedUnit = product.units[0] ?? null
      } else {
        console.error('Error in getting group product : ', res.data)
      }
    },

    setDefaultSelectedOptions(product) {
      this.selectedProduct = product
      this.selectedUnit = product.units[0] ?? null
    },

    getProductPrice() {
      let price = 0
      if (this.selectedUnit && this.selectedUnit.unit && this.selectedUnit.sale_price) {
        if (this.selectedUnit.add_price) {
          price = (this.selectedUnit.sale_price || 0) + this.selectedProduct.price
        } else {
          price = this.selectedUnit.sale_price || 0
        }
      } else {
        price = +this.selectedProduct.price
      }
      return price
    },

    addToCart() {
      this.selectedProduct.price = this.totalPrice
      let product = {
        ...this.selectedProduct,
        quantity: this.quantity,
        unit: this.selectedUnit,
      }

      this.$store.commit('addItem', product)
      this.$emit('close')
    },

    getCoefficient() {
      let unit = this.selectedUnit
      return unit && unit.coefficient ? unit.coefficient : 1
    },
  },
}
</script>
<style scoped>
/* product modal */
h4 {
  margin: 10px 0 5px 0;
}
.sub-product-cont {
  display: flex;
  align-items: center;
  gap: 15px;
}
.img-cont {
  width: 8rem;
  height: 8rem;
  border: 2px solid #e2e2e2;
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 20px;
}
.active-prod {
  border: 2px solid var(--theme);
}
.img-cont img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.package {
  margin: 10px 0;
  padding: 15px 20px;
  background-color: #f7f7f7;
  border-radius: 15px;
}

.package-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.opt-title {
  flex: 1;
}

.add-to-pay {
  padding: 18px 25px;
}

.modal-foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.options-container {
  max-height: 400px;
  overflow: auto;
}
</style>
