<template>
  <div>
    <div class="row">
      <div
        class="col-md-3 text-center"
        v-for="(printer, index) in printers"
        :key="printer.name"
        @click="selectPrinter(printer, index)"
      >
        <img class="mt-3 printer" src="/image/settings/printer.svg" alt="" />

        <h5 class="mt-3 d-flex align-items-center justify-content-center">
          <van-icon class="active" name="checked" color="#4CAF50" size="25" v-if="printer.active" />
          <span>{{ printer.displayName }}</span>
        </h5>
      </div>
    </div>
  </div>
</template>


<script>
import isElectron from 'is-electron'
const { ipcRenderer } = isElectron() ? require('electron') : {}

export default {
  data() {
    return {
      electron: isElectron(),
      printers: [],
      printerName: null,
    }
  },
  mounted() {
    const printerName = window.localStorage.getItem('PrinterName')
    this.printerName = printerName
    try {
      if (isElectron()) {
        this.getPrinters()
        ipcRenderer.on('printer-list', this.listPrinters)
      }
    } catch (error) {}
  },
  methods: {
    selectPrinter(printer, index) {
      this.printers = this.printers.map((printer) => {
        printer.active = false
        return printer
      })
      this.printers[index].active = true
      this.printerName = printer.name
      window.localStorage.setItem('PrinterName', printer.name)
    },

    listPrinters(event, arg) {
      this.printers = JSON.parse(arg).map((printer) => {
        printer.active = printer.name == this.printerName ? true : false
        return printer
      })

      // if (!this.printerName) {
      //   if (this.printers.length > 0) {
      //     this.selectPrinter(this.printers[0], 0)
      //   }
      // }
    },

    getPrinters() {
      ipcRenderer.send('getPrinters')
    },
  },
}
</script>

<style scoped>
.printer {
  cursor: pointer;
}
.active {
  margin-right: 3px;
}
</style>