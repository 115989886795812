<template>
  <div></div>
</template>

<script>
export default {
  created() {
    if (this.$auth.check()) {
      this.$router.push('/pos')
    } else {
      this.$router.push('/login')
    }
  },
}
</script>

<style>
</style>