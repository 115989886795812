<template>
  <div>
    <div v-if="$auth.user().user_type == 'admin'" class="plan mt-4">
      <h1 class="mb-4">{{ $t('setting.profile.currentPlan') }}</h1>
      <div class="d-flex justify-content-between mt-2 align-items-center plan-wrap">
        <div class="d-flex align-items-center">
          <img
            :alt="`${$auth.user().plan} plan`"
            :src="`/image/settings/accounts/${$auth.user().plan}.svg`"
            width="55"
          />
          <div class="ml-3 plan-info">
            <h3>{{ $auth.user().plan }}</h3>
            <div v-if="$auth.user().plan === 'bronze'">
              <div>
                <span>{{ $t('setting.profile.lifetimeFree') }}</span>
              </div>
            </div>
            <div v-else class="d-flex">
              <div>
                <span v-if="$auth.user().expired_date">{{ $t('setting.profile.expireAt') }} </span>
                <br v-if="$auth.user().expired_date" />
                <b>{{ $auth.user().expired_date ? $auth.user().expired_date : 'Never Expire' }}</b>
              </div>
            </div>
          </div>
        </div>
        <div>
          <button class="btn btn-primary py-3" @click="$router.push('/pricing')">
            {{ $t('setting.profile.changePlan') }}
          </button>
        </div>
      </div>
    </div>
    <div class="bg-white mt-4">
      <PaymentLog />
    </div>
  </div>
</template>

<script>
import PaymentLog from './my-accounts/components/PaymentLog'
export default {
  components: { PaymentLog },
}
</script>

<style lang="scss" scoped>
.plan {
  h3 {
    text-transform: capitalize;
    margin-bottom: 3px;
    font-weight: bold;
    font-size: 18px;
  }
  .plan-wrap {
    max-width: 40rem;
    margin-bottom: 30px;
    background-color: rgba(0, 0, 0, 0.06);
    padding: 10px 20px;
    border-radius: 15px;
  }
  .plan-info {
    .d-flex {
      gap: 20px;

      b {
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }

  span {
    color: rgba(0, 0, 0, 0.5);
  }

  .btn-primary {
    font-weight: 500;
    padding: 0 25px;
    height: auto;
  }
}
</style>