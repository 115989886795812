<template>
  <div>
    <h2 class="mt-5">Summary</h2>
    <div class="mt-3 ml-3">
      <div class="row">
        <div class="col-6">
          <label class="label">Created</label>
          <div class="summary-text">Oct 3, 2021</div>
        </div>
        <div class="col-6">
          <label class="label">Last Visited</label>
          <div class="summary-text">2 Days ago</div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-6">
          <label class="label">Visit Frequency</label>
          <div class="summary-text">3 visits a months</div>
        </div>
        <div class="col-6">
          <label class="label">Avg Spend</label>
          <div class="summary-text">$100 - $1500</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style scoped>
  h2 {
    font-size: 14px;
    font-weight: 700;
    color: #000;
  }
  .label {
    font-size: 12px;
    margin-top: 5px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.5);
  }
  .summary-text {
    font-size: 13px;
  }
</style>
