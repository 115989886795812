<template>
  <div class="mt-2">
    <div v-if="data.length > 0">
      <!-- === for bank accounts (container)=== -->
      <div v-if="type === 'bank-account'">
        <div
          class="supplier-container"
          :class="[d.id > 0 ? '' : 'disabled']"
          v-for="(d, index) in data"
          :key="index"
          @click="redirectTo(d.id)"
        >
          <div class="d-flex">
            <img src="/image/settings/accounts/bank-account-logo.svg" class="supplier-profile" />
            <div class="ml-3">
              <h3>{{ d.owner_name }}</h3>
              <div class="account-number" v-if="d.id > 0"> {{ $t('mngmt.bankAccount.accountNumber', {accountNumber: d.account_number})}}</div>
              <div class="account-number" v-else>{{ $t('mngmt.bankAccount.defaultAccount')}}</div>
            </div>
          </div>
          <div class="text-right">
            <div class="balance">{{$t('mngmt.bankAccount.balance')}}</div>
            <div class="balance-number">{{ $currency }} {{ $cast(d.current_balance) }}</div>
          </div>
        </div>
      </div>

      <!-- === for customer and supplier accounts (container) === -->
      <div v-else>
        <div
          class="supplier-container"
          v-for="(d, index) in data"
          :key="index"
          @click="
            type === 'customer'
              ? $router.push({ path: `/management/customers/edit/${d.id}` })
              : $router.push({ path: `/management/suppliers/edit/${d.id}` })
          "
        >
          <div class="d-flex">
            <img :src="d.image" class="supplier-profile" />
            <div class="ml-3">
              <h3>{{ d.name }}</h3>
              <div class="account-number">
                {{ d.email ? d.email[0] : '' }}
                {{ d.phone ? ', ' + d.phone[0] : '' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <van-empty
        :image="`/image/empty-lists/empty-${type}.svg`"
        :description="
          type == 'bank-account' ? $t('mngmt.bankAccount.emptyAccount') : type == 'customer' ? $t('mngmt.customer.emptyCustomer') : $t('mngmt.supplier.emptySupplier')
        "
      />
    </div>
  </div>
</template>
<script>
export default {
  props: ['data', 'type'],
  methods: {
    redirectTo(id) {
      if (id == 0) {
        return false
      } else {
        this.$router.push({ path: `/management/bank-accounts/edit/${id}` })
      }
    },
  },
}
</script>
<style scoped>
.supplier-profile {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.supplier-container {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border: 2px solid #f4f4f4;
  border-radius: 10px;
  padding: 8px 15px;
  margin-bottom: 10px;
}
.disabled {
  cursor: not-allowed;
}
.supplier-container:hover {
  background-color: #f4f4f4;
}
h3 {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin-bottom: 3px;
}
.account-number {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
}
.balance {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}
.balance-number {
  font-size: 15px;
  letter-spacing: 0.8px;
  color: #000;
  font-weight: 700;
}
</style>
