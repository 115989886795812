<template>
  <span class="cp-btn">
<svg v-if="status=== 'idle'" fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"
     @click="copy">
<path d="M17 13.4V16.4C17 20.4 15.4 22 11.4 22H7.6C3.6 22 2 20.4 2 16.4V12.6C2 8.6 3.6 7 7.6 7H10.6" stroke="#4B4B4D"
      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
<path d="M17 13.4H13.8C11.4 13.4 10.6 12.6 10.6 10.2V7L17 13.4Z" stroke="#4B4B4D" stroke-linecap="round"
      stroke-linejoin="round" stroke-width="1.5"/>
<path d="M11.6 2H15.6" stroke="#4B4B4D" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
<path d="M7 5C7 3.34 8.34 2 10 2H12.62" stroke="#4B4B4D" stroke-linecap="round" stroke-linejoin="round"
      stroke-width="1.5"/>
<path d="M21.9999 8V14.19C21.9999 15.74 20.7399 17 19.1899 17" stroke="#4B4B4D" stroke-linecap="round"
      stroke-linejoin="round" stroke-width="1.5"/>
<path d="M22 8H19C16.75 8 16 7.25 16 5V2L22 8Z" stroke="#4B4B4D" stroke-linecap="round" stroke-linejoin="round"
      stroke-width="1.5"/>
</svg>

 <svg v-else fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
<path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#A5A5A5"
      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
<path d="M7.75 12L10.58 14.83L16.25 9.17004" stroke="#A5A5A5" stroke-linecap="round" stroke-linejoin="round"
      stroke-width="1.5"/>
</svg>




      </span>

</template>

<script>
export default {
  data: () => ({
    status: 'idle',
  }),
  props: {
    textValue: {
      type: String,
      required: true,
    },
  },
  methods: {
    copy: function () {
      navigator.clipboard.writeText(this.textValue).then(() => {
        this.status = 'success';
        setTimeout(() => {
          this.status = 'idle';
        }, 2000)
      }, function (err) {
        console.error('Could not copy text: ', err);
      });
    }
  }
}
</script>

<style lang="scss">

.cp-btn {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

</style>