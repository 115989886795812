<template>
  <div>
    <table>
      <tr>
        <th>{{ $t('mngmt.voucher.accountCode') }}</th>
        <th>{{ $t('mngmt.voucher.accountName') }}</th>
        <th>{{ $t('amount') }}</th>
        <th>{{ $t('tax') }}(%)</th>
        <th>{{ $t('mngmt.voucher.taxExclusive') }}</th>
        <th>{{ $t('total') }}</th>
      </tr>
      <div v-for="(voucher, idx) in params.vouchers" :key="idx" class="row-gp">
        <tr>
          <td>
            <v-select
                v-model="voucher.coa"
                :clearable="false"
                :options="coaOptions"
                :reduce="(option) => option"
                class="select-box"
                @input="selectAsset(voucher)"
                @search="(keyword) => debounce(() => getCOAs(keyword), 500)"
            >
            </v-select>
            <small class="text-danger">{{ error[`vouchers.${idx}.coa`] }}</small>
          </td>
          <td>
            <input v-model="voucher.coa_name" class="input" readonly/>
            <small class="text-danger">{{ error[`vouchers.${idx}.coa_name`] }}</small>
          </td>
          <td>
            <input v-model="voucher.amount" class="input" type="number"/>
          </td>
          <td>
            <input v-model="voucher.tax" class="input" type="number"/>
          </td>
          <td>
            <div class="border-wrap">
              <van-switch v-model="voucher.tax_type" active-value="exclusive" inactive-value="inclusive" size="20"/>
            </div>
          </td>
          <td>
            <input :value="getEachTotal(voucher)" class="input" readonly/>
          </td>
          <td>
            <van-icon color="red" name="delete-o" size="20" @click="removeVoucher(idx)"/>
          </td>
        </tr>
        <tr>
          <td class="desc-td" colspan="6">
            <input v-model="voucher.description" :placeholder="$t('mngmt.voucher.description')" class="input w-100"/>
          </td>
        </tr>
      </div>
    </table>
    <div class="py-3 text-bold pointer" @click="addVoucher">
      <van-icon name="plus" size="15"/>
      {{ $t('mngmt.voucher.addVoucher') }}
    </div>
    <div class="d-flex justify-content-end total-section-wrap">
      <section class="total-section">
        <div>
          <div>{{ $t('subtotal') }}</div>
          <div>-</div>
          <div>{{ $toFixed(getPrice().subTotal, 2) }}</div>
        </div>
        <div>
          <div>{{ $t('tax') }}</div>
          <div>-</div>
          <div>{{ $toFixed(getPrice().tax, 2) }}</div>
        </div>
        <div class="text-bold">
          <div>{{ $t('mngmt.voucher.netTotal') }}</div>
          <div>-</div>
          <div>{{ $toFixed(getPrice().grandTotal, 2) }}</div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'voucher',
  props: ['params', 'error'],
  data: () => ({
    coaOptions: [],
    reqParams: {
      page: 1,
      limit: 20,
      keyword: '',
    },
    timer: null,
  }),
  methods: {
    debounce(fn, timeout) {
      clearTimeout(this.timer);
      this.timer = setTimeout(
          fn,
          timeout
      )
    },
    async getCOAs(keyword) {
      this.reqParams.keyword = keyword || '';
      let res = await axios.get('chart_of_accounts', {params: this.reqParams})
      if (res.data.success) {
        this.coaOptions = res.data.data.data.map(data => ({label: data.coa, coa: data.coa, name: data.name}))
      }
    },
    addVoucher() {
      this.params.vouchers.push({
        coa: '',
        coa_name: '',
        amount: 0,
        tax_type: 'inclusive',
        description: '',
        tax: 0,
      })
    },
    removeVoucher(idx) {
      this.params.vouchers.splice(idx, 1);
    },
    selectAsset(voucher) {
      voucher.coa_name = voucher.coa.name
      voucher.coa = voucher.coa.coa
    },
    getEachTotal(voucher) {
      let total = +voucher.amount;
      if (voucher.tax_type === 'exclusive') {
        total += +voucher.tax * 0.01 * +voucher.amount;
      }
      total = total.toFixed(2)
      voucher.total = total;
      return total
    },
    getPrice() {
      let tax = 0
      let subTotal = 0
      let grandTotal = 0
      this.params.vouchers.forEach(v => {
        if (v.tax_type == 'exclusive') {
          subTotal += Number(v.amount)
          tax += Number(v.amount) * Number(v.tax) * 0.01
          grandTotal += Number(v.amount) + Number(v.amount) * Number(v.tax) * 0.01
        } else {
          grandTotal += Number(v.amount)
          subTotal += Number(v.amount) / ((100 + Number(v.tax)) * 0.01)
          tax += (Number(v.amount) / ((100 + Number(v.tax)) * 0.01)) * Number(v.tax) * 0.01
        }
      })
      this.params.grand_total = grandTotal;
      return {subTotal, tax, grandTotal}
    },
  },
  mounted() {
    this.getCOAs()
  }
}
</script>

<style lang="scss" scoped>
table {
  td, th {
    padding: 7px 10px 7px 0;
  }

  .row-gp {
    display: table-row-group;
    border-bottom: 2px solid #f6f6f6;
    padding: 10px 0;
  }

  .desc-td {
    padding-top: 0;
  }

  td {
    padding-bottom: 10px;
  }

  .border-wrap {
    border: 2px solid #f6f6f6;
    margin-bottom: -15px;
    padding: 8px 10px 8px 18px;
    border-radius: 10px;
  }
}

.total-section {
  display: table;
  text-align: right;
  font-size: 15px;


  & > div {
    display: table-row;

    & > div {
      display: table-cell;
      padding: 4px 7px;
    }

  }
}

.total-section-wrap {
  max-width: 1100px;
}
</style>