<template>
  <div>
    <Input :params="params" :errors="errors" field="group_name" :label="$t('mngmt.product.groupName')"/>

    <div v-if="params.id" class="my-3">
      <label class="label">{{ $t('mngmt.product.subProduct')}}</label>
      <div @click="$emit('addSubProduct')" class="sub-product-btn">
        <svg width="10" height="10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="7.125" y1="1" x2="7.125" y2="12.75" stroke="black" stroke-width="2" stroke-linecap="round"/>
          <path d="M1 6.75L13 6.75" stroke="black" stroke-width="2" stroke-linecap="round"/>
        </svg>
        <span class="ml-2">{{ $t('mngmt.product.subProduct')}}</span>
      </div>
    </div>

    <div class="my-3">
      <h6>{{ $t('mngmt.product.selling')}}</h6>
      <van-switch v-model="params.enable_selling" size="20"/>
    </div>
  </div>
</template>

<script>
export default {
  props : ['params', 'errors']
}
</script>

<style lang="scss" scoped>
  .sub-product-btn {
    width: 100%;
    border: 2px solid #f4f4f4;
    border-radius: 10px;
    padding: 10px 15px;
    font-size: 12px;
    color: #000;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content : center;
    cursor : pointer;
  }
</style>