<template>
  <div class="bg-white">
    <div v-if="!editId || loaded">
      <h1 @click="$router.push({ path: '/management/employees/list' })">
        <van-icon class="mr-3" name="arrow-left" size="15px" />
        <span v-if="editId">Edit {{ params.name }}</span>
        <span v-else>{{ $t('mngmt.employee.addNewEmployee')}}</span>
      </h1>
      <div class="col-lg-9 mx-auto">
        <FormCard :data="params" :errors="errors" :isNew="editId ? false : true" />
        <div class="ml-3">
          <Button :type="editId ? 'edit' : 'create'" :loading="loading" name="Employee" @click.native="saveData()" />
          <Button v-if="editId" type="delete" :dloading="dloading" name="Employee" @click.native="deleteData()" />
        </div>
      </div>
    </div>
    <div v-else class="loading">
      <van-loading type="spinner" />
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
import FormCard from './components/FormCard'
export default {
  components: { FormCard },
  data() {
    return {
      loaded: false,
      loading: false,
      dloading: false,
      params: {
        name: '',
        tax_id: '',
        email: '',
        base_salary: 0,
        wht: 0,
        social_sec_fund: 0,
        wk_time_from: '',
        wk_time_to: '',
        employee_id: '',
        image: '',
        social_sec_number: '',
        phone: '',
        address: '',
      },
      errors: {},
      editId: null,
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.editId = this.$route.params.id
      this.fetchData()
    }
  },
  methods: {
    async saveData() {
      //Client side error checks
      if (+this.params.wht > 70) {
        this.errors = {...this.errors, wht: "can't be more than 70%"}
        return;
      }
      this.loading = true
      let formData = this.appendFormData()
      let res =
        this.editId && this.$route.params.submitType == 'edit'
          ? await axios.post(`/employees/${this.editId}?_method=PUT`, formData)
          : await axios.post('employees', formData)
      if (res) {
        if (res.data.code == 422) {
          this.errors = res.data.error
        } else {
          this.clearData()
          Toast.success(this.editId ? 'Updated' : 'Created')
          this.$router.back()
        }
        this.loading = false
      }
    },
    async deleteData() {
      this.dloading = true
      await Dialog.confirm({
        title: this.$t('action.areYouSure'),
        cancelButtonText: this.$t('action.cancel'),
        confirmButtonText: this.$t('action.del'),
      })
      try {
        const res = await axios.delete(`/employees/${this.editId}?force=0`)
        if (res) {
          Toast.success('Deleted')
          this.$router.back()
        }
      } catch (error) {
        Toast.fail("Can't Delete!")
      }
      this.dloading = false
    },
    appendFormData() {
      let fd = new FormData()
      let params = Object.entries(this.params)
      params.forEach((data) => {
        if (data[1]) {
          fd.append(data[0], data[1])
        }
      })
      return fd
    },
    async fetchData() {
      try {
        const res = await axios.get(`/employees/${this.editId}`)
        if (res) {
          this.params = res.data.data
          if (this.$route.params.submitType != 'edit') {
            this.params.employee_id = null
          }
          this.loaded = true
        }
      } catch (error) {
        console.log(error)
      }
    },
    clearData() {
      this.params = {
        name: null,
        tax_id: null,
        email: null,
        base_salary: null,
        wht: null,
        social_sec_fund: null,
        wk_time_from: null,
        wk_time_to: null,
        employee_id: null,
        image: null,
        social_sec_number: null,
        phone: null,
        address: null,
      }
    },
  },
}
</script>

<style scoped>
h1 {
  cursor: pointer;
}
</style>
