<template>
  <div class="pm-left bg-white">
    <van-divider class="mb-0 mt-0" content-position="left">
      <small>{{ $t('setting.store') }}</small>
    </van-divider>

    <router-link to="/settings/my-store">
      <div
        class="col-11 nav-left-items mt-2"
        :class="linkIsActive('/settings/my-store') ? 'nav-left-items-active' : ''"
      >
        <div class="d-flex nav-left-items-container">
          <div class="pm-manage-companies-icons mr-3"></div>
          <p class="pt-1 text-dark pb-0 mb-0">{{ $t('setting.myStore') }}</p>
        </div>
      </div>
    </router-link>
    <!-- TODO: hide for first release
    <router-link to="/settings/companies/list" v-if="$auth.user().user_type == 'admin'">
      <div
        class="col-11 nav-left-items mt-2"
        :class="linkIsActive('/settings/companies/') ? 'nav-left-items-active' : ''"
      >
        <div class="d-flex nav-left-items-container">
          <div class="pm-manage-companies-icons mr-3"></div>
          <p class="pt-1 text-dark pb-0 mb-0">Manage Stores</p>
        </div>
      </div>
    </router-link>
    -->
    <router-link to="/settings/language">
      <div
          class="col-11 nav-left-items mt-2"
          :class="linkIsActive('/settings/language') ? 'nav-left-items-active' : ''"
      >
        <div class="d-flex nav-left-items-container">
          <div class="language-icon mr-3"></div>
          <p class="pt-1 text-dark pb-0 mb-0">{{ $t('language') }}</p>
        </div>
      </div>
    </router-link>

    <router-link to="/settings/appearance">
      <div
        class="col-11 nav-left-items mt-2"
        :class="linkIsActive('/settings/appearance') ? 'nav-left-items-active' : ''"
      >
        <div class="d-flex nav-left-items-container">
          <div class="pm-appearances-icons mr-3"></div>
          <p class="pt-1 text-dark pb-0 mb-0">{{ $t('setting.appearance') }}</p>
        </div>
      </div>
    </router-link>

    <router-link to="/settings/printer" v-if="electron">
      <div class="col-11 nav-left-items mt-2" :class="linkIsActive('/settings/printer') ? 'nav-left-items-active' : ''">
        <div class="d-flex nav-left-items-container">
          <div class="printer-icon mr-3"></div>
          <p class="pt-1 text-dark pb-0 mb-0">{{ $t('setting.posPrinter') }}</p>
        </div>
      </div>
    </router-link>

    <van-divider class="mb-0 mt-0" content-position="left">
      <small>{{ $t('setting.account') }}</small>
    </van-divider>

    <router-link to="/settings/my-accounts">
      <div
        class="col-11 nav-left-items mt-2"
        :class="linkIsActive('/settings/my-accounts') ? 'nav-left-items-active' : ''"
      >
        <div class="d-flex nav-left-items-container">
          <div class="pm-my-accounts-icons mr-3"></div>
          <p class="pt-1 text-dark pb-0 mb-0">{{ $t('setting.myProfile') }}</p>
        </div>
      </div>
    </router-link>

    <router-link to="/settings/my-plan">
      <div class="col-11 nav-left-items mt-2" :class="linkIsActive('/settings/my-plan') ? 'nav-left-items-active' : ''">
        <div class="d-flex nav-left-items-container">
          <div class="my-plan-icon mr-3"></div>
          <p class="pt-1 text-dark pb-0 mb-0">{{ $t('setting.myPlan') }}</p>
        </div>
      </div>
    </router-link>

    <!-- TODO: hide for first release
    <van-divider class="mb-0 mt-0" content-position="left">
      <small>{{$t('setting.developer')}}</small>
    </van-divider>

    <router-link to="/settings/api-keys">
      <div
        class="col-11 nav-left-items mt-2"
        :class="linkIsActive('/settings/api-keys') ? 'nav-left-items-active' : ''"
      >
        <div class="d-flex nav-left-items-container align-items-center">
          <van-icon name="cluster-o" class="mr-3" color="black" size="24" />
          <p class="pt-1 text-dark pb-0 mb-0">{{$t('setting.apiKey')}}</p>
        </div>
      </div>
    </router-link>

    <a href="https://developer.manawstore.com" target="blank">
      <div class="col-11 nav-left-items mt-2">
        <div class="d-flex nav-left-items-container align-items-center">
          <van-icon name="description" class="mr-3" color="black" size="24" />
          <p class="pt-1 text-dark pb-0 mb-0">{{$t('setting.doc')}}</p>
        </div>
      </div>
    </a>
    -->
  </div>
</template>

<script>
import isElectron from 'is-electron'
export default {
  data() {
    return {
      electron: isElectron(),
    }
  },
  methods: {
    linkIsActive(link) {
      const paths = Array.isArray(link) ? link : [link]
      let res = paths.some((path) => {
        // current path starts with this path string
        return this.$route.path.indexOf(path) === 0
      })
      if (res) {
        return true
      }
      return false
    },
  },
}
</script>

<style scoped>
.pm-left {
  min-width: 250px;
  max-width: 250px;
  border-radius: 10px;
  padding: 10px 0px;
  margin-right: 15px;
}
.nav-left-items {
  height: 45px;
  margin: auto;
  position: relative;
  cursor: pointer;
}
.nav-left-items-container {
  position: absolute;
  top: 21%;
}
.nav-left-items-active,
.nav-left-items:hover {
  background: rgba(var(--theme-rgba), 0.07);
  border-radius: 10px;
}
.pm-appearances-icons {
  height: 25px;
  width: 25px;
  background-image: url('/image/settings/appearances.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
.language-icon {
  height: 25px;
  width: 25px;
  background-image: url('/image/settings/language.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
.my-plan-icon {
  height: 25px;
  width: 25px;
  background-image: url('/image/settings/plan.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
.pm-manage-companies-icons {
  height: 25px;
  width: 25px;
  background-image: url('/image/settings/manage-companies.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
.pm-my-accounts-icons {
  height: 25px;
  width: 25px;
  background-image: url('/image/settings/my-accounts.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
.pm-about-us-icons {
  height: 25px;
  width: 25px;
  background-image: url('/image/settings/about-us.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
.printer-icon {
  height: 25px;
  width: 25px;
  background-image: url('/image/settings/printer.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
