<template>
  <div class="footer-section">
    <div class="container">
      <div class="footer-top-section">
        <div class="com-logo">
          <img src="@/assets/logo/bilions-logo.svg" />
          <div class="social-icons">
            <a href="#" class="facebook">
              <img src="@/assets/social/facebook-icon.svg" alt="" />
            </a>
            <a href="#" class="linkedin">
              <img src="@/assets/social/linkedin-icon.svg" alt="" />
            </a>
            <a href="#" class="twitter">
              <img src="@/assets/social/twitter.svg" alt="" />
            </a>
          </div>
        </div>
        <div class="com-address">
          <h3 class="f-n">
            <span><img src="@/assets/utils/location-pin.svg" alt="" /></span>
            Bangkok, Thailand
          </h3>
          <h3 class="f-n">
            <span><img src="@/assets/utils/mail.svg" alt="" /></span>
            hello@bilions.org
          </h3>
        </div>
        <div class="link">
          <h3>Links</h3>
          <ul>
            <li><router-link to="/support">Support</router-link></li>
            <li><router-link to="/pricing">Pricing</router-link></li>
            <li><router-link to="/terms-and-conditions">Terms & Conditions</router-link></li>
          </ul>
        </div>
        <div class="contnet-us">
          <h3>Stay connected with us</h3>
          <input type="text" placeholder="Your e-mail address" />
          <button class="btn-web-primary">Subscribe</button>
        </div>
      </div>

      <div class="copy-right text-center">
        <p class="mb-0">Copyright @ <a href="#">bilions.org.</a> All Right Reserved</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.footer-section {
  width: 100%;
  background-color: #fff;
  padding-top: 2rem;
  border-top: 2px solid #e8e8f7;
}

.footer-top-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 30px;
  gap: 15px;
}

.social-icons {
  margin-top: 20px;
  display: flex;
  gap: 15px;
  align-items: center;
}
.com-logo a > img {
  width: 20px;
  height: 20px;
}

a,
a:hover {
  text-decoration: none;
}

.com-address span {
  margin-right: 10px;
}
.footer-section h3 {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  margin-top: 10px;
}

.link ul {
  list-style: none;
}

.link ul li {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  opacity: 0.7;
  color: #000;
}
.link a {
  color: #000;
  cursor: pointer;
}
.link ul li:hover {
  opacity: 1;
}

.contnet-us input {
  background: #f2f2f2;
  border: none;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 14px;
  margin-top: 8px;
  width: 60%;
}

.contnet-us button {
  padding: 15px 25px;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  margin-left: 5px;
}

.copy-right {
  padding: 30px 10px 15px;
}
.copy-right p,
.copy-right a {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;

  color: #444;
}

@media screen and (max-width: 991px) {
  .com-logo,
  .com-address,
  .link {
    width: 50%;
  }

  .contnet-us {
    width: 50%;
  }
}
@media screen and (max-width: 770px) {
  .contnet-us {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .contnet-us input {
    margin-bottom: 15px;
    display: block;
    width: 100%;
  }
  .contnet-us button {
    margin-left: 0;
  }
}
@media screen and (max-width: 538px) {
  .com-logo,
  .com-address,
  .link {
    width: 100%;
  }

  .contnet-us {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .contnet-us input {
    margin-bottom: 15px;
    display: block;
    width: 100%;
  }
  .contnet-us button {
    margin-left: 0;
  }
}
.f-n {
  font-weight: normal !important;
}
</style>
