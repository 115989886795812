<template>
  <div>
    <div class="position-relative">
      <div class="logo">
        <ImageUploader 
          label="Store Logo" 
          :value.sync="files.logo" 
          @input="getImage($event, 'logo')" 
          radius="50%" 
          border="4px solid white"
        />
        <br />
        <small v-if="errors.logo" class="text-danger mt-2">
          {{ errors.logo }}
        </small>
      </div>
      <div>
        <ImageUploader
          :label="$t('setting.company.storeBanner')"
          :value.sync="files.banner"
          @input="getImage($event, 'banner')"
          width="100%"
          height="320px"
          radius="10px"
        />
        <br />
        <small v-if="errors.banner" class="text-danger mt-2">
          {{ errors.banner }}
        </small>
      </div>
    </div>

    <div class="mt-5">
      <h1>{{$t('setting.company.storeInformation')}}</h1>
      <Input :params="params" :errors="errors" field="name" :label="$t('setting.company.storeName')" />

      <div class="row">
        <div class="col-md-6">
          <div @click="setShowCurrencyPicker(true)">
            <Input :params="params" :errors="errors" field="currency" disabled :label="$t('setting.company.currency')" placeholder="USD" />
          </div>
        </div>
        <div class="col-md-6">
          <Input :params="params" :errors="errors" placeholder="Tax ID" field="tax_id" :label="$t('setting.company.taxId')" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <Input :params="params" :errors="errors" placeholder="7%" field="tax" :label="$t('setting.company.defaultTax')" />
        </div>

        <div class="col-md-6">
          <label class="label">{{$t('setting.company.storeType')}}</label>
          <Select :options="categories" v-model="params.company_category_id" />
        </div>

        <!-- <div class="col-md-6">
          <Input :params="params" :errors="errors" placeholder="3%" field="wht" label="Default WHT ( % )" />
        </div> -->
      </div>

      <div class="row">
        <div class="col-md-6">
          <Input
            :params="params"
            :errors="errors"
            placeholder="INV"
            field="purchase_inv_prefix"
            :label="$t('setting.company.purchaseInvoicePrefix')"
          />
        </div>

        <div class="col-md-6">
          <Input
            :params="params"
            :errors="errors"
            placeholder="SA"
            field="sale_inv_prefix"
            :label="$t('setting.company.saleInvoicePrefix')"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <Input :params="params" :errors="errors" field="phone" :label="$t('phoneNumber')"  />
        </div>

        <div class="col-md-6">
          <Input :params="params" :errors="errors" field="email" :label="$t('email')"  />
        </div>
      </div>

      <Input :params="params" :errors="errors" field="address"  :label="$t('address')" />
    </div>

    <!-- currency picker -->
    <van-popup v-model="showCurrencyPicker" class="currency-picker" :style="{ width: '450px' }">
      <div>
        <div class="label">{{$t('setting.company.selectCurrency')}}</div>
        <input placeholder="Search" type="text" class="search-currency" @keyup="searchCurrency" />
        <div class="list">
          <ul>
            <li v-for="(currency, index) in options" :key="index" @click="setCurrency(currency)">
              <b> {{ currency.code }}</b>
              &nbsp;({{ currency.currency }})
              <span class="float-right"> <van-icon name="circle" size="17" /> </span>
            </li>
          </ul>
        </div>
      </div>
    </van-popup>
    <!-- currency picker -->
  </div>
</template>

<script>
import cc from 'currency-codes/data'
let options = cc.filter((c) => c.code !== 'XXX')

export default {
  props: ['files', 'params', 'errors'],
  data() {
    return {
      options: options,
      originalCurrencies: options,
      editId: null,
      categories: [],
      showCurrencyPicker: false,
      image: null,
    }
  },
  methods: {
    setCurrency(currency) {
      this.params.currency = currency.code
      this.showCurrencyPicker = false
    },

    searchCurrency(event) {
      let keyword = event.target.value
      if (keyword) {
        keyword = keyword.toLowerCase()
        this.options = this.originalCurrencies.filter((c) => {
          return c.code.toLowerCase().includes(keyword) || c.currency.toLowerCase().includes(keyword)
        })
      }
    },

    setShowCurrencyPicker(value) {
      this.showCurrencyPicker = value
    },

    getImage(value, type) {
      if (value && value[0]) {
        this.$emit('imageChanged', { image: value[0].file, type })
      }
    },

    async getCompanyCategories() {
      let res = await axios.get('/company_categories')
      this.categories = res.data.data
    },
  },
  mounted() {
    this.getCompanyCategories()
    this.editId = this.$route.params.id
  },
}
</script>

<style scoped>
.btn-save,
.btn-delete {
  min-width: 150px;
}
.info-check {
  border-radius: 8px;
}
.label {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  text-transform: capitalize;
}
.logo {
  position : absolute;
  bottom : 0;
  transform: translateY(50%);
  left : 0;
  right : 0;
  text-align: center;
  z-index: 5;
}
</style>