<template>
  <div class="app">
    <Tooltip />
    <Sidebar/>
    <div class="main-content">
      <router-view/>
    </div>
    <popup-message :popup-data="$store.state.popup">
      <img :src="$store.state.popup.icon"/>
    </popup-message>
  </div>
</template>
<script>
import Sidebar from '../layouts/Sidebar.vue'
import PopupMessage from "../@components/alert-popup/PopupMessage.vue";
import moment from "moment";
import Tooltip from './../tooltips/index'

export default {
  components: {PopupMessage, Sidebar, Tooltip},
  mounted() {
    // let now = moment('5 Mar 2022'); //test expire
    let now = moment()
    let end = moment(this.$auth.user().expired_date);
    let msgAlreadyShowed = +localStorage.getItem('showed_plan_expire')
    if (end.diff(now, 'days') <= 5 && !msgAlreadyShowed) {
      setTimeout(() => {
        this.$store.commit('setPopup', {
          code: 1000,
          title: 'Plan is about to expire!',
          message: 'Your plan subscription is about to expire. Please consider doing renew or upgrade plan.',
          icon: '/image/popups/time.svg',
          actions: [
            {
              type: 'cancel',
              display:this.$t('action.notNow'),
            },
            {
              type: 'ok',
              route: '/pricing',
              display: this.$t('action.renewPlan'),
            }
          ],
          show: true,
        })
        localStorage.setItem('showed_plan_expire', "1")
      }, 6000)
    }
  }
}
</script>

<style scoped>
.main-content {
  background-color: #f4f4f4;
  margin-left: 75px;
  padding: 15px 0px;
}
</style>
