<template>
  <table class="tabl my-border-tabl edit-table">
    <thead>
    <tr>
      <th>
       {{$t('mngmt.employee.employees')}}
        <div class="text-black">{{ getCount }}</div>
      </th>
      <th class="text-center" width="130">{{$t('mngmt.employee.baseSalary')}} </th>
      <th class="text-center" width="130">{{$t('mngmt.employee.otherIncome')}} </th>
      <th class="text-center" width="80">{{$t('mngmt.employee.wht')}} </th>
      <th class="text-center" width="130">{{$t('mngmt.employee.socialSecFund')}}</th>
      <th class="text-center" width="130">{{$t('mngmt.employee.otherDeduction')}}</th>
      <th class="text-center" width="130">{{$t('mngmt.employee.netIncome')}} </th>
      <th width="110">{{$t('status')}}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(employee, index) in data.employees" :key="index">
      <td>
        <div class="image-card">
          <div class="number">{{ index + 1 }}</div>
          <img @error="placeEmptyImage(employee)" :src="employee.image">
          <div class="ml-3">
            <div>{{ employee.name }}</div>
            <div class="id">{{ employee.employee_id_number }}</div>
          </div>
        </div>
      </td>
      <td class="text-center">
        <input v-model="employee.base_salary" placeholder="-" type="number">
      </td>
      <td class="text-center">
        <input v-model="employee.other_income" placeholder="-" type="number">
      </td>
      <td>
        <input type="number" max="70" min="0" v-model="employee.wht" placeholder="-" @change="validateWht(employee)">
      </td>
      <td class="text-center">
        <input v-model="employee.social_sec_fund" placeholder="-" type="number">
      </td>
      <td class="text-center">
        <input v-model="employee.other_deduction" placeholder="-" type="number">
      </td>
      <td class="text-center">{{ netIncome(employee) }}</td>
      <td>
        <div class="d-flex">
          <van-switch
              v-model="employee.status"
              class="mr-2"
              size="15"
          />
          <span v-if="employee.status">{{$t('mngmt.payrolls.paid')}}</span>
          <span v-else>{{$t('mngmt.payrolls.notPaid')}}</span>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      total: 0,
      count: '',
      params: {
        type: 'current',
        page: 1,
        limit: 10
      }
    }
  },
  methods: {
    async fetchData() {
      let res = await axios.get('payrolls/employee', {params: this.params})
      if (res) {
        this.data.employees = res.data.data.map((employee) => {
          return {
            id: employee.id,
            employee_id: employee.id,
            tax_id: employee.tax_id,
            name: employee.name,
            image: employee.image,
            employee_id_number: employee.employee_id,
            base_salary: employee.base_salary || 0,
            other_income: employee.other_income || 0,
            wht: employee.wht || 0,
            social_sec_fund: employee.social_sec_fund || 0,
            other_deduction: employee.other_deduction || 0,
            status: employee.status,
          }
        })
        this.total = res.data.data.total
        this.$emit('loaded', '')
      }
    },
    netIncome(employee) {
      let base_salary =  +(employee.base_salary) || 0
      let other_income =  +(employee.other_income) || 0
      let wht = +(employee.wht) || 0
      let social_sec_fund =  +(employee.social_sec_fund) || 0
      let other_deduction =  +(employee.other_deduction) || 0

      let income_amount = base_salary + other_income
      let wht_amount = income_amount * (wht / 100)

      return this.$cast(income_amount - wht_amount - social_sec_fund - other_deduction)
    },

    placeEmptyImage(employee) {
     employee.image = '/image/placeholder.png'
    },
    validateWht(e) {
      if (+e.wht > 70 ) {
        e.wht = 70
      }else if (+e.wht < 0) {
        e.wht = 0
      }
    }
  },
  computed: {
    getCount() {
      return `${this.data.employees.filter(employee => employee.status).length} / ${this.data.employees.length}`
    },
  },
  mounted() {
    this.fetchData()
  },

}
</script>

<style lang="scss" scoped>
.image-card {
  display: inline-flex;
  align-items: center;

  .number {
    color: rgba(0, 0, 0, 0.6);
    margin-right: 15px;
  }

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .id {
    color: #929292;
    font-size: 10px;
  }
}

input {
  text-align: center;
  width: 100%;
  border: none;
  padding: 0.75rem;

  &:focus {
    padding: calc(0.75rem - 2px);
    border: 2px solid #f4f4f4;
    border-radius: 8px;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    opacity: 1;
  }
}

.edit-table {
  width: 100%;

  thead {
    th {
      color: rgba(0, 0, 0, 0.5);
      font-weight: 400;
      vertical-align: baseline
    }
  }

  tbody {
    tr {
      border-bottom: 1.5px solid #EDEDED;

      td {
        padding: 0.75rem;
      }
    }
  }
}
</style>