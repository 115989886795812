<template>
  <div>
    <van-icon class="icon-search" name="search" size="20"/>
    <input
        :placeholder="placeholder ? placeholder : $t('action.search')"
        :style="`width: ${width}`"
        :value="value"
        class="search"
        type="text"
        @keypress="$emit('keypress', $event)"
        @keyup="search"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: '260px',
    },
    placeholder: {
      type: String,
    },
  },
  data() {
    return {
      timer: null,
    }
  },
  mounted() {
  },
  methods: {
    search(e) {
      this.$emit('input', e.target.value)
      clearInterval(this.timer)
      this.timer = setTimeout(() => {
        this.$emit('search')
      }, 500)
    },
  },
}
</script>

<style scoped>
.icon-search {
  position: absolute;
  margin-top: 9px;
  margin-left: 10px;
}

.search {
  border-radius: 10px !important;
  font-size: 12px !important;
  padding: 10px 15px !important;
  background: #f8f8f8 !important;
  border: none !important;
  color: #000 !important;
  height: auto !important;
  padding-left: 40px !important;
}

.search::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

</style>