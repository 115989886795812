<template>
  <div class="bg-white">
    <div class="d-flex justify-content-between align-items-center">
      <custom-search v-model="params.keyword" @search="fetchData()"/>
      <van-button class="add-button" type="default" @click="$router.push('/management/payrolls/create/0')">
        <van-icon class="mr-2" name="add-o" size="20"/>
        {{ $t('mngmt.payrolls.newPayroll')}}
      </van-button>
    </div>

    <div v-if="loaded" class="mt-2">
      <div v-if="payrolls.length > 0">
        <table class="table my-border-table mt-0">
          <thead>
          <tr>
            <th>{{$t('mngmt.payrolls.payroll')}}</th>
            <th class="text-center">{{$t('mngmt.payrolls.sent')}}</th>
            <th class="text-center">{{$t('createdAt')}}</th>
            <th class="text-center">{{$t('total')}}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(payroll, index) in payrolls" :key="index">
            <td><span class="pl-4">{{ payroll.payroll_number }}</span></td>
            <td class="text-center">{{ payroll.payroll_employees.length }}/{{ }}</td>
            <td class="text-center">{{ moment(payroll.created_at).format('MMM DD YYYY') }}</td>
            <td class="text-center">{{ $cast(totalNet(payroll.payroll_employees)) }}</td>
            <td class="text-right">
              <van-popover
                  v-model="payroll.show_menu"
                  :actions="getActions(actions)"
                  :close-on-click-outside="true"
                  placement="bottom-end"
                  trigger="click"
                  @open="menuOpen(index)"
                  @select="menuSelect($event, payroll.id)"
              >
                <template #reference>
                  <van-icon class="pointer" name="ellipsis" size="20"/>
                </template>
              </van-popover>
            </td>
          </tr>
          </tbody>
        </table>
        <b-pagination
            :per-page="params.limit"
            :total-rows="total"
            :value="params.page"
            align="center"
            class="mt-4"
            first-number
            last-number
            pills
            @change="changePage"
        ></b-pagination>
      </div>
      <van-empty v-else :description="$t('mngmt.payrolls.emptyPayroll')" image="/image/empty-lists/empty-admin.svg"/>
    </div>

    <div v-else class="loading">
      <van-loading type="spinner"/>
    </div>
  </div>
</template>

<script>
import {Dialog, Toast} from 'vant'
import moment from 'moment'

export default {
  data() {
    return {
      moment,
      showConfirmDialog: false,
      activeTab: 0,
      tabList: ['Current Employees', 'Ex Employees'],
      actions: [
        {text: this.$t('mngmt.payrolls.edit'), icon: 'edit', value: 'edit'},
        {text: this.$t('mngmt.payrolls.clone'), icon: 'cluster-o', value: 'clone'},
        // {text: this.$t('mngmt.payrolls.restore'), icon: 'revoke', value: 'restore'},
        {text: this.$t('mngmt.payrolls.print'), icon: 'description', value: 'print'},
        {text: this.$t('mngmt.payrolls.del'), icon: 'delete-o', value: 'delete'},
      ],
      params: {
        keyword: '',
        page: 1,
        limit: 10,
      },
      payrolls: [],
      admins: [],
      total: 0,
      loaded: false,
      deleteParams: {
        id: null,
        force: false
      }
    }
  },
  methods: {
    totalNet(employees) {
      return employees.reduce((total, employee) => Number(employee.net_income) + total, 0)
    },
    getActions(actions) {
      return actions.filter(action => {
        return this.activeTab == 0
            ? action.value != 'restore'
            : action.value == 'restore' || action.value == 'delete'
      })
    },
    changePage(page) {
      this.params.page = page
      this.fetchData()
    },
    changeTab(name) {
      this.admins = this.admins.map((admin) => {
        admin.show_menu = false
        return admin
      })
      this.params.type = name == 0 ? 'current' : 'ex'
      this.fetchData()
    },
    menuOpen(index) {
      this.admins = this.admins.map((admin) => {
        admin.show_menu = false
        return admin
      })
      this.admins[index].show_menu = true
    },
    menuSelect(e, id) {
      switch (e.value) {
        case 'edit':
          this.$router.push(`/management/payrolls/edit/${id}`)
          break
        case 'delete':
          this.deleteParams.id = id
          this.deleteData()
          break
        case 'clone':
          this.$router.push(`/management/payrolls/clone/${id}`)
          break
        case 'print':
          this.requestPrint(id);
          break

      }
    },
    deleteData() {
       Dialog.confirm({
        title: this.$t('action.areYouSure'),
        cancelButtonText: this.$t('action.cancel'),
        confirmButtonText: this.$t('action.del'),
      })
          .then(async () => {
            let res = await axios.delete(`payrolls/${this.deleteParams.id}`);
            if (res.data.code == 200) {
              Toast.success({message: "Deleted!"})
              this.fetchData();
            } else {
              Toast.fail({message: "Delete failed"})
            }
          }).finally(() => {
      })
    },
    async fetchData() {
      let res = await axios.get('payrolls', {params: this.params})
      if (res) {
        this.payrolls = res.data.data.data.map((payroll) => {
          payroll.show_menu = false
          return payroll
        })
        this.total = res.data.data.total
        this.loaded = true
      }
    },
    async requestPrint(id) {
      const res = await axios.get(`/payrolls/${id}/print_request`);
      if (res.data) {
        let url = res.data.data?.redirect_url;
        url && window.open(url)
      }
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>

<style lang="scss" scoped>
.my-border-table {
  td {
    padding-left: 0 !important;
  }
}
</style>

<style lang="scss">
.custom-tab {
  .van-tab {
    flex: 140px 0 0 !important;
    font-size: 12px !important;
    justify-content: flex-start !important;
    margin-right: 40px;
  }
}

.check-force {
  font-size: 13px;
  padding: 20px 15px;

  span {
    color: rgba(0, 0, 0, 0.6) !important
  }
}
</style>