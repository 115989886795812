<template>
  <!-- ==== category create container ==== -->
  <div class="bg-white pt-2">
    <div>
      <h5 class="text-bold" @click="$router.back()">
        <van-icon class="mr-3" name="arrow-left" size="15px"/>
        {{ $t(`mngmt.category.${editId ? 'editCategory' : 'newCategory'}`) }}
      </h5>
    </div>
    <div class="container pt-3 pt-md-0">
      <div class="d-flex justify-content-center mt-5">
        <ImageUploader :value="uploader" @input="getImage"/>
      </div>

      <div class="row mt-5">
        <div class="m-auto col-lg-7">
          <Input :errors="errors" :label="$t('name')" :params="params" field="name"/>

          <Button :loading="loading" :type="editId ? 'edit' : 'create'" name="Category" @click.native="saveData"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Toast} from 'vant'

export default {
  data() {
    return {
      uploader: [],
      errors: {},
      loading: false,
      params: {},
      editId: null,
      image: null,
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.editId = this.$route.params.id
      this.fetData()
    }
  },
  methods: {
    getImage(image) {
      if (image && image[0]) {
        this.image = image[0].file
      }
    },

    async fetData() {
      const res = await axios.get(`/categories/` + this.editId)
      this.params = res.data.data
      console.log(this.params)
      if (this.params.image) {
        this.uploader = [
          {
            url: this.params.image,
          },
        ]
      }
    },

    saveData() {
      if (this.editId) {
        this.update()
      } else {
        this.create()
      }
    },

    async create() {
      let formData = this.appendFormData()
      this.loading = true
      try {
        const res = await axios.post(`/categories`, formData)
        if (res.data.success) {
          Toast.success(this.$t('state.created'))
          this.loading = false
          this.$router.back()
        } else if (res.data.code == 422) {
          this.loading = false
          this.errors = res.data.error
        }
      } catch (err) {
        console.log(err)
        this.loading = false
      }
    },

    async update() {
      let formData = this.appendFormData()
      this.loading = true
      try {
        const res = await axios.post(`/categories/` + this.editId + '?_method=PUT', formData)
        if (res.data.success) {
          Toast.success(this.$t('state.updated'))
          this.loading = false
          this.$router.back()
        } else if (res.data.code == 422) {
          this.loading = false
          this.errors = res.data.error
        }
      } catch (err) {
        console.log(err)
        this.loading = false
      }
    },

    appendFormData() {
      let fd = new FormData()
      if (this.params.name) {
        fd.append('name', this.params.name)
      }
      if (this.image) {
        fd.append('image', this.image)
      }
      return fd
    },
  },
}
</script>

<style scoped>
.pm-category-create {
  cursor: pointer;
}

.category-image-upload {
  margin-top: 50px;
}

.category-label {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 7px;
}

.save-button {
  padding: 10px 40px;
  border-radius: 10px;
  font-size: 13px;
}
</style>
