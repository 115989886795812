<template>
  <div class="main-category" v-dragscroll>
    <div class="category text-bold" :class="{ 'cat-selected': cat == null }" @click="pick(null)">{{$t('all')}}</div>
    <div
      v-for="(cat, idx) in categories"
      :key="cat.id"
      :class="{ 'cat-selected': cat.selected }"
      class="category text-bold"
      @click="pick({ id: cat.id, index: idx })"
    >
      {{ cat.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['categories'],
  data() {
    return {
      cat: null,
    }
  },
  methods: {
    pick(cat) {
      this.cat = cat
      this.$emit('filer-prod', cat)
    },
  },
}
</script>

<style scoped>
.main-category {
  display: flex;
  overflow-x: scroll;
  width: 100%;
}

.category {
  flex-shrink: 0;
  margin-right: 10px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  height: 36px;
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  text-transform: capitalize;
}

.category.active,
.category:hover {
  background-color: var(--theme);
  color: white;
}

.cat-selected {
  background-color: var(--theme);
  color: white;
}

.category a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  text-transform: capitalize;
}
</style>
