<template>
  <div>
    <div class="card-inv mt-3">
      <div class="row customer-data">
        <div class="col-md-4 mt-2 mb-2">
          <label for="customer_name">
            <slot></slot>
          </label>
          <CustomerSupplierSelect :params="params" />
        </div>

        <div class="col-md-4 mt-2 mb-2">
          <label for="receiver_email">{{ $t('email') }} </label>
          <input type="email" id="receiver_email" v-model="params.receiver_email" />
        </div>

        <div class="col-md-4 mt-2 mb-2">
          <label for="receiver_phone">{{ $t('phoneNumber') }} </label>
          <input type="text" id="receiver_phone" v-model="params.receiver_phone" />
        </div>

        <div class="col-md-4 mt-2 mb-2">
          <label for="tax_id">{{ $t('taxIdNum') }} </label>
          <input type="text" id="tax_id" v-model="params.receiver_tax_id" />

          <van-checkbox
            class="mt-3"
            v-model="params.receiver_auto_create"
            shape="square"
            v-if="params.receiver_id == null"
          >
           {{ $t('invoice.autoCreate', {person:this.$route.params.type == 'sale' ? 'Customers' : 'Suppliers'  }) }}
          </van-checkbox>
        </div>

        <div class="col-md-4 mt-2 mb-2">
          <label for="shipping_address">{{ $t('shippingAddress')}}</label>
          <textarea id="shipping_address" v-model="params.shipping_address" rows="2"></textarea>
        </div>

        <div class="col-md-4 mt-2 mb-2">
          <label for="billing_address">{{ $t('billingAddress')}} </label>
          <textarea id="billing_address" v-model="params.billing_address" rows="2"></textarea>
        </div>
      </div>
    </div>

    <div class="card-inv mt-4">
      <div class="row customer-data mt-2">
        <div class="col-md-4">
          <label for="invoice_number">{{ $t('invoice.invoiceNumber')}} </label>
          <input type="text" id="invoice_number" v-model="params.invoice_number" placeholder="AUTO" />
        </div>

        <div class="col-md-4">
          <label for="reference_number">{{ $t('invoice.referenceNumber')}} </label>
          <input type="text" id="reference_number" v-model="params.reference_number" />
        </div>

        <div class="col-md-4">
          <label>
            {{ $t('invoice.account')}}
            <small v-if="errors.account_id" class="text-danger">{{ errors.account_id }}</small>
          </label>
          <AccountSelect :params="params" pos-tooltip-step="17"/>
        </div>
      </div>
      <!--  -->
      <hr />
      <div class="row customer-data" v-if="params.invoiceDate.startDate">
        <div class="invoice-date col-md-4">
          <!-- === invoice date === -->
          <label>{{ $t('invoice.invoiceDate')}}</label>
          <date-range-picker
            v-model="params.invoiceDate"
            lang="en"
            type="date"
            :showWeekNumbers="false"
            :autoApply="false"
            :ranges="false"
            opens="left"
            :locale-data="locale"
            :singleDatePicker="true"
          >
            <template v-slot:input="picker">
              <div class="calendar-box-2 d-flex justify-content-center">
                <i class="dashboard-calendar-icon"></i>{{ picker.startDate ? dateFormat(picker.startDate) : '' }}
              </div>
            </template>
          </date-range-picker>
        </div>

        <div class="due-date col-md-4" v-if="params.dueDate.startDate">
          <label>{{ $t('invoice.dueDate')}}</label>
          <date-range-picker
            v-model="params.dueDate"
            lang="en"
            type="date"
            :showWeekNumbers="false"
            :autoApply="true"
            :ranges="false"
            opens="left"
            :locale-data="locale"
            :singleDatePicker="true"
          >
            <template v-slot:input="picker">
              <div class="calendar-box-2 d-flex justify-content-center">
                <i class="dashboard-calendar-icon"></i>{{ dateFormat(picker.startDate) }}
              </div>
            </template>
          </date-range-picker>
        </div>
      </div>
      <!--  -->
    </div>
  </div>
</template>

<script>
import CustomerSupplierSelect from './CustomerSupplierSelect'
import AccountSelect from './AccountSelect'
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'
import vSelect from 'vue-select'

export default {
  props: ['params', 'errors'],
  components: {
    DateRangePicker,
    vSelect,
    CustomerSupplierSelect,
    AccountSelect,
  },
  data() {
    return {
      selected_customer: '',
      customers: null,
      locale: {
        format: 'mm/dd/yyyy',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
    }
  },
  methods: {
    dateFormat(date) {
      return date ? moment(date).format('DD MMM, YYYY') : null
    },
  },
  filters: {
    date(val) {
      return val ? moment(val).format('LL') : ''
    },
  },
}
</script>

<style scoped>
.customer-data input {
  background: #ffffff;
  border: 2px solid #f4f4f4;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 10px;
  height: 38px;
  width: 100%;
}
textarea {
  background: #ffffff;
  border: 2px solid #f4f4f4;
  box-sizing: border-box;
  padding: 10px 10px;
  border-radius: 10px;
  width: 100%;
}

.sale-invoice-calendar {
  margin-bottom: 2px;
  margin-top: 2px;
}
.dashboard-calendar-icon {
  background-image: url('/image/dashboard-2/calendar.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: auto;
  width: 18px;
  margin-top: -2px;
  margin-right: 10px;
}
.calendar-box-2 {
  font-size: 12px;
  margin-top: -9px;
}
</style>
