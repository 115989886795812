<template>
  <div class="bg-white">
    <div class="d-flex align-items-center justify-content-between">
      <div class="pointer" @click="$router.push({ path: '/management/payrolls/list' })">
        <van-icon class="mr-3" name="arrow-left" size="15px"/>
        {{$t('mngmt.payrolls.backToPayroll')}}
      </div>
      <custom-search v-model="params.keyword" @search="fetchData()"/>
    </div>

    <div class="mt-3 d-flex justify-content-between align-items-center">
      <div>
        <h1>{{$t('mngmt.payrolls.payroll')}}</h1>
        <div class="text-muted">{{$t('mngmt.payrolls.dateRange', {from: params.date_from, to: params.date_to})}}</div>
      </div>
      <div class="d-flex">
        <button v-if="submitType !== 'create'" class="btn-print" @click="requestPrint">
         {{$t('action.print')}}
          <svg fill="none" height="18" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.75 12.75V8.25L5.25 9.75"
                stroke="#292D32"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
            <path
                d="M6.75 8.25L8.25 9.75"
                stroke="#292D32"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
            <path
                d="M16.5 7.5V11.25C16.5 15 15 16.5 11.25 16.5H6.75C3 16.5 1.5 15 1.5 11.25V6.75C1.5 3 3 1.5 6.75 1.5H10.5"
                stroke="#292D32"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
            <path
                d="M16.5 7.5H13.5C11.25 7.5 10.5 6.75 10.5 4.5V1.5L16.5 7.5Z"
                stroke="#292D32"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
          </svg>
        </button>
        <AccountSelect v-model="params.account_id" class="mx-3"/>
        <MyDateRangePicker :params="dateParams" @select="selectDate"/>
      </div>
    </div>

    <EmployeeList :data="params" class="mt-4" @loaded="fetchData"/>

    <Button
        :loading="loading"
        :type="submitType === 'edit' ? 'edit' : 'create'"
        @click.native="saveData()"
    />
    <Button v-if="submitType === 'edit'" :dloading="dloading" class="ml-4" type="delete" @click.native="deleteData"/>
  </div>
</template>

<script>
import moment from 'moment'
import AccountSelect from './components/AccountSelect'
import EmployeeList from './components/EmployeeList'
import {Dialog, Toast} from 'vant'

export default {
  components: {AccountSelect, EmployeeList},
  data() {
    return {
      moment,
      dloading: false,
      loading: false,
      submitType: this.$route.params.submitType,
      editId: null,
      dateParams: {
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().add(1, 'month').format('YYYY-MM-DD'),
      },
      params: {
        keyword: '',
        account_id: null,
        type: 'specific',
        date_from: moment().format('YYYY-MM-DD'),
        date_to: moment().add(1, 'month').format('YYYY-MM-DD'),
        employees: [],
      },
    }
  },
  methods: {
    selectDate(dates) {
      this.params.date_from = moment(dates.startDate).format('YYYY-MM-DD')
      this.params.date_to = moment(dates.endDate).format('YYYY-MM-DD')
    },
    async saveData() {
      let params = {...this.params}
      params.employees = params.employees.filter((employee) => employee.status).map(emp => {
        emp.wht = +emp.wht
        emp.base_salary = +emp.base_salary
        emp.other_income = +emp.other_income
        emp.social_sec_fund = +emp.social_sec_fund
        emp.other_deduction = +emp.other_deduction
        return emp;
      })
      this.loading = true;
      let res =
          this.submitType == 'edit' && this.editId
              ? await axios.put(`payrolls/${this.editId}`, params)
              : await axios.post('payrolls', params)
      if (res.data.code == 200) {
        this.$router.back()
      }
      this.loading = false;
    },
    async fetchData() {
      if (this.submitType === 'create') {
        return;
      }
      let res = await axios.get(`payrolls/${this.editId}`)
      if (res) {
        let data = res.data.data
        this.params.account_id = data.account_id
        this.params.date_from = data.date_from
        this.params.date_to = data.date_to
        if(this.submitType !== 'create') {
          this.params.employees.map((employee) => {
            // employee.status = false
            data.payroll_employees.forEach((pe) => {
              if (employee.employee_id == pe.id) {
                employee.base_salary = pe.base_salary
                employee.other_income = pe.other_income
                employee.wht = pe.wht
                employee.social_sec_fund = pe.social_sec_fund
                employee.other_deduction = pe.other_deduction
                employee.status = pe.paid
              }
            })
            return employee
          })
        }
      }
    },
    deleteData() {
      Dialog.confirm({
        title: this.$t('action.areYouSure'),
        cancelButtonText: this.$t('action.cancel'),
        confirmButtonText: this.$t('action.del'),
      })
          .then(async () => {
            this.dloading = true;
            let res = await axios.delete(`payrolls/${this.editId}`);
            if (res.data.code == 200) {
              Toast.success({message: this.$t('state.deleted')})
              this.$router.push({path: '/management/payrolls/list'})
            } else {
              Toast.fail({message: this.$t('state.delFail')})
            }
          }).finally(() => {
        this.dloading = false;
      })
    },
    async requestPrint() {
      if (this.submitType === 'create') return;
      const res = await axios.get(`/payrolls/${this.editId}/print_request`);
      if (res.data) {
        let url = res.data.data?.redirect_url;
        url && window.open(url)
      }
    },
  },
  mounted() {
    if (this.submitType !== 'create' && this.$route.params.id) {
      this.editId = this.$route.params.id
      // this.fetchData()
    }
  },
}
</script>

<style lang="scss" scoped>
.btn-print {
  border: 2px solid #f8f8f8;
  border-radius: 10px;
  background-color: white;
  padding: 0 15px;

  &:hover {
    border-color: #4b3636;
  }
}
</style>