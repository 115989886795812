<template>
  <!-- ==== companies container ==== -->
  <div class="bg-white">
    <div class="d-flex justify-content-between align-items-center">
      <custom-search v-model="params.keyword" @search="fetchData()" />
      <div class="d-flex justify-content-end">
        <van-button @click="$router.push('/settings/companies/create')" class="pm-company-add-button" type="default">
          <van-icon name="add-o" size="20" class="mr-2" />
         {{$t('setting.company.newStore')}}
        </van-button>
      </div>
    </div>
    <!-- data table card -->
    <div class="mt-4">
      <DataColumnCard :companies="companies" />
    </div>
  </div>
</template>

<script>
import DataColumnCard from './components/DataColumnCard'

export default {
  components: { DataColumnCard },
  data() {
    return {
      companies: [],
      params: {
        page: 1,
        limit: 10,
        keyword: '',
      },
    }
  },
  methods: {
    /**
     * GET api/companies
     * fetch companies from server with pagination and sorting
     */
    async fetchData() {
      try {
        const res = await axios.get('/companies', {
          params: this.params,
        })
        if (res) {
          this.companies = res.data.data
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>

<style scoped>
h1 {
  font-size: 15px;
  font-weight: 700;
}
.manage-companies-text {
  padding-left: 9px;
  padding-top: 15px;
}
.pm-company-add-button {
  border-radius: 10px;
  height: 40px;
  font-size: 13px;
  border: 2px solid #f4f4f4;
}
</style>
