<template>
  <div class="login-rightside">
    <div class="back-icon text-left" @click="$router.back()">
      <svg fill="none" height="26" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.75033 23.8333H16.2503C21.667 23.8333 23.8337 21.6666 23.8337 16.25V9.74996C23.8337 4.33329 21.667 2.16663 16.2503 2.16663H9.75033C4.33366 2.16663 2.16699 4.33329 2.16699 9.74996V16.25C2.16699 21.6666 4.33366 23.8333 9.75033 23.8333Z"
            stroke="#292D32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
        />
        <path
            d="M14.3651 16.8241L10.5518 12.9999L14.3651 9.17578"
            stroke="#292D32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
        />
      </svg>
    </div>
    <div class="title my-3">Sign in</div>
    <div v-if="error" class="error">{{ error }}! Please Try Again</div>
    <template v-if="!electron">
      <div class="signup-facebook">
        <button @click="continueWithFacebook()">
          <img alt="" class="mr-1" src="/image/auth/facebook.svg"/>
          Sign In with Facebook
        </button>
      </div>
      <div class="signup-google">
        <button @click="continueWithGoogle()">
          <img alt="" class="mr-1" src="/image/auth/google.svg"/>
          Sign in with Google
        </button>
      </div>
      <p id="text">or sign in with Email</p>
    </template>

    <div class="form">
      <div class="email-icon">
        <svg fill="none" height="24" viewBox="0 0 24 24" width="21" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="1.5"
          />
          <path
              d="M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="1.5"
          />
        </svg>
      </div>
      <input v-model="params.email" placeholder="Email" type="email"/>
    </div>
    <small class="text-danger">{{ errors.email }}</small>
    <div class="form">
      <div class="passowrd-icon">
        <svg fill="none" height="24" viewBox="0 0 24 24" width="21" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M19.7901 14.93C17.7301 16.98 14.7801 17.61 12.1901 16.8L7.48015 21.5C7.14015 21.85 6.47015 22.06 5.99015 21.99L3.81015 21.69C3.09015 21.59 2.42015 20.91 2.31015 20.19L2.01015 18.01C1.94015 17.53 2.17015 16.86 2.50015 16.52L7.20015 11.82C6.40015 9.22001 7.02015 6.27001 9.08015 4.22001C12.0301 1.27001 16.8201 1.27001 19.7801 4.22001C22.7401 7.17001 22.7401 11.98 19.7901 14.93Z"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="1.5"
          />
          <path
              d="M6.89014 17.49L9.19014 19.79"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="1.5"
          />
          <path
              d="M14.5 11C15.3284 11 16 10.3284 16 9.5C16 8.67157 15.3284 8 14.5 8C13.6716 8 13 8.67157 13 9.5C13 10.3284 13.6716 11 14.5 11Z"
              stroke="#292D32"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
          />
        </svg>
      </div>
      <input v-model="params.password" :type="isPasswordHidden ? 'password' : 'text'" placeholder="Password"
             @keyup.enter="loginWithEmail"/>
      <div class="toggle-password" @click="togglePassword">
        <svg v-if="isPasswordHidden" fill="none" height="20" viewBox="0 0 22 20" width="22"
             xmlns="http://www.w3.org/2000/svg">
          <path
              d="M13.5299 7.46998L8.46992 12.53C7.81992 11.88 7.41992 10.99 7.41992 10C7.41992 8.02 9.0199 6.41998 10.9999 6.41998C11.9899 6.41998 12.8799 6.81998 13.5299 7.46998Z"
              stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          <path
              d="M16.8198 3.76998C15.0698 2.44998 13.0698 1.72998 10.9998 1.72998C7.46984 1.72998 4.17984 3.80998 1.88984 7.40998C0.989844 8.82 0.989844 11.19 1.88984 12.6C2.67984 13.84 3.59984 14.91 4.59984 15.77"
              stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          <path
              d="M7.41992 17.53C8.55992 18.01 9.7699 18.27 10.9999 18.27C14.5299 18.27 17.8199 16.19 20.1099 12.59C21.0099 11.18 21.0099 8.81 20.1099 7.39999C19.7799 6.87999 19.4199 6.38999 19.0499 5.92999"
              stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          <path d="M14.5104 10.7C14.2504 12.11 13.1004 13.26 11.6904 13.52" stroke="#292D32" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="1.5"/>
          <path d="M10.1004 10.8854L2.63037 18.3554" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="1.5"/>
          <path d="M19.2888 1.7265L11.8188 9.1965" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="1.5"/>
        </svg>
        <svg v-else fill="none" height="19" viewBox="0 0 21 19" width="21" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_7_22)">
            <path
                d="M16.3157 3.80717C14.6522 2.54684 12.6146 1.85793 10.515 1.84601C6.99677 1.84601 3.71771 3.88567 1.43532 7.41577C0.994553 8.19377 0.763184 9.06965 0.763184 9.96035C0.763184 10.851 0.994553 11.7269 1.43532 12.5049C2.17316 13.6678 3.07976 14.7183 4.12636 15.6232C4.96592 16.3417 5.91848 16.9213 6.94692 17.3393C8.07506 17.8133 9.28834 18.06 10.515 18.0649C14.0333 18.0649 17.3124 16.0253 19.5948 12.4952C20.0355 11.7172 20.2669 10.8412 20.2669 9.95053C20.2669 9.05984 20.0355 8.18395 19.5948 7.40595C19.2702 6.90647 18.9175 6.4252 18.5383 5.96446C17.8521 5.1928 17.1093 4.47175 16.3157 3.80717Z"
                stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path
                d="M8.03794 12.3971C7.68821 12.0755 7.40781 11.688 7.21348 11.2576C7.01914 10.8271 6.91484 10.3626 6.90676 9.89173C6.89868 9.42082 6.987 8.95313 7.16645 8.5165C7.34591 8.07987 7.61281 7.68324 7.95131 7.35021C8.28981 7.01717 8.69295 6.75452 9.13675 6.57796C9.58055 6.40141 10.0559 6.31457 10.5346 6.32252C11.0132 6.33047 11.4853 6.43309 11.9228 6.62429C12.3603 6.81548 12.7542 7.09129 13.0811 7.43537C13.4819 7.85347 13.7801 8.35629 13.9529 8.90529C14.1256 9.45429 14.1684 10.0349 14.0778 10.6027C13.9434 11.287 13.6039 11.916 13.103 12.4088C12.6021 12.9017 11.9627 13.2357 11.2672 13.3679C10.6894 13.454 10.0993 13.4115 9.54044 13.2435C8.98157 13.0754 8.46814 12.7862 8.03794 12.3971Z"
                stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          </g>
          <defs>
            <clipPath id="clip0_7_22">
              <rect fill="white" height="19" width="21"/>
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
    <small class="text-danger">{{ errors.password }}</small>
    <div class="forgot-password mb-3">
      <span class="text-bold pointer" @click="$refs.forgotPasswordPopup.open()">Forgot password?</span>
    </div>
    <button class="btn-signin text-bold" @click="loginWithEmail">Sign in</button>
    <p class="mb-4 mt-3 text-center">
      Don't have an account?
      <router-link class="text-bold" to="/register"> Register</router-link>
    </p>
    <ForgotPasswordPopup ref="forgotPasswordPopup"/>
  </div>
</template>

<script>
import {Toast} from 'vant'
import firebase from 'firebase/app'
import 'firebase/auth'
import isElectron from 'is-electron'
import ForgotPasswordPopup from './ForgotPasswordPopup'

export default {
  components: {ForgotPasswordPopup},
  data() {
    return {
      electron: isElectron(),
      error: false,
      errors: {},
      params: {
        email: null,
        password: null,
      },
      isPasswordHidden: true,
    }
  },
  methods: {
    /**
     * login with email
     */
    async loginWithEmail() {
      Toast.loading({
        message: 'Logging in...',
        forbidClick: true,
        duration: 0,
      })
      // let res = await axios.post('/auth/login-email', this.params)
      this.$auth.login({
        url: '/auth/login-email',
        staySignedIn: true,
        redirect: false,
        fetchUser: false,
        method: 'POST',
        data: this.params
      }).then(res => {
        if (res) {
          if (res.data.code == 500) {
            this.errors = {password: 'Wrong Password'}
            Toast.clear()
          } else if (res.data.code == 422) {
            this.errors = res.data.error
            Toast.clear()
          } else {
            this.errors = {}
            this.$auth.fetch()
            this.checkHasPlan()
          }
        }
      }).catch(err => console.warn('err : ', err))
    },
    /**
     * Google
     */
    async continueWithGoogle() {
      try {
        const provider = new firebase.auth.GoogleAuthProvider()
        await firebase.auth().signInWithPopup(provider)
        const token = await firebase.auth().currentUser.getIdToken(true)
        if (token) {
          this.vueLogin('google', token)
        }
      } catch (err) {
        this.error = 'Failed to login'
        console.log(err)
      }
    },

    /**
     * facebok
     */
    async continueWithFacebook() {
      try {
        const provider = new firebase.auth.FacebookAuthProvider()
        provider.setCustomParameters({
          display: 'popup',
        })
        await firebase.auth().signInWithPopup(provider)
        const token = await firebase.auth().currentUser.getIdToken(true)
        if (token) {
          this.vueLogin('facebook', token)
        }
      } catch (err) {
        this.error = 'Failed to login'
        console.log(err)
      }
    },

    /**
     * final login to backend
     */
    async vueLogin(type, token) {
      Toast.loading({
        message: 'Logging in...',
        forbidClick: true,
        duration: 0,
      })
      try {
        let res = await this.$auth.login({
          data: {
            token,
            type,
          },
          redirect: null,
          staySignedIn: true,
          autoLogin: true,
        })
        if (res.data.redirect_url) {
          Toast.clear()
          window.location.href = res.data.redirect_url
        } else {
          this.checkHasPlan()
        }
      } catch (error) {
        if (error.response.data) {
          this.error = error.response.data.error
        } else {
          this.error = 'Failed to login'
        }
        Toast.clear()
      }
    },

    async checkHasPlan() {
      let res = await axios.get('/auth/company')
      if (res.data.success) {
        this.$router.push('/pos')
        Toast.clear()
      } else {
        this.$router.push('/company/registration')
        Toast.clear()
      }
    },

    togglePassword() {
      this.isPasswordHidden = !this.isPasswordHidden;
    }
  },
}
</script>

<style scoped>
a {
  text-decoration: none;
  color: black;
}

.login-rightside {
  padding: 0px 70px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.company {
  padding: 10px 0;
  display: flex;
  align-items: center;
  width: 100%;
  display: none;
}

.logo {
  width: 30px;
  height: 30px;
  background: #000000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  height: 100%;
  width: 100%;
  padding: 6px;
}

.name {
  margin-left: 5px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
}

.back-icon {
  padding: 0 0 10px 0;
}

.back-icon img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.title {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  width: 100%;
  margin-bottom: 5px;
}

.signup-facebook,
.signup-google {
  width: 100%;
}

.signup-facebook button,
.signup-google button {
  padding: 8px;
  margin: 3px;
  height: 50px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 10px;
  font-weight: 700;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

#text {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #cdcbcb;
  text-align: center;
}

#text::before {
  content: '';
  position: absolute;
  border-top: 2px solid #e0e0e0;
  width: 30%;
  height: 1px;
  top: 10px;
  left: 0px;
}

#text::after {
  content: '';
  position: absolute;
  border-top: 2px solid #e0e0e0;
  width: 30%;
  height: 1px;
  top: 10px;
  right: 0px;
}

.form {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  padding-left: 10px;
  margin: 3px;
}

.email-icon img,
.passowrd-icon img {
  height: 20px;
  width: 20px;
  margin-right: 3px;
}

.form input {
  padding: 2px 3px 3px 10px;
  width: 90%;
  border: none;
  height: 50px;
}

.forgot-password {
  text-align: right;
  margin-top: 10px;
  width: 100%;
}

.btn-signin {
  padding: 15px;
  margin: 3px 3px 8px 3px;
  width: 100%;
  background: #000000;
  border: 1px solid #efefef;
  border-radius: 10px;
  color: #ffffff;
}

@media (max-width: 767px) {
  .company {
    display: flex;
  }
}

.error {
  margin-bottom: 13px;
  height: 40px;
  display: flex;
  align-items: center;
  color: red;
  border: 1px solid red;
  border-radius: 8px;
  padding: 0px 15px;
  background: #ff000014;
  font-weight: bold;
}
</style>
