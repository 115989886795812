<template>
  <div class="dashboard-top-items p-4">
    <p style="font-size: 14px" class="mb-0"> {{ $t('dashboard.topProducts') }}</p>
    <div class="resp">
      <van-empty
          :description="$t('dashboard.noProduct')"
          image="/image/empty-lists/empty-product.svg"
          v-if="products.length == 0"
      />

      <table class="table my-border-table mb-0" v-else>
        <tr>
          <th class="pl-0">{{ $t('dashboard.productName') }}</th>
          <th class="text-center">{{ $t('dashboard.totalOrder') }}</th>
          <th class="text-right">{{ $t('dashboard.percentage') }}</th>
        </tr>
        <tr v-for="product in products" :key="product.id">
          <td>
            <div class="d-flex align-items-center">
              <img :src="product.images && product.images[0] ? product.images[0] : ''" class="image"/>
              <div class="p-name">{{ product.name }}</div>
            </div>
          </td>
          <td class="text-center" style="width: 120px">{{ product.total_quantity }}</td>
          <td class="text-right" style="width: 150px">{{ $cast(getPercent(product.total_quantity)) }}%</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: ['products'],
  methods: {
    getPercent(q) {
      let total = parseFloat(this.products.reduce((sum, {total_quantity}) => sum + total_quantity * 1, 0))
      return (q / total) * 100
    },
  },
}
</script>
<style scoped>
.dashboard-top-items {
  background-color: white;
  border-radius: 10px;
}

.image {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 10px;
}

td {
  padding: 5px 10px !important;
}

.resp {
  height: 350px;
  overflow: auto;
}

.p-name {
  overflow: hidden;
  width: 150px;
  font-size: 12px;
  line-height: 13px;
}
</style>
