<template>
  <div>
    <vSelect
      v-model="product.unit"
      :options="product.unitList"
      label="unit"
      :clearable="false"
      @input="$emit('change')"
    >
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <svg width="15" height="14" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.7704 6.64453L10.6088 11.485C9.99921 12.0567 9.00171 12.0567 8.39213 11.485L3.23047 6.64453"
              stroke="rgba(0,0,0,0.2)"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </template>
    </vSelect>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: { vSelect },
  props: ['product'],
}
</script>

<style lang="scss">
  .vs__selected-options {
    overflow: visible !important;
}
</style>