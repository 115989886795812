<template>
  <div class="bg-white">
    <div class="d-flex justify-content-between">
      <custom-search/>
      <van-button class="add-button" pos-tooltip-step="3" type="default" @click="showVoucherCreate = true">
        <van-icon class="mr-2" name="add-o" size="20"/>
        {{ $t('mngmt.voucher.newVoucher') }}
      </van-button>
    </div>
    <van-popup
        v-model="showVoucherCreate"
        class="create-popup"
        close-icon="cross"
        close-icon-position="top-right"
        closeable>
      <div class="popup">
        <h5>{{ $t('mngmt.voucher.newVoucher') }}</h5>
        <div class="d-flex ">
          <div :class="{active: createType === 'payment'}" class="item" @click="createType = 'payment'">
            <svg fill="none" height="38" viewBox="0 0 38 38" width="38" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M14.5303 22.1897C14.5303 23.7255 15.7178 24.9605 17.1745 24.9605H20.1511C21.4178 24.9605 22.4469 23.8838 22.4469 22.538C22.4469 21.0972 21.8136 20.5747 20.8795 20.2422L16.1136 18.5797C15.1794 18.2472 14.5461 17.7405 14.5461 16.2838C14.5461 14.9538 15.5753 13.8613 16.8419 13.8613H19.8186C21.2753 13.8613 22.4628 15.0963 22.4628 16.6322"
                  stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.375"/>
              <path d="M18.4893 12.2939V26.5439" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="2.375"/>
              <circle cx="19.0001" cy="19.0001" r="15.5744" stroke="#292D32" stroke-width="2.38059"/>
            </svg>
            <p>{{ $t('mngmt.voucher.cashPayment') }}</p>
          </div>
          <div :class="{active: createType === 'received'}" class="item" @click="createType = 'received'">
            <svg fill="none" height="38" viewBox="0 0 39 38" width="39" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M15.8828 21.7707C15.8828 23.3065 17.0703 24.5415 18.527 24.5415H21.5036C22.7703 24.5415 23.7995 23.4649 23.7995 22.119C23.7995 20.6782 23.1662 20.1557 22.232 19.8232L17.4661 18.1607C16.532 17.8282 15.8987 17.3215 15.8987 15.8649C15.8987 14.5349 16.9278 13.4424 18.1945 13.4424H21.1711C22.6278 13.4424 23.8153 14.6774 23.8153 16.2132"
                  stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.375"/>
              <path d="M19.8416 11.875V26.125" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="2.375"/>
              <path
                  d="M35.6733 18.9993C35.6733 27.7393 28.5799 34.8327 19.8399 34.8327C11.0999 34.8327 4.00659 27.7393 4.00659 18.9993C4.00659 10.2593 11.0999 3.16602 19.8399 3.16602"
                  stroke="#292D32" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.375"/>
              <path d="M27.7566 4.75V11.0833H34.0899" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="2.375"/>
              <path d="M35.6733 3.16602L27.7566 11.0827" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="2.375"/>
            </svg>
            <p>{{ $t('mngmt.voucher.cashReceived') }}</p>
          </div>
        </div>
        <button class="btn btn-primary" @click="$router.push(`/management/payment-voucher/create?type=${createType}`)">
          {{ $t('action.add') }}
        </button>
      </div>
    </van-popup>
    <div>
      <van-tabs v-model="activeTab" class="mt-3 custom-tab" color="var(--theme)" line-width="140" @change="changeTab">
        <van-tab v-for="(tab, index) in tabList" :key="index" :title="tab">
          <div v-if="loaded" class="mt-2">
            <div v-if="vouchers.length > 0">
              <table class="table my-border-table mt-0">
                <thead>
                <tr>
                  <th>{{ $t('mngmt.voucher.accountCode') }}</th>
                  <th>{{ $t("mngmt.voucher.voucherNo") }}</th>
                  <th>{{ $t('mngmt.voucher.accountName') }}</th>
                  <th>{{ $t('amount') }}</th>
                  <th>{{ $t('tax') }}</th>
                  <th>{{ $t('total') }}</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(voucher, index) in vouchers" :key="index">
                  <td @click="goToEdit(voucher.id)">
                    <div>{{ voucher.cash_accounts[0].coa }}</div>
                  </td>
                  <td @click="goToEdit(voucher.id)">
                    {{ voucher.voucher_no }}
                  </td>
                  <td @click="goToEdit(voucher.id)">
                    {{ voucher.cash_accounts[0].coa_name }}
                  </td>
                  <td>
                    {{ voucher.cash_accounts[0].amount }}
                  </td>
                  <td>
                    {{ voucher.cash_accounts[0].tax }}
                  </td>
                  <td>
                    {{ voucher.cash_accounts[0].total }}
                  </td>
                  <td class="text-right">
                    <van-popover
                        v-model="voucher.show_menu"
                        :actions="actions"
                        :close-on-click-outside="true"
                        placement="bottom-end"
                        trigger="click"
                        @select="menuSelect($event, voucher.id)"
                    >
                      <template #reference>
                        <van-icon class="pointer" name="ellipsis" size="20"/>
                      </template>
                    </van-popover>
                  </td>
                </tr>
                </tbody>
              </table>
              <b-pagination
                  :per-page="params.limit"
                  :total-rows="total"
                  :value="params.page"
                  align="center"
                  class="mt-4"
                  first-number
                  last-number
                  pills
              ></b-pagination>
            </div>
            <van-empty v-else :description="$t('mngmt.voucher.emptyVoucher')"
                       image="/image/empty-lists/empty-coa.svg"/>
          </div>

          <div v-else class="loading">
            <van-loading type="spinner"/>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <van-dialog
        v-model="showConfirmDialog"
        :cancelButtonText="$t('action.cancel')"
        :confirmButtonText="$t('action.del')"
        :title="$t('action.areYouSure')"
        show-cancel-button
        @confirm="deleteVoucher(deleteParams.id)"
    >
    </van-dialog>

  </div>
</template>

<script>
import {Toast} from "vant";

export default {
  name: 'VoucherList',
  data: function () {
    return {
      activeTab: 0,
      showVoucherCreate: false,
      createType: 'payment',
      loaded: false,
      tabList: [
        this.$t('mngmt.voucher.cashPayment'),
        this.$t('mngmt.voucher.cashReceived')
      ],
      actions: [
        {text: this.$t('mngmt.voucher.edit'), icon: 'edit', value: 'edit'},
        {text: this.$t('mngmt.voucher.clone'), icon: 'cluster-o', value: 'clone'},
        {text: this.$t('mngmt.voucher.del'), icon: 'delete-o', value: 'delete'},
      ],
      total: 0,
      vouchers: [],
      params: {
        page: 1,
        limit: 20,
        type: 'payment',
      },
      showConfirmDialog: false,
      deleteParams: {}
    }
  },
  methods: {
    goToEdit(id) {
      this.$router.push(`/management/payment-voucher/edit/${id}`);
    },
    changeTab(name) {
      this.params.type = name == 0 ? 'payment' : 'received'
      this.$router.replace(`/management/payment-voucher/list?type=${this.params.type}`)
      this.fetchData();
    },
    async fetchData() {
      this.loaded = false;
      const res = await axios.get('payment_voucher', {params: this.params});
      if (res.data) {
        console.log('payment vouchers : ', res.data);
        this.total = res.data.data.total
        this.vouchers = res.data.data.data;
        this.loaded = true
      }
    },
    menuSelect(e, id) {
      switch (e.value) {
        case 'edit':
          this.$router.push(`/management/payment-voucher/edit/${id}`)
          break
        case 'delete':
          this.deleteParams.id = id
          this.showConfirmDialog = true
          break
        case 'clone':
          this.$router.push(`/management/payment-voucher/clone/${id}`)
          break
      }
    },
    async deleteVoucher(id) {
      const res = await axios.delete(`payment_voucher/${id}`);
      if (res) {
        Toast.success(this.$t('state.deleted'))
        this.fetchData()
      }
    }
  },
  mounted() {
    this.params.type = this.$route.query.type || 'payment'
    this.activeTab = +(this.params.type === 'received')
    this.changeTab(this.activeTab)
  }
}
</script>

<style lang="scss" scoped>
.create-popup {
  padding: 20px;
  border-radius: 15px;
  min-width: 30rem;
  text-align: center;

  .popup {
    padding: 0 20px;

    & > div {
      gap: 10px;
      margin-top: 20px;
    }

    .item {
      cursor: pointer;
      background-color: #F5F5F5;
      width: 140px;
      height: 140px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 10px;

      svg {
        margin-top: 10px;
      }

      p {
        margin-top: 5px;
      }
    }

    .item.active {
      border: 2px solid #2b2b2b;
    }

    button {
      padding: 10px 40px;
      margin-top: 15px;
    }

  }

}

.loading {
  margin-top: 20rem;
}
</style>