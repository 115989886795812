<template>
  <div>
    <div class="text-center mb-5">
      <ImageUploader :value="uploader" @input="getImage"/>
      <br/>
      <small v-if="errors.image" class="text-danger mt-2">
        {{ errors.image }}
      </small>
    </div>

    <div class="mt-3 ml-3">
      <h2>{{ $t('mngmt.customer.personalInformation') }}</h2>
      <div class="row">
        <div class="col-12">
          <Input
              :params="data"
              :errors="errors"
              field="name"
              :label="$t('mngmt.supplier.name')"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <Input
              :params="data"
              :errors="errors"
              field="company_name"
              :label="$t('mngmt.customer.companyName')"
          />
        </div>
        <div class="col-lg-6">
          <Input
              :params="data"
              :errors="errors"
              field="tax_id"
              :label="$t('mngmt.customer.taxId')"
          />
        </div>
        <div class="col-lg-6">
          <MultiInput
              :params="data"
              :errors="errors"
              field="phone"
              :label="$t('phoneNumber')"
              :addText="$t('mngmt.customer.addPhone')"
          />
        </div>
        <div class="col-lg-6">
          <MultiInput
              :params="data"
              :errors="errors"
              type="email"
              field="email"
              :label="$t('email')"
              :addText="$t('mngmt.customer.addEmail')"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <Input
              :params="data"
              :errors="errors"
              field="address"
              :label="$t('mngmt.supplier.address')"
          />
        </div>
        <div class="col-12">
          <Input
              :params="data"
              :errors="errors"
              field="billing_address"
              :label="$t('mngmt.supplier.billingAddress')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    isNew: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: Object,
    },
  },
  data() {
    return {
      uploader: this.isNew ? [] : [{url: this.data.image}],
    }
  },
  watch: {
    data() {
      this.uploader = this.isNew ? [] : [{url: this.data.image}]
    }
  },
  methods: {
    getImage(value) {
      this.uploader = value
      if (this.uploader[0]) {
        this.data.image = this.uploader[0].file
      }
    },
  },
}
</script>
