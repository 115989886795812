<template>
  <div>
    <!-- ===== start data table ===== -->
    <div class="mt-3 mb-4 table-container">
      <van-checkbox-group ref="productTable" v-model="selectedProducts">
        <table class="table my-border-table">
          <thead>
            <tr>
              <th colspan="6">
                <div class="d-flex justify-content-between">
                  <div>
                    <span class="mr-3">{{ $t('action.selected', { count: selectedProducts.length }) }}</span>
                    <span
                      v-if="selectAll != true"
                      class="ml-4"
                      @click="$refs.productTable.toggleAll(true), (selectAll = true)"
                      >{{ $t('action.selectAll') }}</span
                    >
                    <span
                      class="text-danger"
                      v-else
                      @click="$refs.productTable.toggleAll(false), (selectAll = false)"
                      >{{ $t('action.unselectAll') }}</span
                    >
                  </div>
                  <div>
                    <!-- <span class="text-success mr-4" @click="addStock" pos-tooltip-step="16">{{ $t('mngmt.product.addStock') }}</span> -->
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th>{{ $t('mngmt.product.productName') }}</th>
              <th class="text-center">{{ $t('mngmt.product.instock') }}</th>
              <th class="text-center">{{ $t('mngmt.product.retailPrice') }}</th>
              <th class="text-center">{{ $t('mngmt.product.selling') }}</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <template v-for="(product, index) in products">
              <tr :key="index" :class="[product.show_sub == false ? '' : 'tr-active']">
                <!-- ==== product name column ==== -->
                <td>
                  <div class="d-flex align-items-center">
                    <i
                      v-if="product.type != 'single'"
                      :class="['pm-product-option my-auto', product.show_sub == false ? '' : 'active']"
                      @click="toggleSub(index)"
                    >
                      <van-icon name="arrow-down" />
                    </i>
                    <i v-else class="mr-4" @click="$events.fire('nextStep')" pos-tooltip-step="15">
                      <van-checkbox :name="product" icon-size="15px" shape="square"></van-checkbox>
                    </i>
                    <img :src="product.images[0]" alt="" class="product-images my-auto" @click="editLink(product)" />
                    <p class="ml-2 my-auto p-name d-flex flex-column" @click="editLink(product)">
                      <span class="mb-1">
                        {{ $short(product.name) }}
                      </span>
                      <span v-if="product.need_approval" class="text-warning">Waiting for approval</span>
                    </p>
                  </div>
                </td>

                <!-- ==== instock column ==== -->
                <td class="text-center" v-if="!product.n_stock">
                  <p :class="totalInstock(product) > 10 ? '' : 'danger'" class="m-auto">
                    {{ totalInstock(product) || $t('mngmt.product.outOfStock') }}
                  </p>
                </td>

                <td class="text-center" v-else>
                  <p class="m-auto">-</p>
                </td>

                <!-- ==== retail price column ==== -->
                <td class="text-center">
                  {{ product.retail_price }}
                </td>

                <!-- ==== enable selling switch ==== -->
                <td class="text-center">
                  <van-switch
                    v-model="product.enable_selling"
                    class="enable-switch m-auto"
                    size="15"
                    @change="toggleEnableSelling(product.id, product.is_single ? null : product)"
                  />
                </td>
                <td class="text-right">
                  <van-popover
                    v-model="product.showAction"
                    :actions="
                      product.type == 'single'
                        ? actions
                        : actions.filter((action) => action.value !== 'clone' && action.value !== 'print')
                    "
                    placement="bottom-end"
                    trigger="click"
                    @select="onSelect($event, product)"
                  >
                    <template #reference>
                      <b-icon class="pointer" font-scale="1.2" icon="three-dots"></b-icon>
                    </template>
                  </van-popover>
                </td>
              </tr>

              <!-- sub products -->
              <template v-if="product.show_sub">
                <tr v-for="sub in product.sub_products" :key="sub.id" class="sub-products" style="background: #f6f9ff">
                  <td>
                    <div class="d-flex align-items-center">
                      <!-- <i class="toggler-empty"></i> -->
                      <i class="mr-4">
                        <van-checkbox :name="sub" icon-size="15px" shape="square"></van-checkbox>
                      </i>
                      <img
                        :src="sub.images[0]"
                        alt=""
                        class="product-images my-auto"
                        @click="editLink(product, sub.id)"
                      />
                      <p class="ml-2 my-auto p-name" @click="editLink(product, sub.id)">{{ $short(sub.name) }}</p>
                    </div>
                  </td>
                  <td class="text-center">
                    <p :class="sub.instock > 10 ? '' : 'danger'" class="m-auto">
                      {{ sub.instock > 0 ? sub.instock : $t('mngmt.product.outOfStock') }}
                    </p>
                  </td>
                  <td class="text-center">
                    {{ sub.retail_price }}
                  </td>
                  <td class="text-center">
                    <van-switch
                      v-model="sub.enable_selling"
                      class="enable-switch m-auto"
                      size="15"
                      @change="toggleEnableSelling(sub.id)"
                    />
                  </td>
                  <td class="text-right">
                    <van-popover
                      v-model="sub.showAction"
                      :actions="actions"
                      placement="bottom-end"
                      trigger="click"
                      @select="onSelect($event, product, sub.id)"
                    >
                      <template #reference>
                        <b-icon class="pointer" font-scale="1.2" icon="three-dots"></b-icon>
                      </template>
                    </van-popover>
                  </td>
                </tr>
              </template>
              <!--  -->
            </template>
          </tbody>
        </table>
      </van-checkbox-group>
      <b-pagination
        :per-page="params.limit"
        :total-rows="total"
        :value="params.page"
        align="center"
        first-number
        last-number
        pills
        @change="pageChange"
      ></b-pagination>
      <van-popup position="center" v-model="showPrintDialog" style="border-radius: 17px">
        <section class="print-confirm">
          <h4>{{ $t('mngmt.product.printBarcode') }}</h4>

          <section class="inputs-container">
            <div class="mb-3 mt-1">
              <div class="mb-2">{{ $t('mngmt.product.quantity') }}</div>
              <van-stepper :min="1" v-model="barcode.quantity" />
            </div>
            <div class="mb-3 mt-1">
              <div class="mb-2">{{ $t('mngmt.product.barcodePerRow') }}</div>
              <van-stepper :min="1" v-model="barcode.perRow" />
            </div>

            <div class="mb-3 mt-1">
              <div class="mb-2">{{ $t('mngmt.product.barcodeSize') }}</div>
              <van-stepper :min="0.5" :max="3" step="0.1" v-model="barcode.width" />
            </div>

            <div class="mb-3 mt-1">
              <div>{{ $t('mngmt.product.barcodeHeight') }}</div>
              <van-stepper :min="1" v-model="barcode.height" />
            </div>

            <div class="mb-3 mt-1">
              <div>{{ $t('mngmt.product.pageWidth') }}</div>
              <input type="number" v-model="barcode.pageWidth" class="input-box" />
            </div>
            <div class="mb-3 mt-1">
              <div>{{ $t('mngmt.product.pageHeight') }}</div>
              <input type="number" v-model="barcode.pageHeight" class="input-box" />
            </div>
            <div class="mb-3 mt-1">
              <div>{{ $t('mngmt.product.gap') }}</div>
              <van-stepper :min="10" :step="5" v-model="barcode.marginBetween" />
            </div>
          </section>

          <div class="remember">
            <van-checkbox v-model="barcode.remember" shape="square"
              >{{ $t('mngmt.product.rememberSetting') }}
            </van-checkbox>
          </div>

          <button class="btn btn-primary w-100" @click="printBarcode">{{ $t('mngmt.product.printBarcode') }}</button>
        </section>
      </van-popup>
    </div>
    <!-- ===== end data table ===== -->
  </div>
</template>
<script>
import { Dialog, Toast } from 'vant'
export default {
  props: ['products', 'params', 'total'],
  data() {
    return {
      selectAll: false,
      enable_selling: true,
      actions: [
        { text: this.$t('mngmt.product.edit'), icon: 'edit', value: 'edit' },
        { text: this.$t('mngmt.product.clone'), icon: 'cluster-o', value: 'clone' },
        { text: this.$t('mngmt.product.del'), icon: 'delete-o', value: 'delete' },
        { text: this.$t('mngmt.product.printBarcode'), icon: 'orders-o', value: 'print' },
      ],
      selectedProducts: [],
      showPrintDialog: false,
      barcode: {
        product: null,
        // perProduct: 1,
        quantity: 1,
        marginBetween: 70,
        width: 1,
        height: 30,
        perRow: 3,
        pageHeight: 200,
        pageWidth: 100,
        remember: false,
      },
    }
  },
  mounted() {
    let barcodeSetting = JSON.parse(window.localStorage.getItem('barcode_setting'))
    if (barcodeSetting) {
      this.barcode = barcodeSetting
    }
  },
  methods: {
    editLink(product, subId = '') {
      let productType = product.type != 'single' ? 'group' : 'single'
      this.$router.push(`/management/products/edit/${productType}/${product.id}/${subId}`)
    },
    onSelect(e, product, subId = '') {
      let productType = product.type != 'single' ? 'group' : 'single'
      switch (e.value) {
        case 'edit':
          this.$router.push(`/management/products/edit/${productType}/${product.id}/${subId}`)
          break
        case 'delete':
          this.deleteProduct(product.id, productType, subId)
          break
        case 'clone':
          this.$router.push(`/management/products/clone/${productType}/${product.id}/${subId}`)
          break
        case 'print':
          this.barcode.product = product.id || subId
          this.showPrintDialog = true
          break
      }
    },
    async deleteProduct(id, productType, subId = null) {
      await Dialog.confirm({
        title: this.$t('action.areYouSure'),
        cancelButtonText: this.$t('action.cancel'),
        confirmButtonText: this.$t('action.del'),
      })
      try {
        let url =
          subId && productType == 'group'
            ? `/group_products/${id}/sub_products/${subId}`
            : productType == 'group' && !subId
            ? `/group_products/${id}`
            : `/products/${id}`
        let res = await axios.delete(url)
        if (res) {
          this.pageChange(this.params.page)
          Toast.success(this.$t('state.deleted'))
        }
      } catch (error) {
        console.log(error)
        Toast.fail(this.$t('state.delFail'))
      }
    },
    toggleEnableSelling(id, product = null) {
      if (product) {
        product.sub_products.forEach((sub) => {
          if (sub.enable_selling != product.enable_selling) {
            sub.enable_selling = product.enable_selling
          }
        })
      }
      axios.post(`/products/enable/${id}`)
    },
    pageChange(page) {
      this.params.page = page
      this.$emit('pageChange')
    },
    async toggleSub(index) {
      if (this.products[index].sub_products.length > 0) {
        return (this.products[index].show_sub = !this.products[index].show_sub)
      }
      let { id } = this.products[index]
      if (id) {
        let res = await axios.get(`/group_products/${id}/sub_products`)
        if (res.data.data) {
          this.products[index].sub_products = res.data.data
          this.products[index].show_sub = true
        }
      }
    },
    totalInstock(product) {
      let total = product.instock
      product.sub_products.forEach((sub) => {
        total += sub.instock
      })
      total = product.type === 'group' ? total || '-' : total
      return total
    },
    addStock() {
      if (this.selectedProducts.length == 0) {
        alertFail('mngmt.product.pleaseSelectProducts')
        return false
      }
      this.$router.push({
        path: '/invoices/purchase/create',
        query: { products: this.selectedProducts.map((p) => p.id).join(',') },
      })
      this.$events.fire('nextStep')
    },
    async printBarcode() {
      let printParams = {
        product_id: this.barcode.product,
        width: this.barcode.width,
        height: this.barcode.height,
        per_row: this.barcode.perRow,
        quantity: this.barcode.quantity,
        margin_between: this.barcode.marginBetween,
        color: '#000000',
        page_width: this.barcode.pageWidth,
        page_height: this.barcode.pageHeight,
      }
      // console.log('printBarcode : ', printParams)
      Toast.loading({
        message: 'Preparing',
        forbidClick: true,
        duration: 0,
      })
      let api = axios.create()
      const res = await api.post('barcode/print_request', printParams, { params: { token: this.$auth.token() } })
      if (res.data.success) {
        window.open(res.data.data.redirect_url, '_blank')
      } else {
        Dialog.alert({
          title: this.$t('mngmt.product.noBarcode'),
          message: this.$t('mngmt.product.noBarcodeMessage'),
          confirmButtonText: 'OK',
        })
      }
      Toast.clear()
      if (this.barcode.remember) {
        window.localStorage.setItem('barcode_setting', JSON.stringify(this.barcode))
      } else {
        window.localStorage.removeItem('barcode_setting')
        this.barcode.perProduct = 1
      }
    },
  },
}
</script>

<style scoped lang="scss">
.tr-active {
  background: #f6f9ff !important;
}
.danger {
  color: red;
}
.table-options-data {
  height: 45px;
}
.table-product-data {
  height: 40px;
}
.table-options-container {
  height: auto;
}
.table-data {
  min-height: 40px;
  height: auto;
}
.product-table td,
.product-table th {
  border: none;
  vertical-align: middle;
}
.product-table th {
  padding-bottom: 17px;
}
.name-heading {
  padding-left: 57px;
}
.pm-product-option {
  margin-right: 17px;
  border-radius: 4px;
  background-color: #dadada;
  padding: 6px 7px 6px 7px;
  cursor: pointer;
}
.pm-product-option i {
  transition: 0.3s;
}
.product-table p {
  font-weight: 400px;
  font-size: 13px;
}
.product-option-images {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  margin-left: 41px;
}
.product-images {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  object-fit: cover;
}
.product-table th {
  color: rgba(0, 0, 0, 0.5) !important;
}
.hidden {
  visibility: hidden;
}
.active i {
  transform: rotate(180deg);
}
.toggler-empty {
  width: 42px;
}
.p-name {
  font-size: 11px;
  line-height: 12px;
}
.sub-products td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.print-confirm {
  padding: 20px 30px;
  border-radius: 20px;
  .inputs-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  button {
    margin-top: 15px;
  }
  .remember {
    margin-top: 9px;
  }
}
</style>