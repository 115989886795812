<template>
  <div class="bg-white">
    <div class="ref-heading">
      <h3>{{ $t('mngmt.referrals') }}</h3>
      <div>
        <div>

          <h2>{{ $t('mngmt.referral.yourReferral') }}</h2>
          <small>{{ $t('mngmt.referral.shareReferral') }}</small>
        </div>

        <code>
          {{ referralCode }}
          <Copy :text-value="referralCode"/>
        </code>

      </div>
    </div>
    <iframe :src="url"></iframe>

  </div>
</template>

<script>
import env from "@/env";
import Copy from '@/@components/Copy';

export default {
  name: 'ReferralView',
  data: () => ({
    referralCode: $company.referral_code,
    url: env.baseURL
  }),
  mounted() {
    const token = this.$auth.token();
    this.url = env.baseURL + `/auth/tree?authorization=Bearer%20${token}`;
  },
  components: {Copy},
}


</script>


<style lang="scss" scoped>
.ref-heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & > div {
    display: flex;
    gap: 10px;
    text-align: right;
    align-items: center;

    h2 {
      margin: 0;
    }
  }

  code {
    padding: 8px 10px;
    border: 1px solid #d0d0d0;
    background-color: #f8f8f8;
    border-radius: 7px;
    color: var(--primary);
    font-size: 16px;
  }
}

iframe {
  width: 100%;
  min-height: 70vh;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  margin-top: 1.3rem;
}
</style>