<template>
  <div></div>
</template>

<script>
export default {
  async mounted() {
    let id = this.$route.params.id
    let firebase_id = this.$route.params.firebaseId
    let token = this.$route.params.token
    if (this.$auth.check()) {
      this.$route.push('/pos')
    } else {
      this.login(id, firebase_id, token)
    }
  },
  methods: {
    async login(id, firebase_id, token) {
      let res = await axios.post('/auth/login', {
        id,
        firebase_id,
        token,
        type: 'redirect',
      })
      if (res.data.status == 'success') {
        this.$auth.token(null, res.data.token, false)
        this.$auth.remember()
        this.$auth.fetch()
        this.$router.push('/pos')
        this.$toast.success(this.$t('state.success'))
      } else {
        if (res.data.code == 422) {
          this.$toast.fail(this.$t('state.requiredFields'))
          this.errors = res.data.error
        } else {
          this.$toast.fail(res.data.error)
        }
      }
    },
  },
}
</script>

<style>
</style>