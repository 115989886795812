<template>
  <div class="report px-3">
    <div class="tabs">
      <div :class="{ active: activeTab == 'inventory' }" class="tab" @click="changeTab('inventory')">
        Inventory Reports
      </div>
      <div :class="{ active: activeTab == 'accounting' }" class="tab" @click="changeTab('accounting')">
        Accounting Reports
      </div>
    </div>
    <div class="content-report" :class="[activeTab == 'inventory' ? 'c-r-b1' : 'c-r-b']">
      <InventoryList v-if="activeTab == 'inventory'" />
      <AccountingList v-else />
    </div>
  </div>
</template>

<script>
import InventoryList from './components/InventoryList.vue'
import AccountingList from './components/AccountingList.vue'
export default {
  components: { InventoryList, AccountingList },
  data() {
    return {
      activeTab: 'inventory',
    }
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab
    },
  },
}
</script>

<style lang="scss" scoped>
.content-report {
  background: white;
  border-radius: 10px 10px 10px 10px;
  min-height: 500px;
}

.c-r-b1 {
  border-radius: 0px 10px 10px 10px;
}

.c-r-b {
  border-radius: 10px 10px 10px 10px;
}

.tabs {
  display: flex;
  width: 100%;
  gap: 2px;
  justify-content: flex-start !important;
}
.tab {
  display: flex;
  min-width: 250px;
  max-width: 250px;
  height: 35px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-transform: capitalize;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
}
.tab.active {
  background: white;
  color: black;
  border-radius: 10px 10px 0px 0px;
  padding: auto 30px;
}
</style>