<template>
  <div>
    <!-- ===== start category columns container ===== -->
    <div class="mb-4 product-container p-0 pb-5">
      <div class="product-item" v-for="(company, index) in companies" :key="index">
        <div class="d-flex justify-content-end bg-grey" style="height: auto">
          <div class="pt-2" style="width: 15px; height: 30px; cursor: pointer">
            <van-popover
              :id="company.id"
              v-model="company.selected"
              trigger="click"
              :actions="actions[index]"
              placement="bottom-end"
              @select="onSelect($event, company.id, index)"
            >
              <template #reference>
                <svg width="12" height="3" viewBox="0 0 12 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="1.51094" cy="1.50648" r="1.48207" fill="#202020"/>
                  <circle cx="5.96198" cy="1.50648" r="1.48207" fill="#202020"/>
                  <circle cx="10.413" cy="1.50648" r="1.48207" fill="#202020"/>
                </svg>
              </template>
            </van-popover>
          </div>
        </div>

        <!-- ==== category image ==== -->
        <div
          class="pm-layout-categories-top d-flex"
          @click="$router.push({ path: `/settings/companies/edit/${company.id}` })"
        >
          <img :src="company.logo" class="image-cover" />
        </div>
        <!-- ==== end category image ==== -->

        <div class="p-2 pl-3 pr-3 pm-layout-categories-bottom">
          <div>
            <h5 class="font-weight-bolder mb-0">{{ company.name }}</h5>
          </div>

          <div class="d-flex" v-if="company.isActive">
            <div>
              <h6 class="mt-0 mb-0 last-use-text text-weight-bold" style="color: green">Active Now</h6>
            </div>
          </div>

          <div class="d-flex justify-content-between" v-else>
            <div>
              <p class="mt-0 mb-0 last-use-text">{{ company.domain }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="product-item invisible" v-for="i in 10" :key="'v' + i"></div>
    </div>
    <!-- ===== end category columns container ===== -->
  </div>
</template>
<script>
import { Toast, Dialog } from 'vant'

export default {
  props: {
    companies: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      actions: [],
    }
  },
  methods: {
    async onSelect(event, id, index) {
      switch (event.text) {
        case 'Edit':
          this.$router.push({ path: `/settings/companies/edit/${id}` })
          break
        case 'Remove':
          await Dialog.confirm({
            title: this.$t('action.areYouSure'),
            cancelButtonText: this.$t('action.cancel'),
            confirmButtonText: this.$t('action.del'),
          })
          try {
            this.companies.splice(index, 1)
            const res = axios.delete(`/companies/${id}`)
            Toast.success(this.$t('state.deleted'))
          } catch (error) {
            Toast.fail(this.$t('state.delFail'))
          }
          break
        default:
          let res = await axios.post(`/companies/switch/${id}`);
          if(res) {
            Toast.success(this.$t('state.switched'))
            window.location.reload();
          }
          break
      }
    },
  },
  watch: {
    companies: {
      handler() {
        this.companies.map((company, index) => {
          const action = [
            {
              text: this.$t('action.switchTo', {company: company.name}),
              className: 'popoverDuplicate',
            },
            { text: this.$t('action.edit'), className: 'popoverEdit' },
            { text: this.$t('action.remove'), className: 'popoverDelete' },
          ]
          return this.actions.push(action)
        })
      },
    },
  },
}
</script>

<style scoped>
.last-use-text {
  font-weight: 500;
}
.last-use-date {
  font-weight: 500;
}
.pm-layout-categories-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: 135px;
}
.pm-layout-categories-bottom {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 100%;
  height: 55px;
  background-color: #f4f4f4;
}

.danger {
  color: red;
}
.product-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.product-item {
  flex: 1 1 200px;
}

.image-cover {
  width: 100%;
  object-fit: contain;
  background: #f4f4f4;
}
.bg-grey {
  background: #f4f4f4;
  border-radius: 10px 10px 0px 0px;
}
</style>
