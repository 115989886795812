<template>
  <svg
    width="246"
    style="background: #e7e7e7; border-radius: 26px; border: 2px solid white"
    height="174"
    viewBox="0 0 246 174"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_2501_13880"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="246"
      height="174"
    >
      <rect width="245.999" height="173.647" rx="24.1176" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_2501_13880)">
      <g clip-path="url(#clip0_2501_13880)">
        <path d="M438.94 38.5884H64.3135V272.73H438.94V38.5884Z" fill="#F4F4F4" />
        <path
          d="M142.36 84.6357H98.9141C97.4773 84.6357 96.3125 85.8005 96.3125 87.2373V94.5217C96.3125 95.9585 97.4773 97.1233 98.9141 97.1233H142.36C143.797 97.1233 144.962 95.9585 144.962 94.5217V87.2373C144.962 85.8005 143.797 84.6357 142.36 84.6357Z"
          :fill="color"
        />
        <path
          d="M196.474 84.6357H153.027C151.591 84.6357 150.426 85.8005 150.426 87.2373V94.5217C150.426 95.9585 151.591 97.1233 153.027 97.1233H196.474C197.91 97.1233 199.075 95.9585 199.075 94.5217V87.2373C199.075 85.8005 197.91 84.6357 196.474 84.6357Z"
          fill="#E2E2E2"
        />
        <path
          d="M250.587 84.6357H207.141C205.704 84.6357 204.539 85.8005 204.539 87.2373V94.5217C204.539 95.9585 205.704 97.1233 207.141 97.1233H250.587C252.024 97.1233 253.188 95.9585 253.188 94.5217V87.2373C253.188 85.8005 252.024 84.6357 250.587 84.6357Z"
          fill="#E2E2E2"
        />
        <path
          d="M151.206 68.7666H98.9141C97.4773 68.7666 96.3125 69.9314 96.3125 71.3682V78.6526C96.3125 80.0894 97.4773 81.2542 98.9141 81.2542H151.206C152.643 81.2542 153.807 80.0894 153.807 78.6526V71.3682C153.807 69.9314 152.643 68.7666 151.206 68.7666Z"
          fill="#E2E2E2"
        />
        <path
          d="M330.456 47.6936H223.011C221.574 47.6936 220.409 48.8584 220.409 50.2952V56.539C220.409 57.9758 221.574 59.1405 223.011 59.1405H330.456C331.893 59.1405 333.057 57.9758 333.057 56.539V50.2952C333.057 48.8584 331.893 47.6936 330.456 47.6936Z"
          fill="#E8E8E8"
        />
        <path d="M90.3292 38.5884H64.3135V272.73H90.3292V38.5884Z" :fill="color" />
        <path
          d="M65.6133 68.5064C65.6133 67.7881 66.1957 67.2056 66.9141 67.2056H90.3282V78.6525H66.9141C66.1957 78.6525 65.6133 78.07 65.6133 77.3517V68.5064Z"
          fill="#9B63BE"
        />
        <path
          d="M89.8105 67.2056H89.2869C88.9996 67.2056 88.7666 67.4385 88.7666 67.7259V78.1322C88.7666 78.4195 88.9996 78.6525 89.2869 78.6525H89.8105C90.0979 78.6525 90.3308 78.4195 90.3308 78.1322V67.7259C90.3308 67.4385 90.0979 67.2056 89.8105 67.2056Z"
          fill="#753A99"
        />
        <path
          d="M77.3205 157.859L74.4824 154.595L75.6176 152.892H79.0233L80.1586 154.595L77.3205 157.859Z"
          fill="#54C2FF"
        />
        <path d="M75.334 154.595L77.3206 157.717L76.1209 154.595H75.334Z" fill="url(#paint0_linear_2501_13880)" />
        <path d="M77.3217 157.149V154.595H76.1865L77.3217 157.149Z" fill="url(#paint1_linear_2501_13880)" />
        <path d="M77.3203 157.717L79.307 154.595H78.5201L77.3203 157.717Z" fill="url(#paint2_linear_2501_13880)" />
        <path d="M77.3203 154.595V157.149L78.4555 154.595H77.3203Z" fill="url(#paint3_linear_2501_13880)" />
        <path d="M77.3203 153.176V154.595H78.4555L77.3203 153.176Z" fill="#1BB9FD" />
        <path d="M77.3201 154.595L77.3217 153.176L76.1865 154.595H77.3201Z" fill="#1BB9FD" />
        <path
          d="M79.1198 152.608H75.5225C75.4536 152.608 75.3895 152.643 75.352 152.7L74.2321 154.411C74.1822 154.487 74.1897 154.587 74.2503 154.655L77.1688 157.932C77.2074 157.975 77.2629 158 77.3211 158C77.3794 158 77.4348 157.975 77.4735 157.932L80.3919 154.655C80.4525 154.587 80.46 154.487 80.4101 154.411L79.2902 152.7C79.2527 152.643 79.1885 152.608 79.1198 152.608ZM79.9308 154.42H78.6679L79.1082 153.163L79.9308 154.42ZM77.3211 157.493L77.3153 157.487L76.2671 154.622H78.3796L77.3346 157.478L77.3211 157.493ZM76.3192 154.42L77.3235 153.018L78.3277 154.42H76.3192ZM77.5687 153.012H78.9458L78.4935 154.303L77.5687 153.012ZM76.1532 154.303L75.7011 153.012H77.0781L76.1532 154.303ZM76.0507 154.622L76.948 157.074L74.7643 154.622H76.0507ZM78.5961 154.622H79.8779L77.702 157.065L78.5961 154.622ZM75.537 153.159L75.9788 154.42H74.7114L75.537 153.159Z"
          fill="url(#paint4_linear_2501_13880)"
        />
        <path
          d="M79.727 158.565L74.163 159.907C73.4646 160.075 73.0349 160.778 73.2033 161.476L73.5082 162.741C73.6766 163.439 74.3792 163.869 75.0776 163.7L80.6416 162.359C81.34 162.191 81.7697 161.488 81.6013 160.79L81.2965 159.525C81.1281 158.827 80.4254 158.397 79.727 158.565Z"
          fill="url(#paint5_linear_2501_13880)"
        />
        <path
          d="M75.2227 162.429C75.2496 162.541 75.3405 162.598 75.4543 162.571C75.5681 162.543 75.6229 162.451 75.596 162.339L75.489 161.895L75.9032 161.795C76.2652 161.708 76.4191 161.437 76.3472 161.139C76.2594 160.775 75.9694 160.681 75.6553 160.757L75.0839 160.895C74.9519 160.926 74.8781 161 74.9181 161.166L75.2227 162.429ZM75.3079 161.144L75.6174 161.07C75.8018 161.025 75.9344 161.065 75.9739 161.229C76.0079 161.37 75.9183 161.488 75.7681 161.525L75.4198 161.609L75.3079 161.144ZM76.8254 162.043C76.8523 162.154 76.9432 162.212 77.057 162.185C77.1708 162.157 77.2256 162.064 77.1987 161.953L77.0829 161.473L77.4516 161.384C77.6565 161.334 77.7368 161.438 77.8059 161.725C77.8219 161.79 77.8855 161.985 78.0904 161.935C78.1769 161.915 78.2514 161.834 78.2305 161.747C78.2119 161.67 78.1448 161.611 78.0992 161.423C78.0592 161.256 77.9916 161.126 77.7938 161.144L77.7927 161.14C77.9603 161.056 78.0228 160.856 77.98 160.678C77.929 160.467 77.7519 160.252 77.308 160.359L76.6866 160.508C76.5546 160.54 76.4807 160.613 76.5208 160.779L76.8254 162.043ZM76.9106 160.758L77.2952 160.665C77.4591 160.625 77.5771 160.645 77.6193 160.821C77.6638 161.005 77.5314 161.075 77.3903 161.109L77.017 161.199L76.9106 160.758ZM78.275 160.971C78.3947 161.467 78.7281 161.791 79.3154 161.65C79.8753 161.515 80.0435 161.033 79.9321 160.571C79.8163 160.091 79.4388 159.804 78.9016 159.933C78.3644 160.063 78.1592 160.49 78.275 160.971ZM78.6483 160.881C78.5726 160.567 78.669 160.307 78.9741 160.234C79.2791 160.16 79.4831 160.347 79.5588 160.661C79.6345 160.975 79.5821 161.267 79.2429 161.349C78.8924 161.434 78.7241 161.195 78.6483 160.881Z"
          fill="white"
        />
        <path
          d="M79.9233 93.3571V92.176C79.9233 91.8092 79.7568 91.6609 79.3431 91.6609H78.2921C77.8784 91.6609 77.7119 91.8092 77.7119 92.176V93.3545C77.7119 93.7239 77.8784 93.8696 78.2921 93.8696H79.3431C79.7568 93.8722 79.9233 93.7239 79.9233 93.3571Z"
          stroke="white"
          stroke-width="0.390236"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M79.9233 96.2835V95.2325C79.9233 94.8188 79.7568 94.6523 79.3431 94.6523H78.2921C77.8784 94.6523 77.7119 94.8188 77.7119 95.2325V96.2835C77.7119 96.6972 77.8784 96.8637 78.2921 96.8637H79.3431C79.7568 96.8637 79.9233 96.6972 79.9233 96.2835Z"
          stroke="white"
          stroke-width="0.390236"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M76.932 93.3571V92.176C76.932 91.8092 76.7655 91.6609 76.3519 91.6609H75.3009C74.8872 91.6609 74.7207 91.8092 74.7207 92.176V93.3545C74.7207 93.7239 74.8872 93.8696 75.3009 93.8696H76.3519C76.7655 93.8722 76.932 93.7239 76.932 93.3571Z"
          stroke="white"
          stroke-width="0.390236"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M76.932 96.2835V95.2325C76.932 94.8188 76.7655 94.6523 76.3519 94.6523H75.3009C74.8872 94.6523 74.7207 94.8188 74.7207 95.2325V96.2835C74.7207 96.6972 74.8872 96.8637 75.3009 96.8637H76.3519C76.7655 96.8637 76.932 96.6972 76.932 96.2835Z"
          stroke="white"
          stroke-width="0.390236"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M79.9232 114.034V114.664C79.9232 115.075 79.6631 115.335 79.252 115.335H78.3623V113.516C78.3623 113.228 78.599 112.993 78.8878 112.993C79.1714 112.996 79.4316 113.11 79.6189 113.298C79.8062 113.488 79.9232 113.748 79.9232 114.034Z"
          stroke="white"
          stroke-width="0.390236"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M74.7207 114.294V117.936C74.7207 118.152 74.9653 118.275 75.137 118.145L75.5818 117.812C75.6859 117.733 75.8316 117.744 75.9252 117.838L76.3571 118.272C76.4586 118.373 76.6251 118.373 76.7265 118.272L77.1636 117.835C77.2546 117.744 77.4003 117.733 77.5018 117.812L77.9467 118.145C78.1184 118.272 78.3629 118.15 78.3629 117.936V113.514C78.3629 113.228 78.597 112.993 78.8832 112.993H76.0215H75.7613C74.9809 112.993 74.7207 113.459 74.7207 114.034V114.294Z"
          stroke="white"
          stroke-width="0.390236"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M75.7617 114.814H77.3227"
          stroke="white"
          stroke-width="0.390236"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M75.9561 115.855H77.1268"
          stroke="white"
          stroke-width="0.390236"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M75.0254 135.742L77.3226 137.071L79.6042 135.75"
          stroke="white"
          stroke-width="0.390236"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M77.3203 139.428V137.069"
          stroke="white"
          stroke-width="0.390236"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M76.7819 134.451L75.3927 135.221C75.0779 135.396 74.8203 135.833 74.8203 136.192V137.661C74.8203 138.02 75.0779 138.458 75.3927 138.632L76.7819 139.405C77.0785 139.568 77.565 139.568 77.8616 139.405L79.2508 138.632C79.5656 138.458 79.8231 138.02 79.8231 137.661V136.192C79.8231 135.833 79.5656 135.396 79.2508 135.221L77.8616 134.449C77.5624 134.285 77.0785 134.285 76.7819 134.451Z"
          stroke="white"
          stroke-width="0.390236"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M77.1906 58.6206C80.1361 58.6206 82.5239 56.2328 82.5239 53.2873C82.5239 50.3419 80.1361 47.9541 77.1906 47.9541C74.2452 47.9541 71.8574 50.3419 71.8574 53.2873C71.8574 56.2328 74.2452 58.6206 77.1906 58.6206Z"
          fill="white"
        />
        <path
          d="M76.547 70.546L75.1448 71.6387C74.9106 71.8208 74.7207 72.2084 74.7207 72.5024V74.4302C74.7207 75.0337 75.2124 75.528 75.816 75.528H78.8286C79.4322 75.528 79.9239 75.0337 79.9239 74.4328V72.5388C79.9239 72.224 79.7131 71.8208 79.4556 71.6413L77.8478 70.5148C77.4836 70.2599 76.8982 70.2729 76.547 70.546Z"
          stroke="white"
          stroke-width="0.390236"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M77.3203 74.4875V73.707"
          stroke="white"
          stroke-width="0.390236"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M140.28 47.6936H99.6953C98.2585 47.6936 97.0938 48.8584 97.0938 50.2952V51.8561C97.0938 53.2929 98.2585 54.4577 99.6953 54.4577H140.28C141.717 54.4577 142.881 53.2929 142.881 51.8561V50.2952C142.881 48.8584 141.717 47.6936 140.28 47.6936Z"
          fill="#E2E2E2"
        />
        <path
          d="M124.41 56.5388H99.6953C98.2585 56.5388 97.0938 57.7036 97.0938 59.1404C97.0938 60.5772 98.2585 61.742 99.6953 61.742H124.41C125.847 61.742 127.012 60.5772 127.012 59.1404C127.012 57.7036 125.847 56.5388 124.41 56.5388Z"
          fill="#E2E2E2"
        />
        <path
          d="M124.41 103.367H99.6953C98.2585 103.367 97.0938 104.532 97.0938 105.969C97.0938 107.406 98.2585 108.571 99.6953 108.571H124.41C125.847 108.571 127.012 107.406 127.012 105.969C127.012 104.532 125.847 103.367 124.41 103.367Z"
          fill="#E2E2E2"
        />
        <path
          d="M150.425 112.733H98.9141C97.4773 112.733 96.3125 113.898 96.3125 115.334V200.926C96.3125 202.363 97.4773 203.528 98.9141 203.528H150.425C151.862 203.528 153.027 202.363 153.027 200.926V115.334C153.027 113.898 151.862 112.733 150.425 112.733Z"
          fill="white"
        />
        <path
          d="M108.021 172.309H102.558C101.839 172.309 101.257 172.891 101.257 173.61V179.073C101.257 179.792 101.839 180.374 102.558 180.374H108.021C108.739 180.374 109.322 179.792 109.322 179.073V173.61C109.322 172.891 108.739 172.309 108.021 172.309Z"
          fill="#E2E2E2"
        />
        <path
          d="M117.387 172.309H111.924C111.205 172.309 110.623 172.891 110.623 173.61V179.073C110.623 179.792 111.205 180.374 111.924 180.374H117.387C118.106 180.374 118.688 179.792 118.688 179.073V173.61C118.688 172.891 118.106 172.309 117.387 172.309Z"
          fill="#E2E2E2"
        />
        <path
          d="M126.751 172.309H121.288C120.57 172.309 119.987 172.891 119.987 173.61V179.073C119.987 179.792 120.57 180.374 121.288 180.374H126.751C127.47 180.374 128.052 179.792 128.052 179.073V173.61C128.052 172.891 127.47 172.309 126.751 172.309Z"
          fill="#E2E2E2"
        />
        <path
          d="M136.116 172.309H130.652C129.934 172.309 129.352 172.891 129.352 173.61V179.073C129.352 179.792 129.934 180.374 130.652 180.374H136.116C136.834 180.374 137.416 179.792 137.416 179.073V173.61C137.416 172.891 136.834 172.309 136.116 172.309Z"
          fill="#E2E2E2"
        />
        <path
          d="M145.484 172.309H140.021C139.302 172.309 138.72 172.891 138.72 173.61V179.073C138.72 179.792 139.302 180.374 140.021 180.374H145.484C146.202 180.374 146.785 179.792 146.785 179.073V173.61C146.785 172.891 146.202 172.309 145.484 172.309Z"
          fill="#E2E2E2"
        />
        <path
          d="M96.3125 115.334C96.3125 113.898 97.4772 112.733 98.9141 112.733H150.425C151.862 112.733 153.027 113.898 153.027 115.334V154.618H96.3125V115.334Z"
          fill="#E2E2E2"
        />
        <path
          d="M210.781 112.733H159.27C157.833 112.733 156.668 113.898 156.668 115.334V200.926C156.668 202.363 157.833 203.528 159.27 203.528H210.781C212.218 203.528 213.382 202.363 213.382 200.926V115.334C213.382 113.898 212.218 112.733 210.781 112.733Z"
          fill="white"
        />
        <path
          d="M156.668 115.334C156.668 113.898 157.833 112.733 159.27 112.733H210.781C212.218 112.733 213.382 113.898 213.382 115.334V154.618H156.668V115.334Z"
          fill="#E2E2E2"
        />
        <path
          d="M168.639 172.309H162.915C162.197 172.309 161.614 172.891 161.614 173.61V179.333C161.614 180.052 162.197 180.634 162.915 180.634H168.639C169.357 180.634 169.939 180.052 169.939 179.333V173.61C169.939 172.891 169.357 172.309 168.639 172.309Z"
          fill="#E2E2E2"
        />
        <path
          d="M178.002 172.309H172.278C171.56 172.309 170.978 172.891 170.978 173.61V179.333C170.978 180.052 171.56 180.634 172.278 180.634H178.002C178.72 180.634 179.303 180.052 179.303 179.333V173.61C179.303 172.891 178.72 172.309 178.002 172.309Z"
          fill="#E2E2E2"
        />
        <path
          d="M187.367 172.309H181.644C180.925 172.309 180.343 172.891 180.343 173.61V179.333C180.343 180.052 180.925 180.634 181.644 180.634H187.367C188.085 180.634 188.668 180.052 188.668 179.333V173.61C188.668 172.891 188.085 172.309 187.367 172.309Z"
          fill="#E2E2E2"
        />
        <path
          d="M196.732 172.309H191.009C190.29 172.309 189.708 172.891 189.708 173.61V179.333C189.708 180.052 190.29 180.634 191.009 180.634H196.732C197.451 180.634 198.033 180.052 198.033 179.333V173.61C198.033 172.891 197.451 172.309 196.732 172.309Z"
          fill="#E2E2E2"
        />
        <path
          d="M271.138 112.733H219.627C218.19 112.733 217.025 113.898 217.025 115.334V200.926C217.025 202.363 218.19 203.528 219.627 203.528H271.138C272.575 203.528 273.74 202.363 273.74 200.926V115.334C273.74 113.898 272.575 112.733 271.138 112.733Z"
          fill="white"
        />
        <path
          d="M217.025 115.334C217.025 113.898 218.19 112.733 219.627 112.733H271.138C272.575 112.733 273.74 113.898 273.74 115.334V154.618H217.025V115.334Z"
          fill="#E2E2E2"
        />
        <path
          d="M228.993 172.309H223.27C222.551 172.309 221.969 172.891 221.969 173.61V179.333C221.969 180.052 222.551 180.634 223.27 180.634H228.993C229.711 180.634 230.294 180.052 230.294 179.333V173.61C230.294 172.891 229.711 172.309 228.993 172.309Z"
          fill="#E2E2E2"
        />
        <path
          d="M238.358 172.309H232.635C231.916 172.309 231.334 172.891 231.334 173.61V179.333C231.334 180.052 231.916 180.634 232.635 180.634H238.358C239.077 180.634 239.659 180.052 239.659 179.333V173.61C239.659 172.891 239.077 172.309 238.358 172.309Z"
          fill="#E2E2E2"
        />
        <path
          d="M247.723 172.309H242C241.282 172.309 240.699 172.891 240.699 173.61V179.333C240.699 180.052 241.282 180.634 242 180.634H247.723C248.442 180.634 249.024 180.052 249.024 179.333V173.61C249.024 172.891 248.442 172.309 247.723 172.309Z"
          fill="#E2E2E2"
        />
        <path
          d="M127.793 160.342H103.858C102.422 160.342 101.257 161.506 101.257 162.943V163.984C101.257 165.421 102.422 166.585 103.858 166.585H127.793C129.23 166.585 130.394 165.421 130.394 163.984V162.943C130.394 161.506 129.23 160.342 127.793 160.342Z"
          fill="#E2E2E2"
        />
        <path
          d="M188.15 160.342H164.216C162.779 160.342 161.614 161.506 161.614 162.943V163.984C161.614 165.421 162.779 166.585 164.216 166.585H188.15C189.587 166.585 190.752 165.421 190.752 163.984V162.943C190.752 161.506 189.587 160.342 188.15 160.342Z"
          fill="#E2E2E2"
        />
        <path
          d="M248.505 160.342H224.57C223.134 160.342 221.969 161.506 221.969 162.943V163.984C221.969 165.421 223.134 166.585 224.57 166.585H248.505C249.942 166.585 251.106 165.421 251.106 163.984V162.943C251.106 161.506 249.942 160.342 248.505 160.342Z"
          fill="#E2E2E2"
        />
        <path
          d="M145.224 160.342H141.321C139.884 160.342 138.72 161.506 138.72 162.943V163.984C138.72 165.421 139.884 166.585 141.321 166.585H145.224C146.66 166.585 147.825 165.421 147.825 163.984V162.943C147.825 161.506 146.66 160.342 145.224 160.342Z"
          fill="#E2E2E2"
        />
        <path
          d="M205.58 160.342H201.678C200.241 160.342 199.076 161.506 199.076 162.943V163.984C199.076 165.421 200.241 166.585 201.678 166.585H205.58C207.017 166.585 208.182 165.421 208.182 163.984V162.943C208.182 161.506 207.017 160.342 205.58 160.342Z"
          fill="#E2E2E2"
        />
      </g>
      <rect
        x="62.7056"
        y="36.9805"
        width="377.842"
        height="237.357"
        rx="17.6862"
        stroke="#F3F3F3"
        stroke-width="3.21568"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2501_13880"
        x1="76.1854"
        y1="154.595"
        x2="76.1854"
        y2="156.865"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1BCCFD" />
        <stop offset="1" stop-color="#0032E1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2501_13880"
        x1="76.7541"
        y1="154.595"
        x2="76.7541"
        y2="157.149"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1BCCFD" />
        <stop offset="1" stop-color="#0032E1" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2501_13880"
        x1="78.4555"
        y1="154.595"
        x2="78.4555"
        y2="156.865"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1BCCFD" />
        <stop offset="1" stop-color="#0032E1" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2501_13880"
        x1="77.8879"
        y1="154.595"
        x2="77.8879"
        y2="157.149"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1BCCFD" />
        <stop offset="1" stop-color="#0032E1" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2501_13880"
        x1="77.3211"
        y1="152.608"
        x2="77.3211"
        y2="158"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#17D5FF" />
        <stop offset="1" stop-color="#7800C1" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2501_13880"
        x1="73.6789"
        y1="162.293"
        x2="80.443"
        y2="159.301"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#19D2FE" />
        <stop offset="1" stop-color="#2449CC" />
      </linearGradient>
      <clipPath id="clip0_2501_13880">
        <rect x="64.3135" y="38.5884" width="374.627" height="234.142" rx="16.0784" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: ['color'],
}
</script>
