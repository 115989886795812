<template>
  <div class="bg-white">
    <div class="d-flex justify-content-between align-items-center">
      <custom-search v-model="params.keyword" @search="fetchData()"/>
      <van-button @click="$router.push('/management/employees/create')" class="add-button" type="default">
        <van-icon name="add-o" size="20" class="mr-2"/>
        {{ $t('mngmt.employee.newEmployee') }}
      </van-button>
    </div>

    <van-tabs @change="changeTab" v-model="activeTab" line-width="140" color="var(--theme)" class="mt-3 custom-tab">
      <van-tab v-for="(tab, index) in tabList" :title="tab" :key="index">
        <div v-if="loaded" class="mt-2">
          <div v-if="employees.length > 0">
            <table class="table my-border-table mt-0">
              <tbody>
              <tr v-for="(employee, index) in employees" :key="index">
                <td>
                  <div class="d-flex align-items-center">
                    <img :src="employee.image" alt="" class="profile-image ml-3"/>
                    <div class="d-flex">
                      <div class="ml-3" @click="$router.push(`/management/employees/edit/${employee.id}`)">
                        <p class="mb-0 text-bold">{{ employee.name }}</p>
                        <p class="mb-0 text-muted text-sm">{{ employee.email }}, {{ employee.phone }}</p>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-right">
                  <van-popover
                      v-model="employee.show_menu"
                      placement="bottom-end"
                      :close-on-click-outside="true"
                      trigger="click"
                      @open="menuOpen(index)"
                      :actions="getActions(actions)"
                      @select="menuSelect($event, employee.id)"
                  >
                    <template #reference>
                      <van-icon name="ellipsis" size="20" class="pointer"/>
                    </template>
                  </van-popover>
                </td>
              </tr>
              </tbody>
            </table>
            <b-pagination
                :value="params.page"
                :per-page="params.limit"
                :total-rows="total"
                @change="changePage"
                first-number
                last-number
                pills
                align="center"
                class="mt-4"
            ></b-pagination>
          </div>
          <van-empty image="/image/empty-lists/empty-admin.svg" v-else
                     :description="$t('mngmt.employee.emptyEmployee')"/>
        </div>

        <div v-else class="loading">
          <van-loading type="spinner"/>
        </div>
      </van-tab>
    </van-tabs>

    <van-dialog
        v-model="showConfirmDialog"
        title="Are you sure?"
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        show-cancel-button
        @confirm="deleteEmployee()"
    >
      <div v-if="activeTab == 0" class="check-force">
        <van-checkbox v-model="deleteParams.force" shape="square" icon-size="17px">Delete permanently?</van-checkbox>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {Toast} from 'vant'

export default {
  data() {
    return {
      showConfirmDialog: false,
      activeTab: 0,
      tabList: [this.$t('mngmt.employee.currentEmployee'), this.$t('mngmt.employee.exEmployee')],
      actions: [
        {text: this.$t('mngmt.employee.edit'), icon: 'edit', value: 'edit'},
        {text: this.$t('mngmt.employee.clone'), icon: 'cluster-o', value: 'clone'},
        {text: this.$t('mngmt.employee.restore'), icon: 'revoke', value: 'restore'},
        {text: this.$t('mngmt.employee.del'), icon: 'delete-o', value: 'delete'},
      ],
      params: {
        keyword: '',
        page: 1,
        limit: 10,
        type: 'current',
      },
      employees: [],
      total: 0,
      loaded: false,
      deleteParams: {
        id: null,
        force: false,
      },
    }
  },
  methods: {
    getActions(actions) {
      return actions.filter((action) => {
        return this.activeTab == 0 ? action.value != 'restore' : action.value == 'restore' || action.value == 'delete'
      })
    },
    changePage(page) {
      this.params.page = page
      this.fetchData()
    },
    changeTab(name) {
      this.employees = this.employees.map((employee) => {
        employee.show_menu = false
        return employee
      })
      this.params.type = name == 0 ? 'current' : 'ex'
      this.fetchData()
    },
    menuOpen(index) {
      this.employees = this.employees.map((employee) => {
        employee.show_menu = false
        return employee
      })
      this.employees[index].show_menu = true
    },
    menuSelect(e, id) {
      switch (e.value) {
        case 'edit':
          this.$router.push(`/management/employees/edit/${id}`)
          break
        case 'delete':
          this.deleteParams.id = id
          this.deleteParams.force = this.activeTab == 0 ? false : true
          this.showConfirmDialog = true
          break
        case 'clone':
          this.$router.push(`/management/employees/clone/${id}`)
          break
        case 'restore':
          this.restoreEmployee(id)
          break
      }
    },
    async deleteEmployee() {
      let id = this.deleteParams.id
      let force = this.deleteParams.force ? 1 : 0
      let res = await axios.delete(`employees/${id}?force=${force}`)
      if (res) {
        Toast.success(this.$t('state.deleted'))
        this.fetchData()
      }
    },
    async restoreEmployee(id) {
      let res = await axios.put(`employees/${id}/restore`)
      if (res) {
        Toast.success(this.$t('state.restored'))
        this.fetchData()
      }
    },
    async fetchData() {
      let res = await axios.get('employees', {params: this.params})
      if (res) {
        this.employees = res.data.data.data.map((employee) => {
          employee.show_menu = false
          return employee
        })
        this.total = res.data.data.total
        this.loaded = true
      }
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>

<style lang="scss" scoped>
.my-border-table {
  td {
    padding-left: 0 !important;
  }
}
</style>

<style lang="scss">
.custom-tab {
  .van-tab {
    flex: 140px 0 0 !important;
    font-size: 12px !important;
    justify-content: flex-start !important;
    margin-right: 40px;
  }
}

.check-force {
  font-size: 13px;
  padding: 20px 15px;

  span {
    color: rgba(0, 0, 0, 0.6) !important;
  }
}

.profile-image {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  object-fit: cover;
}
</style>