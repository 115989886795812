<template>
  <div class="bg-white">
    <div class="d-flex justify-content-between align-items-center">
      <custom-search v-model="keyword" @keypress="searchData" @search="searchData" />
      <van-button @click="$router.push('/management/bank-accounts/create'); $events.fire('nextStep')" class="add-button" type="default" pos-tooltip-step="11">
        <van-icon name="add-o" size="20" class="mr-2" />
       {{ $t('mngmt.bankAccount.addAssets') }}
      </van-button>
    </div>
    <DataView type="bank-account" :data="accounts" />
    <div v-if="!loaded" class="loading">
      <van-loading type="spinner" />
    </div>
  </div>
</template>

<script>
import DataView from '../components/DataView'
export default {
  components: { DataView },
  data() {
    return {
      loaded: false,
      accounts: {},
      keyword: null,
      total: 0,
      params: {
        page: 1,
        limit: 100,
      },
    }
  },
  methods: {
    searchData() {
      if (!this.keyword) {
        this.accounts = this.original
      } else {
        let k = this.keyword.toLowerCase()
        this.accounts = this.original.filter((acc) => {
          return lowerCase(acc.owner_name).includes(k) || lowerCase(acc.account_number).includes(k)
        })
      }
    },

    async fetchData() {
      try {
        const res = await axios.get('/accounts', {
          params: this.params,
        })
        if (res) {
          this.loaded = true
          this.original = res.data.data
          this.accounts = res.data.data
          this.$events.fire('stepDataReady')
        }
      } catch (error) {
        console.log(error)
      }
    },
    changePage(page) {
      this.params.page = page
      this.loaded = false
      this.fetchData()
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>
