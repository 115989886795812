<template>
  <van-popup v-model="show" class="custom-popup">
    <h1 class="mb-4">
      {{ params.id && clone ? 'Clone' : params.id ? 'Edit' : 'Create' }}
      COA
    </h1>

    <Input
      :params="params"
      :errors="errors"
      field="name"
      label="Name"
    />

    <Input
      :params="params"
      :errors="errors"
      field="coa"
      label="Coa Number"
      type="number"
      maxlength="6"
      :prefix="prefix"
    />

    <label class="label">Group</label>
    <b-form-select v-model="params.group" :options="groupOptions" @change="changePrefix" class="input"></b-form-select>

    <Button :type="params.id ? 'edit' : 'create'" :loading="loading" @click.native="saveData()" />

  </van-popup>
</template>

<script>
import { Toast } from 'vant'
export default {
  methods : {
    async saveData() {
      this.loading = true
      let params = {...this.params}
      params.coa = `${this.prefix}${params.coa}`
      let res = (params.id && !this.clone)
        ? await axios.put(`/chart_of_accounts/${params.id}`, params)
        : await axios.post('chart_of_accounts', params)
      if(res) {
        if(res.data.code == 422) {
          this.errors = res.data.error
        } else {
          Toast.success(this.$t('state.created'))
          this.show = false
          this.$emit('done')
          this.errors = {}
        }
        this.loading = false
      }
    },
    changePrefix(group) {
      this.prefix = group == 'asset' ? 1
       : group == 'liability' ? 2
       : group == 'equity' ? 3
       : group == 'revenue' ? 4
       : group == 'expense' ? 5 
       : 6
    },
    openForm(params = false, clone = false) {
      if(params) {
        this.changePrefix(params.group)
        this.params = {...params}
        this.params.coa = this.params.coa.toString().slice(1)
      } else {
        this.prefix = 6
        this.params = {
          name : null,
          coa : null,
          group : 'other'
        }
      }
      this.clone = clone
      this.show = true
    }
  },
  data() {
    return {
      loading : false,
      show : false,
      prefix : 6,
      clone : false,
      errors : {},
      params : {
        name : null,
        coa : null,
        group : 'other'
      },
      groupOptions : [
        'asset',
        'liability',
        'equity',
        'revenue',
        'expense',
        'other'
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
  .custom-popup {
    width : 540px;
    border-radius: 10px;
    padding : 20px 20px 0 20px;
  }
  .input {
    height: auto !important;
  }
</style>