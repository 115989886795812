<template>
  <!-- ==== products container ==== -->
  <div class="bg-white">
    <div class="d-flex justify-content-between align-items-center">
      <custom-search v-model="params.keyword" @search="getProducts()" />
      <div class="d-flex justify-content-end">
        <van-button @click="$refs.productTable.addStock()" pos-tooltip-step="16" class="add-button mr-2" type="default">
          <van-icon name="plus" size="15" class="mr-2" />
         {{ $t('mngmt.product.addStock') }} 
        </van-button>
        <van-button @click="$router.push(`/management/products/create/single`); $events.fire('nextStep')" class="add-button" type="default" pos-tooltip-step="3">
          <van-icon name="add-o" size="20" class="mr-2" />
         {{ $t('mngmt.product.newProduct')}} 
        </van-button>
      </div>
    </div>

    <div v-if="loaded">
      <div v-if="products.length > 0">
        <div class="mt-4">
          <ProductsDataTable
            :params="params"
            :products="products"
            :total="total"
            @pageChange="getProducts()"
            v-if="isRow"
            ref="productTable"
          />
        </div>
      </div>
      <van-empty image="/image/empty-lists/empty-product.svg" v-else :description="params.keyword ? $t('mngmt.product.notFound'): $t('mngmt.product.noProduct')" />
    </div>

    <div v-else class="loading">
      <van-loading type="spinner" />
    </div>

    <!-- <ProductTypeModal v-model="showProductTypeModal" /> -->
  </div>
  <!-- ==== end products container ==== -->
</template>

<script>
import ProductsDataTable from './components/ProductsDataTable'
import ProductTypeModal from './components/ProductTypeModal'

export default {
  props: ['id'],
  components: { ProductsDataTable, ProductTypeModal },
  data() {
    return {
      loaded: false,
      showProductTypeModal: false,
      isRow: true,
      products: [],
      categories: {},
      params: {
        page: 1,
        limit: 10,
        filter_type: 'product',
        keyword: '',
      },
      total: 0,
    }
  },
  methods: {
    setProducts(id) {
      this.products = this.categories[id].products
      this.categories.map((category, index) => {
        category.isActive = false
        return true
      })
      this.categories[id].isActive = true
    },

    /**
     * GET api/categories
     * fetch categories
     */
    async fetchCategories() {
      try {
        const res = await axios.get('/categories')
        this.categories = res.data.data
        this.products = this.categories[this.id ? this.id : 0].products
        this.categories[this.id ? this.id : 0].isActive = true
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },

    async getProducts() {
      try {
        let res = await axios.get('/products', { params: this.params })
        if (res) {
          this.products = res.data.data.data
          this.total = res.data.data.total
          this.loaded = true
          this.$events.fire('stepDataReady');
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
  mounted() {
    // this.fetchCategories()
    this.getProducts()
    console.log(this.$refs)
  },
}
</script>

<style scoped>
h1 {
  font-size: 15px;
  font-weight: 700;
}
.product-category-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scroll-behavior: smooth;
}
.product-category-item {
  margin-right: 80px;
  font-weight: bold;
  cursor: pointer;
  padding-bottom: 5px;
  font-size: 13px;
  flex-shrink: 0;
}
.pm-product-add-button {
  border-radius: 10px;
  height: 40px;
  border: 2px solid #f4f4f4;
  color: #000;
  font-size: 13px;
  font-weight: 500;
}
.pm-product-layout-button {
  border-radius: 10px;
  height: 40px;
  width: 40px;
  border: 2px solid #f4f4f4;
}
.pm-product-layout-column-icon {
  background-image: url('/image/dashboard-pm/element-equal.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
  width: 20px;
}
.pm-product-layout-row-icon {
  background-image: url('/image/dashboard-pm/task-square.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
  width: 20px;
}
.products-management-container {
  height: auto;
  margin-right: 15px;
}

.save-button {
  padding: 11px 60px;
  border-radius: 10px;
}

.van-popover--light .van-popover__content {
  background-color: #fff;
  box-shadow: 0 2px 12px rgb(50 50 51 / 12%);
  width: 500%;
  margin-left: -200%;
}
.product-category-item.active,
.product-category-item:hover {
  font-weight: bolder;
  border-bottom: 3px solid var(--theme);
}
.line {
  width: 100%;
  border-bottom: 3px solid #f4f4f4;
  margin: -3px -20px 0 -20px;
}
</style>
