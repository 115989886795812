<template>
  <van-popup :value="value" @input="$emit('input', false)" class="custom-popup" closeable>
    <div>
      <h2>{{ $t('mngmt.product.newProduct') }} </h2>

      <div class="products">
        <div @click="type = 'single'; $events.fire('nextStep')" class="product" :class="type == 'single' ? 'active' : ''" pos-tooltip-step="4">
          <div class="icon">
            <svg width="55" height="55" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle r="30.5" transform="matrix(1 0 0 -1 30.5 30.5)" fill="#DEDEDE"/>
              <path
                  d="M42.8568 25.2791L31.9336 31.6061C31.4938 31.8614 30.9405 31.8614 30.4866 31.6061L19.5633 25.2791C18.7831 24.8251 18.5845 23.7612 19.1803 23.0944C19.5917 22.6263 20.0599 22.2433 20.5564 21.9738L28.2452 17.7179C29.8908 16.7958 32.5578 16.7958 34.2033 17.7179L41.8922 21.9738C42.3887 22.2433 42.8568 22.6405 43.2682 23.0944C43.8357 23.7612 43.6371 24.8251 42.8568 25.2791Z"
                  fill="#292D32"
              />
              <path
                  d="M30.4017 34.2451V43.92C30.4017 44.9981 29.3094 45.7074 28.3448 45.2393C25.4224 43.8065 20.4999 41.1253 20.4999 41.1253C18.7692 40.1465 17.3506 37.6781 17.3506 35.6495V28.3295C17.3506 27.2088 18.528 26.4995 19.4927 27.0528L29.6924 32.9684C30.118 33.2379 30.4017 33.7202 30.4017 34.2451Z"
                  fill="#292D32"
              />
              <path
                  d="M32.0186 34.2451V43.92C32.0186 44.9981 33.1109 45.7074 34.0755 45.2393C36.9979 43.8065 41.9204 41.1253 41.9204 41.1253C43.6511 40.1465 45.0697 37.6781 45.0697 35.6495V28.3295C45.0697 27.2088 43.8923 26.4995 42.9276 27.0528L32.7279 32.9684C32.3023 33.2379 32.0186 33.7202 32.0186 34.2451Z"
                  fill="#292D32"
              />
            </svg>
          </div>
          <div class="text">{{ $t('mngmt.product.singleProduct') }}</div>
        </div>
        <div @click="type = 'group'" class="product" :class="type == 'group' ? 'active' : ''">
          <div class="icon">
            <svg width="100" height="100" viewBox="0 0 103 103" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle r="21.5" transform="matrix(1 0 0 -1 21.5 21.5)" fill="#DEDEDE"/>
              <circle r="21.5" transform="matrix(1 0 0 -1 21.5 81.5)" fill="#DEDEDE"/>
              <circle r="21.5" transform="matrix(1 0 0 -1 81.5 21.5)" fill="#DEDEDE"/>
              <circle r="21.5" transform="matrix(1 0 0 -1 81.5 81.5)" fill="#DEDEDE"/>
              <path d="M25.5 22H74" stroke="#DEDEDE" stroke-width="3" stroke-linecap="round"/>
              <path d="M25.5 82H74" stroke="#DEDEDE" stroke-width="3" stroke-linecap="round"/>
              <path d="M22 70.5L22 22" stroke="#DEDEDE" stroke-width="3" stroke-linecap="round"/>
              <path d="M82 70.5V22" stroke="#DEDEDE" stroke-width="3" stroke-linecap="round"/>
              <path
                  d="M30.2102 17.8199L22.5102 22.2799C22.2002 22.4599 21.8102 22.4599 21.4902 22.2799L13.7902 17.8199C13.2402 17.4999 13.1002 16.7499 13.5202 16.2799C13.8102 15.9499 14.1402 15.6799 14.4902 15.4899L19.9102 12.4899C21.0702 11.8399 22.9502 11.8399 24.1102 12.4899L29.5302 15.4899C29.8802 15.6799 30.2102 15.9599 30.5002 16.2799C30.9002 16.7499 30.7602 17.4999 30.2102 17.8199Z"
                  fill="#292D32"
              />
              <path
                  d="M21.4305 24.1399V30.9599C21.4305 31.7199 20.6605 32.2199 19.9805 31.8899C17.9205 30.8799 14.4505 28.9899 14.4505 28.9899C13.2305 28.2999 12.2305 26.5599 12.2305 25.1299V19.9699C12.2305 19.1799 13.0605 18.6799 13.7405 19.0699L20.9305 23.2399C21.2305 23.4299 21.4305 23.7699 21.4305 24.1399Z"
                  fill="#292D32"
              />
              <path
                  d="M22.5703 24.1399V30.9599C22.5703 31.7199 23.3403 32.2199 24.0203 31.8899C26.0803 30.8799 29.5503 28.9899 29.5503 28.9899C30.7703 28.2999 31.7703 26.5599 31.7703 25.1299V19.9699C31.7703 19.1799 30.9403 18.6799 30.2603 19.0699L23.0703 23.2399C22.7703 23.4299 22.5703 23.7699 22.5703 24.1399Z"
                  fill="#292D32"
              />
              <path
                  d="M30.2102 77.8199L22.5102 82.2799C22.2002 82.4599 21.8102 82.4599 21.4902 82.2799L13.7902 77.8199C13.2402 77.4999 13.1002 76.7499 13.5202 76.2799C13.8102 75.9499 14.1402 75.6799 14.4902 75.4899L19.9102 72.4899C21.0702 71.8399 22.9502 71.8399 24.1102 72.4899L29.5302 75.4899C29.8802 75.6799 30.2102 75.9599 30.5002 76.2799C30.9002 76.7499 30.7602 77.4999 30.2102 77.8199Z"
                  fill="#292D32"
              />
              <path
                  d="M21.4305 84.1399V90.9599C21.4305 91.7199 20.6605 92.2199 19.9805 91.8899C17.9205 90.8799 14.4505 88.9899 14.4505 88.9899C13.2305 88.2999 12.2305 86.5599 12.2305 85.1299V79.9699C12.2305 79.1799 13.0605 78.6799 13.7405 79.0699L20.9305 83.2399C21.2305 83.4299 21.4305 83.7699 21.4305 84.1399Z"
                  fill="#292D32"
              />
              <path
                  d="M22.5703 84.1399V90.9599C22.5703 91.7199 23.3403 92.2199 24.0203 91.8899C26.0803 90.8799 29.5503 88.9899 29.5503 88.9899C30.7703 88.2999 31.7703 86.5599 31.7703 85.1299V79.9699C31.7703 79.1799 30.9403 78.6799 30.2603 79.0699L23.0703 83.2399C22.7703 83.4299 22.5703 83.7699 22.5703 84.1399Z"
                  fill="#292D32"
              />
              <path
                  d="M90.2102 17.8199L82.5102 22.2799C82.2002 22.4599 81.8102 22.4599 81.4902 22.2799L73.7902 17.8199C73.2402 17.4999 73.1002 16.7499 73.5202 16.2799C73.8102 15.9499 74.1402 15.6799 74.4902 15.4899L79.9102 12.4899C81.0702 11.8399 82.9502 11.8399 84.1102 12.4899L89.5302 15.4899C89.8802 15.6799 90.2102 15.9599 90.5002 16.2799C90.9002 16.7499 90.7602 17.4999 90.2102 17.8199Z"
                  fill="#292D32"
              />
              <path
                  d="M81.4305 24.1399V30.9599C81.4305 31.7199 80.6605 32.2199 79.9805 31.8899C77.9205 30.8799 74.4505 28.9899 74.4505 28.9899C73.2305 28.2999 72.2305 26.5599 72.2305 25.1299V19.9699C72.2305 19.1799 73.0605 18.6799 73.7405 19.0699L80.9305 23.2399C81.2305 23.4299 81.4305 23.7699 81.4305 24.1399Z"
                  fill="#292D32"
              />
              <path
                  d="M82.5703 24.1399V30.9599C82.5703 31.7199 83.3403 32.2199 84.0203 31.8899C86.0803 30.8799 89.5503 28.9899 89.5503 28.9899C90.7703 28.2999 91.7703 26.5599 91.7703 25.1299V19.9699C91.7703 19.1799 90.9403 18.6799 90.2603 19.0699L83.0703 23.2399C82.7703 23.4299 82.5703 23.7699 82.5703 24.1399Z"
                  fill="#292D32"
              />
              <path
                  d="M90.2102 77.8199L82.5102 82.2799C82.2002 82.4599 81.8102 82.4599 81.4902 82.2799L73.7902 77.8199C73.2402 77.4999 73.1002 76.7499 73.5202 76.2799C73.8102 75.9499 74.1402 75.6799 74.4902 75.4899L79.9102 72.4899C81.0702 71.8399 82.9502 71.8399 84.1102 72.4899L89.5302 75.4899C89.8802 75.6799 90.2102 75.9599 90.5002 76.2799C90.9002 76.7499 90.7602 77.4999 90.2102 77.8199Z"
                  fill="#292D32"
              />
              <path
                  d="M81.4305 84.1399V90.9599C81.4305 91.7199 80.6605 92.2199 79.9805 91.8899C77.9205 90.8799 74.4505 88.9899 74.4505 88.9899C73.2305 88.2999 72.2305 86.5599 72.2305 85.1299V79.9699C72.2305 79.1799 73.0605 78.6799 73.7405 79.0699L80.9305 83.2399C81.2305 83.4299 81.4305 83.7699 81.4305 84.1399Z"
                  fill="#292D32"
              />
              <path
                  d="M82.5703 84.1399V90.9599C82.5703 91.7199 83.3403 92.2199 84.0203 91.8899C86.0803 90.8799 89.5503 88.9899 89.5503 88.9899C90.7703 88.2999 91.7703 86.5599 91.7703 85.1299V79.9699C91.7703 79.1799 90.9403 78.6799 90.2603 79.0699L83.0703 83.2399C82.7703 83.4299 82.5703 83.7699 82.5703 84.1399Z"
                  fill="#292D32"
              />
            </svg>
          </div>
          <div class="text">{{ $t('mngmt.product.gpProduct') }}</div>
        </div>
      </div>

      <Button @click.native="submit(); $events.fire('nextStep')" type="create" pos-tooltip-step="5"/>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      type: 'single',
    }
  },
  methods: {
    submit() {
      this.$router.push(`/management/products/create/${this.type}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-popup {
  border-radius: 10px;
  padding: 1.5rem 2rem 0 2rem;
  border: 2px solid #f4f4f4;
  display: flex;
  justify-content: center;
  text-align: center;
}

.products {
  margin-top: 20px;
  display: flex;
  gap: 1rem;

  .product {
    width: 175px;
    height: 175px;
    background-color: #f5f5f5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .icon {
      height: 125px;
      display: flex;
      align-items: center;
    }

    .text {
      color: #000;
      font-size: 12px;
    }
  }

  .product.active {
    border: 2px solid #181818;
  }
}
</style>