<template>
  <van-uploader
    v-model="files"
    :after-read="sendImage"
    :preview-options="{
      closeable: true,
    }"
    :deletable="false"
    :max-count="max"
    :multiple="multiple"
    :style="cssVars"
    :class="width == '100%' ? 'full' : ''"
  >
    <div class="upload-container" :style="cssVars">
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M2.66992 18.9496L7.59992 15.6396C8.38992 15.1096 9.52992 15.1696 10.2399 15.7796L10.5699 16.0696C11.3499 16.7396 12.6099 16.7396 13.3899 16.0696L17.5499 12.4996C18.3299 11.8296 19.5899 11.8296 20.3699 12.4996L21.9999 13.8996"
          stroke="black"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div v-if="label" class="label">{{ label }}</div>
      <!-- <div class="upload">{{$t('uploadFile')}}</div> -->
    </div>
    <template #preview-cover="{ index }">
      <div @click="(e) => deleteImage(index, e)" class="delete" :class="{'delete--center' : deleteIconCenter}">
        <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line
            x1="5.56558"
            y1="5.36328"
            x2="12.6366"
            y2="12.4343"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
          />
          <line
            x1="5.36328"
            y1="12.4344"
            x2="12.4343"
            y2="5.36335"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </div>
    </template>
  </van-uploader>
</template>

<script>
export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Add Image',
    },
    value: {
      type: [Array],
    },
    width: {
      type: String,
      default: '140px',
    },
    height: {
      type: String,
      default: '140px',
    },
    radius: {
      type: String,
      default: '9px',
    },
    border : {
      type : String,
      default : 'none',
    },
    max: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    deleteIconCenter: {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      files: this.value,
    }
  },
  watch: {
    value: function () {
      this.files = this.value
    },
  },
  methods: {
    sendImage() {
      this.$emit('input', this.files)
    },
    deleteImage(index, e) {
      e.stopPropagation();
      this.files.splice(index, 1)
      this.$emit('delete', this.files)
    },
  },
  computed: {
    cssVars () {
      return{
        '--color' : this.color,
        '--width' : this.width,
        '--height' : this.height, 
        '--radius' : this.radius,
        '--border' : this.border
      }
    }
  }
}
</script>

<style lang="scss">
.upload-container {
  width: var(--width);
  height: var(--height);
  border-radius: var(--radius);
  border : var(--border);
  background-color: #f4f4f4;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  // padding: 30px 0 20px 0;
}

.upload {
  color: #000;
  font-size: 12px;
  background: #dfdfdf;
  border-radius: 8px;
  padding: 7px 11px;
}
.delete {
  position: absolute;
  background-color: white;
  width: 21px;
  height: 21px;
  top: 0;
  right: 0;
  margin: 5px;
  border-radius: 50%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  &--center {
    top : 50%;
    right : 50%;
    transform: translate(50%, -50%);
    margin : 0;
  }
}

.van-uploader.full {
  width : var(--width) !important;
  .van-uploader__input-wrapper {
    width : var(--width) !important;
  }
}
.van-uploader__preview-image {
  width: var(--width) !important;
  height: var(--height) !important;
  border: none !important;
}
.van-uploader__preview {
  width: var(--width) !important;
  height: var(--height) !important;
  margin-bottom: 0;
}
.van-uploader__wrapper--disabled {
  opacity: 1;
}
.van-image__img {
  border-radius : var(--radius);
  border : var(--border)
}
</style>
<style scoped lang="scss">
.label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  margin : 10px;
}
</style>
