<template>
  <div class="main-dashboard container-fluid">
    <DataTableCard/>
  </div>
</template>

<script>
import DataTableCard from './components/DataTableCard'

export default {
  components: {
    DataTableCard,
  },
}
</script>
