<template>
  <div class="dashboard-top-items p-4">
    <p style="font-size: 14px" class="mb-0">{{ $t('dashboard.topCustomers') }}</p>
    <div class="resp">
      <van-empty :description="$t('dashboard.noCustomer')" image="/image/empty-lists/empty-customer.svg" v-if="customers.length == 0" />

      <table class="table my-border-table mb-0" v-else>
        <tr>
          <th class="pl-0">{{ $t('dashboard.cusName') }}</th>
          <th class="text-right">{{ $t('dashboard.totalPurchase') }}</th>
          <th class="text-right">{{ $t('dashboard.percentage') }}</th>
        </tr>
        <tr v-for="customer in customers" :key="customer.id">
          <td>
            <div>
              <img :src="customer.image" class="image" />
              <span> {{ customer.name }} </span>
            </div>
          </td>
          <td class="text-right" style="width: 150px">{{ $currency }} {{ $cast(customer.amount) }}</td>
          <td class="text-right" style="width: 150px">{{ $cast(getPercent(customer.amount)) }}%</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: ['customers'],
  data() {
    return {}
  },
  methods: {
    getPercent(q) {
      let total = parseFloat(this.customers.reduce((sum, { amount }) => sum + amount * 1, 0))
      return (q / total) * 100
    },
  },
}
</script>
<style scoped>
.dashboard-top-items {
  background-color: white;
  border-radius: 10px;
}
.image {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 10px;
}
td {
  padding: 5px 10px !important;
}
.resp {
  height: 350px;
  overflow: auto;
}
</style>
