<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <h1>{{ $t('setting.paymentLog.title') }}</h1>
      <custom-search v-model="params.keyword" @search="getPaymentLogs()" />
    </div>

    <table class="table my-border-table mt-3">
      <thead>
        <tr>
          <th>{{ $t('setting.paymentLog.id') }}</th>
          <th>{{ $t('setting.paymentLog.amt') }}</th>
          <th>{{ $t('setting.paymentLog.invoiceNumber') }}</th>
          <th>{{ $t('setting.paymentLog.period') }}</th>
          <th class="text-center">{{ $t('setting.paymentLog.status') }}</th>
          <th>{{ $t('setting.paymentLog.validFrom') }}</th>
          <th>{{ $t('setting.paymentLog.validTo') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="log in logs" :key="log.id">
          <td>#{{ log.id }}</td>
          <td>{{ log.amount }}</td>
          <td>{{ log.invoice_number }}</td>
          <td class="text-capitalize">{{ log.period_count }} {{ log.period.slice(0, -2) }}</td>
          <td class="text-center">
            <span
              :class="`${
                log.status === 'success'
                  ? 'badge-success'
                  : log.status === 'fail'
                  ? 'badge-danger'
                  : log.status === 'new'
                  ? 'badge-warning'
                  : 'badge-primary'
              } badge badge-pill`"
              >{{ log.status == 'new' ? 'pending' : log.status }}</span
            >
          </td>
          <td>{{ moment(log.from_date).format('DD-MM-YYYY') }}</td>
          <td>{{ moment(log.to_date).format('DD-MM-YYYY') }}</td>
        </tr>
      </tbody>
    </table>
    <b-pagination
      :per-page="params.limit"
      :total-rows="total"
      :value="params.page"
      align="center"
      first-number
      last-number
      pills
      @change="pageChange"
    ></b-pagination>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data() {
    return {
      moment,
      params: {
        keyword: null,
        page: 1,
        limit: 5,
      },
      logs: [],
      total: 0,
    }
  },
  methods: {
    async getPaymentLogs() {
      let res = await axios.get('payment_logs', { params: this.params })
      if (res) {
        this.logs = res.data.data.data
        this.total = res.data.data.total
      }
    },
    pageChange(page) {
      this.params.page = page
      this.getPaymentLogs()
    },
  },
  mounted() {
    this.getPaymentLogs()
  },
}
</script>

<style lang="scss" scoped>
.my-border-table {
  td {
    padding: 10px 10px 10px 20px !important;
  }
}
</style>