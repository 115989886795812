<template>
  <van-popup v-model="show" class="custom-popup">
    <h1 class="text-capitalize">Choose {{type}}s</h1>
    <custom-search v-model="params.keyword" @search="fetchData()" width="calc(100% - 15px)" class="mt-3"/>

    <van-checkbox-group v-model="selectedItems">
      <div class="card-list-container">
        <div v-for="(item, index) in items" :key="index" class="card-list" @click="$refs.checkboxes[index].toggle()">
          <div class="d-flex">
            <img v-if="type == 'product'" :src="item.images[0]" :alt="item.name" style="border-radius : 5px">
            <img v-else :src="item.image" :alt="item.name" style="border-radius : 50%">
            <div class="ml-3">
              <h5>{{ item.name }}</h5>
              <div v-if="type == 'product'" class="info">$ {{ $cast(item.retail_price) }}</div>
              <div v-else class="info">{{ item.email[0] }}, {{ item.phone[0] }}</div>
            </div>
          </div>
          <van-checkbox :name="item" shape="square" icon-size="17px" ref="checkboxes"></van-checkbox>
        </div>
      </div>
    </van-checkbox-group>

    <div class="card-list-footer">
      <div>
        <div class="font-weight-bold text-muted">Selected</div>
        <h1>{{ selectedItems.length }} Products</h1>
      </div>
      <button @click="submit()" class="btn btn-primary add-btn btn-sm">Add</button>
    </div>
  </van-popup>
</template>

<script>
export default {
  props : ['params'],
  data() {
    return {
      show : false,
      type : null,
      selectedItems : [],
      items : [],
      dataParams : {
        limit : 20,
        keyword : '',
        page : 1,
      }
    }
  },
  methods: {
    open(type) {
      this.type = type
      this.show = true
      this.selectedItems = type == 'product'
        ? this.params.products
        : this.params.customers
      this.fetchData()
    },
    submit() {
      if(this.type == 'product') {
        this.params.products = this.selectedItems
      } else {
        this.params.customers = this.selectedItems
      }
      this.show = false
    },
    async fetchData() {
      this.items = []
      let res = this.type == 'product'
        ? await axios.get('/product_picker', { params : this.dataParams})
        : await axios.get('/customers', { params: this.dataParams })
      if (res) {
        let items = this.type == 'product' 
          ? res.data.data
          : res.data.data.customers
        if(this.selectedItems.length > 0) {
          let selectedIds = this.selectedItems.map(item => item.id)
          items = items.filter(item => !selectedIds.includes(item.id) )
        }
        this.items = this.selectedItems.concat(items)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .custom-popup {
    width : 540px;
    border-radius: 10px;
    padding : 20px;

    .card-list-footer {
      display: flex;

      div:first-child {
        flex-basis: calc(50% - 60px);
      }

      .add-btn {
        width : 120px;
      }
    }

    .card-list-container {
      display: flex;
      flex-direction: column;
      gap : 10px;
      margin : 1.25rem 0;
      height: 335px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: block;
        width: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(0,0,0,0.7); 
        border-radius : 1000px;
        height: 130px;
      }
      &::-webkit-scrollbar-track {
        background: #EFEFEF; 
        border-radius : 100px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #000;
      }

      .card-list {
        display: flex;
        width : calc(100% - 15px);
        justify-content: space-between;
        align-items: center;
        border: 2px solid #F4F4F4;
        border-radius: 8px;
        padding : 10px;
        cursor: pointer;

        img {
          width : 35px;
          height: 35px;
          object-fit: cover;
        }

        h5 { 
          font-size : 13px;
          font-weight: 700;
          margin-bottom : 2px;
        }

        .info {
          font-size : 12px;
          color : rgba(0,0,0,0.6);
        }
      }
    }
  }
</style>