<template>
  <div>
    <vSelect
      :value="params.receiver_name"
      :options="datas"
      label="name"
      :reduce="(list) => list"
      :clearable="true"
      @option:created="optionCreated"
      @input="pushData"
      :taggable="true"
    >
      <template #option="{ name, image }">
        <div class="data">
          <img v-if="image" :src="image" :alt="name" />
          <div>{{ name }}</div>
        </div>
      </template>
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <svg width="15" height="14" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.7704 6.64453L10.6088 11.485C9.99921 12.0567 9.00171 12.0567 8.39213 11.485L3.23047 6.64453"
              stroke="rgba(0,0,0,0.2)"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </template>
    </vSelect>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: { vSelect },
  props: ['value', 'params', 'type'],
  data() {
    return {
      datas: [],
      dataParams: {
        page: 1,
        limit: 10,
      },
    }
  },
  methods: {
    async fetchData() {
      let res =
        this.$route.params.type == 'sale'
          ? await axios.get('/customers', { params: this.dataParams })
          : await axios.get('/suppliers', { params: this.dataParams })
      if (res) {
        this.datas = this.$route.params.type == 'sale' ? res.data.data.customers : res.data.data.suppliers
        if (this.datas.length == 0) {
          this.datas.unshift({
            name: '',
            receiver_id: null,
          })
        }
      }
    },
    optionCreated(name) {
      this.datas.push(name)
    },
    pushData(e) {
      console.log('from_event', e)
      if (e) {
        this.params.receiver_name = e.name
        if (e.id) {
          this.params.receiver_email = e.email && e.email[0] ? e.email[0] : ''
          this.params.receiver_phone = e.phone && e.phone[0] ? e.phone[0] : ''
          this.params.shipping_address = e.address
          this.params.billing_address = e.address
          this.params.receiver_id = e.id
          this.params.receiver_tax_id = e.tax_id
        } else {
          this.params.receiver_id = null
        }
      } else {
        this.params.receiver_name = null
        this.params.receiver_id = null
      }
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>

<style lang="scss" scoped>
.data {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}
</style>