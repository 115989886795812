<template>
  <div v-if="params && params.length > 0">
    <h4>{{ $t('mngmt.product.subProductImage')}}</h4>

    <div class="sub-products">
      <div v-for="(subProduct, index) in params" :key="index" class="sub-product">
        <h3>{{ subProduct.name }}</h3>
        <div class="image">
          <img :src="subProduct.images[0]" :alt="subProduct.name">
          <div @click="editSubProduct(subProduct.id)" class="edit">
            <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.6794 3.64953C11.3861 2.3562 10.1194 2.32287 8.79273 3.64953L7.98606 4.4562C7.9194 4.52287 7.89273 4.62953 7.9194 4.72287C8.42607 6.48953 9.8394 7.90286 11.6061 8.40953C11.6327 8.4162 11.6594 8.42287 11.6861 8.42287C11.7594 8.42287 11.8261 8.3962 11.8794 8.34287L12.6794 7.5362C13.3394 6.88287 13.6594 6.24953 13.6594 5.60953C13.6661 4.94953 13.3461 4.30953 12.6794 3.64953Z" fill="#292D32"/>
              <path d="M10.4075 9.01564C10.2142 8.9223 10.0275 8.82897 9.84753 8.7223C9.70086 8.63564 9.56086 8.5423 9.42086 8.4423C9.30753 8.36897 9.1742 8.2623 9.04753 8.15564C9.0342 8.14897 8.98753 8.10897 8.9342 8.05564C8.7142 7.86897 8.46753 7.62897 8.24753 7.3623C8.22753 7.34897 8.1942 7.3023 8.14753 7.2423C8.08086 7.1623 7.96753 7.02897 7.86753 6.87564C7.78753 6.77564 7.6942 6.62897 7.60753 6.4823C7.50086 6.3023 7.40753 6.1223 7.3142 5.93564C7.30007 5.90537 7.28641 5.87526 7.27316 5.84533C7.17477 5.62312 6.88502 5.55815 6.71318 5.72999L2.8942 9.54897C2.80753 9.63564 2.72753 9.8023 2.70753 9.91564L2.34753 12.469C2.28086 12.9223 2.40753 13.349 2.68753 13.6356C2.92753 13.869 3.26086 13.9956 3.62086 13.9956C3.70086 13.9956 3.78086 13.989 3.86086 13.9756L6.42086 13.6156C6.54086 13.5956 6.70753 13.5156 6.78753 13.429L10.6017 9.61476C10.7748 9.44173 10.7095 9.14475 10.4844 9.04871C10.4591 9.0379 10.4335 9.02688 10.4075 9.01564Z" fill="#292D32"/>
            </svg>
          </div>
        </div>
        <div v-if="subProduct.images.length > 0" class="options">
          <img v-for="(image, index) in subProduct.images" :key="index" :src="image">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props : ['params'],
  methods : {
    editSubProduct(id) {
      this.$emit('edit', id)
    }
  }
}
</script>

<style lang="scss" scoped>
  h4 {
  font-size : 13px;
  color : rgba(0,0,0,0.5);
  font-weight: 700;
}
  .sub-products {
  display: flex;
  margin-top : 1.75rem;
  gap: 1.25rem;
}

.sub-product {
  width : 140px;

  h3 {
    font-size : 13px;
    color : #000;
    font-weight: 700;
    margin-bottom : 8px;
  }
  .image {
    height: 140px;
    position : relative;

    .edit {
      position : absolute;
      right: 0;
      top : 0;
      background-color : white;
      border-radius: 50%;
      width : 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin : 7px 5px;
      cursor: pointer;
    }

    img {
      width : 100%;
      height: 100%;
      border-radius : 8px;
      object-fit: cover;
    }
  }

  .options {
    display : flex;
    margin-top : 7px;
    gap : 5px;
    flex-wrap: wrap;

    img {
      width : 30px;
      height : 30px;
      border-radius : 5px;
      object-fit: cover;
    }
  }

  .add {
    color : #000;
    font-size : 11px;
    text-align : center;
    font-weight : 700;
    margin-top : 7px;
    cursor : pointer;
  }
}
</style>