<template>
  <div class="dashboard-income-vs-expense p-4">
    <div class="pl-2 pt-2 pb-4">
      <span style="line-height: 7px">
        <p style="font-size: 14px">{{$t('dashboard.incomeVsExpense')}}</p>
      </span>
    </div>
    <div
      class="top-income-vs-expenses text-center"
      :class="[revenue() < 50 ? 'text-danger' : 'text-success']"
      v-if="revenue()"
    >
      <Progress :strokeColor="color" :transitionDuration="1000" :radius="70" :strokeWidth="17" :value="revenue()" />
    </div>
    <div class="top-income-vs-expenses-ref d-flex justify-content-center pt-3">
      <div class="col-5 ref-left d-flex pt-2 text-center align-items-center">
        <div class="ref-left-div d-flex align-items-center justify-content-center">
          <van-icon name="upgrade" size="18" color="white" />
        </div>
        &nbsp;&nbsp;{{$t('expense')}}
      </div>
      <div class="col-5 ref-right d-flex pt-2 text-center align-items-center">
        <div class="ref-right-div d-flex align-items-center justify-content-center">
          <van-icon name="diamond-o" size="18" color="white" />
        </div>
        &nbsp;&nbsp;{{$t('revenue')}}
      </div>
    </div>
  </div>
</template>

<script>
import Progress from 'easy-circular-progress'
export default {
  props: {
    summary: {
      type: Object,
    },
  },
  data() {
    return {
      color: this.$store.state.theme,
    }
  },
  components: {
    Progress,
  },
  methods: {
    revenue() {
      let { expense, revenue } = this.summary
      let e = (expense * 100) / (revenue + expense)
      return parseFloat((100 - e).toFixed(2))
    },

    percent() {
      let { expense, revenue } = this.summary
      return ((revenue - expense) * 100) / expense
    },
  },
}
</script>

<style scoped>
.dashboard-income-vs-expense {
  height: 100%;
  background-color: white;
  border-radius: 10px;
}

.top-income-vs-expenses {
  height: 200px;
  margin: auto;
}

.top-income-vs-expenses-ref {
  height: auto;
}

.ref-left,
.ref-right {
  height: 40px;
  align-content: center;
  display: flex;
  justify-content: center;
}

.ref-left-div {
  background-color: var(--theme);
  min-width: 25px;
  opacity: 0.3;
  height: 25px;
  border-radius: 5px;
}

.ref-right-div {
  background-color: var(--theme);
  min-width: 25px;
  height: 25px;
  border-radius: 5px;
}
small {
  font-size: 12px;
}
</style>
