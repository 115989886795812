<template>
  <div :style="{top: top || '10px', height: '38rem' }" class="noti-wrapper"
       @click.stop="() => ({})">
    <div class="w-100 h-100 noti-container">
      <h5 class="text-center text-bold">Inventory Alert</h5>

      <div class="noti-item-container mt-3">
        <!--        <div class="text-muted mb-1">Today</div>-->
        <div v-if="notifications.length">
          <section v-for="noti in notifications" :key="noti.id" class="pt-1 pb-2">
            <div class="noti-item d-flex align-items-center"
                 @click="$router.push({ path: '/invoices/purchase/create', query: {products: noti.id}})">
              <div class="img-container">
                <img :src="noti.images[0]"/>
              </div>

              <div class="w-100">
                <div class="d-flex justify-content-between w-100">
                  <h6 class="font-weight-bold product-name">{{ noti.name }}</h6>
                  <p class="text-muted">{{ $t('dashboard.stockLeft', {stock: noti.instock}) }}</p>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div v-else class="text-center text-muted">{{ $t('dashboard.noLowStock') }}</div>
      </div>

      <div class="expand" @click="$router.push('management/products/list')">
        {{ isExpandNotification ? $t('dashboard.seeLess') : $t('dashboard.seeAll') }}
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Notification',
  data: () => ({
    // minNotiCount: 10,
    isExpandNotification: false,
  }),
  props: {
    top: String,
    notifications: {
      type: Array,
    },
  },
}
</script>

<style lang="scss" scoped>
.noti-wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 30rem;
  background: #ffffff;
  padding: 20px;
  padding-bottom: 0;
  border-radius: 15px;
  z-index: 10;
  border: 2px solid #eaeaea;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  cursor: default;

  .noti-container {
    position: relative;

    .expand {
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      width: 100%;
      background: #fff;
      border-top: 2px solid #F4F4F4;
      z-index: 20;
      padding: 12px 0;
      cursor: pointer;
    }

    .noti-item-container {
      height: 100%;
      overflow: auto;
      padding-bottom: 80px;

      .img-container {
        width: 65px;
        height: 50px;
        border-radius: 8px;
        overflow: hidden;
        background-color: #F3F3F3;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .noti-item {
    gap: 10px;
    padding-bottom: 3px;
    cursor: pointer;

    .product-name {
      max-width: 150px;
    }
  }

}
</style>