<template>
  <div class="d-flex flex-column custom-div">
    <FrontendNav />
    <div class="error-page">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6 mb-4">
            <div class="error-image">
              <img alt="Not Found" src="@/assets/images/illustrations/error-404.svg" />
            </div>
          </div>
          <div class="col-lg-6 col-md-6 error-text">
            <div class="">
              <h2>Oops!</h2>
              <h4>We couldn't find this page.</h4>
              <p>You are lost but don't worry! You can easily go back to the home page.</p>
              <div class="btn-backhome">
                <router-link to="/" class="home">
                  <van-icon name="arrow-left"></van-icon>
                  Back to home
                </router-link>
                <router-link to="/support" class="ml-5 text-bold" href="#">Visit our help center</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FrontendFooter />
  </div>
</template>

<script>
import FrontendFooter from '../layouts/FrontendFooter.vue'
import FrontendNav from '../layouts/FrontendNav.vue'

export default {
  components: {
    FrontendFooter,
    FrontendNav,
  },
  data() {
    return {}
  },
}
</script>

<style scoped>
.custom-div {
  justify-content: space-between;
  align-content: space-between;
  height: 100vh;
}

a {
  text-decoration: none;
  color: black;
}

.error-page {
  margin: 5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.error-image {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.error-image img {
  width: 90%;
  min-width: 70%;
}

.error-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  gap: 20px;
}
.error-text > div > * {
  margin: 10px 0;
}
.error-text h2 {
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0em;
}

.error-text h4 {
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}

.error-text p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
}
.btn-backhome {
  display: flex;
  align-items: center;
}
.btn-backhome a:hover {
  text-decoration: underline;
}
.btn-backhome .home {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  gap: 10px;
  background-color: var(--web-theme-secondary);
  border-radius: 9px;
  color: #fff;
}
.btn-backhome .home:hover {
  text-decoration: none;
  background-color: #5e53ff;
}

@media screen and (max-width: 330px) {
  .error-text a {
    display: block;
    margin-top: 30px;
    margin-left: 0 !important;
  }
}
</style>
