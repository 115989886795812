<template>
  <div>
    <h1 class="pointer" @click="$router.back()">
      <van-icon class="mr-3" name="arrow-left" size="15px" />
      {{ editId ? 'Update' : 'Create' }} Store
    </h1>

    <div class="col-lg-9 mx-auto">
      <FormCard @imageChanged="imageChanged" :files="files" :params="params" :errors="errors" ref="formCard" />

      <Button :type="editId ? 'edit' : 'create'" :loading="loading" name="Store" @click.native="save()" />
    </div>

    <alert-popup
      :message="`Store ${editId ? 'Updated' : 'Created'} Successfully`"
      :autoCloseTime="2000"
      ref="updateAlert"
    />
  </div>
</template>

<script>
import FormCard from './components/FormCard'
import { Toast, Dialog } from 'vant'

export default {
  components: { FormCard },
  data() {
    return {
      loading: false,
      files: {
        logo : [],
        banner : []
      },
      params: {},
      errors: {},
      editId: null,
      logo: null,
      banner : null,
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.editId = this.$route.params.id
      this.getCompanyData()
    }
  },
  methods: {
    imageChanged(value) {
      this[value.type] = value.image
    },

    async getCompanyData() {
      let res = await axios.get('/companies/' + this.editId)
      if(res) {
        this.params = res.data.data
        if (this.params.logo) {
          this.files.logo = [{ url: this.params.logo }]
        }
        if (this.params.banner) {
          this.files.banner = [{ url: this.params.banner }]
        }
      }
    },

    async save() {
      this.loading = true
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        duration: 0,
      })
      let fd = this.getFd()
      let res = this.editId ? await axios.post('/companies/' + this.editId, fd) : await axios.post('/companies/', fd)
      if (res.data.success) {
        this.$refs.updateAlert.open()
        this.$router.back()
        this.loading = false
        Toast.clear()
      } else {
        this.loading = false
        Toast.clear()
        if (res.data.code == 422) {
          this.errors = res.data.error
        }
      }
    },

    getFd() {
      let fd = new FormData()

      let keywords = [
        'name',
        'purchase_inv_prefix',
        'sale_inv_prefix',
        'email',
        'phone',
        'currency',
        'tax',
        'wht',
        'address',
        'domain',
        'company_category_id',
        'tax_id',
      ]

      keywords.forEach((keyword) => {
        if (this.params[keyword]) {
          fd.append(keyword, this.params[keyword])
        }
      })
      if (this.logo) {
        fd.append('logo', this.logo)
      }
      if(this.banner) {
        fd.append('banner', this.banner)
      }
      return fd
    },
  },
}
</script>
