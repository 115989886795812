<template>
  <div>
    <h1>{{$t('setting.myStore')}}</h1>
    <div class="col-lg-9 mx-auto">
      <FormCard
        @imageChanged="imageChanged"
        :files="files"
        :params="params"
        :errors="errors"
        @save="update"
        ref="formCard"
      />

      <Button type="edit" :loading="loading" name="Store" @click.native="update()" />
    </div>

    <alert-popup message="Store Updated Successfully" :autoCloseTime="2000" ref="updateAlert" />
  </div>
</template>

<script>
import FormCard from './components/FormCard'
import { Toast } from 'vant'

export default {
  components: { FormCard },
  data() {
    return {
      files: {
        logo : [],
        banner : []
      },
      params: {},
      errors: {},
      loading: false,
      logo: null,
      banner : null,
    }
  },
  mounted() {
    this.params = { ...this.$auth.user().company }
    if (this.params.logo) {
      this.files.logo = [{ url: this.params.logo }]
    }
    if (this.params.banner) {
      this.files.banner = [{ url: this.params.banner }]
    }
  },
  methods: {
    imageChanged(value) {
      this[value.type] = value.image
    },

    async update() {
      this.loading = true
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        duration: 0,
      })
      let fd = this.getFd()
      let res = await axios.post('/companies/' + this.params.id, fd)
      if (res) {
        if ((res.data.code = 422 && res.data.error)) {
          this.errors = res.data.error
        } else {
          this.$refs.updateAlert.open()
          this.$auth.fetch({url: 'auth/user', method: 'get', redirect: false})
        }
        this.loading = false
        Toast.clear()
      }
      window.callCompanyMiddleware = true
    },

    getFd() {
      let fd = new FormData()

      let keywords = [
        'name',
        'purchase_inv_prefix',
        'sale_inv_prefix',
        'email',
        'phone',
        'currency',
        'tax',
        'wht',
        'address',
        'domain',
        'company_category_id',
        'tax_id',
      ]

      keywords.forEach((keyword) => {
        if (this.params[keyword]) {
          fd.append(keyword, this.params[keyword])
        }
      })
      if (this.logo) {
        fd.append('logo', this.logo)
      }
      if (this.banner) {
        fd.append('banner', this.banner)
      }
      return fd
    },
  },
}
</script>

