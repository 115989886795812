<template>
  <div>
    <div class="bg-white">
      <div class="pointer" @click="$router.back()">
        <van-icon class="mr-3" name="arrow-left" size="15px"/>
        {{ $t('action.back') }}
      </div>
      <header class="d-flex justify-content-between">
        <h1 class="my-4">
          {{ params.type === 'payment' ? $t('mngmt.voucher.cashPayment') : $t('mngmt.voucher.cashReceived') }}</h1>
        <div class="mr-3">
          <p class="label my-1">
            {{ $t('mngmt.voucher.bankBalance') }}
          </p>
          <h1>{{ $currency }} {{ $cast(params.account_id.current_balance || 0) }}</h1>
          <small v-if="params.type === 'payment' && !hasEnoughBalance" class="text-red">Not enough balance</small>
        </div>
      </header>
      <div class="d-flex voc-input align-items-center my-2">
        <p class="text-bold m-0">{{ $t('mngmt.voucher.voucherNo') }}</p>
        <input v-model="params.voucher_no" class="input" type="text"/>
      </div>
      <div class="d-flex voc-input align-items-center">
        <p class="text-bold m-0"> {{ $t('mngmt.voucher.date') }}</p>
        <date-picker
            v-model="params.invoice_date"
            :firstDayOfWeek="1"
            class="date-pick"
            max="2030-01-01"
            min="2018-6-1"
        />
      </div>
      <div class="d-flex justify-content-between">
        <div class="d-flex voc-input align-items-center">
          <p class="text-bold m-0">{{ $t('mngmt.voucher.account') }}</p>
          <div class="my-4">
            <v-select
                v-model="params.account_id"
                :clearable="false"
                :options="accounts"
                :reduce="(option) => option"
                class="date-pick"
                label="owner_name"
            >
            </v-select>
          </div>
        </div>
        <div class="my-4 voc-input">
          <v-select
              v-model="params.type"
              :clearable="false"
              :options="[{label: $t('mngmt.voucher.cashPayment'), type: 'payment'},{label: $t('mngmt.voucher.cashReceived'), type: 'received'}]"
              :reduce="(option) => option.type"
              class="date-pick"
          >
          </v-select>
        </div>
      </div>
    </div>
    <div class="bg-white pb-5">
      <div class="d-flex">
        <div class="flex-fill w-100">
          <h2>{{ params.type === 'payment' ? $t('mngmt.voucher.paymentTo') : $t('mngmt.voucher.paymentBy') }}</h2>
          <div class="target-container">
            <van-radio-group v-model="targetPerson" class="d-flex radio-container">
              <van-radio name="customer">
                {{ person.customer.display }}
              </van-radio>
              <van-radio name="supplier">
                {{ person.supplier.display }}
              </van-radio>
              <van-radio name="employee">
                {{ person.employee.display }}
              </van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="flex-fill w-100">
          <h2> {{ person[targetPerson].display }}</h2>
          <v-select
              v-model="selectedPerson"
              :options="personList"
              :placeholder="`Choose ${person[targetPerson].display}`"
              :selectable="(option) => !option.disabled"
              label="name"
              @open="getPersonData"
              @search="(keyword) => debounce(() => getPersonData(keyword), 800)"
          >
          </v-select>
          <small class="text-danger">{{ errors.payment_to_id }}</small>
        </div>
      </div>
    </div>
    <div>
      <voucher :error="errors" :params="params"/>
      <div class="d-flex justify-content-center my-3">
        <Button :disabled="params.type === 'payment' && !hasEnoughBalance" :loading="loading"
                :type="mode === 'edit' ? 'edit': 'create'"
                class="btn btn-primary py-2 px-5"
                @click.native="saveData"></Button>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "@/pages/management/employees/components/DatePicker";
import axios from "axios";
import Voucher from "@/pages/management/payment-voucher/components/voucher";
import moment from "moment";

export default {
  name: 'VoucherCreateEdit',
  components: {Voucher, DatePicker},
  computed: {
    hasEnoughBalance: function () {
      return this.params.account_id.current_balance >= this.params.grand_total
    }
  },
  data: function () {
    return {
      loading: false,
      accounts: [],
      targetPerson: 'customer',
      selectedPerson: '',
      fetchedPersonType: '',
      personList: [],
      mode: 'create',
      id: '',
      person: {
        customer: {
          display: this.$t('mngmt.voucher.customer'),
          route: 'customers',
        },
        supplier: {
          display: this.$t('mngmt.voucher.supplier'),
          route: 'suppliers',
        },
        employee: {
          display: this.$t('mngmt.voucher.employee'),
          route: 'employees',
        }
      },
      params: {
        voucher_no: '',
        type: '',
        account_id: 0,
        invoice_date: moment(),
        vouchers: [
          {
            coa: '',
            coa_name: '',
            amount: 0,
            tax_type: 'exclusive',
            description: '',
            tax: 0,
            error: {},
          }
        ],
        grand_total: 0,
      },
      errors: {},
      timer: null,
    }
  },
  methods: {
    debounce(fn, timeout) {
      clearTimeout(this.timer);
      this.timer = setTimeout(
          fn,
          timeout
      )
    },
    async getPersonData(keyword, rememberType) {
      if (this.fetchedPersonType === this.targetPerson) {
        return;
      }
      this.personList = [{name: this.$t('mngmt.voucher.loading'), disabled: true}]
      let params = {
        page: 1,
        limit: 10,
        keyword,
      }
      let person = this.person[this.targetPerson];
      if (this.targetPerson === 'employee') params.type = 'current';
      const res = await axios.get(person.route, {params});
      if (res.data) {
        this.personList = this.targetPerson === 'employee' ? res.data.data.data : res.data.data[person.route]
        this.selectedPerson = this.personList[0]
        this.fetchedPersonType = rememberType ? this.targetPerson : '';
      }
    },
    async getAccounts() {
      let res = await axios.get('/accounts', {
        params: {
          page: 1,
          limit: 10
        }
      })
      if (res.data) {
        this.accounts = res.data.data.filter(acc => acc.id !== 0);
        this.params.account_id = this.accounts.find((acc) => acc.id == this.params.account_id) || this.accounts[0]
      }
    },
    async saveData() {
      this.loading = true;
      this.errors = {};
      let params = {
        ...this.params,
        voucher_no: +this.voucher_no,
        payment_to_type: this.targetPerson,
        payment_to_id: this.selectedPerson?.id,
        account_id: this.params.account_id.id,
        vouchers: this.params.vouchers.map(voc => ({
          coa: +voc.coa,
          coa_name: voc.coa_name,
          amount: +voc.amount,
          tax_type: voc.tax_type,
          tax: +voc.tax,
          description: voc.description,
        }))
      }
      let res;
      if (this.mode === 'edit') {
        res = await axios.post(`payment_voucher/${this.id}?_method=PUT`, params);
      } else {
        res = await axios.post('payment_voucher', params);
      }
      if (res.data.success) {
        this.$router.push(`/management/payment-voucher/list?type=${this.params.type}`);
      } else {
        this.errors = res.data.error
      }
      this.loading = false;
    },
    async fetchData(id) {
      const res = await axios.get(`payment_voucher/${id}`);
      if (res.data) {
        this.params.voucher_no = res.data.data.voucher_no
        this.params.invoice_date = res.data.data.invoice_date
        this.params.account_id = res.data.data.account_id
        this.params.type = res.data.data.type;
        this.targetPerson = res.data.data.receiver.payment_to_type;
        this.params.vouchers = res.data.data.vouchers.map(v => {
          if (v.tax_type == 'inclusive') {
            v.amount = v.total
          }
          return v
        });
        this.getPersonData(res.data.data.receiver.receiver_name, false)
        this.getAccounts();
      }
    }
  },

  mounted() {
    this.params.type = this.$route.query['type'];
    this.mode = this.$route.params.type || 'create';
    if (this.mode !== 'create') {
      this.id = this.$route.params.id
      this.fetchData(this.id);
    } else {
      this.getAccounts();
    }
  }
}
</script>

<style lang="scss" scoped>

.bg-white {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 2px solid #f6f6f6;
}

.voc-input {
  gap: 30px;

  .input {
    max-width: 196px;
  }

  p {
    min-width: 7rem;
  }

  .date-pick {
    min-width: 196px;
  }
}

.target-container {
  border: 2px solid #f6f6f6;
  border-radius: 10px;
  padding: 10px 20px;
  max-width: fit-content;

  .radio-container {
    gap: 30px;
  }

  .user-pick {
    width: 30rem;
  }
}
</style>