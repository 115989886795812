import Handsontable from 'handsontable'
import moment from 'moment'

function cast(value) {
  value = value * 1
  return value.toLocaleString('en-US', { maximumFractionDigits: 2 })
}

function currency(instance, td, row, col, prop, value, cellProperties) {
  Handsontable.renderers.TextRenderer.apply(this, arguments)
  td.innerHTML = cast(td.innerHTML)
}

function dateFormat(instance, td, row, col, prop, value, cellProperties) {
  Handsontable.renderers.TextRenderer.apply(this, arguments)
  td.innerHTML = moment(td.innerHTML).format('DD MMM, YYYY')
}

function timeFormat(instance, td, row, col, prop, value, cellProperties) {
  Handsontable.renderers.TextRenderer.apply(this, arguments)
  td.innerHTML = moment(td.innerHTML).format('hh:ss A')
}

export default {
  data: [],
  width: '100%',
  height: 'auto',
  colHeaders: ['Date', 'Time', 'Invoice/Ref No.', 'Detail', 'Tax ID', 'COA', 'Info', 'Type', `Debit`, `Credit`],
  rowHeaders: true,
  manualColumnResize: true,
  stretchH: 'all',
  licenseKey: 'non-commercial-and-evaluation',
  columns: [
    { readOnly: true, data: 'created_at' },
    { readOnly: true, data: 'created_at' },
    { readOnly: true, data: 'ref1' },
    { readOnly: true, data: 'info' },
    { readOnly: true, data: 'tax_id' },
    { readOnly: true, data: 'coa' },
    { readOnly: true, data: 'coa_name' },
    { readOnly: true, data: 'type' },
    { readOnly: true, data: 'debit' },
    { readOnly: true, data: 'credit' },
  ],
  cells: function (row, col, extra) {
    var cellPrp = {}
    if (col == 0) {
      cellPrp.renderer = dateFormat
    }
    if (col == 1) {
      cellPrp.renderer = timeFormat
    }
    if (extra == 'debit' || extra == 'credit') {
      cellPrp.className = 'htRight text-black'
      cellPrp.renderer = currency
    } else if (['coa_name', 'type', 'tax_id', 'info'].includes(extra)) {
      cellPrp.className = 'htLeft text-capitalize'
    } else if (['created_at', 'coa'].includes(extra)) {
      cellPrp.className = 'htCenter text-capitalize'
    }
    return cellPrp
  },
}
