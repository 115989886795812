<template>
  <div class="company-profile-three pb-5 pt-5">
    <div class="profile-three-box">
      <p>Step 3 of 3</p>
      <h4>Choose your plan</h4>
      <h5>We made the right plan for your business.</h5>
      <div class="d-flex justify-content-end month-year">
        <div class="monthly active">Monthly</div>
        <!-- <div class="yearly">Yearly</div> -->
      </div>
      <div class="box-container">
        <div :class="plan === 'bronze' ? 'active' : ''" class="plan-box" @click="selectPlan('bronze')">
          <img alt="" src="/image/businessprofile/bronzeplan.svg"/>
          <h3>Bronze Plan</h3>
          <div class="price"><span> $0 </span> /Month</div>
          <ul class="">
            <li>
              <img alt="" src="/image/businessprofile/tick-square.svg"/>
              1 Store
            </li>
            <li>
              <img alt="" src="/image/businessprofile/tick-square.svg"/>
              5 GB Storage
            </li>
            <li>
              <img alt="" src="/image/businessprofile/tick-square.svg"/>
              Free Sub Domain
            </li>
          </ul>
        </div>
        <div :class="plan === 'silver' ? 'active' : ''" class="plan-box" @click="selectPlan('silver')">
          <div class="popular">Popular</div>
          <img alt="" src="/image/businessprofile/silverplan.svg"/>
          <h3>Silver Plan</h3>
          <div class="price"><span> $8 </span> /Month</div>
          <ul>
            <li>
              <img alt="" src="/image/businessprofile/tick-square.svg"/>
              3 Stores
            </li>
            <li>
              <img alt="" src="/image/businessprofile/tick-square.svg"/>
              10 GB Storage
            </li>
            <li>
              <img alt="" src="/image/businessprofile/tick-square.svg"/>
              Free Sub Domain
            </li>
            <li>
              <img alt="" src="/image/businessprofile/tick-square.svg"/>
              Own Domain Integration
            </li>
            <li>
              <img alt="" src="/image/businessprofile/tick-square.svg"/>
              24/7 Support
            </li>
          </ul>
        </div>
        <div :class="plan === 'gold' ? 'active' : ''" class="plan-box" @click="selectPlan('gold')">
          <img alt="" src="/image/businessprofile/goldplan.svg"/>
          <h3>Gold Plan</h3>
          <div class="price"><span> $20 </span> /Month</div>
          <ul>
            <li>
              <img alt="" src="/image/businessprofile/tick-square.svg"/>
              20 Store
            </li>
            <li>
              <img alt="" src="/image/businessprofile/tick-square.svg"/>
              20 GB Storage
            </li>
            <li>
              <img alt="" src="/image/businessprofile/tick-square.svg"/>
              Free Sub Domain
            </li>
            <li>
              <img alt="" src="/image/businessprofile/tick-square.svg"/>
              Own Domain Integration
            </li>
            <li>
              <img alt="" src="/image/businessprofile/tick-square.svg"/>
              24/7 Support
            </li>
          </ul>
        </div>
      </div>

      <div class="btn-group">
        <button class="back d-flex" @click="backStep">
          <img alt="" class="my-auto" src="/image/businessprofile/arrow-left.svg"/>
          <p class="my-auto back-text">Back</p>
        </button>
        <button class="start-plan" @click="registerCompany">Start with {{ plan }} plan</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      plan: 'bronze',
    }
  },
  methods: {
    backStep() {
      this.$emit('step', 'two')
    },
    selectPlan(plan) {
      this.plan = plan
    },
    registerCompany() {
      this.$emit('submit', this.plan)
    },
  },
}
</script>

<style scoped>
.back-text:hover {
  text-decoration: underline;
}

.company-profile-three {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #e4e4e4;
}

.profile-three-box {
  width: auto;
  min-width: 30%;
  height: auto;
  background: #ffffff;
  border-radius: 10px;
  margin: 0 0 40px 0;
  text-align: center;
  padding: 25px 20px;
}

.profile-three-box h4 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
}

.profile-three-box h5 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
}

.month-year {
  width: 100%;
  padding: 10px 20px;
  display: flex;
}

.monthly {
  padding: 5px;
  width: 80px;
  margin-right: 15px;
  border-radius: 35px;
  color: #ffffff;
  cursor: pointer;
}

.yearly {
  padding: 5px;
  width: 80px;
  background: #ebebeb;
  border-radius: 35px;
  cursor: pointer;
}

.monthly.active,
.monthly:hover,
.yearly.active,
.yearly:hover {
  background: var(--theme);
  color: white;
}

.box-container {
  margin: 0 15px;
  display: flex;
  justify-content: space-between;
}

.plan-box {
  width: 250px;
  height: 310px;
  margin: 20px 10px;
  background: #f4f4f4;
  border-radius: 20px;
  position: relative;
}

.popular {
  background: var(--theme);
  border-radius: 56.5px;
  color: #ffffff;
  padding: 5px;
  width: 80px;
  position: absolute;
  left: 35%;
  top: -14px;
}

.plan-box {
  cursor: pointer;
  border: 2px solid transparent;
}

.plan-box:hover,
.plan-box.active {
  border: 2px solid var(--theme);
}

.plan-box img {
  margin-top: 20px;
  width: 80px;
  height: 80px;
}

.plan-box h3 {
  margin-top: -25px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
}

.plan-box .price span {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
}

.plan-box ul {
  text-align: left;
  margin: 0 0 0 15px;
}

.plan-box ul li {
  margin: 10px;
}

.plan-box ul li img {
  width: 20px;
  height: 20px;
  margin: 0;
}

.btn-group {
  width: 92%;
  justify-content: space-between;
  margin: 0;
}

.btn-group .back {
  background: #ffffff;
  border: none;
  padding: 0;
}

.btn-group .back img {
  width: 20px;
  height: 20px;
  /* margin-left: -4px; */
}

.btn-group .start-plan {
  background: var(--theme);
  border-radius: 9px;
  color: #ffffff;
  padding: 0px 20px;
  width: auto;
  height: 40px;
  border: none;
}

.btn-group .start-plan:hover {
  background: white;
  border: 2px solid var(--theme);
  color: var(--theme);
  font-weight: bold;
}

@media (max-width: 846px) {
  .profile-three-box {
    width: 100%;
  }

  .month-year {
    justify-content: center !important;
  }

  .box-container {
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-left: 0;
  }
}
</style>
