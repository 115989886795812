/* eslint-disable */
export default [
    {
        path: '/dashboard',
        component: render('dashboard/Index'),
    },
    {path: '/pos', component: render('pos/Index')},

    {
        path: 'invoices/:type/list',
        component: render('invoices/InvoiceList'),
    },
    {
        path: 'invoices/:type/:submitType',
        component: render('invoices/InvoiceCreateEdit'),
    },
    {
        path: 'invoices/:type/:submitType/:id',
        component: render('invoices/InvoiceCreateEdit'),
    },

    {
        path: '/management',
        component: render('management/Index'),
        children: [
            {
                path: 'products/create/:type',
                component: render('management/all-products/ProductCreateEdit'),
            },
            {
                path: 'products/:submitType/:type/:id/:subId?',
                component: render('management/all-products/ProductCreateEdit'),
            },
            {
                path: 'products/list',
                props: true,
                component: render('management/all-products/AllProducts'),
            },

            {
                path: 'categories/list',
                component: render('management/categories/AllCategories'),
            },
            {
                path: 'categories/create',
                component: render('management/categories/CategoryCreateEdit'),
            },
            {
                path: 'categories/edit/:id',
                props: true,
                component: render('management/categories/CategoryCreateEdit'),
            },

            {
                path: 'coupons/list',
                component: render('management/coupons/CouponList'),
            },
            {
                path: 'coupons/create',
                component: render('management/coupons/CouponCreateEdit'),
            },
            {
                path: 'coupons/:submitType/:id',
                component: render('management/coupons/CouponCreateEdit'),
            },

            {
                path: 'customers/list',
                component: render('management/customers/AllCustomers'),
            },
            {
                path: 'customers/create',
                component: render('management/customers/CustomerCreateEdit'),
            },
            {
                path: 'customers/edit/:id',
                props: true,
                component: render('management/customers/CustomerCreateEdit'),
            },

            {
                path: 'suppliers/list',
                component: render('management/suppliers/AllSuppliers'),
            },
            {
                path: 'suppliers/create',
                component: render('management/suppliers/SupplierCreateEdit'),
            },
            {
                path: 'suppliers/edit/:id',
                props: true,
                component: render('management/suppliers/SupplierCreateEdit'),
            },

            {
                path: 'admins/list',
                component: render('management/admins/AdminList'),
            },
            {
                path: 'admins/create',
                component: render('management/admins/AdminCreateEdit'),
            },
            {
                path: 'admins/:submitType/:id',
                component: render('management/admins/AdminCreateEdit'),
            },

            {
                path: 'employees/list',
                component: render('management/employees/EmployeeList'),
            },
            {
                path: 'employees/create',
                component: render('management/employees/EmployeeCreateEdit'),
            },
            {
                path: 'employees/:submitType/:id',
                component: render('management/employees/EmployeeCreateEdit'),
            },
            {
                path: 'referrals',
                component: render('management/referrals/ReferralView'),
            },
            {
                path: 'bank-accounts/list',
                component: render('management/bank-accounts/AllBankAccounts'),
            },
            {
                path: 'bank-accounts/edit/:id',
                props: true,
                component: render('management/bank-accounts/BankAccountCreateEdit'),
            },
            {
                path: 'bank-accounts/create',
                props: true,
                component: render('management/bank-accounts/BankAccountCreateEdit'),
            },
            {
                path: 'payment-voucher/list',
                component: render('management/payment-voucher/VoucherList'),
            },
            {
                path: 'payment-voucher/create',
                component: render('management/payment-voucher/VoucherCreateEdit'),
            },
            {
                path: 'payment-voucher/:type/:id',
                component: render('management/payment-voucher/VoucherCreateEdit'),
            },
            {
                path: 'payrolls/list',
                component: render('management/payrolls/PayrollList'),
            },
            {
                path: 'payrolls/create',
                component: render('management/payrolls/PayrollCreateEdit'),
            },
            {
                path: 'payrolls/:submitType/:id?',
                component: render('management/payrolls/PayrollCreateEdit'),
            },

            {
                path: 'coa/list',
                component: render('management/coa/CoaList'),
            },

            {
                path: 'gl_formula/list',
                component: render('management/gl-formula/FormulaList'),
            },
            {
                path: 'gl_formula/create',
                component: render('management/gl-formula/FormulaCreateOrEdit'),
            },
            {
                path: 'gl_formula/edit/:id',
                component: render('management/gl-formula/FormulaCreateOrEdit'),
            },
        ],
    },

    {
        path: '/settings',
        component: render('settings/SettingMainIndex'),
        children: [
            {path: 'appearance', component: render('settings/appearance/Index')},
            {path: 'my-store', component: render('settings/companies/MyCompanyUpdate')},
            {
                path: 'companies/list',
                component: render('settings/companies/AllCompanies'),
            },
            {
                path: 'companies/create',
                component: render('settings/companies/CompanyCreateEdit'),
            },
            {
                path: 'companies/edit/:id',
                props: true,
                component: render('settings/companies/CompanyCreateEdit'),
            },
            {
                path: 'my-accounts',
                component: render('settings/my-accounts/Index'),
            },
            {
                path: 'language',
                component: render('settings/Language'),
            },
            {
                path: 'my-plan',
                component: render('settings/MyPlan'),
            },
            {
                path: 'api-keys',
                component: render('settings/developer/ApiKeyList'),
            },
            {
                path: 'printer',
                component: render('settings/Printer'),
            },
        ],
    },
    {
        path: '/reports',
        component: render('reports/Index'),
    },
]

function render(template) {
    return require(`@/pages/${template}.vue`).default
}
