<template>
  <div class="bg-white">
    <div class="d-flex justify-content-between align-items-center">
      <custom-search v-model="params.keyword" @search="fetchData()" />
      <div class="d-flex justify-content-end">
        <van-button @click="$router.push('/management/categories/create')" class="pm-product-add-button" type="default">
          <van-icon name="add-o" size="20" class="mr-2" />
          {{ $t('mngmt.category.newCategory')}}
        </van-button>
      </div>
    </div>

    <div v-if="loaded">
      <div v-if="categories.length > 0">
        <!-- start data table card -->
        <DataColumnCard :categories="categories" />

        <b-pagination
          :value="params.page"
          :per-page="params.limit"
          :total-rows="total"
          @change="changePage"
          first-number
          last-number
          pills
          align="center"
          class="mt-4"
        ></b-pagination>
      </div>
      <van-empty image="/image/empty-lists/empty-category.svg" v-else description="No Categories Yet" />
    </div>

    <div v-else class="loading">
      <van-loading type="spinner" />
    </div>
  </div>
</template>

<script>
import DataColumnCard from './components/DataColumnCard'

export default {
  components: { DataColumnCard },
  data() {
    return {
      loaded : false,
      params: {
        page: 1,
        limit: 10,
      },
      total: 0,
      categories: [],
    }
  },
  methods: {
    async getCategory() {
      try {
        const res = await axios.get('/categories', { params: this.params })
        this.categories = res.data.data.data
        this.total = res.data.data.total
        this.loaded = true
      } catch (error) {}
    },

    changePage(page) {
      this.params.page = page
      this.getCategory()
    },
  },
  mounted() {
    this.getCategory()
  },
}
</script>

<style scoped>
.pm-product-add-button {
  border-radius: 10px;
  height: 40px;
  border: 2px solid #f4f4f4;
  font-size: 13px;
}
</style>
