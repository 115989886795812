<template>
  <div class="bg-white">
    <div class="d-flex justify-content-between align-items-center">
      <custom-search v-model="params.keyword" @search="fetchData()" />
      <van-button @click="$router.push('/management/admins/create')" class="add-button" type="default">
        <van-icon name="add-o" size="20" class="mr-2" /> {{ $t('mngmt.admin.createAdmin') }}
      </van-button>
    </div>

    <div v-if="loaded">
      <div v-if="admins.length > 0">
        <table class="table my-border-table mt-0">
          <tbody>
            <tr v-for="(admin, index) in admins" :key="index">
              <td>
                <div class="d-flex">
                  <div class="ml-4">
                    <p class="mb-0 text-bold">{{ admin.full_name }}</p>
                    <p class="mb-0 text-muted text-sm">{{ admin.email }}, {{ admin.phone }}</p>
                  </div>
                </div>
              </td>
              <td class="text-right">
                <van-popover
                  v-model="admin.show_menu"
                  placement="bottom-end"
                  :close-on-click-outside="true"
                  trigger="click"
                  @open="menuOpen(index)"
                  :actions="actions"
                  @select="menuSelect($event, admin.user_id)"
                >
                  <template #reference>
                    <van-icon name="ellipsis" size="20" class="pointer" />
                  </template>
                </van-popover>
              </td>
            </tr>
          </tbody>
        </table>
        <b-pagination
          :value="params.page"
          :per-page="params.limit"
          :total-rows="total"
          @change="changePage"
          first-number
          last-number
          pills
          align="center"
          class="mt-4"
        ></b-pagination>
      </div>
      <van-empty image="/image/empty-lists/empty-admin.svg" v-else :description="$t('mngmt.admin.emptyAdmin')" />
    </div>

    <div v-else class="loading">
      <van-loading type="spinner" />
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
export default {
  data() {
    return {
      actions: [
        { text: this.$t('mngmt.admin.edit'), icon: 'edit', value: 'edit' },
        { text: this.$t('mngmt.admin.clone'), icon: 'cluster-o', value: 'clone' },
        { text: this.$t('mngmt.admin.del'), icon: 'delete-o', value: 'delete' },
      ],
      params: {
        keyword: '',
        page: 1,
        limit: 10,
      },
      admins: [],
      total: 0,
      loaded: false,
    }
  },
  methods: {
    changePage(page) {
      this.params.page = page
      this.fetchData()
    },
    menuOpen(index) {
      this.admins = this.admins.map((admin) => {
        admin.show_menu = false
        return admin
      })
      this.admins[index].show_menu = true
    },
    menuSelect(e, id) {
      switch (e.value) {
        case 'edit':
          this.$router.push(`/management/admins/edit/${id}`)
          break
        case 'delete':
          this.deleteAdmin(id)
          break
        case 'clone':
          this.$router.push(`/management/admins/clone/${id}`)
          break
      }
    },
    async deleteAdmin(id) {
      await Dialog.confirm({
        title: this.$t('action.areYouSure'),
        cancelButtonText: this.$t('action.cancel'),
        confirmButtonText: this.$t('action.del'),
      })
      let res = await axios.delete(`admins/${id}`)
      if (res) {
        Toast.success(this.$t('state.deleted'))
        this.fetchData()
      }
    },
    async fetchData() {
      let res = await axios.get('/admins', { params: this.params })
      if (res) {
        this.admins = res.data.data.users.map((admin) => {
          admin.show_menu = false
          return admin
        })
        this.total = res.data.data.total
        this.loaded = true
      }
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>

<style lang="scss" scoped>
.my-border-table {
  td {
    padding-left: 0 !important;
  }
}
</style>