<template>
  <div class="container-fluid">
    <div class="center">
      <div class="row">
        <!-- company selector -->
        <div class="col-8">
          <div class="d-flex main-icon align-items-center justify-content-between">
            <!--            <i id="scroll-left-button" class="far fa-chevron-left mr-3" @click="scrollBar('left')"></i>-->
            <van-icon id="scroll-left-button" class="mr-3" @click="scrollBar('left')" name="arrow-left" size="13px" />
            <ItemCategories id="nav-bars-container" :categories="categories" @filer-prod="filterProduct" />
            <!--            <i id="scroll-right-button" class="far fa-chevron-right ml-3" @click="scrollBar('right')"></i>-->
            <van-icon id="scroll-right-button" class="ml-3" @click="scrollBar('right')" name="arrow" size="13px" />
          </div>
        </div>
        <!-- search input -->
        <div class="col-4">
          <div class="input-group">
            <form class="w-100 d-flex" @submit.prevent="searchProducts">
              <button class="border-0 pl-4" type="button">
                <b-icon icon="upc-scan" scale="1.4" />
              </button>
              <input v-model="searchInput" placeholder="Barcode / SKU" type="text" />
            </form>
          </div>
        </div>
      </div>

      <!-- =============Start Items============ -->
      <div class="py-4">
        <div class="product-container mt-1">
          <template v-if="products.length > 0">
            <div class="product-item create-product-card pointer" @click="$router.push('/management/products/list')">
              <div class="mb-4">
                <van-icon name="add-o" size="30" />
              </div>
              <div>
                {{ $t('invoice.createProduct') }}
              </div>
            </div>

            <div v-for="(product, index) in products" :key="'prod_' + index" class="product-item">
              <ItemCard :product="product" />
            </div>

            <div v-for="i in 10" :key="i" class="product-item invisible"></div>
          </template>

          <template v-else-if="products.length == 0 && searchInput">
            <div class="d-flex flex-column justify-content-center align-items-center no-products">
              <van-empty
                :description="$t('pos.searchNotFound', { input: searchInput })"
                image="/image/empty-lists/not-found.svg"
              />
            </div>
          </template>

          <template v-else>
            <div class="d-flex flex-column justify-content-center align-items-center no-products">
              <van-empty :description="$t('pos.productEmpty')" image="/image/empty-lists/empty-pos.svg" />
              <router-link class="text-muted text-bold" to="/management/products/list">
                {{ $t('pos.createProduct') }}
              </router-link>
            </div>
          </template>
          <div v-if="haveMore" class="floated-bottom">
            <van-button class="btn btn-primary text-white add-to-pay" @click="loadMore">{{
              $t('pos.loadMore')
            }}</van-button>
          </div>
        </div>
      </div>
    </div>

    <!-- ============Start Right======= -->
    <div class="right">
      <RightSidebar></RightSidebar>
    </div>

    <!-- ======Start Customer Box=======-->
  </div>
</template>
<script>
import ItemCard from './components/ItemCard'
import ItemCategories from './components/ItemCategories'
import RightSidebar from './components/RightSidebar'
import { Toast } from 'vant'

export default {
  components: {
    ItemCard,
    ItemCategories,
    RightSidebar
  },
  computed: {
    haveMore: function () {
      return this.products.length < this.totalProduct
    },
  },
  data() {
    return {
      printers: [],
      company: window.$company,
      categories: [],
      products: [],
      params: { page: 1, limit: 20 },
      categoryParams: { page: 1, limit: 20 },
      totalProduct: 0,
      showPickCustomer: false,
      searchInput: '',
    }
  },
  mounted() {
    this.getProducts()
    this.getCategories()
    this.$barcodeScanner.init(this.getBarcode)
  },
  beforeRouteLeave(to, from, next) {
    this.$barcodeScanner.destroy()
    // this.$store.commit('clearItem')
    next()
  },
  methods: {
    scrollBar(type) {
      let ele = document.querySelector('.main-category')
      if (type == 'right') {
        ele.scroll({
          left: ele.scrollLeft + 100,
          top: 0,
          behavior: 'smooth',
        })
      } else {
        ele.scroll({
          left: ele.scrollLeft - 100,
          top: 0,
          behavior: 'smooth',
        })
      }
    },

    chooseCustomer() {
      this.showPickCustomer = true
    },

    async getProducts() {
      Toast.loading({
        message: 'Loading',
        forbidClick: true,
        duration: 0,
      })
      let res = await axios.get('/pos/products', { params: this.params })
      this.products.push(...res.data.data.data)
      this.totalProduct = res.data.data.total
      Toast.clear()
    },

    async getCategories() {
      let res = await axios.get('/categories', { params: this.categoryParams })
      this.categories = res.data.data.data
    },

    getBarcode(barcode) {
      this.searchWithBarcode(barcode)
    },

    filterProduct(cat) {
      this.params.category = cat ? cat.id : null
      this.categories = this.categories.map((category) => {
        return {
          ...category,
          selected: cat ? cat.id === category.id : false,
        }
      })
      this.products = []
      this.getProducts()
    },

    async searchProducts() {
      let keyword = this.searchInput
      this.params.page = 1
      this.params.keyword = keyword
      this.products = []
      this.getProducts()
    },

    async searchWithBarcode(barcode) {
      if (barcode) {
        this.params.page = 1
        Toast.loading({
          message: 'Searching',
          forbidClick: true,
          duration: 0,
        })
        let res = await axios.get('/pos/products', {
          params: {
            ...this.params,
            barcode,
          },
        })
        let data = res.data.data.data
        Toast.clear()
        if (data.length) {
          const product = data[0]
          if (!product.apo_obs) {
            product.options = []
            this.addToCart(product)
          } else {
            product.price = product.price + product.option_price
            this.addToCart(product)
          }
        } else {
          Toast('No matching product')
        }
      }
    },

    addToCart(resProduct) {
      let product = {
        ...resProduct,
        quantity: 1,
      }
      this.$store.commit('addItem', product)
    },

    loadMore() {
      this.params.page++
      this.getProducts()
    },
  },
}
</script>
<style scoped>
.product-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.product-item {
  flex: 1 1 160px;
}

.invisible {
  visibility: hidden;
}

#scroll-left-button,
#scroll-right-button {
  cursor: pointer;
}

.no-products {
  width: 100%;
  height: 50vh;
}

.item-cards-container {
  margin-top: 13px;
}

.center {
  margin-right: 300px;
}

.right {
  top: 0;
  right: 0;
  padding-bottom: 50px;
  bottom: 0;
  height: 100vh;
  background: white;
  position: fixed;
}

.input-group {
  width: 100%;
  margin-top: -4px;
}

.input-group button {
  width: 15%;
  padding: 8px;
  background-color: #e8e8e8;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.search-icon {
  width: 17px;
  height: 17px;
}

.input-group input {
  border: none;
  width: 85%;
  height: 40px;
  padding-left: 7px;
  background-color: #e8e8e8;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.main-icon i {
  padding: 10px 14px 10px 12px;
  background-color: #fff;
  border-radius: 10px;
  font-size: 12px;
  height: 33px;
  width: 33px;
}

.main-icon i:active {
  background: var(--theme);
  color: white;
}

.fs-20 {
  font-size: 20px;
}

.floated-bottom {
  position: fixed;
  bottom: 0;
  margin-bottom: 10px;
  width: calc(100% - 300px);
  text-align: center;
}
.create-product-card {
  border: 2px solid #e8e8e8;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #e8e8e8;
  font-weight: bold;
}
</style>
