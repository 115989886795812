<template>
  <div class="total-amount mt-2">
    <table>
      <tr>
        <td class="blank-space"></td>
        <td class="text-right">{{ $t('subtotal') }} <span class="span-left">- </span></td>
        <td class="text-right">
          <span class="span-right">{{ $currency }} {{ $cast(getPrice().subTotal) }}</span>
        </td>
      </tr>
      <tr>
        <td class="blank-space"></td>
        <td class="text-right">
          <div class="price-label">{{ $t('discount.discount') }}</div>
          <div class="d-flex align-items-center justify-content-end">
            <select v-model="params.discount_type">
              <option value="fixed">{{ $t('discount.fixed') }}</option>
              <option value="percent">{{ $t('discount.percent') }}</option>
            </select>
            <input v-model="params.discount" type="number" id="discount" />
            <span class="span-left">- </span>
          </div>
        </td>
        <td class="text-right">
          <span class="span-right">{{ $currency }} {{ $cast(getPrice().discount) }}</span>
        </td>
      </tr>
      <tr>
        <td class="blank-space"></td>
        <td class="text-right">
          <div class="d-flex align-items-center justify-content-end">
            {{ $t('tax') }} ( % )
            <input v-model="params.tax" class="w-40" type="number" id="tax" />

            <select v-model="params.vat_type" style="width: 90px">
              <option value="exclusive">{{ $t('exclusive') }}</option>
              <option value="inclusive">{{ $t('inclusive') }}</option>
            </select>

            <span class="span-left">- </span>
          </div>
        </td>
        <td class="text-right">
          <span class="span-right">{{ $currency }} {{ $cast(getPrice().tax) }}</span>
        </td>
      </tr>
      <tr>
        <td class="blank-space"></td>
        <td class="text-right">{{ $t('grandTotal') }} <span class="span-left">- </span></td>
        <td class="text-right">
          <span class="span-right">{{ $currency }} {{ $cast(getPrice().grandTotal) }} </span>
        </td>
      </tr>
      <!-- <tr>
        <td class="blank-space"></td>
        <td class="text-right">
          <div class="d-flex align-items-center justify-content-end">
            <div class="d-flex">
              <van-switch v-model="params.partial_paid" size="15px" @change="togglePaidAmount" shape="square">
              </van-switch>
              <span class="ml-2">Partially Paid</span>
            </div>

            <span class="span-left">- </span>
          </div>
        </td>
        <td class="text-right">
          <input v-model="params.paid_amount" class="text-right" type="number" v-if="params.partial_paid" />
        </td>
      </tr>
      <tr>
        <td></td>
        <td colspan="2"><hr style="width: 100%" /></td>
      </tr>
      <tr v-if="params.partial_paid">
        <td class="blank-space"></td>
        <td class="text-right">Total Left Amount<span class="span-left">- </span></td>
        <td class="text-right">
          <span class="span-right">{{ $currency }} {{ $cast(getRemainPrice()) }} </span>
        </td>
      </tr>
      -->
    </table>
  </div>
</template>

<script>
export default {
  props: ['params'],
  data() {
    return {
      discountType: 'fixed',
    }
  },

  mounted() {},
  methods: {
    // togglePaidAmount() {
    //   if (this.params.partial_paid) {
    //     this.params.paid_amount = this.getGrandTotal()
    //   } else {
    //     this.params.paid_amount = 0
    //   }
    // },
    getPrice() {
      let total = 0
      let tax = 0
      let discount = 0
      let subTotal = 0
      let grandTotal = 0
      this.params.products.forEach((product) => {
        total += Number(product.price) * Number(product.quantity) - Number(product.discount)
      })
      if (this.params.vat_type == 'exclusive') {
        subTotal = total
        discount = this.getDiscount(subTotal)
        tax = (subTotal - discount) * (this.params.tax / 100)
        grandTotal = subTotal - discount + tax
      } else {
        discount = this.getDiscount(total)
        grandTotal = total - discount
        subTotal = grandTotal / ((100 + Number(this.params.tax)) / 100)
        tax = subTotal * (this.params.tax / 100)
      }
      return { subTotal, discount, tax, grandTotal }
    },
    // getSubTotal() {
    //   let subTotal = 0
    //   this.params.products.forEach((product) => {
    //     subTotal += ( product.price * product.quantity ) - product.discount
    //   })
    //   return subTotal
    // },
    getDiscount(total) {
      if (this.params.discount_type == 'percent') {
        if (!this.params.discount || this.params.discount == 0) {
          return 0
        }
        return total * (Number(this.params.discount) / 100)
      } else {
        return Number(this.params.discount) ?? 0
      }
    },
    // getTax() {
    //   let discountedPrice = Number(this.getSubTotal()) - Number(this.getDiscount())
    //   let taxPrice = discountedPrice * (this.params.tax / 100)
    //   return taxPrice
    // },
    // getGrandTotal() {
    //   let discountedPrice = Number(this.getSubTotal()) - Number(this.getDiscount())
    //   let grandTotal = discountedPrice
    //   if(this.params.vat_type == 'exclusive') {
    //     grandTotal += Number(this.getTax())
    //   } else {
    //     grandTotal -= Number(this.getTax())
    //   }
    //   return grandTotal
    // },
    // getRemainPrice() {
    //   let remainPrice = Number(this.getGrandTotal()) - this.params.paid_amount
    //   return remainPrice
    // },
  },
}
</script>

<style scoped>
.total-amount .blank-space {
  width: 50vw;
}
select {
  margin-left: 7px;
  border-radius: 9px;
  border: 2px solid #f4f4f4;
  min-height: 30px;
  outline: none;
  box-shadow: none;
  padding: 0px 10px;
}
td {
  padding: 10px;
  vertical-align: middle !important;
}
.total-amount td {
  width: 250px;
}
.total-amount td .span-left {
  margin-left: 10%;
}
.price-label {
  margin-right: 32px;
}

.total-amount td input {
  border: 2px solid #f4f4f4;
  padding: 5px;
  border-radius: 10px;
}

.w-40 {
  width: 40px;
  margin-left: 10px;
  text-align: right;
  padding-right: 7px !important;
}
#discount {
  width: 100px;
  margin-left: 10px;
  text-align: right;
  padding-right: 5px;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  display: none;
}
</style>
