<template>
  <div class="bg-white">
    <div v-if="!editId || loaded">
      <h1 @click="showSubProductForm ? (showSubProductForm = false) : $router.back()">
        <van-icon class="mr-3" name="arrow-left" size="15px" />
        <span class="text-capitalize">{{ editId ? 'Edit' : 'Create' }} Product</span>
        <span class="text-warning ml-2">(Waiting for approval)</span>
      </h1>

      <div v-if="this.$route.params.type == 'group'" class="mt-4 px-5">
        <div v-if="showSubProductForm">
          <ProductFormCard :params="productParams" :errors="errors" @submit="submitSubProduct()" :loading="loading" />
        </div>

        <div v-else class="group-form">
          <GroupFormCard :params="groupParams" :errors="errors" @addSubProduct="addSubProduct()" />
          <SubProductList :params="groupParams.sub_products" @edit="editSubProduct; $router.push(`/management/products/edit/group/${editId}/${$event}`)" />
          <Button @click.native="submitGroupProduct()" :type="editId ? 'edit' : 'create'" :loading="loading" />
        </div>
      </div>

      <div v-else class="mt-4 px-5">
        <ProductFormCard :params="productParams" :errors="errors" @submit="submitSingleProduct()" :loading="loading" />
      </div>
    </div>
    <div v-else class="loading">
      <van-loading type="spinner" />
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import GroupFormCard from './components/GroupFormCard'
import ProductFormCard from './components/ProductFormCard'
import SubProductList from './components/SubProductList'
import productParams from './ProductParams.js'

export default {
  components: { GroupFormCard, ProductFormCard, SubProductList },
  data() {
    return {
      loaded: false,
      loading: false,
      editId: null,
      subProductEditId: null,
      showSubProductForm: false,
      groupParams: {
        group_name: null,
        enable_selling: true,
      },
      productParams: JSON.parse(JSON.stringify(productParams)),
      errors: {},
    }
  },
  watch: {
    $route() {
      this.$store.commit('setOptionChanged', false)
      if (this.$route.params.id) {
        this.editId = this.$route.params.id
        this.fetchData()
      }
      if (this.$route.params.subId) {
        this.editSubProduct(this.$route.params.subId)
      }
    },
  },
  mounted() {
    this.$store.commit('setOptionChanged', false)
    if (this.$route.params.id) {
      this.editId = this.$route.params.id
      this.fetchData()
    }
    if (this.$route.params.subId) {
      this.editSubProduct(this.$route.params.subId)
    }
  },
  methods: {
    async fetchData() {
      let res = await axios.get(`/products/${this.editId}`)
      if (res) {
        let data = res.data.data
        data.enable_selling = data.enable_selling ? true : false

        if (this.$route.params.type == 'group') {
          this.groupParams = data
        } else {
          this.getOptions(data)
        }
        this.loaded = true
      }
    },
    async submitGroupProduct() {
      this.loading = true
      if (this.editId) {
        let res = await axios.post(`/group_products/${this.editId}?_method=PUT`, this.groupParams)
        this.loading = false
        if (res.data.code == 422) {
          this.errors = res.data.error
        } else {
          this.errors = {}
          this.$router.push(`/management/products/list`)
        }
      } else {
        let res = await axios.post('/group_products', this.groupParams)
        this.loading = false
        if (res.data.code == 422) {
          this.errors = res.data.error
        } else {
          this.errors = {}
          this.$router.push(`/management/products/edit/group/${res.data.data.id}`)
        }
      }
    },
    async submitSubProduct() {
      this.loading = true
      let formData = this.appendFormData()
      if (this.subProductEditId && this.$route.params.submitType == 'edit') {
        let res = await axios.post(
          `/group_products/${this.editId}/sub_products/${this.subProductEditId}?_method=PUT`,
          formData,
        )
        this.loading = false
        if (res.data.code == 422) {
          this.errors = res.data.error
        } else {
          this.errors = {}
          this.submitOptions(this.subProductEditId)
          this.fetchData()
          this.clearProductParams()
          this.showSubProductForm = false
          this.$router.back()
        }
      } else {
        let res = await axios.post(`/group_products/${this.editId}/sub_products`, formData)
        this.loading = false
        if (res.data.code == 422) {
          this.errors = res.data.error
        } else if(res.data.data){
          this.errors = {}
          this.submitOptions(res.data.data.id)
          this.fetchData()
          this.clearProductParams()
          this.showSubProductForm = false
          this.$router.push(`/management/products/edit/group/${this.editId}/${res.data.data.id}`)
        }
      }
    },
    async submitSingleProduct() {
      this.loading = true
      let formData = this.appendFormData()
      if (this.editId && this.$route.params.submitType == 'edit') {
        let res = await axios.post(`/products/${this.editId}?_method=PUT`, formData)
        this.loading = false
        if (res.data.code == 422) {
          this.errors = res.data.error
        } else {
          this.errors = {}
          this.submitOptions(this.editId)
          Toast.success(this.$t('state.updated'))
          this.$router.back()
        }
      } else {
        let res = await axios.post(`/products`, formData)
        this.loading = false
        if (res.data.code == 422) {
          this.errors = res.data.error
        } else if(res.data.data) {
          this.errors = {}
          this.submitOptions(res.data.data.id)
          Toast.success(this.$t('state.created'))
          this.$router.push(`/management/products/edit/single/${res.data.data.id}`)
        }
      }
    },
    async submitOptions(id) {
      if (!this.$store.state.optionChanged) {
        return false
      }
      //check options is not empty
      return axios.post(`/products/${id}/product_options`, {
        product_options: this.productParams.options,
      })
    },
    async editSubProduct(id) {
      this.loaded = false
      let res = await axios.get(`group_products/${this.editId}/sub_products/${id}`)
      if (res.data) {
        let data = res.data.data
        data.enable_selling = data.enable_selling ? true : false
        this.getOptions(data)
        this.subProductEditId = id
        this.showSubProductForm = true
        this.loaded = true
      }
    },
    async getOptions(data) {
      let res = await axios.get(`/products/${data.id}/product_options`)
      if (res) {
        let options = res.data.data
        if (options && options.length > 0) {
          data.options = options.map((option) => {
            option.showSetting = false
            option.is_multiple = option.is_multiple ? true : false
            return option
          })
        } else {
          data.options = [
            {
              is_multiple: true,
              sort: 10,
              title: null,
              showSetting: false,
              product_option_items: [
                {
                  title: null,
                  sort: 1,
                  price: 0,
                  active: 0,
                  auto_select: 0,
                },
              ],
            },
          ]
        }
        this.productParams = data
      }
    },
    appendFormData() {
      let fd = new FormData()
      if (this.productParams.name) {
        fd.append('name', this.productParams.name)
      }
      if (this.productParams.images.length > 0) {
        this.productParams.images.forEach((image) => {
          if (typeof image != 'string') {
            fd.append('images[]', image)
          } else {
            fd.append('old_images[]', image)
          }
        })
      }

      if (this.productParams.category_id) {
        fd.append('category_id', this.productParams.category_id)
      }
      if (this.productParams.buy_price) {
        fd.append('buy_price', this.productParams.buy_price)
      }
      if (this.productParams.barcode) {
        fd.append('barcode', this.productParams.barcode)
      }
      if (this.productParams.sku) {
        fd.append('sku', this.productParams.sku)
      }
      if (this.productParams.retail_price) {
        fd.append('retail_price', this.productParams.retail_price)
      }
      if (this.productParams.original_price) {
        fd.append('original_price', this.productParams.original_price)
      }
      if (this.productParams.instock) {
        fd.append('instock', this.productParams.instock)
      }
      if (this.productParams.n_stock) {
        fd.append('n_stock', this.productParams.n_stock ? 1 : 0)
      }
      if (this.productParams.apo_obs) {
        fd.append('apo_obs', this.productParams.apo_obs ? 1 : 0)
      }
      if (this.productParams.enable_selling) {
        fd.append('enable_selling', this.productParams.enable_selling ? 1 : 0)
      }
      if (this.productParams.unit) {
        fd.append('unit', this.productParams.unit)
      }
      if (this.productParams.units) {
        fd.append('units', JSON.stringify(this.productParams.units))

        this.productParams.units.forEach((unit,index) => {
          if(unit.image) {
            fd.append(`unit_image${index}`, unit.image)
          }
        })
      }
      return fd
    },
    clearProductParams() {
      this.productParams = { ...productParams }
    },
    addSubProduct() {
      this.$router.push(`/management/products/edit/group/${this.editId}`)
      this.showSubProductForm = true
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  cursor: pointer;
}
.group-form {
  max-width: 580px;
  margin: 0 auto;
}
</style>