export default {
    all: 'All',
    amount: 'Amount',
    total: 'Total',
    subtotal: 'Subtotal',
    grandTotal: 'Grand Total',
    discount: {
        discount: 'Discount',
        fixed: 'Fixed',
        percent: 'Percent',
        type: 'Discount Type',
    },
    tax: 'Tax',
    chooseCustomer: 'Choose Customer',
    chooseSupplier: 'Choose Supplier',
    revenue: 'Revenue',
    expense: 'Expense',
    sale: 'Sale',
    purchase: 'Purchase',
    customer: 'Customer',
    supplier: 'Supplier',
    name: 'Name',
    email: 'Email',
    password: 'Password',
    phoneNumber: 'Phone Number',
    address: 'Address',
    date: 'Date',
    taxIdNum: 'Tax ID Number',
    shippingAddress: 'Shipping Address',
    billingAddress: 'Billing Address',
    sale_invoice: 'Sale Invoice',
    purchase_invoice: 'Purchase Invoice',
    sale_invoices: 'Sale Invoices',
    purchase_invoices: 'Purchase Invoices',
    createdAt: 'Created At',
    status: 'Status',
    language: 'Language',
    //image uploader
    addImage: 'Add Image',
    uploadFile: 'Upload a file',
    upgradeError: 'Upgrade to get full access',
    error: 'Error!',
    sidebar: {
        pos: 'POS',
        dashboard: 'Dashboard',
        revenue: 'Revenue',
        expense: 'Expense',
        report: 'Reports',
        mngmt: 'Mngmt',
        setting: 'Setting',
        logout: 'LOGOUT',
    },
    action: {
        cancel: 'Cancel',
        filter: 'Filter',
        export_excel: 'Export',
        selected: '{count} Selected',
        selectAll: 'Select All',
        unselectAll: 'Unselect All',
        printAll: 'Print All',
        create: 'Create',
        del: 'Delete',
        saveChange: 'Save Changes',
        print: 'Print',
        remove: 'Remove',
        edit: 'Edit',
        save: 'Save',
        change: 'Change',
        areYouSure: 'Are you sure?',
        printReceipt: 'Print Receipt',
        printPdf: 'Print PDF',
        notNow: 'Not now',
        renewPlan: 'Renew Plan',
        upgrade: 'Upgrade',
        update: 'Update',
        switchTo: 'Switch to {company}',
        add: 'Add',
        apply: 'Apply',
        search: 'Search',
        back: 'Back',
    },
    state: {
        loading: 'Loading',
        creating: 'Creating',
        saving: 'Saving',
        deleting: 'Deleting',
        deleted: 'Deleted',
        delFail: "Can't Delete!",
        fail: 'Fail',
        edited: 'Edited',
        restored: 'Restored',
        success: 'Success',
        requiredFields: 'Required All Fields',
        updated: 'Updated',
        created: 'Created',
        switched: 'Switched',
        yes: 'Yes',
        addingAddress: 'Adding Addresses',
    },
    pos: {
        createProduct: 'Click here to create product.',
        loadMore: 'Load More',
        productEmpty: 'Product will be show up here.',
        searchNotFound: '{input} Not Found!',
        emptyCart: 'No Cart items',
        checkOut: 'Checkout',
        printConfirm: 'Do you want to print receipt?',
        print: 'PRINT',
        checkoutConfirm: 'Are you sure to checkout?',
        checkingOut: 'Checking Out',
        noAccountAlert: 'You have no bank accounts. Do you want to add one?',
        bankAccount: 'Bank Account',
        selectAccountInfo: 'Please select a bank account',
        selectAccount: 'Select Bank Account',
        clearAll: 'Clear All {count} items',
    },
    dashboard: {
        dashboard: 'Dashboard',
        //income
        revenueOverview: 'Revenue Overview',
        overviewSummary: '{status} {percentage}% Previous {percentageType}',
        barChart: 'Bar Chart',
        colChart: 'Column Chart',
        incomeVsExpense: 'Revenue Vs Expense (COGS)',
        accountOverview: 'Accounts / Assets Overview',
        noAccount: 'No Accounts Yet',
        noProduct: 'No Products Yet',
        noCustomer: 'No Customers Yet',
        //Summary Card
        capital: 'Capital',
        assets: 'Assets',
        totalLiability: 'Total Liability',
        saleTax: 'Sale Tax',
        accountPayable: 'Account Payable',
        withholdingTax: 'Withholding Tax',
        taxes: 'Taxes',
        purchase: 'Purchase',
        purchaseTax: 'Purchase Tax',
        //top product
        topProducts: 'Top Products',
        productName: 'Product Name',
        totalOrder: 'Total Order',
        percentage: 'Percentage (%)',
        //top customer
        topCustomers: 'Top Customers',
        cusName: 'Customer Name',
        totalPurchase: 'Total Purchase',
        //low stock
        inventoryAlert: 'Inventory Alert',
        lowStockAlert: 'Low Stock Alert',
        lowStockInfo: 'This product has low stock. Check those product to reorder before the stock reach zero.',
        stockLeft: '{stock} stocks left',
        seeAll: 'See all inventories',
        seeLess: 'See less inventories',
        noLowStock: "Nothing to worry. There's enough instock.",
    },
    invoice: {
        create_sale_invoice: 'New Sale Invoice',
        create_purchase_invoice: 'New Purchase Invoice',
        invoiceNumber: 'Invoice Number',
        createdDate: 'Created Date',
        account: 'Account',
        paidByTotal: 'Paid/Total',
        leftAmount: 'Left Amount',
        fullyPaid: 'Paid',
        emptyInvoice: 'No Invoices Yet',
        //create edit
        general: 'General',
        payment: 'Payments',
        //customer form
        autoCreate: 'Auto Create {person}',
        referenceNumber: 'Reference Number',
        invoiceDate: 'Invoice Date',
        createdDateTime: 'Created Date/Time',
        dueDate: 'Due Date',
        //product
        productDetail: 'Product Details',
        unit: 'Variations',
        qty: 'Qty',
        price: 'Price',
        totalPrice: 'Total Price',
        addProduct: 'Add Product',
        reset: 'Reset',
        searchProduct: 'Search Product',
        createProduct: 'Create New Product',
        //payments
        payments: 'Payments',
        payInfo: ' This will allow you to split payments on multiple dates and will apply suitable accounting formula.',
        payer: 'Payer',
        //actions
        edit: 'Edit Invoice',
        clone: 'Clone Invoice',
        del: 'Delete Invoice',
    },
    report: {
        assets: 'Assets',
        liability: 'Liability',
        emptyRecord: 'No records found',
    },
    mngmt: {
        inventory: 'Inventory',
        products: 'Products',
        categories: 'Categories',
        coupons: 'Coupons',
        contacts: 'Contacts',
        admins: 'Admins',
        suppliers: 'Suppliers',
        customers: 'Customers',
        employees: 'Employees',
        finance: 'Finance',
        paymentVoucher: 'Payment Voucher',
        accounts: 'Accounts / Assets',
        payroll: 'Payroll',
        accounting: 'Accounting',
        chartOfAccount: 'Chart of Account',
        glFormula: 'GL Formula',
        referrals: 'Referrals',
        referral: {
            yourReferral: 'Your Referral Code',
            shareReferral: 'Share the referral code to get benefits',
        },
        admin: {
            createAdmin: 'Create New Admin',
            emptyAdmin: 'No Admins Yet',
            addNewAdmin: 'Add New Admin',
            role: 'Role',
            //actions
            edit: 'Edit Admin',
            clone: 'Clone Admin',
            del: 'Delete Admin',
        },
        category: {
            newCategory: 'Create New Category',
            emptyCategory: 'No Categories Yet',
            editCategory: 'Edit Category',
        },
        coa: {
            newCoa: 'Create New COA',
            emptyCoa: 'No Chart Of Accounts Yet',
            //actions
            edit: 'Edit COA',
            clone: 'Clone COA',
            del: 'Delete COA',
        },
        coupon: {
            newCoupon: 'Create New Coupon',
            emptyCoupon: 'No Coupons Yet',
            code: 'Code',
            //create edit
            couponName: 'Coupon Name',
            couponCode: 'Coupon Code',
            amountOff: 'Amount Off',
            percentOff: 'Percentage Off',
            timeLimitInfo: `Usable time is to limit discount/coupon for each per use.
                This will not need to fill for unlimited.`,
            usableTime: 'Usable Time',
            validDateRange: 'Set Valid Date Length',
            applyTo: 'Applies to {target}',
            add: 'Add {target}',
            validEnds: 'All Products, Ends {time}',
            valid: 'All Products, Valid {time}',
            validUnlimited: 'All Products, Valid Unlimited',
        },
        product: {
            addPrice: 'Add price',
            newProduct: 'Create New Product',
            gpProduct: 'Create Group Product',
            singleProduct: 'Create Single Product',
            addStock: 'Add Stock',
            productName: 'Product Name',
            instock: 'In Stock (Total)',
            retailPrice: 'Retail Price',
            selling: 'Enable Selling',
            outOfStock: 'out of stock',
            printBarcode: 'Print Barcode',
            quantity: 'Quantity',
            barcodePerRow: 'Barcode Per Row',
            barcodeSize: 'Barcode Size',
            barcodeHeight: 'Barcode Height',
            pageWidth: 'Page Width(mm)',
            pageHeight: 'Page Height(mm)',
            gap: 'Gap between barcodes',
            rememberSetting: 'Remember setting for next time',
            notFound: 'No Products Found',
            noProduct: 'No Products Yet',
            subProduct: 'Sub-Product',
            groupName: 'Group Name',
            subProductImage: 'Sub-product Image',
            noBarcode: 'No Barcode',
            noBarcodeMessage: 'This product do not have an assigned barcode or barcode is invalid',
            //create edit
            allowNegativeStock: 'Allow Negative Stock',
            barcode: 'Barcode',
            productSKU: 'Product SKU',
            category: 'Category',
            originalPrice: 'Original Sale Price (optional)',
            unitBuyPrice: 'Unit Buy Price',
            initialStock: 'Initial Stock',
            unit: 'Unit (optional)',
            //create eidt => units
            unitsTitle: 'Variations',
            unitTitle: 'Variation',
            coefficient: 'Coefficient',
            sellPrice: 'Sell Price',
            buyPrice: 'Buy Price',
            active: 'Active',
            inactive: 'Inactive',
            autoPickup: 'Auto pick below options on scanned barcode for POS',
            variations: 'Variations',
            variationsWarningInfo: 'Create product first to add variations',
            actions: 'Actions',
            addProductUnit: 'Add Product Variation',
            unitName: 'Unit Name',
            Coefficient: 'coefficient',
            //create edit -> options
            optionTitle: 'Option/ Addons',
            optionName: 'Option name',
            requiredOne: 'Required at least one item to select',
            enableMulti: 'Enable Multiple Selection',
            item: 'Item',
            salePrice: 'Sale Price',
            purchasePrice: 'Purchase Price',
            autoPick: 'Auto Pick',
            //actions
            edit: 'Edit Product',
            clone: 'Clone Product',
            del: 'Delete Product',
            pleaseSelectProducts: 'Please select products to add stocks',
        },
        voucher: {
            newVoucher: 'Add Payment Voucher',
            emptyVoucher: 'No Vouchers Yet',
            cashPayment: 'Cash Payment',
            cashReceived: 'Cash Received',
            accountCode: 'Account Code',
            voucherNo: 'Voucher No.',
            accountName: 'Account Name',

            bankBalance: 'Bank Balance',
            date: 'Date',
            account: 'Account',
            paymentTo: 'Payment to',
            paymentBy: 'Payment by',
            customer: 'Customer',
            supplier: 'Supplier',
            employee: 'Employee',
            loading: 'Loading...',
            taxExclusive: 'Tax Exclusive',
            description: 'Description',
            addVoucher: 'Add Voucher',
            netTotal: 'Net total',

            //actions
            edit: 'Edit Voucher',
            clone: 'Clone Voucher',
            del: 'Delete Voucher',
        },
        customer: {
            newCustomer: 'Create New Customer',
            emptyCustomer: 'No Customers Yet',
            //create edit
            title: 'Add New Customer',
            personalInformation: 'Personal Information',
            name: 'Customer Name',
            companyName: 'Company Name',
            taxId: 'Tax Id',
            addEmail: 'Add Email',
            addPhone: 'Add Phone',
            address: 'Address',
            addNewAddress: 'Add New Address',
            editAddress: 'Edit Address',
            addresses: 'Addresses',
            addressName: 'Name',
            icon: 'Icon',
            description: 'Description',
            myLocation: 'My Location',
            location: 'Location',
        },
        supplier: {
            newSupplier: 'Create New Supplier',
            emptySupplier: 'No Suppliers Yet',
            name: 'Supplier Name',
            address: 'Address',
            billingAddress: 'Billing Address',
        },
        employee: {
            employees: 'Employees',
            newEmployee: 'Create New Employee',
            emptyEmployee: 'No Employees Yet',
            currentEmployee: 'Current Employees',
            exEmployee: 'Ex Employees',
            //create edit
            addNewEmployee: 'Add New Employee',
            employeeName: 'Employee Name',
            employeeId: 'Employee Id',
            workTimeFrom: 'Work Time From',
            workTimeTo: 'Work Time To',
            socialSecFund: 'Social Security Fund',
            baseSalary: 'Base Salary',
            socialSecNumber: 'Social Security Number',
            address: 'Address',
            wht: 'Wht (%)',
            netIncome: 'Net Income',
            otherIncome: 'Other Income (Optional)',
            otherDeduction: 'Other Deduction (Optional)',

            //actions
            edit: 'Edit Employee',
            clone: 'Clone Employee',
            restore: 'Restore Employee',
            del: 'Delete Employee',
        },
        bankAccount: {
            addAssets: 'Add Custom Assets',
            balance: 'Balance',
            accountNumber: 'Account number : {accountNumber}',
            defaultAccount: 'Default Account',
            emptyAccount: 'No Accounts Yet',
            //create edit
            title: 'Add Custom Account',
            accountDetail: 'Account Detail',
            accountTitle: 'Account Title',
            accNumber: 'Account Number',
            bankName: 'Bank Name',
            initialBalance: 'Initial Balance',
        },
        payrolls: {
            newPayroll: 'Create New Payroll',
            emptyPayroll: 'No Payrolls Yet',
            sent: 'Sent',
            backToPayroll: 'Back to payroll',
            payroll: 'Payroll',
            dateRange: 'From {from} - To {to}',
            payFrom: 'Pay From',
            paid: 'Paid',
            notPaid: 'Not Paid',
            //actions
            edit: 'Edit Payroll',
            clone: 'Clone Payroll',
            restore: 'Restore Payroll',
            del: 'Delete Payroll',
            print: 'Print Payroll',
        },
    },
    setting: {
        myProfile: 'My Profile',
        appearance: 'Appearances',
        posPrinter: 'POS Printers',
        changeLanguage: 'Change Language',
        myStore: 'My Store',
        store: 'Store',
        account: 'Account',
        developer: 'Developer',
        myPlan: 'My Plan',
        apiKey: 'API Keys',
        doc: 'Documentation',
        appear: {
            info: 'You can make changes of color theme.',
            chooseColor: 'Choose Color',
        },
        profile: {
            currentPlan: 'Current Plan',
            lifetimeFree: 'Lifetime Free',
            startAt: 'Starts at',
            expireAt: 'Expire at',
            changePlan: 'Change Plan',
        },
        company: {
            newStore: 'Create New Store',
            storeInformation: 'Store Information',
            storeBanner: 'Store Banner',
            storeLogo: 'Store Logo',
            storeName: 'Store Name',
            currency: 'Currency',
            taxId: 'Tax ID',
            defaultTax: 'Default TAX ( % )',
            storeType: 'Store Type',
            purchaseInvoicePrefix: 'Purchase Invoice Prefix',
            saleInvoicePrefix: 'Sale Invoice Prefix',
            selectCurrency: 'Select Currency',
        },
        paymentLog: {
            title: 'Payment Logs',
            id: 'ID',
            amt: 'Amount',
            invoiceNumber: 'Invoice Number',
            period: 'Period',
            status: 'Status',
            validFrom: 'Valid From',
            validTo: 'Valid To',
        },
    },
    vatType: 'Vat Type',
    exclusive: 'Exclusive',
    inclusive: 'Inclusive',
}
