let app = {}

app.getErrors = (err) => {
  if (err.response.status === 422) {
    const p = err.response.data.errors
    const errors = {}
    for (const key in p) {
      errors[key] = p[key][0]
    }
    return errors
  }
  return err.response.data.message
}

app.isElementInViewport = (el, container) => {
  var rect = el.getBoundingClientRect();
  return (
    (rect.top <= 0
      && rect.bottom >= 0)
    ||
    (rect.bottom >= (container.clientHeight) &&
      rect.top <= (container.clientHeight))
    ||
    (rect.top >= 0 &&
      rect.bottom <= (container.clientHeight))
  );
}

app.validationObject = (value) => {
  let tmp = {}
  for (let key in value) {
    let keys = key.split('.')
    if(keys.length > 1) {
      if(!tmp[keys[0]]) {
        tmp[keys[0]] = {}
      }
      tmp[keys[0]][keys.pop()] = value[key]
    } else {
      tmp[key] = value[key]
    }
  }
  return tmp

  // from {
  //   'product.0.id' : 'required',
  //   'user.id' : 'required',
  //   'price' : 'required',
  // }

  // to {
  //   product : [
  //     { id : 'required'}
  //   ],
  //   user : {
  //     id : 'required'
  //   },
  //   price : 'required'
  // }
}

export default app
