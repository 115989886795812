<template>
  <div>
    <div class="main-dashboard container-fluid">
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="text-bold d-flex text-uppercase align-items-center">
          <van-icon name="chart-trending-o" size="30" class="mr-2"/>
          {{ $t('dashboard.dashboard') }}
        </h5>
        <div class="d-flex align-items-center">
          <div class="noti-container">
            <div v-if="notifications.length" class="noti-badge">{{ notifications.length }}</div>
            <div tabindex="0" class="bg-white py-2 px-3 stock-noti" @focusout="toggleNotification" @click="toggleNotification" >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.16992 7.44043L11.9999 12.5504L20.7699 7.4704" stroke="#292D32" stroke-width="1.63636"
                      stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.999 21.61V12.54" stroke="#292D32" stroke-width="1.63636" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path
                    d="M9.92963 2.48049L4.58963 5.45052C3.37963 6.12052 2.38965 7.8005 2.38965 9.1805V14.8305C2.38965 16.2105 3.37963 17.8905 4.58963 18.5605L9.92963 21.5305C11.0696 22.1605 12.9396 22.1605 14.0796 21.5305L19.4196 18.5605C20.6296 17.8905 21.6196 16.2105 21.6196 14.8305V9.1805C21.6196 7.8005 20.6296 6.12052 19.4196 5.45052L14.0796 2.48049C12.9296 1.84049 11.0696 1.84049 9.92963 2.48049Z"
                    stroke="#292D32" stroke-width="1.63636" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.9998 13.2401V9.58014L7.50977 4.1001" stroke="#292D32" stroke-width="1.63636"
                      stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <div v-if="showNotification">
                <Notification top="40px" :notifications="notifications"/>
              </div>
            </div>

          </div>

          <MyDateRangePicker :params="params" @select="selectDate"/>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg-8 mb-4">
          <IncomeChart :start_date="params.start_date" :end_date="params.end_date"/>
        </div>
        <div class="col-lg-4">
          <div class="row">
            <div class="col-12 mb-4">
              <TotalCard :data="expenseData" :chartType="chartType" successTextColor=""/>
            </div>
            <div class="col-12 mb-4">
              <TotalCard :data="profitData" :chartType="chartType" successTextColor="text-success"/>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3 row">
        <div class="col-lg-4 mb-4">
          <IncomeExpenseCard :summary="summary"/>
        </div>

        <div v-if="$auth.user().user_type == 'admin' || $auth.user().user_type == 'sub_admin'" class="col-lg-4 mb-4">
          <AccountsCard/>
        </div>

        <div class="col-lg-4 mb-4">
          <SummaryCard :summary="summary"/>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg-6 col-md-12 mb-4">
          <TopItemCard :products="topProducts"/>
        </div>
        <div class="col-lg-6 col-md-12">
          <TopCustomerCard :customers="topCustomers"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import TopItemCard from './components/TopItemCard'
import TopCustomerCard from './components/TopCustomerCard'
import IncomeChart from './components/IncomeChart'
import IncomeExpenseCard from './components/IncomeExpenseCard'
import TotalCard from './components/TotalCard'
import AccountsCard from './components/AccountsCard'
import SummaryCard from './components/SummaryCard'
import Notification from "@/pages/dashboard/components/Notification";

let start_date = moment().subtract(1, 'month').format('YYYY-MM-DD')
let end_date = moment().format('YYYY-MM-DD')

export default {
  components: {
    Notification,
    TopItemCard,
    TopCustomerCard,
    IncomeExpenseCard,
    IncomeChart,
    TotalCard,
    AccountsCard,
    SummaryCard,
  },

  data() {
    return {
      notifications: [],
      showNotification: false,
      chartType: 'area',
      topProducts: [],
      topCustomers: [],
      summary: {},
      params: {
        start_date,
        end_date,
      },
      expenseData: null,
      profitData: null,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getTopProducts()
      this.getTopCustomer()
      this.getSummary()
      this.getExpense()
      this.getProfit()
      this.getLowStockProducts()
    },

    selectDate(dates) {
      this.params.start_date = moment(dates.startDate).format('YYYY-MM-DD')
      this.params.end_date = moment(dates.endDate).format('YYYY-MM-DD')
      this.getExpense()
      this.getProfit()
    },

    changedChartType(value) {
      this.chartType = value
    },

    async getTopProducts() {
      let res = await axios.get('/dashboard/top_products', {params: this.params})
      this.topProducts = res.data.data
    },

    async getTopCustomer() {
      let res = await axios.get('/dashboard/top_customers', {params: this.params})
      this.topCustomers = res.data.data
    },

    async getSummary() {
      let res = await axios.get('/dashboard/summary', {params: this.params})
      this.summary = res.data.data
    },

    async getExpense() {
      let res = await axios.get('/dashboard/graph/expense', {params: this.params})
      this.expenseData = res.data.data
    },

    async getProfit() {
      let res = await axios.get('/dashboard/graph/profit', {params: this.params})
      this.profitData = res.data.data
      console.log(this.profitData)
    },
    toggleNotification(e) {
      if (e.type == 'focusout') {
        this.showNotification = false
      }else if (e.type == 'click') {
        this.showNotification = !this.showNotification;
      }
    },
    async getLowStockProducts() {
      let res = await axios.get('dashboard/low_stock')
      if (res.data)
      {
        this.notifications = res.data.data
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.main-dashboard {
  width: 100%;
  background-color: #f4f4f4;
}

.card {
  height: 100%;
  background-color: white;
  border-radius: 10px;
  border: none;
}

.overlay {
  position: fixed;
  z-index: 10;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.06);
}

.noti-container {
  position: relative;

  .noti-badge {
    background: red;
    position: absolute;
    padding: 0 8px;
    color: #fff;
    right: 3px;
    top: -8px;
    border-radius: 15px;
  }
  .stock-noti {
    cursor: pointer;
    border-radius: 10px;
    margin-right: 10px;
  }
}

</style>
