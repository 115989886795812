export default {
  images: [],
  name: null,
  category_id: null,
  barcode: null,
  sku: null,
  original_price: 0,
  retail_price: 0,
  instock: 0,
  enable_selling: true,
  n_stock: 0,
  apo_obs: 1,
  unit: null,
  units: [
    {
      unit: 'Blue Color',
      coefficient: 1,
      sale_price: 0,
      purchase_price: 0,
      description: null,
      is_default: 1,
      add_price: false,
      active: 0,
      uploader: [],
    },
  ],
  options: [
    {
      is_multiple: true,
      title: null,
      sort: 10,
      required: true,
      showSetting: false,
      product_option_items: [
        {
          title: null,
          sort: 1,
          sale_price: 0,
          purchase_price: 0,
          active: 0,
          auto_select: false,
        },
      ],
    },
  ],
}
