<template>
  <div>
    <div v-for="(report, index) in reports" :key="index">
      <h4>{{ reportData(report).name }}</h4>
      <table class="table my-border-table">
        <thead>
          <tr>
            <th width="80" class="text-center">Code</th>
            <th>Account</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="code in reportData(report).codes" :key="code.coa" @click="$refs.accountingDetail.open(code)">
            <td class="text-center text-danger">{{ code.coa }}</td>
            <td>{{ code.name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <AccountingDetail ref="accountingDetail"/>
  </div>
</template>

<script>
import AccountingDetail from './AccountingDetail.vue'
export default {
  components : { AccountingDetail },
  data() {
    return {
      reports : []
    }
  },
  methods : {
    async getData() {
      let res = await axios.get('/chart_of_accounts/group')
      if(res) {
        console.log(res.data.data)
        this.reports = res.data.data
      }
    },
    reportData(report) {
      return report[Object.keys(report)[0]]
    }
  },
  mounted() {
    this.getData()
  },
}
</script>

<style lang="scss" scoped>
  h4 {
    text-transform: capitalize;
    font-size : 14px;
    font-weight: 700;
    padding : 15px 20px 5px 20px;
  }
  .table.my-border-table {
    margin-bottom : 0;
    border-spacing: 0;
    thead {
      th {
        background-color: #F4F4F4;
        border-radius : 0;
        font-weight: 400;
        padding : 10px 20px;
        font-size : 12px;
      }
    };
    tbody {
      td {
        border-radius : 0;
        border: 0.5px solid #F4F4F4;
        border-left : 0 !important;
      }
    }
  }
</style>