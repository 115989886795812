<template>
  <div>
    <div class="text-center mb-5">
      <ImageUploader :value="uploader" @input="getImage"/>
      <br/>
      <small v-if="errors.image" class="text-danger mt-2">
        {{ errors.image }}
      </small>
    </div>

    <div class="mt-3 ml-3">
      <h2>{{ $t('mngmt.customer.personalInformation') }}</h2>
      <div class="row">
        <div class="col-lg-7">
          <Input
              :params="data"
              :errors="errors"
              field="name"
              :label="$t('mngmt.employee.employeeName')"
          />
        </div>
        <div class="col-lg-5">
          <Input
              :params="data"
              :errors="errors"
              field="employee_id"
              :label="$t('mngmt.employee.employeeId')"
          />
        </div>
        <div class="col-lg-7">
          <Input
              :params="data"
              :errors="errors"
              field="email"
              type="email"
              :label="$t('email')"
          />
        </div>
        <div class="col-lg-5">
          <Input
              :params="data"
              :errors="errors"
              field="tax_id"
              :label="$t('mngmt.customer.taxId')"
          />
        </div>
        <div class="col-lg-7 mb-3">
          <!--          <DatePicker v-model="data.wk_time_from" label="Work Time From"/>-->
          <label class="field-label">{{ $t('mngmt.employee.workTimeFrom') }}</label>
          <div class="timer-container">
            <Timepicker v-model="data.wk_time_from" type="minute" :timeStr="['Hour', 'Min', 'Sec']" placeholder="From"
                        btnStr="Apply"/>
          </div>
        </div>
        <div class="col-lg-5 mb-3">
          <!--          <DatePicker v-model="data.wk_time_to" label="Work Time To"/>-->
          <label class="field-label">{{ $t('mngmt.employee.workTimeTo') }}</label>
          <div class="timer-container">
            <Timepicker v-model="data.wk_time_to" type="minute" :timeStr="['Hour', 'Min', 'Sec']" placeholder="To"
                        btnStr="Apply"/>
          </div>
        </div>
        <div class="col-lg-4">
          <Input
              :params="data"
              :errors="errors"
              type="number"
              field="social_sec_fund"
              :label="$t('mngmt.employee.socialSecFund')"
          />
        </div>
        <div class="col-lg-4">
          <Input
              :params="data"
              :errors="errors"
              type="number"
              field="wht"
              :label="$t('withholdingTax')"
              :min="0"
              :max="70"
          />
        </div>
        <div class="col-lg-4">
          <Input
              :params="data"
              :errors="errors"
              field="base_salary"
              type="number"
              :label="$t('mngmt.employee.baseSalary')"
          />
        </div>
        <div class="col-lg-6">
          <Input
              :params="data"
              :errors="errors"
              field="phone"
              :label="$t('phoneNumber')"
          />
        </div>
        <div class="col-lg-6">
          <Input
              :params="data"
              :errors="errors"
              field="social_sec_number"
              :label="$t('mngmt.employee.socialSecNumber')"
          />
        </div>
        <div class="col-12">
          <Input
              :params="data"
              :errors="errors"
              field="address"
              :label="$t('mngmt.employee.address')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import DatePicker from './DatePicker'
import '@livelybone/vue-datepicker/lib/css/index.css';
import {Timepicker} from '@livelybone/vue-datepicker';

export default {
  components: {Timepicker},
  props: {
    data: {
      type: Object,
      required: true,
    },
    isNew: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: Object,
    },
  },
  data() {
    return {
      uploader: this.isNew ? [] : [{url: this.data.image}],
      locale: {
        format: 'mm/dd/yyyy',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
    }
  },
  watch: {
    data() {
      this.uploader = this.isNew ? [] : [{url: this.data.image}]
    }
  },
  beforeMount() {
    console.error = () => {
    } //to dismiss time picker package error logs
  },
  methods: {
    getImage(value) {
      this.uploader = value
      if (this.uploader[0]) {
        this.data.image = this.uploader[0].file
      }
    },
  },
}
</script>

<style lang="scss">
.field-label {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  text-transform: capitalize;
}

.timepicker {
  .input-wrapper {
    border-radius: 8px;
    border: 2px solid #f4f4f4;
    font-size: 13px;
    color: #000;
    padding: 10px 15px;
  }

  .input-wrapper.focus {
    border: 1px solid var(--theme);
  }

  .btn {
    background-color: var(--theme) !important;
    color: #fff !important;
    height: auto !important;
    padding: 5px 8px !important;
    border-radius: 7px;
    margin-right: 40px !important;
  }

  .picker {
    .picker-items {
      .item.selected::before {
        background-color: var(--theme) !important;
        border-color: var(--theme) !important;
      }

      .item:hover {
        opacity: 0.8;
      }
    }
  }
}

</style>