<template>
  <div class="company-profile-one pt-3 pb-5">
    <div class="profile-one-box p-4">
      <p>Step 1 of 2</p>

      <h4 class="mt-1">Please Fill-in Your Business Profile</h4>
      <p>This will be used as your default business profile. You can always change this later.</p>

      <div class="business-name mt-4">
        <label class="label">
          Company / Store Name
          <span class="text-danger">{{ error.name }}</span>
        </label>
        <input v-model="params.name" placeholder="Your Store" type="text" />
      </div>

      <div class="company-address">
        <label class="label">
          Company / Store Address <span class="text-danger"> {{ error.address }}</span>
        </label>
        <input v-model="params.address" placeholder="Myitkyina" type="text" />
      </div>

      <!-- <div class="subdomain-name">
        <label class="label">
          Subdomain Name <span class="text-danger">{{ error.domain }}</span>
        </label>
        <input v-model="params.domain" placeholder="your-store" type="text" />
      </div>

      <p v-if="params.domain" class="font-weight-bold text-left subdomain-text">{{ params.domain }}.manawstore.com</p> -->

      <!-- <div v-if="error.not_available" class="alert info-check alert-warning show" user_type="alert">
        <strong>
          {{ error.not_available }}
        </strong>
      </div> -->

      <div class="company-address" @click="setShowCurrencyPicker(true)">
        <label class="label">
          Currency <span class="text-danger">{{ error.currency }}</span>
        </label>
        <input v-model="params.currency" placeholder="USD" disabled type="text" />
      </div>

      <div class="company-address" @click="setShowCategoryPicker(true)">
        <label class="label">
          Store Category
          <span class="text-danger">{{ error.company_category_id }}</span>
        </label>
        <input v-model="params.category.name" placeholder="Select One" disabled type="text" />
      </div>

      <div class="buttons-group mx-auto d-flex justify-content-between">
        <div></div>
        <button class="next" @click="nextStep">Next</button>
      </div>
    </div>

    <!-- currency picker -->
    <van-popup v-model="showCurrencyPicker" class="currency-picker" :style="{ width: '450px' }">
      <div>
        <div class="label">Select Currency</div>
        <input placeholder="Search" type="text" class="search-currency" @keyup="searchCurrency" />
        <div class="list">
          <ul>
            <li v-for="(currency, index) in options" :key="index" @click="setCurrency(currency)">
              <b> {{ currency.code }}</b>
              &nbsp;({{ currency.currency }})
              <span class="float-right"> <van-icon name="circle" size="17" /> </span>
            </li>
          </ul>
        </div>
      </div>
    </van-popup>
    <!-- currency picker -->

    <!-- currency picker -->
    <van-popup v-model="showCategoryPicker" class="currency-picker" :style="{ width: '400px' }">
      <div>
        <div class="label">Select Store Category</div>
        <div class="list">
          <ul>
            <li v-for="(category, index) in categories" :key="index" @click="setCompanyCategory(category)">
              {{ category.name }}
            </li>
          </ul>
        </div>
      </div>
    </van-popup>
    <!-- currency picker -->
  </div>
</template>

<script>
import cc from 'currency-codes/data'
import vSelect from 'vue-select'
import { Popup, Toast } from 'vant'

let options = cc.filter((c) => c.code !== 'XXX')

export default {
  props: ['params'],
  components: {
    vSelect,
    Popup,
  },
  data() {
    return {
      error: {},
      originalCurrencies: options,
      options: options,
      showCurrencyPicker: false,
      showCategoryPicker: false,
      categories: [],
    }
  },
  mounted() {
    this.getCompanyCategories()
  },
  methods: {
    async getCompanyCategories() {
      let res = await axios.get('/company_categories')
      this.categories = res.data.data
    },

    async nextStep() {
      this.validate()
      // await this.checkDomainAvailable()
      this.$emit('step', 'two')
    },

    setShowCurrencyPicker(value) {
      this.showCurrencyPicker = value
    },

    setShowCategoryPicker(value) {
      this.showCategoryPicker = value
    },

    setCompanyCategory(category) {
      this.params.category = category
      this.params.company_category_id = category.id
      this.showCategoryPicker = false
    },

    setCurrency(currency) {
      this.params.currency = currency.code
      this.showCurrencyPicker = false
    },

    searchCurrency(event) {
      let keyword = event.target.value
      if (keyword) {
        keyword = keyword.toLowerCase()
        this.options = this.originalCurrencies.filter((c) => {
          return c.code.toLowerCase().includes(keyword) || c.currency.toLowerCase().includes(keyword)
        })
      }
    },

    validate() {
      this.error = {}
      if (!this.params.name) {
        this.error.name = 'Required'
      }
      if (!this.params.address) {
        this.error.address = 'Required'
      }
      // if (!this.params.domain) {
      //   this.error.domain = 'Required'
      // }
      // if (this.params.domain.length < 5) {
      //   this.error.domain = 'Domain must be at least 5 characters'
      // }
      if (!this.params.currency) {
        this.error.currency = 'Required'
      }
      if (Object.keys(this.error).length != 0) {
        throw 'Validation error'
      }
    },

    async checkDomainAvailable() {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        duration: 0,
      })
      let res = await axios.post('/check/domain', {
        subdomain: this.params.domain,
      })
      Toast.clear()
      if (res.data.code == 422) {
        let fullSubdomain = res.data.error
        if (!res.data.success) {
          this.error = {}
          this.error.not_available = `${fullSubdomain} is not available. Already taken by somebody. Please try other.`
          throw 'domain error'
        }
      }
    },
  },
}
</script>

<style lang="scss">
.currency-picker {
  border-radius: 10px;
  border: 2px solid #efefef;
  padding: 10px 20px;
  .list {
    height: 300px;
    overflow: auto;
  }

  li {
    margin: 8px 0px;
    border: 2px solid #efefef;
    padding: 5px 10px;
    border-radius: 7px;
    cursor: pointer;
  }

  li:hover {
    background: #efefef;
  }

  input {
    width: 100%;
    border: 2px solid #efefef;
    padding: 5px 10px;
    border-radius: 8px;
    height: 40px;
    margin-top: 10px;
  }
}
.company-profile-one {
  background: #e4e4e4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.label {
  color: #9d9d9d;
}
.profile-one-box {
  width: 450px;
  height: auto;
  background: #ffffff;
  border-radius: 10px;
  margin: 40px;
  text-align: center;
  padding: 20px 0;
}
.profile-one-box h4 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
}
.profile-one-box h5 {
  width: 90%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  margin: 0 auto 20px auto;
}
.subdomain-text {
  width: 90%;
  margin-top: 5px;
  margin: auto;
  padding: 0px 14px;
}
.business-name,
.company-address,
.subdomain-name,
.currency {
  width: 90%;
  border: 2px solid #efefef;
  padding: 5px 10px;
  margin: 8px auto;
  border-radius: 10px;
  height: 60px;
}
.profile-one-box label {
  width: 100%;
  text-align: left;
  margin: 0;
  margin-left: 3px;
}
.profile-one-box input {
  width: 100%;
  border: none;
  min-height: 31px;
  background: white !important;
  font-size: 15px;
  color: black !important;
}
.buttons-group {
  margin-top: 20px;
  width: 90%;
}
.buttons-group .back {
  background: #ffffff;
  border: none;
}
.buttons-group .back img {
  width: 20px;
  height: 20px;
  margin-left: -4px;
}
.buttons-group .next {
  background: var(--theme);
  border-radius: 9px;
  color: #ffffff;
  width: 80px;
  height: 40px;
  border: none;
}
.info-check {
  border-radius: 8px;
  border-radius: 8px;
  margin: 0px 20px;
  text-align: left;
}
.next:hover {
  background: white;
  border: 2px solid var(--theme);
  color: var(--theme);
  font-weight: bold;
}
.back-text:hover {
  text-decoration: underline;
}
@media (max-width: 375px) {
  .profile-one-box {
    width: 340px;
    height: 450px;
  }
}
</style>
