<template>
  <div>
    <div class="mb-4 settings-container container-fluid">
      <LeftNavBar/>
      <div class="right-container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import LeftNavBar from './components/LeftNavBar'

export default {
  components: {LeftNavBar},
}
</script>

<style scoped>
.settings-container {
  height: auto;
  align-items: flex-start;
  display: flex;
  width: 100%;
}

.right-container {
  width: 100%;
  padding: 20px 25px 30px 25px;
  background: white;
  border-radius: 10px;
  min-height: 70vh;
  position: relative;
}

.right-container /deep/ .add-button {
  border-radius: 10px;
  font-size: 13px;
  height: 40px;
  border: 2px solid #f4f4f4;
}

.right-container /deep/ h1 {
  font-size: 15px;
  font-weight: 700;
  color: #000;
}

.right-container /deep/ h2 {
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.right-container /deep/ .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.right-container /deep/ .edit-btn {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  gap: 5px;
  margin-right: 15px;
  margin-top: 5px;
  cursor: pointer;
  font-size: 12px;
  color: #000;
}

.right-container /deep/ .edit-icon {
  background-image: url('/image/settings/edit.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  display: block;
}
</style>
