<template>
  <div class="box">
    <!-- cover image -->
    <div class="main_img text-center">
      <div v-for="(image, imageIndex) in product.images" :key="imageIndex">
        <img v-show="visibleImage === imageIndex" :src="image" alt="" />
      </div>
    </div>

    <div class="px-2">
      <div class="line"></div>
      <!-- product info -->
      <div class="box-content d-flex flex-column">
        <h5 class="mb-0">{{ product.name }}</h5>
        <h5 class="mt-1 product-name text-muted">{{ $currency }} {{ $cast(product.price) }}</h5>
      </div>

      <!-- small images -->
      <div class="item_detail">
        <div v-for="(image, index) in product.images" :key="index">
          <img :src="image" alt="" class="small-images" @click="changeImg(index)" />
        </div>
      </div>

      <div class="main_button">
        <van-stepper v-model="quantity" min="1" :max="product.instock"/>
        <van-button class="add-to-pay-button" @click="showProductModal(); $events.fire('nextStep')" pos-tooltip-step="24">
          <van-icon name="cart-o" size="18" />
          +
        </van-button>
      </div>
    </div>

    <van-popup v-model="showModalPopup" class="product-modal">
      <product-modal v-if="selectedProduct" :product="selectedProduct" :quantity="quantity" @close="closeModal()" />
    </van-popup>
  </div>
</template>

<script>
import ProductModal from './ProductModal'

export default {
  props: ['product'],
  components: {
    ProductModal,
  },
  data() {
    return {
      visibleImage: 0,
      quantity: 1,
      selectedProduct: null,
      showModalPopup: false,
    }
  },
  computed: {
    subitemLen() {
      return this.product.images.length
    },
  },
  methods: {
    closeModal() {
      this.showModalPopup = false
      this.selectedProduct = null
    },

    showProductModal() {
      this.selectedProduct = JSON.parse(JSON.stringify(this.product))
      if (this.product.type !== 'group' && !this.product.has_units && !this.product.options?.length) {
        this.addToCart()
      } else {
        this.showModalPopup = true
      }
    },
    addToCart() {
      console.log('selected prod to cart : ', this.selectedProduct)
      let product = {
        ...this.selectedProduct,
        quantity: this.quantity,
      }
      this.$store.commit('addItem', JSON.parse(JSON.stringify(product)))
    },
    changeImg(index) {
      this.visibleImage = index
    },

    next() {
      if (this.visibleImage >= this.subitemLen - 1) {
        this.visibleImage = 0
      } else {
        this.visibleImage += 1
      }
    },
    prev() {
      if (this.visibleImage <= 0) {
        this.visibleImage = this.subitemLen - 1
      } else {
        this.visibleImage -= 1
      }
    },
  },
}
</script>

<style scoped>
.box {
  padding: 0 10px 10px 10px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #fff;
  padding: 0;
  margin: 0;
}

.product-name {
  width: 100%;
  height: 28px;
  overflow: hidden;
}

.box .main_img img {
  height: 110px;
  width: 100%;
  object-fit: contain;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.main_img {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.main_img .prev {
  position: absolute;
  top: 50%;
  left: 5%;
  font-size: 15px;
  cursor: pointer;
}

.main_img .next {
  position: absolute;
  top: 50%;
  left: 90%;
  font-size: 15px;
  cursor: pointer;
}

.dot {
  height: 5px;
  width: 5px;
  margin: 0 5px;
  border-radius: 50%;
  display: inline-block;
  background-color: #e4e4e4;
  cursor: pointer;
}

.active {
  background-color: #000000;
}

.box-content {
  padding: 0 5px;
}

.box-content h5 {
  font-size: 11px;
  font-weight: 600;
}

.item_detail {
  display: inline-flex;
  padding: 3px 0 0 5px;
}

.item_detail div {
  border-radius: 5px;
  margin-right: 4px;
}

.item_detail img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.main_button {
  width: 100%;
  padding: 7px 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_button button {
  height: 30px;
  padding: 5px 10px;
  border-radius: 10px;
  color: white;
  background: var(--theme);
  font-size: 11px;
  outline: none;
  border: none;
}

.add-to-pay-button:active {
  box-shadow: 0 0 0 2px var(--theme);
  color: var(--theme);
  background: white;
  border: 1px solid var(--theme);
}

.main_button button:hover {
  box-shadow: none;
  outline: none;
  background: var(--theme);
  color: white;
}

.main_button button:active {
  box-shadow: none;
  outline: none;
  background: var(--theme);
  color: white;
  opacity: 0.5;
}

.small-images {
  border-radius: 4px;
  object-fit: cover;
}

.product-modal {
  border-radius: 20px;
  padding: 20px 25px;
}
.line {
  border: none;
  border-bottom: 1px solid whitesmoke;
  margin: 0 5px;
  margin-bottom: 10px;
}
</style>
