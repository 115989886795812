<template>
  <div>
    <label class="label">
      {{ label }}
      <small v-if="errors[field]" class="text-danger ml-1">
        {{ errors[field] }}
      </small>
    </label>
    <vSelect
        :clearable="false"
        :options="options"
        :reduce="(option) => option.key"
        :value="value"
        label="title"
        @input="pushData"
    >
      <template #selected-option="{ title }">
        <div :class="['data', `text-${align}`]">{{ title }}</div>
      </template>
      <template #option="{ title }">
        <div :class="['data', `text-${align}`]">{{ title }}</div>
      </template>
      <template #open-indicator="{ attributes }">
      <span v-bind="attributes">
        <svg fill="none" height="14" viewBox="0 0 19 18" width="15" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M15.7704 6.64453L10.6088 11.485C9.99921 12.0567 9.00171 12.0567 8.39213 11.485L3.23047 6.64453"
              stroke="rgba(0,0,0,0.2)"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="10"
              stroke-width="2"
          />
        </svg>
      </span>
      </template>
    </vSelect>
  </div>

</template>

<script>
import vSelect from 'vue-select'

export default {
  components: { vSelect },
  props: ['value', 'align', 'options', 'label', 'field', 'errors'],
  data() {
    return {
    }
  },
  methods: {
    pushData(e) {
      this.$emit('input', e)
    },
  },
}
</script>

<style lang="scss" scoped>
.data {
  width: 100%;
}
</style>