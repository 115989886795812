<template>
  <div class="customer_main">
    <div class="title d-flex justify-content-items justify-content-between">
      <div>{{ $t('chooseCustomer') }}</div>
      <img @click="closeBox" src="/image/dashboard/choosecustomers/cross_sign.svg" alt="" class="cross-sign" />
    </div>

    <div class="mt-2">
      <custom-search width="100%" v-model="params.keyword" @search="fetchData()" />
    </div>
    <div class="customer-list-container">
      <table class="table my-border-table">
        <tr v-for="customer in customers" :key="customer.id" @click="pickCustomer(customer)">
          <td>
            <div class="d-flex align-items-center">
              <img :src="customer.image" class="cus-image" alt="" />
              <span class="text-bold d-flex flex-column">
                <span>{{ customer.name }}</span>
                <span class="text-muted">{{ customer.email && customer.email[0] ? customer.email[0] : '' }}</span>
              </span>
            </div>
          </td>
          <td class="text-right">
            <van-icon name="circle" size="20" />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      customers: {},
      suppliers: {},
      params: {
        page: 1,
        keyword: null,
        limit: 10,
      },
    }
  },
  methods: {
    closeBox() {
      this.$emit('closeBox')
    },
    pickCustomer(customer) {
      this.$emit('pick', customer)
      this.$emit('closeBox')
    },
    async fetchData() {
      try {
        const res = await axios.get('/customers', {
          params: this.params,
        })
        this.customers = res.data.data.customers
      } catch (error) {
        console.log(error)
      }
    },
  },
  created() {
    this.fetchData()
  },
}
</script>

<style scoped>
.cross-sign {
  cursor: pointer;
}
.customer-list-container {
  overflow: auto;
  max-height: 280px;
}
.customer_main {
  padding: 25px;
}
.title {
  font-size: 18px;
  font-weight: 700;
}
.title img {
  width: 20px;
  height: 20px;
}
.cus-image {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50px;
  margin-right: 10px;
}
</style>
