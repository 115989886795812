<template>
  <div class="accounts-card p-4">
    <p class="mb-0" style="font-size: 14px">{{ $t('dashboard.accountOverview') }}</p>
    <div class="scroll">
      <van-empty
        :description="$t('dashboard.noAccount')"
        image="/image/empty-lists/empty-bank-account.svg"
        v-if="accounts.length == 0"
      />

      <table class="table my-border-table">
        <tr v-for="(account, index) in accounts" :key="index">
          <td style="width: 10px">
            <div class="d-flex align-items-center">
              <img src="/image/settings/accounts/bank-account-logo.svg" class="mr-3" />
              <span>
                {{ account.owner_name }} <br />
                <span v-if="account.account_number">({{ account.account_number }})</span>
              </span>
            </div>
          </td>
          <td class="text-right">{{ $currency }} {{ $cast(account.current_balance) }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accounts: [],
    }
  },
  async mounted() {
    if (this.$auth.user().user_type === 'admin') {
      let res = await axios.get('/accounts', { params: { page: 1, limit: 10 } })
      this.accounts = res.data.data
    }
  },
}
</script>

<style lang="scss" scoped>
.accounts-card {
  height: 100%;
  background-color: white;
  border-radius: 10px;
  .scroll {
    height: 300px;
    overflow: auto;
  }
}
</style>