import store from '../@plugins/store'
import Vue from 'vue'

export default function auth(to, from, next) {
    window.callCompanyMiddleware = false
    axios.get('/auth/company').then((res) => {
        if (res.data.success) {
            window.$company = res.data.data
            setThemeColor(window.$company.color)
            Vue.prototype.$currency = window.$company.currencySymbol
            return next()
        } else {
            return next({path: '/company/registration'})
        }
    })
}

function setThemeColor(color) {
    var r = document.querySelector(':root')
    r.style.setProperty('--theme', color)
    console.log('hextorgba', hexToRgbA(color))
    r.style.setProperty('--theme-rgba', hexToRgbA(color))
    store.commit('setTheme', color)
}

function hexToRgbA(hex) {
    var c
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('')
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = '0x' + c.join('')
        return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')
    }
    throw new Error('Bad Hex')
}
