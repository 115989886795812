<template>
  <transition name="expand">
    <div v-if="show">
      <div  class="filter-card">
<!--        <h1 class="mb-4">Advance Filter</h1>-->
        <div class="w-480">
          <label class="label">Chart Of Account</label>
          <v-select 
            multiple 
            v-model="params.coas" 
            :options="coaOptions"
            label="label"
            class="filter-multi-select"
            placeholder="Select Coa"
            @input="(val) => $emit('select', val)"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <svg width="15" height="14" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.7704 6.64453L10.6088 11.485C9.99921 12.0567 9.00171 12.0567 8.39213 11.485L3.23047 6.64453"
                    stroke="rgba(0,0,0,0.2)"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </template>
          </v-select>
        </div>
<!--        <button @click="$emit('filter')" class="btn btn-primary filter-btn mt-4">Search</button>-->
      </div>
    </div>
  </transition>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components : { vSelect },
  props : ['params'],
  data() {
    return {
      show : false,
      coaOptions : [
      ],
      reqParams: {
        page: 1,
        limit: 10,
      }
    }
  },
  mounted() {
    this.getCOAs();
  },
  methods: {
    async getCOAs() {
      let res = await axios.get('chart_of_accounts', {params: this.reqParams})
      if (res.data.success ) {
        this.coaOptions.push(...res.data.data.data.map(data => ({label: `${data.coa} (${data.name})`, coa: data.coa})))
        if(res.data.data.can_load_more) {
          this.reqParams.page++
          this.getCOAs()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  h1 {
    font-size  :15px;
    font-weight: 700;
  }

  .w-480 {
    max-width : 480px;
  }

  .filter-card {
    background-color : #f4f4f4;
    border-radius : 10px;
    padding : 20px;
    margin : 10px 0 13px 0;
  }

  .expand-enter-active,
  .expand-leave-active {
    max-height: 400px;
    opacity: 1;
    transition: max-height 0.5s linear, opacity 0.5s;
  }

  .expand-enter, .expand-leave-to {
    max-height: 0;
    opacity : 0
  }

</style>

<style lang="scss">
  .filter-multi-select {
    .vs__selected-options {
      flex-direction: row;
      align-items: center !important;
      gap : 5px;
    }
    .vs__selected {
      width : auto;
      background-color : #f0f0f0f0;
      border-radius: 10px;
      padding: 4px 10px;
      font-size : 11px;
      height: auto;
      border-color : #f4f4f4f4;
    }
  }
</style>