<template>
  <!-- ==== appearance container ==== -->
  <div class="bg-white" id="appearance">
    <div class="info mt-3 mb-4">{{$t('setting.appear.info')}}</div>

    <!-- ==== preview mini card ==== -->
    <PreviewSvg :color="color" />

    <h2 class="mt-4">{{$t('setting.appear.chooseColor')}}</h2>
    <div class="d-flex">
      <verte v-model="color" display="widget" id="just-recent-color" model="hex" :colorHistory.sync="list"></verte>
      <ColorPicker @color="getColor" />
    </div>
  </div>
  <!-- ==== end appearance container ==== -->
</template>

<script>
export default {
  data() {
    return {
      color: this.$store.state.theme,
      list: [
        'hsl(260, 93%, 71%)',
        'hsl(171, 66%, 61%)',
        'hsl(43, 100%, 83%)',
        'hsl(213, 95%, 75%)',
        'hsl(301, 75%, 75%)',
        'hsl(240, 14%, 14%)',
      ],
    }
  },
  methods: {
    getColor(value) {
      this.color = value
    },
  },
  watch: {
    color: function () {
      document.documentElement.style.setProperty('--theme', this.color)
      this.$store.commit('setTheme', this.color)
      axios.post('company/setting/color', { color: this.color })
    },
    list: function () {
      this.color = value
    },
  },
}
</script>

<style scoped>
.info {
  font-size: 13px;
  color: #000;
}
.data-containers {
  border-radius: 10px;
  border: 2px solid #f4f4f4;
  height: 45px;
  width: 100%;
}
</style>
