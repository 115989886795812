import NoneAuthRoutes from './routes_none_auth'
import companyMiddleware from './@middleware/companyMiddleware'
import isElectron from 'is-electron'

let routes = [
  {
    path: '/',
    component: isElectron() ? render('auth/Login') : render('Home'),
  },
  {
    path: '/terms-and-conditions',
    component: render('home/Terms'),
  },
  {
    path: '/login',
    component: render('auth/Login'),
    meta: { auth: false },
  },
  {
    path: '/register',
    component: render('auth/Register'),
    meta: { auth: false },
  },
  {
    path: '/redirect_login/:id/:firebaseId/:token',
    component: render('auth/RedirectLogin'),
    meta: { auth: false },
  },
  {
    path: '/redirect-main-login',
    component: render('auth/RedirectMainLogin'),
  },
  {
    path: '',
    component: render('MainApp'),
    children: NoneAuthRoutes,
    beforeEnter: companyMiddleware,
    meta: { auth: true, requireCompany: true },
  },
  {
    path: '/company/registration',
    component: render('company-register/RegistrationSteps'),
    meta: { auth: true },
  },
  {
    path: '/pricing',
    component: render('pricing/PricingPage'),
    meta: {},
  },
  {
    path: '/choose_payment_method',
    component: render('pricing/PaymentMethod'),
    meta: { auth: true },
  },
  {
    path: '/support',
    component: render('support/Support'),
  },
  {
    path: '/payment/:status/:invoiceNumber',
    component: render('payment/Index'),
  },
  {
    path: '/content/:lang/:blogId',
    component: render('blog/BlogDetail'),
  },
  {
    path: '/404',
    component: isElectron() ? render('Home') : render('ErrorPage'),
  },
  {
    path: '/*',
    redirect: isElectron() ? '/' : '/404',
  },
]

function render(template) {
  return require(`@/pages/${template}.vue`).default
}

export default routes
