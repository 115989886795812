<template>
  <div :class="{ 'not-allowed': !$route.params.id }">
    <div :class="{ disabled: !$route.params.id }">
      <label class="label">
        {{ $t('mngmt.product.variations') }}
        <span v-if="!$route.params.id">( {{ $t('mngmt.product.variationsWarningInfo') }})</span>
      </label>
      <div class="units">
        <div class="unit">
          <div>
            <table style="width: 100%">
              <tr>
                <th class="text-center" width="46">
                  <div @click="addUnit()" class="icon">+</div>
                </th>
                <th>{{ $t('mngmt.product.unitTitle') }}</th>
                <th class="text-center">{{ $t('mngmt.product.sellPrice') }}</th>
                <th class="text-center">{{ $t('mngmt.product.buyPrice') }}</th>
                <th class="text-center">{{ $t('mngmt.product.active') }}</th>
                <!-- <th class="text-center">{{ $t('mngmt.product.addPrice') }}</th> -->
                <th class="text-center" width="110">{{ $t('mngmt.product.actions') }}</th>
              </tr>
              <tr v-for="(unit, index) in params.units" :key="index">
                <td class="text-center">{{ index + 1 }}.</td>
                <td>
                  <div class="d-flex align-items-center">
                    <img v-if="unit.image" :src="unit.image" class="unit-image" />
                    <img v-else :src="params.images[0]" class="unit-image" />
                    {{ unit.unit }}
                  </div>
                </td>
                <td class="text-center">{{ unit.sale_price }}</td>
                <td class="text-center">{{ unit.purchase_price }}</td>
                <td>
                  <van-checkbox
                    class="justify-content-center"
                    :value="unit.active == 1 ? true : false"
                    @input="changeStatus($event, index)"
                    shape="square"
                  ></van-checkbox>
                </td>
                <!-- <td>
                  <van-checkbox
                    class="justify-content-center"
                    :value="unit.add_price ? true : false"
                    @input="toggleAddPrice($event, index)"
                    shape="square"
                  ></van-checkbox>
                </td> -->
                <td class="text-center">
                  <div class="d-flex justify-content-center">
                    <span @click="editUnit(index)" class="pointer">
                      <svg width="15" height="14" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M-0.0638001 8.49702C-1.70428 10.035 -2.73776 12.0505 -2.99435 14.2122L-2.99529 14.219C-3.04841 14.6666 -2.64498 15.0451 -2.16749 14.9956C0.141235 14.7566 2.29424 13.7874 3.93672 12.2476L6.29291 10.0386L2.29239 6.28804L-0.0638001 8.49702ZM12.1715 0.776731C11.0668 -0.25891 9.27564 -0.25891 8.17099 0.776731L7.491 1.41423L6.34853 0.343432C5.86042 -0.114184 5.06888 -0.114184 4.58108 0.343432L1.32741 3.39381C1.13211 3.57692 1.13211 3.87369 1.32741 4.0568L2.03427 4.71949C2.22958 4.9026 2.54613 4.9026 2.74144 4.71949L5.46481 2.16628L6.07698 2.7402L2.99956 5.62506L7.00008 9.37563L12.1715 4.52731C13.2762 3.49166 13.2762 1.81267 12.1715 0.776731Z"
                          fill="var(--theme)"
                        />
                      </svg>
                    </span>
                    <span @click="deleteUnit(index)" class="ml-4 pointer">
                      <svg width="14" height="15" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9.64286 0.750004H6.96429L6.75446 0.311723C6.71002 0.218023 6.64155 0.139205 6.55677 0.084135C6.47198 0.0290653 6.37425 -7.09679e-05 6.27455 4.10923e-06H3.72321C3.62375 -0.000397388 3.52618 0.0286302 3.4417 0.0837612C3.35721 0.138892 3.28923 0.217897 3.24554 0.311723L3.03571 0.750004H0.357143C0.262423 0.750004 0.171582 0.789513 0.104605 0.859839C0.0376274 0.930165 0 1.02555 0 1.125L0 1.875C0 1.97446 0.0376274 2.06984 0.104605 2.14017C0.171582 2.21049 0.262423 2.25 0.357143 2.25H9.64286C9.73758 2.25 9.82842 2.21049 9.8954 2.14017C9.96237 2.06984 10 1.97446 10 1.875V1.125C10 1.02555 9.96237 0.930165 9.8954 0.859839C9.82842 0.789513 9.73758 0.750004 9.64286 0.750004V0.750004ZM1.1875 10.9453C1.20453 11.2309 1.32459 11.499 1.52323 11.6949C1.72186 11.8909 1.98415 12 2.2567 12H7.7433C8.01585 12 8.27814 11.8909 8.47677 11.6949C8.67541 11.499 8.79547 11.2309 8.8125 10.9453L9.28571 3H0.714286L1.1875 10.9453Z"
                          fill="#FF3333"
                        />
                      </svg>
                    </span>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <UnitFormModel v-model="showUnitForm" :unit="unit" :units="params.units" :editIndex="editIndex" />
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import UnitFormModel from './UnitFormModal'
export default {
  props: ['params', 'errors'],
  components: { UnitFormModel },
  data() {
    return {
      showUnitForm: false,
      unit: {},
      editIndex: null,
    }
  },
  methods: {
    addUnit() {
      if (this.params.units.length == 15) {
        this.$toast.fail('Max 15')
        return false
      }
      this.editIndex = null
      this.unit = {
        product_id: this.params.id,
        company_id: this.params.company_id,
        image: null,
        unit: null,
        coefficient: 1,
        add_price: false,
        sale_price: null,
        purchase_price: null,
        active: true,
        uploader: [],
      }
      this.showUnitForm = true
    },
    async editUnit(index) {
      let unit = this.params.units[index]
      this.editIndex = index
      this.unit = { ...unit }
      this.unit.uploader =this.unit.image ? [{ url: this.unit.image }] : []
      this.unit.active = this.unit.active == 1 ? true : false
      this.showUnitForm = true
    },
    async changeStatus(e, index) {
      let unit = this.params.units[index]
      this.params.units[index].active = e
      await axios.put(`/products/${unit.product_id}/product_units/${unit.id}/active`)
    },
    async toggleAddPrice(e, index) {
      let unit = this.params.units[index]
      this.params.units[index].add_price = e
      await axios.put(`/products/${unit.product_id}/product_units/${unit.id}/toggle_add_price`)
    },
    async deleteUnit(index) {
      await Dialog.confirm({
        title: this.$t('action.areYouSure'),
        cancelButtonText: this.$t('action.cancel'),
        confirmButtonText: this.$t('action.del'),
      })
      let unit = this.params.units[index]
      let res = await axios.delete(`/products/${unit.product_id}/product_units/${unit.id}`)
      if (res.data.code == 200) {
        Toast.success(this.$t('state.deleted'))
        this.params.units.splice(index, 1)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
div.not-allowed {
  cursor: not-allowed;
  div.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}
.units {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .unit {
    .remove {
      cursor: pointer;
      position: absolute;
      right: -14px;
      margin-top: -20px;
    }
  }
  .unit-image {
    height: 33px;
    width: 33px;
    border-radius: 3px;
    object-fit: cover;
    flex-shrink: 0;
    margin-right: 1rem;
  }

  table {
    width: 100%;
    border-collapse: separate !important;
    border-spacing: 0;

    th:first-child {
      border-radius: 7px 0 0 0;
    }

    th:not(last-child):not(:first-child) {
      border-left: none;
    }

    th {
      border-bottom: none !important;
    }

    tr:not(:last-child) td {
      border-bottom: none !important;
    }

    td:not(last-child):not(:first-child) {
      border-left: none;
    }

    th:last-child {
      border-radius: 0 7px 0 0;
    }

    tr:last-child td:first-child {
      border-radius: 0 0 0 7px;
    }

    tr:last-child td:last-child {
      border-radius: 0 0 7px 0;
    }

    td,
    th {
      padding: 0.75rem;
      font-size: 13px;
      color: rgb(0, 0, 0);
      border: 2px solid #f4f4f4;
    }
    th {
      color: rgba(0, 0, 0, 0.5);
      font-weight: 700;
    }
    .icon {
      background: var(--theme);
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 23px;
      height: 23px;
      cursor: pointer;
      color: white;
      font-weight: 400;
      font-size: 30px;
    }
    .empty-input {
      border: none;
      outline: none;
      width: 100%;
    }
    .empty-input::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
    .empty-input.end::placeholder {
      text-align: end;
    }
    .empty-input.error::placeholder {
      color: var(--danger);
    }
  }
}
</style>