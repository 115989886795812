<template>
  <div class="bg-white">
    <div class="d-flex justify-content-between align-items-center">
      <div></div>
      <van-button @click="createKey()" class="add-button" type="default">
        <van-icon name="add-o" size="20" class="mr-2" />Create New Key
      </van-button>
    </div>

    <div v-if="loaded">
      <div v-if="lists.length > 0">
        <table class="table my-border-table mt-0">
          <tbody>
            <tr v-for="(list, index) in lists" :key="index">
              <td>
                <p class="mb-0 text-bold">{{ list.secret_key }}</p>
              </td>

              <td>
                <p class="mb-0 text-muted text-sm">Created By {{ list.user.email }}</p>
              </td>

              <td class="text-right">
                <van-popover
                  v-model="list.show_menu"
                  placement="bottom-end"
                  :close-on-click-outside="true"
                  trigger="click"
                  @open="menuOpen(index)"
                  :actions="actions"
                  @select="menuSelect($event, list.id)"
                >
                  <template #reference>
                    <van-icon name="ellipsis" size="20" class="pointer" />
                  </template>
                </van-popover>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <van-empty image="/image/empty-lists/empty-keys.svg" v-else description="No Api Keys" />
    </div>

    <div v-else class="loading">
      <van-loading type="spinner" />
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
export default {
  data() {
    return {
      actions: [{ text: 'Delete Key', icon: 'delete-o', value: 'delete' }],
      params: {
        keyword: '',
        page: 1,
        limit: 10,
      },
      lists: [],
      total: 0,
      loaded: false,
    }
  },
  methods: {
    changePage(page) {
      this.params.page = page
      this.fetchData()
    },
    menuOpen(index) {
      this.lists = this.lists.map((admin) => {
        admin.show_menu = false
        return admin
      })
      this.lists[index].show_menu = true
    },
    menuSelect(e, id) {
      switch (e.value) {
        case 'delete':
          this.deleteList(id)
          break
      }
    },
    async createKey() {
      await Dialog.confirm({
        title: this.$t('action.areYouSure'),
        cancelButtonText: this.$t('action.cancel'),
        confirmButtonText: this.$t('action.yes'),
      })
      let res = await axios.post(`api_keys`)
      if (res.data.success) {
        Toast.success(this.$t('state.success'))
        this.fetchData()
      } else {
        Dialog.alert({
          title: 'Error',
          message: res.data.error,
          confirmButtonText: 'OK',
        })
      }
    },
    async deleteList(id) {
      await Dialog.confirm({
        title: this.$t('action.areYouSure'),
        cancelButtonText: this.$t('action.cancel'),
        confirmButtonText: this.$t('action.del'),
      })
      let res = await axios.delete(`api_keys/${id}`)
      if (res) {
        Toast.success(this.$t('state.deleted'))
        this.fetchData()
      }
    },
    async fetchData() {
      let res = await axios.get('api_keys', { params: this.params })
      console.log(res.data)
      if (res) {
        console.log(res)
        this.lists = res.data.data.map((admin) => {
          admin.show_menu = false
          return admin
        })
        this.total = res.data.data.total
        this.loaded = true
      }
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>

<style lang="scss" scoped>
</style>